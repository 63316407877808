import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CertificateModel } from 'src/app/models/certificateModel';
import { environment } from 'src/environments/environment';
import { CertificateService } from 'src/app/services/api/certificate.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-certificate',
  templateUrl: './edit-certificate.component.html',
  styleUrls: ['./edit-certificate.component.css']
})

export class EditCertificateComponent implements OnInit {

  clientID = 0;
  editCertificateForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;
  model: CertificateModel;
  courses: any = ['WHMIS', 'H2S Alive', 'Chainsaw Safety', 'Confined Entry', 'Petroleum Safety Training', 'Standard First Aid'];
  id = '';
  collectionCertificateId = '';

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private certificateService: CertificateService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (!this.sqldb) {
        this.collectionCertificateId = this.id.toString();
      }
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;

      this.editCertificateForm = this.fb.group({
        course: ['', [Validators.required]],
        Institution: ['', [Validators.required]],
        start_date: ['', [Validators.required]],
        completion_date: ['', []],
        expiry_date: ['', [Validators.required]],
        description: ['', []],
      });

      if (this.sqldb) {

        this.certificateService.getCertificateById(newId).subscribe(x => {
          if (x) {

            this.model = x[0];
            this.editCertificateForm.patchValue({ course: this.model.course });
            this.editCertificateForm.patchValue({ Institution: this.model.institution });
            this.editCertificateForm.patchValue({ start_date: moment(this.model.start_date).format('DD/MMM/YYYY') });
            if (this.model.completion_date) { this.editCertificateForm.patchValue({ completion_date: moment(this.model.completion_date).format('DD/MMM/YYYY') }); }
            this.editCertificateForm.patchValue({ expiry_date: moment(this.model.expiry_date).format('DD/MMM/YYYY') });
            this.editCertificateForm.patchValue({ description: this.model.description });

            setTimeout(() => {
              $('select').formSelect();
              $('.modal').modal();
              $('.datepicker1').datepicker({
                format: 'dd/mmm/yyyy',
                defaultDate: new Date(),
                // setDefaultDate: new Date(),
                yearRange: 20,
                minDate: new Date(new Date().getFullYear() - 20, 0, 1),
                maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
                onSelect(e) {
                  //localStorage.setItem('selectedDate', e);
                },
              });

              $('.datepicker2').datepicker({
                format: 'dd/mmm/yyyy',
                defaultDate: new Date(),
                //setDefaultDate: new Date(),
                //defaultDate: new Date(this.model.completion_date.getFullYear(), this.model.completion_date.getMonth(), this.model.completion_date.getDate()),
                //setDefaultDate: new Date(this.model.completion_date.getFullYear(), this.model.completion_date.getMonth(), this.model.completion_date.getDate()),

                yearRange: 20,
                minDate: new Date(new Date().getFullYear() - 20, 0, 1),
                maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
                onSelect(e) {
                  // localStorage.setItem('selectedDate', e);
                },
              });

              const selectedDate = new Date();
              selectedDate.setDate(selectedDate.getDate() + 30);
              $('.datepicker3').datepicker({
                format: 'dd/mmm/yyyy',
                defaultDate: selectedDate,
                //setDefaultDate: selectedDate,
                //defaultDate: new Date(),
                //setDefaultDate: new Date(),

                yearRange: 20,
                minDate: new Date(new Date().getFullYear() - 20, 0, 1),
                maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
                onSelect(e) {
                  // localStorage.setItem('selectedDate', e);
                },
              });
              M.updateTextFields();
            }, 25);
          }
        });
      } else {
        /*
        this.certificateFirebaseService.getCertificateById(this.collectionCertificateId).valueChanges().subscribe(x => {
          if (x) {
            this.model = x;
            this.editCertificateForm.setValue({
              course: this.model.course,
              Institution: this.model.institution,
              start_date: this.model.start_date,
              completion_date: this.model.completion_date,
              expiry_date: this.model.expiry_date,
              description: this.model.description
            });

          }

        });
        */
      }
    }
  }

  get course() { return this.editCertificateForm.get('course'); }
  get Institution() { return this.editCertificateForm.get('Institution'); }
  get start_date() { return this.editCertificateForm.get('start_date'); }
  get completion_date() { return this.editCertificateForm.get('completion_date'); }
  get expiry_date() { return this.editCertificateForm.get('expiry_date'); }
  get description() { return this.editCertificateForm.get('description'); }

  startDateChange() {
    if (this.editCertificateForm) { this.editCertificateForm.patchValue({ start_date: $('#start_date').val() }); }
    setTimeout(() => {
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        //setDefaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          // localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  completedDateChange() {
    if (this.editCertificateForm) { this.editCertificateForm.patchValue({ completion_date: $('#completion_date').val() }); }
    setTimeout(() => {
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //localStorage.setItem('selectedDate', e);
        },
      });

    }, 25);
  }

  expiryDateChange() {
    if (this.editCertificateForm) { this.editCertificateForm.patchValue({ expiry_date: $('#expiry_date').val() }); }
    setTimeout(() => {
      const selectedDate = new Date();
      selectedDate.setDate(selectedDate.getDate() + 30);
      $('.datepicker3').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: selectedDate,
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //  localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  DeleteCertificate() {
    $('.modal').modal('close');
    if (this.sqldb) {
      this.certificateService.deleteCertificate(this.model).subscribe(resp => {
        //console.log(resp);
        toast({ html: 'Certificate Removed Successfully!', classes: 'green' });
        this.backClicked();
      });
    } else {
      /*
      this.certificateFirebaseService.DeleteCertificate(this.model.id).then(x => {
        toast({ html: 'Certificate Removed Successfully!', classes: 'green' });
        this.backClicked();
      });
      */
    }
  }

  submitForm(value: any) {
    this.model.course = value.course;
    this.model.institution = value.Institution;
    this.model.description = value.description;
    this.model.start_date = value.start_date;
    this.model.expiry_date = value.expiry_date;
    this.model.completion_date = value.completion_date;
    if (value.completion_date === '') {
      this.model.completion_date = null;
    }

    if (this.sqldb) {
      this.certificateService.updateCertificate(this.model).subscribe(x => {
        if (x) {
          // console.log("x: ", x);
          toast({ html: 'Certificate Successfully Updated!', classes: 'green' });
          this.location.back();
        }
      });
    } else {
      /*
      this.certificateFirebaseService.updateCertificate(this.model).then(x => {
        toast({ html: 'Certificate Successfully Updated!', classes: 'green' });
        this.location.back();
      });
      */
    }
  }

  backClicked() {
    if (localStorage.getItem('certificate_call_from') == 'case_plan') {
      this.router.navigate(['/member/member-main-home/profile/case-plan']);
    } else if (localStorage.getItem('certificate_call_from') == 'certificate') {
      this.router.navigate(['/member/member-main-home/profile/certificate']);
    } else { this.location.back(); }
  }

}
