<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined bold-500 p-r-1 "
                        (click)="backClicked()"> arrow_back </i>

                    <span *ngIf="sectionName"><span *ngIf="fromWhere == 'edit'">Edit</span><span
                            *ngIf="fromWhere == 'add'">Add</span> {{sectionName}}</span>
                    <span *ngIf="!sectionName"><span *ngIf="fromWhere == 'edit'">Edit</span><span
                            *ngIf="fromWhere == 'add'">Add</span> Section</span>
                </h4>
                <p class="bold-100 sub-title-section3">This is where you can add and manage your sections.</p>
            </div>
        </div>

        <form [formGroup]="sectionForm" (ngSubmit)="saveSection(sectionForm.value)">
            <div class="row col l12 s12 m12">

                <!----------------- Basic Information ------------------>
                <div class="col s12 m12 card-panel white">
                    <div class="input-field col s12">
                        <div class="label-input">Section Name<span class="red-text">*</span></div>
                        <p class="label-input bold-100">This is the section name that appears in the menu as well as the
                            titles.</p>
                        <input id="displayName" type="text" formControlName='displayName' class="validate"
                            autocomplete="off" placeholder="Name here..." />
                        <!-- <app-editor id="displayName" [title]=true [control]="displayName"
                            [showChatGpt]="false"></app-editor> -->
                        <div *ngIf="(displayName.dirty || displayName.touched) && displayName.invalid">
                            <small *ngIf="displayName.errors.required" class="red-text"> Section Name is required.
                            </small>
                        </div>
                    </div>

                    <div class="input-field col s12">
                        <div class="label-input">Section Description</div>
                        <p class="label-input bold-100">This is the section description that appears in the menu that
                            describes what the section does.</p>
                        <app-editor id="sectionDescription" [control]="sectionDescription"
                            [showChatGpt]="false"></app-editor>
                        <div
                            *ngIf="(sectionDescription.dirty || sectionDescription.touched) && sectionDescription.invalid">
                            <small *ngIf="sectionDescription.errors.required" class="red-text"> Section Description is
                                required.
                            </small>
                        </div>
                    </div>

                    <div class="input-field col s12">
                        <div class="label-input">Section Icon <span class="red-text">*</span></div>
                        <p class="label-input bold-100">This is the icon that appears in the menu for the section.</p>
                        <button type="button" class="btn-icon left modal-trigger" data-target="iconPickerModal"
                            [ngStyle]="{ 'background-color': themeColor}">
                            Select Icon
                        </button>
                        <div *ngIf="selectedIcon" class="icon-text rr-m-5 p-l-2 valign-wrapper">
                            <i class="material-symbols-outlined bold-300 p-r-1">
                                {{ selectedIcon }}
                            </i>
                            {{ selectedIcon }} |
                            <a class="p-l-3 red-text" (click)="deleteSelectedIcon()"> Delete </a>
                        </div>
                    </div>
                </div>

                <!----------------- Add Fields ------------------>
                <div class="row col l12 s12 m12 card-panel white">
                    <div formGroupName='options'>
                        <h4 class="bold-700 title-section3">
                            Check options that the section must include<span class="red-text">*</span>
                        </h4>
                        <p class="label-input">Check all the options that you want to be included while
                            creating the section.</p>

                        <div
                            *ngIf="(sectionForm.get('options').dirty || sectionForm.get('options').touched) && sectionForm.get('options').invalid">
                            <small *ngIf="sectionForm.get('options').errors.atLeastOneTrue" class="red-text"> At least
                                one field must be selected
                            </small>
                        </div>

                        <small class="red-text">

                        </small>
                        <label class="right rr-m-5">
                            <input type="checkbox" (click)="selectAll()" [checked]="allSelected" />
                            <span>Select All</span>
                        </label>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="title" />
                                <span>Title</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="description" />
                                <span>Description</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="imageUpload" />
                                <span>Image Upload</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="videoUpload" />
                                <span>Video Upload</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="youtubeLink" />
                                <span>Youtube Link</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" formControlName="pdfUpload" />
                                <span>PDF Upload</span>
                            </label>
                        </p>
                    </div>
                </div>

                <!----------------- Add To Admin ------------------>
                <div class="col s12 m12 card-panel white">
                    <!-- <h4 class="bold-700 title-section3">Add Menu on the Admin Side<span class="red-text">*</span></h4> -->
                    <div class="row">
                        <div class="col s12">
                            <h6 class="title-section3 bold-700">Where do you want to the add the Menu on the Admin Side?
                                <span class="red-text">*</span>
                            </h6>
                            <p class="sub-title-section2">Allows the section name to appear on the dashboard or as a
                                submenu.</p>
                        </div>

                        <div class="col s12">
                            <p class="col s6">
                                <label>
                                    <input class="with-gap" name="admin" type="radio"
                                        [checked]="addToAdmin.value == 'dashboard'" (click)="addToDashboard()" />
                                    <span>Dashboard</span>
                                </label>
                            </p>

                            <p class="col s6">
                                <label>
                                    <input class="with-gap" name="admin" type="radio"
                                        [checked]="addToAdmin.value != '' && addToAdmin.value != 'dashboard'"
                                        (click)="addAsSubmenu()" />
                                    <span>Submenu</span>
                                </label>
                            </p>

                            <!-- <p class="col s6">
                                <label>
                                    <input name="group1" type="radio" (click)="addToDashboard()" [checked]="!submenu" />
                                    <span>Dashboard</span>
                                    {{addToAdmin.value == 'dashboard'}}
                                </label>
                            </p> -->
                            <!-- <p class="col s6">
                                <label>
                                    <input name="group1" type="radio" (click)="addAsSubmenu()"
                                        [checked]="addToAdmin.value != '' && addToAdmin.value != 'dashboard'" />
                                    <span>Submenu</span>
                                </label>
                            </p> -->
                        </div>

                        <div class="col s12" *ngIf="submenu">
                            <h6 class="sub-title-section2 bold-700">Which section do you want to add the menu to?</h6>
                            <div class="input-field col s6" *ngIf="sections">
                                <select class="validate browser-default" formControlName="addToAdmin">
                                    <option *ngFor="let sec of sections" [value]="sec.name">{{sec.displayName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <!----------------- Add To Menu ------------------>
                <div class="col s12 m12 card-panel white">
                    <!-- <h4 class="bold-700 title-section3">Add Section to Menu<span class="red-text">*</span></h4> -->
                    <div class="row col s12 p-m-1">
                        <div class="left">
                            <h6 class="title-section3 bold-700">Would you like to add section to the menu?</h6>
                            <p class="sub-title-section2">Allows the section name to appear under the sidebar menu.</p>
                        </div>

                        <div class="switch col s6 m4 right">
                            <label class="right">
                                <input type="checkbox" formControlName="addToMenu">
                                <span class="lever"></span>
                                Yes
                            </label>
                        </div>
                    </div>

                    <!----------------- Add To Landing Page ------------------>
                    <div class="row col s12">
                        <div class="left">
                            <h6 class="title-section3 bold-700">Would you like to add section to the landing page?</h6>
                            <p class="sub-title-section2">Allows the section to appear in the landing page.</p>
                        </div>
                        <div class="switch col s6 m4 right">
                            <label class="right">
                                <input type="checkbox" formControlName="addToHomePage">
                                <span class="lever"></span>
                                Yes
                            </label>
                        </div>

                        <!-- section colors -->
                        <div class="row card-panel1" *ngIf="addToHome.value">
                            <div class="row">
                                <h5 class="bold-600 sub-title-section2 black-text col l8 s12">
                                    Color Setup
                                </h5>
                            </div>
                            <!-- =========== theme Color ============ -->
                            <div class="col l5 s12 card-container">
                                <div class="col s12">
                                    <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                    <span>
                                        The card located in the section
                                        will display the applied background color.
                                    </span>
                                </div>
                                <div class="col s12">
                                    <div class="color-type row">
                                        <p class="col s8">Choose Color</p>
                                        <input id="iconColor" formControlName="bgColor" type="color" class="col s4" />
                                    </div>
                                </div>
                            </div>

                            <!-- =========== bg Color ============ -->
                            <div class="col l4 s12 offset-l1 card-container">
                                <div class="col s12">
                                    <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                    <span>The Title located in the section will display the applied
                                        Section color.
                                    </span>
                                </div>
                                <div class="col s12">
                                    <div class="color-type row">
                                        <p class="col s8">Choose Color</p>
                                        <input id="iconColor" formControlName="displayNameColor" type="color"
                                            class="col s4" />
                                    </div>
                                </div>
                            </div>

                            <div class="col l8 s12">
                                <div class="col s12">
                                    <h4 class="sub-title-section2 bold-600">Would you like to upload an image ?</h4>
                                </div>

                                <div class="row col s12">
                                    <label class="col l4">
                                        <input name="group1" type="radio" (click)="bgImageYes()" [checked]="logoUrl" class="with-gap" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col l4">
                                        <input name="group1" type="radio" (click)="bgImageNo()" [checked]="!logoUrl" class="with-gap" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row card-panel1" *ngIf="bgImage">
                                <div class="card col s12 m6 l4" (change)="handlelogoInput($event.target.files)">
                                    <div class="center-align">
                                        <input type="file" accept="image/*"
                                            (change)="handlelogoInput($event.target.files)" />
                                        <i class="fa fa-cloud-upload p-t-2"></i>
                                        <h4 class="label-input p-b-2 bold-200">
                                            Drop & Drop to Upload file <br />
                                            or <br />
                                            <u class="bold-200">Click to Upload</u>
                                        </h4>
                                    </div>
                                </div>

                                <div class="col s12 m6 l6" *ngIf="logoUrl">
                                    <button class="btn red" type="button" (click)="deleteImage()">
                                        Delete Image
                                    </button>
                                    <img class="materialboxed responsive-img" [src]="logoUrl" width="200px"
                                        height="auto" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="title-nation col l6">
                                    <h6>Preview</h6>
                                </div>

                                <div class="col s12 m12 l12">
                                    <div class="col l4 s6 card-padding">
                                        <a class="card1"
                                            [ngStyle]="{ 'background-color': sectionForm.get('bgColor').value ? sectionForm.get('bgColor').value: defaultBgColor}">
                                            <i *ngIf="!logoUrl" class="material-symbols-outlined"
                                                [ngStyle]="{color: sectionForm.get('displayNameColor').value ?  sectionForm.get('displayNameColor').value: defaultSectionColor }">
                                                {{ sectionForm.get('icon').value ? sectionForm.get('icon').value:
                                                'insert_emoticon' }}</i>
                                            <img *ngIf="logoUrl" class="activator responsive-img center circle"
                                                [src]="logoUrl"
                                                style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block;" />
                                            <h3
                                                [ngStyle]="{ color: sectionForm.get('displayNameColor').value ? sectionForm.get('displayNameColor').value: defaultSectionColor }">
                                                {{ sectionForm.get('displayName').value ?
                                                sectionForm.get('displayName').value : 'Section Name' }}
                                            </h3>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!----------------- Add To Member ------------------>
                <div class="col s12 m12 card-panel white">
                    <!-- <h4 class="bold-700 title-section3">Add Section to Menu<span class="red-text">*</span></h4> -->
                    <div class="row col s12 p-m-1">
                        <div class="left">
                            <h6 class="title-section3 bold-700">Would you like to add section to the member side?</h6>
                            <p class="sub-title-section2">Allows the section name to appear on the member menu.</p>
                        </div>

                        <div class="switch col s6 m4 right">
                            <label class="right">
                                <input type="checkbox" formControlName="addToMember">
                                <span class="lever"></span>
                                Yes
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 l3 m3 p-m-3 right">
                        <button type="submit" class="col s12 btn save-button" [disabled]="!sectionForm.valid"
                            style="margin-bottom: 15px;"><span *ngIf="fromWhere == 'add'">Save</span><span
                                *ngIf="fromWhere == 'edit'">Update</span></button>
                    </div>
                    <div *ngIf="fromWhere == 'edit'" class="col s12 l3 m3 p-m-3 left">
                        <button type="button" class="col s12 btn delete-button" (click)="onDelete()"
                            style="margin-bottom: 15px;">Delete</button>
                    </div>
                </div>
            </div>
        </form>

        <div id="iconPickerModal" class="modal modal-fixed-footer" *ngIf="allIcons">
            <div class="modal-content">
                <div class="slider-modal-header">
                    <h4 class="col s11">Icon Selector</h4>
                    <span class="col s1 modal-close material-symbols-outlined" [ngStyle]="{ color: themeColor }"
                        style="cursor: pointer" type="button">close</span>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <input id="iconSearch" type="text" [(ngModel)]="searchQuery" placeholder="Search icons"
                            (input)="filterIcons()" />
                    </div>
                </div>

                <!-- Inside your modal content -->
                <div class="row">
                    <ul>
                        <li *ngFor="let icon of filteredIcons" (click)="selectIcon(icon)" class="col l6">
                            <label class="icon-container">
                                <input class="with-gap" name="group1" type="radio" value="yes" />
                                <span class="icon-text">
                                    <i class="material-symbols-outlined bold-300 p-r-1">{{ icon }}</i>
                                    {{ icon }}
                                </span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="modal-footer left">
                <button class="btn-small col s1 l1 save-button" (click)="saveSelectedIcon(selectedIcon); closeModal()"
                    [ngStyle]="{ 'background-color': themeColor }">
                    Save
                </button>

                <button class="btn-small col s1 l1 cancel-button modal-close" [ngStyle]="{ color: themeColor }"
                    (click)="closeModal()">
                    Cancel
                </button>
            </div>
        </div>

        <div *ngIf="openDeleteModal">
            <app-modals [modalType]="'delete'" [data]="modalData" (confirm)="confirmHandler($event)"
                (cancel)="cancelHandler($event)"></app-modals>
        </div>


        <!-- <div id="deleteModal" class="modal b-n-1">
            <i class="material-icons right modal-close col s1">close</i>
            <div class="modal-content">
                <div class="col s3">
                    <span class="material-symbols-outlined red-text" style="font-size: 70px;">
                        warning
                    </span>
                </div>
                <div class="col s9">
                    <h4 class="red-text">Delete Section </h4>
                    <h6>Are you sure you want to delete this Section? </h6>
                    <h6><strong>The section and its contents will be permanently deleted.</strong>
                    </h6>
                </div>
            </div>
            <div class="modal-footer row ">
                <a class="modal-close cancel-button btn col l5 s5">Cancel</a>
                <a class="modal-close continue-button btn col l5 s5 modal-trigger"
                    href="#comformdeleteModal">Continue</a>
            </div>
        </div>

        <div id="comformdeleteModal" class="modal b-n-1">
            <i class="material-icons right modal-close col s1">close</i>
            <div class="modal-content">

                <div class="col s12 center">
                    <h4 class="red-text">Delete Section? </h4>
                    <p>Are you really sure you want to delete this section?</p>
                </div>

                <div class="col s12 warning-red p-t-1">
                    <h4 class="red-text">Warning</h4>
                    <p style="font-size: 14px;">Please, be absolutely certain before proceeding, as
                        this action
                        this action cannot be reversed. </p>
                    <p class="bold-700">Confirm your decision to
                        proceed with the deletion.
                    </p>
                </div>
            </div>
            <div class="modal-footer row ">
                <a class="modal-close cancel-button btn col l5 s5">Cancel</a>
                <a (click)="deleteSection()" class="modal-close delete-button btn col l5 s5">Delete</a>
            </div>
        </div> -->

    </div>
</div>