<div class="row">
    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <div class="col s12 l10">
        <div class="body-h">
            <div class="header-setting b-m-2">
                <div class="col l12 m12 s12">
                    <h4 class="icon-text"><i class="material-symbols-outlined  p-r-1" (click)="backClicked()"> arrow_back </i>Survey / Poll Result</h4>
                </div>
            </div>

            <main class="StickyContent white ">
                <div class="row">
                    <div class="col s12 l12">
                        <div>
                            <app-survey-result></app-survey-result>
                        </div>
                        <!-- <div class="row col s12">
                            <button class="col s12 btn waves-effect" [ngClass]="'btn ' + buttonType"
                                [ngStyle]="{'background-color': themeColor}" (click)="backClicked()">
                                <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
                            </button>
                        </div> -->
                    </div>
                </div>
            </main>
        </div>
    </div>
    <div class="col l1"></div>
</div>