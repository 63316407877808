<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="" *ngIf="sectionName">{{sectionName}}</h4>
                <h4 class="" *ngIf="!sectionName">Registration / Login Setup</h4>
                <p class="bold-300 sub-title-section3">
                    This is where you customize your app's login and registration
                    pages to suit your business requirements.</p>
            </div>
        </div>

        <div class="padding-collapse">
            <ul class="collapsible col l10 s12 m12">
                <!-- ======== Welcoming Setting -->
                <li class="row" *ngIf="welcomeSetting">
                    <div class="col l10 s11 m11">
                        <div class="collapsible-header">Welcome Modal Setup</div>
                        <p>This content will be displayed as a welcome message on the registration page.</p>
                    </div>
                    <a class=" col l1 s1 m1 right collapsible-header p-t-3">
                        <span class="material-symbols-outlined">
                            keyboard_arrow_down
                        </span>
                    </a>

                    <div class="collapsible-body col l12 s12 m12 p-l-4">
                        <!-- title -->
                        <div class="input-field col l11 m10 s12">
                            <div class="label-input">Title</div>
                            <app-editor [title]=true [model]="welcomeSetting.title" [styler]=false
                                (onModelChange)="styleChanged($event, 'Welcome Title')" [type]=true></app-editor>
                        </div>

                        <!-- Description -->
                        <div class="input-field col l11 m10 s12">
                            <div class="label-input">Descriptions</div>
                            <app-editor [title]=false [model]="welcomeSetting.description" [styler]=false
                                (onModelChange)="styleChanged($event, 'Welcome Description')" [type]=true></app-editor>
                        </div>

                        <!-- Upload Image -->
                        <div class="input-field col l11 m10 s12">
                            <div class="card" [ngClass]="[welcomeSetting.image ? 'col s5' : 'col s12']">
                                <div class="center-align ">
                                    <div class="white">
                                        <input name="file" type="file" id="fileField" accept="image/*"
                                            (change)="handleFileInput($event.target.files, 'welcome')" />
                                        <i class="small material-symbols-outlined p-t-2">add</i>
                                        <h4 class="label-input p-b-2">
                                            Upload Image</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="card col s6 push-s1" *ngIf="welcomeSetting.image">
                                <div class="card-image">
                                    <img [src]="welcomeSetting.image ">
                                </div>
                            </div>
                        </div>

                        <!-- Save Button -->
                        <div class="col l12 m12 s12 p-b-2">
                            <button class="btn save-button col l3 s12 right" (click)="saveWelcomeSettings()"
                                [ngClass]="'btn ' + buttonType"><span *ngIf="welcomeSetting">Save</span> <span
                                    *ngIf="!welcomeSetting">Update</span></button>
                        </div>
                    </div>

                    <div id="modal-welcome-preview" class="modal modal_max">
                        <div class="col s12 modal-close "><span class="material-symbols-outlined black-text right">
                                close
                            </span></div>
                        <div class="modal-content">
                            <div class="col l12 m12 s12 welcome-content">
                                <h6 class="center-align bold-600 title-section2" [innerHTML]="welcomeSetting.title">
                                </h6>
                                <p [innerHTML]="welcomeSetting.description">
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer white">
                            <a class="modal-close white-text btn white-text"
                                [ngStyle]="{'background-color': themeColor}"> <i
                                    class="material-icons right">check</i>OK</a>
                        </div>
                    </div>
                </li>

                <!-- ========Registration Setup -->
                <li class="row" *ngIf="signupSetting">
                    <div class="col l9 s11 m11">
                        <div class="collapsible-header">Registration Setup</div>
                        <p>This is the content that will be displayed on the registration page.</p>
                    </div>

                    <a class=" col l1 s1 m1 right collapsible-header p-t-3">
                        <span class="material-symbols-outlined">
                            keyboard_arrow_down
                        </span>
                    </a>

                    <div class="collapsible-body col l12 s12 m12 p-l-4">
                        <!-- title -->
                        <div class="input-field col l11 m10 s12">
                            <div class="label-input">Title</div>
                            <app-editor [title]=true [model]="signupSetting.title" [styler]=false
                                (onModelChange)="styleChanged($event, 'Signup Title')" [type]=true></app-editor>
                        </div>

                        <!-- Description -->
                        <div class="input-field col l11 m10 s12">
                            <div class="label-input">Descriptions</div>
                            <app-editor [title]=false [model]="signupSetting.description" [styler]=false
                                (onModelChange)="styleChanged($event, 'Signup Description')" [type]=true></app-editor>
                        </div>

                        <!-- Upload Image -->
                        <div class="input-field col l11 m10 s12">
                            <div class="card" [ngClass]="[signupSetting.image ? 'col s5' : 'col s12']">
                                <div class="center-align ">
                                    <div class="white">
                                        <input name="file" type="file" id="fileField" accept="image/*"
                                            (change)="handleFileInput($event.target.files, 'signup')" />
                                        <i class="small material-symbols-outlined p-t-2">add</i>
                                        <h4 class="label-input p-b-2">
                                            Upload Image</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="card col s6 push-s1" *ngIf="signupSetting.image">
                                <div class="card-image">
                                    <img [src]="signupSetting.image ">
                                </div>
                            </div>
                        </div>

                        <!-- Save Button -->
                        <div class="col l12 m12 s12 p-b-2">
                            <button class="btn save-button col l3 s12 right" (click)="saveSignUpSettings()"
                                [ngClass]="'btn ' + buttonType"><span *ngIf="signupNewSetting">Save</span> <span
                                    *ngIf="!signupNewSetting">Update</span></button>
                        </div>
                    </div>
                </li>

                <!-- ========Login Setup -- -->
                <li class="row" *ngIf="loginSetting">
                    <div class="col l9 s10 m10" *ngIf="loginSetting">
                        <div class="collapsible-header">Login Setup</div>
                        <p>This is the content that will be displayed on the login page.</p>
                    </div>
                    <a class="col l1 s1 m1 right collapsible-header p-t-3 "><span class="material-symbols-outlined ">
                            keyboard_arrow_down
                        </span></a>
                    <a class="col l1 s1 m1 right modal-trigger p-t-3" data-target="modal-login-preview"
                        style="cursor: pointer;">
                        <span class="material-symbols-outlined black-text tooltipped" data-position="bottom"
                            data-tooltip="Preview">
                            visibility
                        </span>
                    </a>

                    <div class="collapsible-body col l12 s12 m12 p-l-4">
                        <!-- title -->
                        <div class="input-field col l11 m9 s12">
                            <div class="label-input">Title</div>
                            <app-editor [title]=true [model]="loginSetting.title" [styler]=false
                                (onModelChange)="styleChanged($event, 'Login Title')" [type]=true></app-editor>
                        </div>

                        <!-- Description -->
                        <div class="input-field col l11 m9 s12">
                            <div class="label-input">Descriptions</div>
                            <app-editor [title]=false [model]="loginSetting.description" [styler]=false
                                (onModelChange)="styleChanged($event, 'Login Description')" [type]=true></app-editor>
                        </div>

                        <!-- Upload Image -->
                        <div class="input-field col l11 m10 s12">
                            <div class="card" [ngClass]="[loginSetting.image ? 'col s5' : 'col s12']">
                                <div class="center-align">
                                    <div class="white">
                                        <input name="file" type="file" id="fileField" accept="image/*"
                                            (change)="handleFileInput($event.target.files, 'login')" />
                                        <i class="small material-symbols-outlined p-t-2">add</i>
                                        <h4 class="label-input p-b-2">
                                            Upload Image</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="card col s6 push-s1" *ngIf="loginSetting.image">
                                <div class="card-image">
                                    <img [src]="loginSetting.image ">
                                </div>
                            </div>
                        </div>

                        <!-- Save Button -->
                        <div class="col l12 m12 s12 p-b-2">
                            <button class="btn save-button col l3 s12 right" (click)="saveLogInSettings()"
                                [ngClass]="'btn ' + buttonType"><span *ngIf="loginNewSetting">Save</span> <span
                                    *ngIf="!loginNewSetting">Update</span></button>
                        </div>
                    </div>

                    <!-- Modal -->
                    <div id="modal-login-preview" class="modal modal_max">
                        <div class="col s12 modal-close "><span class="material-symbols-outlined black-text right">
                                close
                            </span></div>
                        <div class="modal-content ">
                            <div class="col s12">
                                <app-login-preview></app-login-preview>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>