<div class="col s12  ">
  <div class="body-h">
    
    <main class="StickyContent white ">
      <div class="row col s12">
        <div class="col s12 l10">
          <div class="row">

            <div class="col s12 m4 l4">
              <a [routerLink]="" (click)="addDepartmentEmployee($event);">
                <div class="card  hoverable b-n-1 " [ngStyle]="{'color': themeColor}"
                  style="height: 95px; border: 2px solid ;">
                  <div class="card-content">
                    <div class="row center ">
                      <i class="material-icons" [ngStyle]="{'color': themeColor}">add_circle</i><br />
                      <strong *ngIf="department?.shortName" [ngStyle]="{'color': themeColor}">{{(department?.shortName
                        == 'education') ? "Add Support Staff" :
                        "Add Employee"}}</strong>
                      <strong *ngIf="!department?.shortName" [ngStyle]="{'color': themeColor}" class="h4">
                        Add Employee</strong>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col s12 m4 l4">
              <a [routerLink]="" (click)="addDepartmentManager($event);">
                <div class="card  hoverable b-n-1" [ngStyle]="{'color': themeColor}"
                  style="height: 95px;  border: 2px solid;">
                  <div class="card-content">
                    <div class="row center">
                      <i class="material-icons" [ngStyle]="{'color': themeColor}">add_circle</i><br />
                      <strong *ngIf="department?.shortName" [ngStyle]="{'color': themeColor}">{{(department?.shortName
                        == 'education') ? "Add Director" : "Add Manager"}}</strong>
                      <strong *ngIf="!department?.shortName" [ngStyle]="{'color': themeColor}">Add
                        Manager</strong>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col s6 m4 l3 " *ngFor="let departmentMember of departmentMembers" [@swing]="swing">
              <a [routerLink]="[]" (click)="editDepartmentMember(departmentMember.id,$event);">
                <div class="card-panel white darken-3 hoverable" style="height: 95px;">
                  <div class="row center">
                    <span class="truncate"> <strong class="black-text" style="margin-top: 12px;"> {{
                        departmentMember.givenName }} {{ departmentMember.lastName }}
                      </strong>
                    </span>
                    <span class="truncate blue-text text-darken-3"><strong>{{departmentMember.jobTitle}}</strong></span>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</div>