<div class="col s12" [ngClass]="darkOption">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l10 m10 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">App Setting</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>

        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">It is where you configure your app's form, views , color and
          other items
          to adjust your app to your business needs. </p>
      </div>
      <div class="col s12 l2 m2 p-m-3">
        <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
          <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
            style="margin-bottom: 15px;">Save</button><br />
        </form>
      </div>
    </div>

    <!-- ================= new design ================== -->
    <main class="StickyContent  row">
      <ul class="collapsible col l10 s12">


        <!-- ================= Dark and light Mode Setup ================== -->


        <li class="row">
          <!-- =========== dark and light mode ============ -->
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              light_mode
            </span>Dark Mode</div>
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="row col l10 push-l1 p-b-3">

              <div class="logo-section col s12 l5">
                <label>
                  <input class="radioBtn with-gap" type="radio" name="darkOption" value="lightMode"
                    formControlName="darkOption" (click)="lightClicked()" />
                  <span class="label-content">

                    <div class="card b-n-1">
                      <div class="card-content center">
                        <div class="logo-image icon-text">
                          <span class="material-symbols-outlined bold-300" style="font-size: 30px;">
                            light_mode
                          </span>
                          <span class="p-l-1 sub-title-section2 bold-600 black-text"> Light Mode</span>
                        </div>

                      </div>
                    </div>
                  </span>
                </label>
              </div>


              <div class="logo-section col s12 l5">
                <label>
                  <input class="radioBtn with-gap" type="radio" name="darkOption" value="darkMode"
                    formControlName="darkOption" (click)="darkClicked()" />
                  <span class="label-content">
                    <div class="card black white-text b-n-1">
                      <div class="card-content center">
                        <div class="logo-image icon-text">
                          <span class="material-symbols-outlined bold-300 white-text" style="font-size: 30px;">
                            dark_mode
                          </span>
                          <span class="p-l-1 sub-title-section bold-600 white-text"> Dark Mode</span>
                        </div>

                      </div>
                    </div>
                  </span>
                </label>
              </div>

            </div>
          </form>


        </li>

        <!-- ================= Brand Setup ================== -->
        <li class="row">

          <!-- =========== Color Setup ============ -->
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              palette
            </span> Color Setup</div>
          <p> Setting up the color scheme for your website involves defining the theme color and background
            color. </p>

          <div class="collapsible-body ">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <div class="row card-panel">

                <!-- =========== theme Color ============ -->
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Theme Color</h4>
                    <span> Themecolor: Primary color for consistency. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="themeColor" type="color" class="col s4"
                        (input)="themeColorChanged($event)">
                    </div>
                  </div>
                </div>

                <!-- =========== bg Color ============ -->
                <div class="col l4 s12  card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Background Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="bodyBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
                <!-- =========== login bg Color ============ -->
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Background</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
                <!-- =========== login section Color ============ -->
                <div class="col l4 s12   card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Setion Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformSColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- =========== Button Option ============ -->
        <!-- -=================== nation Name of the header  ========== -->
        <li class="row">
          <div class="col l11 s9">
            <div class="collapsible-header">
              <span class="material-symbols-outlined p-r-1 " [ngStyle]="{'color': themeColor}">
                subheader
              </span>Header Logo Setup
            </div>
            <p>This is the content that will be displayed on the header section.</p>
          </div>

          <div class="col l1 s3 vertical-center p-t-3">
            <span class="tooltip center">
              <i class="material-symbols-outlined" (click)="editNation()" style="cursor: pointer;">info</i>
              <span class="tooltip-text">Please click on the icon to <br /> edit the nation's name and logo.
              </span>
            </span>
          </div>

          <div class="collapsible-body row col s12">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
              <div class="row align-center">

                <!-- ========= logo only ============= -->
                <div class="logo-section col s12 l4" *ngIf="logo">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="logoOnly"
                      formControlName="logoOption" (click)="logoOnlyClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Logo Only</span>
                    </span>
                    <div class="card">
                      <div class="card-content">
                        <div class="logo-image ">
                          <img *ngIf="logo" class="responsive-img" width="80" [src]="logo">
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

                <!-- ========= nation name only ============= -->
                <div class="logo-section col s12 l4">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="nationOnly"
                      formControlName="logoOption" (click)="nationOnlyClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Nation Name Only</span>
                    </span>
                    <div class="card" style="display: flex; align-items: center;">
                      <div class="card-content">
                        <div class="logo-image p-l-1 p-t-1 center">
                          <span class="label-content">
                            <a [routerLink]="[]" class="brand-logo" style="font-size: 15px;">{{nationName}}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

                <!-- ========= logo and nation name ============= -->
                <div class="logo-section col s12 l4" *ngIf="logo">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="bothNationLogo"
                      formControlName="logoOption" (click)="bothNationLogoClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Logo with Nation Name </span>
                    </span>
                    <div class="card">
                      <div class="card-content">
                        <div class="logo-image ">
                          <span class="label-content" style="display: flex; align-items: center;">
                            <img *ngIf="logo" class="responsive-img" [src]="logo" width="80">
                            <a [routerLink]="[]" class="brand-logo"
                              style="font-size: 15px; margin-left: 10px;">{{nationName}}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- ================= Register/Login Setup ================== -->
        <li class="row">
          <div class="col l11 s9">
            <div class="collapsible-header">
              <span class="material-symbols-outlined p-r-1 " [ngStyle]="{'color': themeColor}">
                how_to_reg
              </span>Member Login/Register Setup
            </div>
            <p>The edited content is what will appear on the Login and Registration Setup for the public-facing aspect.
            </p>
          </div>

          <div class="col l1 s3 p-t-3">
            <a class=" modal-trigger" data-target="modal-login-preview" style="cursor: pointer;">
              <span class="material-symbols-outlined black-text tooltipped center" data-position="bottom"
                data-tooltip="Preview">
                visibility
              </span>
            </a>
          </div>

          <div class="collapsible-body row col s12">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
              <div class="card-panel" style="padding-left: 2vh;">
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Background</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>

                <div class="col l4 s12  offset-l1 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Setion Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformSColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9 ">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- ================= nav bar ================== -->
        <li class="row">
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="col l9 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  bottom_navigation
                </span> Navigation bar Option
              </div>
              <p>The navigation bar option enables customizable menus on desktop, tablet, and mobile, allowing for
                flexible placement and display of multiple menus.</p>
            </div>

            <div class="col l3 s3">
              <div class="switch">
                <label class="right">
                  <span class="hide-on-small-only">Hide</span>
                  <input type="checkbox" formControlName="navbarOption" (change)="navbarOptionChanged()">
                  <span class="lever"></span>
                  <span class="hide-on-small-only">Show</span>
                </label>
              </div>
            </div>

            <div class="col s12 collapsible-body" [ngClass]="{'disabledDiv': !navbarOptionOn}"
              [style.display]="navbarOptionOn ? 'block' : 'none'">

              <!-- =========== Nav BAr Option ============ -->
              <div class="row">

                <!-- Content for the navigation bar options -->
                <div class="row col s12 white">
                  <div class="card-content">
                    <div class="col s12">
                      <!-- Tabs for desktop, tablet, and mobile views -->
                      <ul class="tabs">
                        <li class="tab col s4"><a class="active" href="#desktopView">Desktop View</a></li>
                        <li class="tab col s4"><a href="#tabletView">Tablet View</a></li>
                        <li class="tab col s4"><a href="#mobileView">Mobile View</a></li>
                      </ul>
                    </div>

                    <!-- Contents for the desktop view -->
                    <div id="desktopView" class="row section col s12" formGroupName="desktopView">
                      <div class="col s12 l9 m12">
                        <h6 class="sub-title-section2 bold-700">Side of the Menu</h6>
                        <label class="right">
                          <input type="radio" value="right" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the right</span>
                        </label>
                        <label class="left">
                          <input type="radio" value="left" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the left</span>
                        </label>
                        <div class="row section"></div>

                        <div class="">
                          <div class="col l6 s12">
                            <h6 class="sub-title-section2 bold-700">Number of Items</h6>
                            <div class="input-field ">
                              <select id="numberOfMenu" formControlName="numberOfMenus">
                                <option value="" disabled selected>Choose</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Contents for the tablet view -->
                    <div id="tabletView" class="row section col s12" formGroupName="tabletView">
                      <div class="col s12 l8 m12">
                        <h6 class="sub-title-section2 bold-700">Side of the Menu</h6>
                        <label class="right">
                          <input type="radio" value="right" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the right</span>
                        </label>
                        <label class="left">
                          <input type="radio" value="left" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the left</span>
                        </label>
                      </div>
                    </div>

                    <!-- Contents for the mobile view -->
                    <div id="mobileView" class="row section col s12" formGroupName="mobileView">
                      <div class="col s12 l8 m12">
                        <h6 class="sub-title-section2 bold-700">Position of the Menu</h6>
                        <label class="left">
                          <input type="radio" value="top" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on top</span>
                        </label>
                        <label class="right">
                          <input type="radio" value="bottom" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the bottom</span>
                        </label>
                        <div class="row section"></div>
                        <h6 class="sub-title-section2 bold-700">Number of Items</h6>
                        <div class="input-field col s6">
                          <select id="numberOfMenu" formControlName="numberOfMenus">
                            <option value="" disabled selected>Choose</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <!-- button -->
                    <div class="col s12 l3 m3 offset-l9 offset-m9">
                      <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                        style="margin-bottom: 15px;">save updates</button><br />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </li>

        <!-- ================= Welcome Setup ================== -->
        <li class="row" *ngIf="welcomeSetting">
          <form (ngSubmit)="SaveSettings(appSettings.value)" [formGroup]="appSettings">
            <div class="col l10 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  restart_alt
                </span>
                Pop Up Welcome On/Off
              </div>
              <p>This is the content that will be displayed on welcoming modal on the registration
                page.</p>
            </div>
            <div class="col l2 s3">
              <div class="switch">
                <label class="right">
                  Off
                  <input type="checkbox" formControlName="welcomeSetup" (change)="welcomeSetupChanged()">
                  <span class="lever"></span>
                  On
                </label>
              </div>
            </div>
          </form>

          <div *ngIf="welcomeSetupOn" class="collapsible-body" [ngClass]="{'disabledDiv': !welcomeSetupOn}"
            [style.display]="welcomeSetupOn ? 'block' : 'none'">
            <span>
              <div class="row">
                <!-- title -->
                <div class="input-field col l12 m11 s12 card">
                  <div class="sub-title-section bold-600 black-text">Title</div>
                  <app-editor [title]=true [model]="welcomeSetting.title" [styler]=false
                    (onModelChange)="styleChanged($event, 'Welcome Title')" [type]=true></app-editor>
                </div>

                <!-- Description -->
                <div class="input-field col l12 m11 s12 card">
                  <div class="sub-title-section bold-600 black-text">Descriptions</div>
                  <app-editor [title]=false [model]="welcomeSetting.description" [styler]=false
                    (onModelChange)="styleChanged($event, 'Welcome Description')" [type]=true></app-editor>
                </div>

                <!-- Upload Image -->
                <div class="card-panel1  col l12 m11 s12">
                  <div class="card" [ngClass]="[welcomeSetting.image ? 'col l4' : 'col s12']">
                    <div class="center-align ">
                      <input name="file" type="file" id="fileField" accept="image/*"
                        (change)="handleFileInput($event.target.files, 'welcome')" />
                      <i class="fa fa-cloud-upload p-t-2"></i>
                      <h4 class="label-input p-b-2  bold-200">
                        Drop & Drop to Upload file <br />
                        or <br />
                        <u class="bold-200">Click to Upload</u>
                      </h4>
                    </div>
                  </div>

                  <div class="card col l6 push-l1 s12" *ngIf="welcomeSetting.image">
                    <div class="card-image">
                      <img [src]="welcomeSetting.image" width="200px" height="200px">
                    </div>

                  </div>
                </div>

                <!-- Save Button -->
                <div class="col l12 m12 s12">
                  <button class="btn save-button col l3 s12 right" (click)="saveWelcomeSettings()"
                    [ngClass]="'btn ' + buttonType"><span *ngIf="welcomeSetting">Save</span> <span
                      *ngIf="!welcomeSetting">Update</span></button>
                </div>
              </div>
            </span>
          </div>
        </li>

        <!-- =================Registration setup  ================== -->
        <li class="row">
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="col l10 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  switches
                </span>
                Access to the app
                <!-- Would you like to enable direct app sign-up for your app? -->
              </div>
              <p>Do you want members to be verified before they can access {{nationName}} ?</p>
            </div>

            <div class="col l2 s3">
              <div class="switch ">
                <label class="right">
                  No
                  <input type="checkbox" formControlName="directSignup" (change)="signupOptionChanged()">
                  <span class="lever"></span>
                  Yes
                </label>
              </div>
            </div>
          </form>
        </li>


        <!-- =================  Governance Setup ================== -->
        <!-- <li class="row">
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              gavel
            </span> Governance Setting</div>
          <p>This is the section where you have the ability to customize the titles for the leaders of the nations,
            ensuring consistency throughout the application.</p>

          <div class="collapsible-body ">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <div class="row card-panel">

                <div class="col s12">
                  <h4 class="sub-title-section2 bold-600">Chief Title</h4>
                  <span>Chief: The primary leader of a First Nation community. </span>
                  <input formControlName="chiefTitle" type="text" class="validate">
                </div>

                <div class="col s12">
                  <h4 class="sub-title-section2 bold-600">Councillor Title</h4>
                  <span>Councillors: Individuals elected or appointed to work alongside the Chief in the governance of
                    the First Nation. </span>
                  <input formControlName="councillorTitle" type="text" class="validate">
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li> -->

        <!-- =========================== SEO integrations ================================== -->
        <li class="row">

          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              gavel
            </span> SEO Setup</div>

          <p>SEO (Search Engine Optimization) is the process of improving your website so it appears higher in search results, making it easier for people to find you online. </p>
          <div class="collapsible-body ">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <!-- App name Section -->
              <div class="mt-6  ">
                <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
                  Website Title
                  <div class="tooltip" data-tip="This is what will show to the public">
                    <span class="red-text
                    ">*</span>
                  </div>
                </label>
                <input
                  class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                  id="appTitle" type="text" placeholder="Website title" formControlName="appTitle">
              </div>

              <div class="mt-6  ">
                <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
                  Website Description
                  <div class="tooltip" data-tip="This is what will show to the public">
                    <span class="red-text
                    ">*</span>
                  </div>
                </label>
                <input
                  class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                  id="appTitle" type="text" placeholder="Website Description" formControlName="description">
              </div>
              <div>
                <button type="button" (click)="
                saveTitle() " class="btn-custom-solid float-right mt-5">Save</button>
              </div>
            </form>
          </div>
        </li>

        <!-- =========================== SMS Customization================================== -->
        <li class="row">
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              sms
            </span>SMS Setting</div>
          <p>Setup your SMS preference and customize your message Sent to your Clients .</p>

          <div class="collapsible-body ">
            <!-- 
            (ngSubmit)="SaveSettings(appSettings.value)"
             -->
            <form [formGroup]="appSettings" (ngSubmit)="saveSMS(appSettings.get('messageSignature').value)">
              <div formGroupName="messageSignature">
                <div class="card-panel">

                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Greetings / Salutation</h4>
                    <span>This is what will be at the beginning of the Message. This will set the tone for the message
                      and helps establish the level of formality.</span>
                    <input formControlName="greeting" placeholder="Greeting Phrase..." type="text" class="validate">
                  </div>

                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Closing Line</h4>
                    <span>The closing line wraps up the message, often expressing gratitude, summarizing key points, or
                      offering a call to action. </span>
                    <input formControlName="closing" placeholder="Closing line ..." type="text" class="validate">
                  </div>

                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Message Signature</h4>
                    <span>The message signature adds a professional touch and provides recipients with important
                      information for further contact or context about the sender. </span>

                    <div class="p-m-2">
                      <label class="col s12">
                        <input type="checkbox" formControlName="signName" />
                        <span class="bold-600">Signoff with Sender Name</span>
                      </label>
                    </div>

                    <div class="col s12">
                      <h4 class="sub-title-section2 bold-600">Customize Content</h4>
                      <span>This section is designated to each admin, allowing them to customize it according to their
                        individual preferences.
                      </span>

                      <!-- <button type="button" (click)="updateInfoModal()" *ngIf="adminInfo"
                        class="btn-small btn-flat right waves-effect waves-light"
                        [ngStyle]="{'color': themeColor}">Update
                        Info</button> -->

                      <div class="mt-6" *ngIf="adminInfo">
                        <label class="col s12 m6">
                          <input type="checkbox" [checked]="show_title" (click)="titleChecked()" />
                          <span>Title</span>
                        </label>

                        <label class="col s12 m6">
                          <input type="checkbox" [checked]="show_email" (click)="emailChecked()" />
                          <span>Email</span>
                        </label>

                        <label class="col s12 m6">
                          <input type="checkbox" [checked]="show_cell" (click)="cellChecked()" />
                          <span>Cell Phone</span>
                        </label>

                        <label class="col s12 m6">
                          <input type="checkbox" [checked]="show_office" (click)="officeChecked()" />
                          <span>Office Phone</span>
                        </label>
                      </div>

                      <div class="card-panel col s12" *ngIf="!adminInfo">
                        <h6 class="col s12 red-text">Admin Information is unavailable!
                        </h6>
                        <span class="col s12 red-text">This section cannot be
                          customized without the admin information.
                        </span>
                        <span class="col s12 red-text">For further assistance, please contact the support team or your
                          system administrator.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col s12 l3 m3 offset-l9 offset-m9">
                  <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                    style="margin-bottom: 15px;">Save Updates</button><br />
                </div>
              </div>
            </form>
          </div>
        </li>

        <div id="updateContactModal" class="modal modal update-modal" *ngIf="adminInfo">
          <div class="modal-content">
            <h5 class="bold-600">Update Contact Info</h5>
            <!-- <h6 class="red-text" *ngIf="send == 'email' && !adminInfo.Email_Address">Upate you Email Address to use this
              feature</h6>
            <h6 class="red-text" *ngIf="send == 'text' && !adminInfo.Cell">Update you Cell to use this feature</h6> -->
            <br>
            <form [formGroup]="contactInfo" (ngSubmit)="updateInfo(contactInfo.value)">

              <div class="col s6">
                <h4 class="sub-title-section2 bold-400">Phone</h4>
                <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName='phone'
                  class="validate col s4" autocomplete="off" />

                <div class="col s2">
                  <h4 class="sub-title-section2 bold-400">Phone Ext</h4>
                  <input id="ph_extension" type="text" formControlName='ph_extension' class="validate"
                    autocomplete="off" />
                </div>

                <!-- <label class="col s12 m4 right-align">
                  <input name="filter" type="checkbox" formControlName='show_office' />
                  <span>show on Message </span>
                </label> -->

              </div>

              <div class="col s6">
                <h4 class="sub-title-section2 bold-400">Cell</h4>
                <input id="Cell" type="text" [inputMask]="phoneInputMask" formControlName='Cell' class="validate"
                  autocomplete="off" />
                <!-- <div *ngIf="(Cell.dirty || Cell.touched) && Cell.invalid">
                  <small *ngIf="Cell.errors.required" class="red-text errMsg"> Cell Number is required. </small>
                </div> -->
                <!-- <label class="col s12 m4 right-align">
                  <input name="filter" type="checkbox" formControlName='show_cell' />
                  <span>Show on Message </span>
                </label> -->
              </div>

              <div class="col s6">
                <h4 class="sub-title-section2 bold-400">Title</h4>
                <input id="Title" type="text" formControlName='Title' class="validate" autocomplete="off"
                  style="text-transform: capitalize;" />
                <!-- <div *ngIf="(Title.dirty || Title.touched) && Title.invalid">
                  <small *ngIf="Title.errors.required" class="red-text errMsg">Title is required</small>
                </div> -->
                <!-- <label class="col s12 m4 right-align">
                  <input name="filter" type="checkbox" formControlName='show_title' />
                  <span>Show title on Message </span>
                </label> -->
              </div>

              <div class="col s6">
                <h4 class="sub-title-section2 bold-400">Email</h4>
                <input id="Email_Address" type="text" formControlName='Email_Address' class="validate"
                  autocomplete="off" />
                <!-- <div *ngIf="(Email_Address.dirty || Email_Address.touched) && Email_Address.invalid">
                  <small *ngIf="Email_Address.errors.email" class="red-tex errMsgt">Invalid Email Address</small>
                  <small *ngIf="Email_Address.errors.required" class="red-text errMsg">Email Address is required</small>
                </div> -->
                <!-- <label class="col s12 m4 right-align">
                  <input name="filter" type="checkbox" formControlName='show_email' />
                  <span>Show on Message </span>
                </label> -->
              </div>

              <div class="row">
                <div class="input-field ">
                  <button type="submit" [disabled]="!contactInfo.valid" class='btn right waves-effect green'
                    style="margin-top: 15px;">
                    Update Info
                  </button>

                  <button type="button" class='btn waves-effect red left' style="margin-top: 15px;" (click)="cancel()">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- =================Modal Login -->
        <div id="modal-login-preview" class="modal bottom-sheet custom-modal-height">
          <div class="col s12 modal-close p-t-1">
            <a class="close_btn btn right" [ngStyle]="{'color': themeColor}">X</a>
          </div>
          <div class="modal-content ">
            <div class="col s12 l10 offset-l1 p-t-2">
              <app-login-preview></app-login-preview>
            </div>
          </div>
        </div>
      </ul>
    </main>

    <!-- ================= end new design ================== -->
  </div>
</div>