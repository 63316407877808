<div *ngIf="isCouncillor">
  <div>
    <form [formGroup]="editCouncillorForm" (submit)="updateCouncillorData()">
      <div class="row  card-panel white">
        <div class="card-image" *ngIf="user">
          <div class="prof-img row center">
            <div *ngIf="!user.profileImgLink">
              <img class="row imgC center" src="../../../../../assets/img/profile.jpg"
                style="width: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
            <div *ngIf="user.profileImgLink">
              <img class="row imgC center" [src]="user.profileImgLink"
                style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
          </div>
        </div>

        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Councillor Information</strong></h6>
        <br />
        <div class="row">
          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">person</i>
            <input id="firstName" type="text" formControlName='firstName' />
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">person</i>
            <input id="lastName" type="text" formControlName='lastName' />
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix teal-text">person</i>
            <input id="displayName" type="text" formControlName='displayName' />
            <label for="displayName">Display Name :</label>
          </div>
          <div class="input-field col s12">
            <i class="material-icons prefix teal-text">person</i>
            <textarea formControlName="chiefBio" class="materialize-textarea" id="chiefBioC"></textarea>
            <label for="chiefBioC">Councillor Bio</label>
          </div>

          <div class="input-field class col s11">
            <i class="material-icons prefix green-text">today</i>
            <input id="appointmentDate" disable type="text" formControlName='appointmentDate' class="input-box"
              autocomplete="off" angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
              #dp1="angular-mydatepicker" disabled />
            <label for="appointmentDate">Elected On :</label>
            <div *ngIf="(getAppointmentDate.dirty || getAppointmentDate.touched) && getAppointmentDate.invalid">
              <small *ngIf="getAppointmentDate.errors.required" class="red-text"> Please enter The Appointment Date.
              </small>
            </div>
          </div>

          <div class="col s1">
            <div class="row center">
              <br />
              <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
            </div>
          </div>

          <div class="input-field class col s11">
            <i class="material-icons prefix red-text">event</i>
            <input formControlName="expiryDate" id="expiryDate" type="text" class="validate input-box"
              autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
              #dp2="angular-mydatepicker" disabled />
            <label for="expiryDate"> Mandate Ends On : </label>
            <div *ngIf="(getExpiryDate.dirty || getExpiryDate.touched) && getExpiryDate.invalid">
              <small *ngIf="getExpiryDate.errors.required" class="red-text"> Please enter The Expiry Date.
              </small>
            </div>
          </div>
          <div class="col s1">
            <div class="row center">
              <br />
              <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">email</i>
            <input id="email" type="email" formControlName='email' class="validate" autocomplete="off" />
            <label for="email">Email</label>
            <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
              <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
              <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix purple-text ">smartphone</i>
            <!-- <input id="office_phone" type="text" formControlName='office_phone' class="validate" autocomplete="off"
              [textMask]="{mask: phoneNumberMask}" /> -->
            <input id="office_phone" type="text" [inputMask]="phoneInputMask" formControlName='office_phone'
              class="validate" autocomplete="off" />
            <label for="office_phone">Office Phone :</label>
            <div *ngIf="(getOffice_phone?.dirty || getOffice_phone?.touched) && getOffice_phone?.invalid">
              <small *ngIf="getOffice_phone?.errors.required" class="red-text"> The Office Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix purple-text text-lighten-3">smartphone</i>
            <!-- <input id="cell_phone" type="text" formControlName='cell_phone' class="validate" autocomplete="off"
              [textMask]="{mask: phoneNumberMask}" /> -->
            <input id="cell_phone" type="text" [inputMask]="phoneInputMask" formControlName='cell_phone'
              class="validate" autocomplete="off" />
            <label for="cell_phone">Phone :</label>
            <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
              <small *ngIf="getCell_phone.errors.required" class="red-text"> The Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix purple-text">description</i>
            <textarea id="chiefBio" type="text" formControlName='chiefBio'
              class="materialize-textarea validate"> </textarea>
            <label for="chiefBio">Councillor's Bio</label>

            <div *ngIf="(getChiefBio.dirty || getChiefBio.touched) && getChiefBio.invalid">
              <small *ngIf="getChiefBio.errors.required" class="red-text"> The Description / Bio is required. </small>
            </div>
          </div>

          <!--  facebook  -->
          <div class="input-field col s12">
            <i class="material-icons prefix " style="color: #3b5998;">facebook</i>
            <input id="councillorFacebook" type="text" formControlName='councillorFacebookAcc'
              class="materialize-textarea">
            <label for="councillorFacebook">councillor's Facebook account</label>
            <div
              *ngIf="(getCouncillorFacebookAcc?.dirty || getCouncillorFacebookAcc?.touched) && getCouncillorFacebookAcc?.invalid">
              <small *ngIf="getCouncillorFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url format
              </small>
            </div>
          </div>

          <!--  instagram  -->
          <div class="input-field col s12">
            <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
            <input id="councillorInsta" type="text" formControlName='councillorInstagramAcc'
              class="materialize-textarea">
            <label for="councillorInsta">councillor's Instagram account</label>
            <div
              *ngIf="(getCouncillorInstagramAcc?.dirty || getCouncillorInstagramAcc?.touched) && getCouncillorInstagramAcc?.invalid">
              <small *ngIf="getCouncillorInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format
              </small>
            </div>
          </div>

          <!--  twitter  -->
          <div class="input-field col s12">
            <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
            <input id="councillorTwitter" type="text" formControlName='councillorTwitterAcc'
              class="materialize-textarea">
            <label for="councillorTwitter">councillor's twitter account</label>
            <div
              *ngIf="(getCouncillorTwitterAcc?.dirty || getCouncillorTwitterAcc?.touched) && getCouncillorTwitterAcc?.invalid">
              <small *ngIf="getCouncillorTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format </small>
            </div>
          </div>

          <!--  linked in  -->
          <div class="input-field col s12">
            <i class="fa fa-linkedin prefix " style="color: #0077b5;"></i>
            <input id="councillorLinkedin" type="text" formControlName='councillorLinkedinAcc'
              class="materialize-textarea">
            <label for="councillorLinkedin">councillor's linkedin account</label>
            <div
              *ngIf="(getCouncillorLinkedinAcc?.dirty || getCouncillorLinkedinAcc?.touched) && getCouncillorLinkedinAcc?.invalid">
              <small *ngIf="getCouncillorLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format
              </small>
            </div>
          </div>

          <!--  youtube  -->
          <div class="input-field col s12">
            <i class="fa fa-youtube prefix red-text"></i>
            <input id="councillorYoutube" type="text" formControlName='councillorYoutubeAcc'
              class="materialize-textarea">
            <label for="councillorYoutube">councillor's youtube account</label>
            <div
              *ngIf="(getCouncillorYoutubeAcc?.dirty || getCouncillorYoutubeAcc?.touched) && getCouncillorYoutubeAcc?.invalid">
              <small *ngIf="getCouncillorYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format </small>
            </div>
          </div>

          <!-- contact settings -->
          <div class="row card-panel white">
            <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact Settings</strong></h6>
            <!-- mobile -->
            <div class="col s12">
              <p><strong>Show Mobile Phone</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showMobile" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showMobile" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showMobile" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

            <!-- office number -->
            <div class="col s12">
              <p><strong>Show Office Phone</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value="Yes" value='Public' formControlName="showOfficePhone" class="with-gap"/>
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value="No" value='Members' formControlName="showOfficePhone" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>

              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showOfficePhone" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show</strong></span>
                </label>
              </div>
            </div>

            <!-- emial address -->
            <div class="col s12">
              <p><strong>Show Email Address</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showEmail" class="with-gap"/>
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showEmail" class="with-gap"/>
                  <span class="blue-text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showEmail" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show</strong></span>
                </label>
              </div>
            </div>

            <!-- contact us message -->
            <div class="col s12">
              <p><strong>Allow Contact Us Messages </strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="allowContact" class="with-gap"/>
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="allowContact" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="allowContact" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Allow</strong></span>
                </label>
              </div>
            </div>

            <!-- facebook -->
            <div class="input-field col s12">
              <p><strong>Show Facebook Account</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showFacebookAcc" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showFacebookAcc" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showFacebookAcc" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

            <!-- instagram -->
            <div class="input-field col s12">
              <p><strong>Show Instagram Account</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showInstagramAcc" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showInstagramAcc" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showInstagramAcc" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

            <!-- twitter -->
            <div class="input-field col s12">
              <p><strong>Show Twitter Account</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showTwitterAcc" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showTwitterAcc" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showTwitterAcc" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

            <!-- linkedin -->
            <div class="input-field col s12">
              <p><strong>Show Linkedin Account</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showLinkedinAcc" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showLinkedinAcc" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showLinkedinAcc" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

            <!-- youtube -->
            <div class="input-field col s12">
              <p><strong>Show Youtube Account</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Public' formControlName="showYoutubeAcc" class="with-gap"/>
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Members' formControlName="showYoutubeAcc" class="with-gap"/>
                  <span class="text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value='Private' formControlName="showYoutubeAcc" class="with-gap"/>
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>

    <div class="row card-panel white">
      <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Portfolio</strong></h6>
      <div class="row">
        <ul class="collection col s12" *ngIf="departmentsOptions">
          <li class="collection-item" *ngFor="let item of departmentsOptions">
            <div>
              <label>
                <input type="checkbox" class="filled-in" [(ngModel)]="item.isSelected" value="item.id" />
                <span [innerHTML]="item.name"></span>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <button class="col s12 btn waves-effect green" type="button" title="Save" [disabled]="!editCouncillorForm.valid"
        (click)="updateCouncillorData()" style="margin-bottom: 15px; width: 100%"><i
          class="material-icons right">edit</i>Update Councillor</button>
    </div>
    <div class="row">
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()" style="width: 100%"><i
          class="material-icons left">arrow_back_ios</i> Back To Previous Page</button>
    </div>
    <div class="row">
      <br />
    </div>
  </div>
</div>

<div *ngIf="!isCouncillor" class="center">
  You Dont have a councillor account
</div>