export class SearchMemberModel {
  firstName: string;
  lastName: string;
}


export class SearchMembersModel {
  clientId: number = null;
  firstName: string = '';
  lastName: string = '';
  dob: string = '';
  isn: string = '';
  isMember: boolean;
  email: string = '';
}