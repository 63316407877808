import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MemberModel } from 'src/app/models/memberModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { BandMemberModel } from 'src/app/models/BandMemberModel';
import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';
import { ImageModel } from 'src/app/models/ImageModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { createMask } from '@ngneat/input-mask';

declare var navigator: any;
declare var $: any;
declare var M: any;


@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css'],
})

export class PersonalInformationComponent implements OnInit {

  valueEmittedFromChildComponent: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  isUploading = false;
  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'success') {
      this.memberService.getMember(this.applicantID).subscribe(x => {
        this.member = x[0];
        toast({ html: 'Address Successfully Updated!', classes: 'green' });
      });
    } else if (this.valueEmittedFromChildComponent === 'fail') {
      toast({ html: 'Unable To Update The Address, Please Try Agin Later!', classes: 'red' });
    }
  }

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  member: MemberModel;
  dep = false;
  applicantID = 0;
  sqldb = environment.firstNation.SQLDB;
  addUpdateDepartmentForm: UntypedFormGroup;
  postalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // tslint:disable-next-line: max-line-length
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  bandMemberModel: BandMemberModel;
  base64Image: any;
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';

  isMobile = false;
  model: any;
  binDoc: File;
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  constructor(
    private authService: AuthService,
    private memberService: MemberService,
    private location: Location, private formBuilder: UntypedFormBuilder, private fireMembersService: FireMembersService,
    private mediaService: MediaService,
    private appSettingService: AppSettingsService,) {

    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
    if (!this.sqldb) {
      this.validateUpdateMemberForm();
    }
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit() {
    this.applicantID = this.authService.getGlobalUser().applicantID
    
    this.memberService.getMember(this.applicantID).subscribe(x => {
      this.member = x[0];
      if (!this.sqldb) {
        this.addUpdateDepartmentForm.setValue({
          GivenName: this.member.GivenName,
          middle_name: this.member.middle_name,
          LastName: this.member.LastName,
          DOB: this.member.DOB,
          Band: this.member.Band,
          TreatyNo: this.member.TreatyNo,
          MailAddress: this.member.MailAddress,
          city: this.member.city,
          province: this.member.province,
          PostalCode: this.member.PostalCode,
          Telephone: this.member.Telephone,
          Fax: this.member.Fax,
          Email: this.member.Email,
        });
      }
      this.fireMembersService.getMemberByAppliciantId(this.applicantID).valueChanges().subscribe(x => {
        if (x) {
          this.bandMemberModel = x[0];
          this.imgLink = this.bandMemberModel.profileImgLink || '';
        }
      })
    });

    setTimeout(() => {
      (<any>$('.datepicker')).datepicker({
        format: 'dd-mm-yyyy',
        defaultDate: new Date(),
        setDefaultDate: new Date(),
        yearRange: 50,
        minDate: new Date(new Date().getFullYear() - 50, 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),
        onSelect(e) {
        },
      });
      (<any>$('.tooltipped')).tooltip();
    }, 25);
  }

  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else { return ''; }
  }

  validateUpdateMemberForm() {
    this.addUpdateDepartmentForm = this.formBuilder.group({
      GivenName: ['', [Validators.required]],
      middle_name: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      DOB: ['', [Validators.required]],
      Band: ['', [Validators.required]],
      TreatyNo: ['', [Validators.required]],
      MailAddress: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      PostalCode: ['', [Validators.required, Validators.pattern(this.postalCode)]],
      Telephone: ['', [Validators.required, Validators.pattern(this.mobilePattern)]],
      Fax: ['', [Validators.required, Validators.pattern(this.mobilePattern)]],
      Email: ['', [Validators.required]],
    });
  }

  onUpdateMemberProfile() {
    this.member.GivenName = this.addUpdateDepartmentForm.value.GivenName;
    this.member.middle_name = this.addUpdateDepartmentForm.value.middle_name;
    this.member.LastName = this.addUpdateDepartmentForm.value.LastName;
    this.member.DOB = this.addUpdateDepartmentForm.value.DOB;
    this.member.Band = this.addUpdateDepartmentForm.value.Band;
    this.member.TreatyNo = this.addUpdateDepartmentForm.value.TreatyNo;
    this.member.MailAddress = this.addUpdateDepartmentForm.value.MailAddress;
    this.member.city = this.addUpdateDepartmentForm.value.city;
    this.member.province = this.addUpdateDepartmentForm.value.province;
    this.member.PostalCode = this.addUpdateDepartmentForm.value.PostalCode;
    this.member.Telephone = this.addUpdateDepartmentForm.value.Telephone;
    this.member.Fax = this.addUpdateDepartmentForm.value.Fax;
    this.member.Email = this.addUpdateDepartmentForm.value.Email;
    this.fireMembersService.updateAccount(this.member);
  }

  dateOfBirthChange() {
    if (this.addUpdateDepartmentForm) { this.addUpdateDepartmentForm.patchValue({ DOB: $('#DOB').val() }); }
  }

  handleImageInput(files: FileList) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
    setTimeout(() => {
      const img = new ImageModel();
      img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
      this.mediaService.uploadImage(img).subscribe(x => {
        if (x) {
          this.imgLink = x.imgLink;
          this.bandMemberModel.profileImgLink = this.imgLink;
          this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
        }
      });
    }, 30);

  }

  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'IMG.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          // this.mediaService.UploadBinImage(this.binDoc).subscribe(x => {
          //   if (x) {
          //     this.imgLink = x.imgLink;
          //     this.bandMemberModel.profileImgLink = this.imgLink;
          //     this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
          //   }
          // });

          setTimeout(() => {
            const img = new ImageModel();
            img.imgBase64 = imageData;
            this.mediaService.uploadImage(img).subscribe(x => {
              if (x) {
                this.imgLink = x.imgLink;
                this.bandMemberModel.profileImgLink = this.imgLink;
                this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
              }
            });
          }, 30)
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
        // allowEdit:true,
      });
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'IMG.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;

          // this.mediaService.UploadBinImage(this.binDoc).subscribe(x => {
          //   if (x) {
          //     this.imgLink = x.imgLink;
          //     this.bandMemberModel.profileImgLink = this.imgLink;
          //     this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
          //   }
          // });
          setTimeout(() => {
            const img = new ImageModel();
            img.imgBase64 = imageData;
            this.mediaService.uploadImage(img).subscribe(x => {
              if (x) {
                this.imgLink = x.imgLink;
                this.bandMemberModel.profileImgLink = this.imgLink;
                this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
              }
            });
          }, 30)
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
        // allowEdit:true,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  backClicked() {
    localStorage.setItem('Profile_updated', 'Yes');
    this.location.back();
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  // Handle file upload event
  handleFileUpload(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.isUploading = true; 
      const reader = new FileReader();
      reader.onload = () => {
        this.base64Image = reader.result as string;
        this.uploadImageToServer(this.base64Image);
      };
      reader.readAsDataURL(file);
    }
  }

  // Upload the image to the server
  uploadImageToServer(base64Image: string) {
    const img = new ImageModel();
    img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, ''); // Strip base64 prefix
    this.mediaService.uploadImage(img).subscribe(
      (response) => {
        this.imgLink = response.imgLink; // Update the displayed profile picture
        this.updateProfileImageInFirebase(response.imgLink);
        this.isUploading = false; // Reset uploading state
        toast({ html: 'Profile picture updated successfully!', classes: 'green' });
      },
      (error) => {
        this.isUploading = false; // Reset uploading state
        toast({ html: 'Failed to upload image. Please try again.', classes: 'red' });
      }
    );
  }

  // Update the profile image in Firebase
  updateProfileImageInFirebase(imgLink: string) {
    this.bandMemberModel.profileImgLink = imgLink;
    this.fireMembersService.updateAccountByMemberId(this.bandMemberModel);
  }

}
  
