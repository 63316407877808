<div>
  <div id="clockdiv">
    <div>
      <span class="days">{{days}}</span>
      <div class="smalltext">Days Left to vote</div>
    </div>
  </div>

  <div class="row" *ngIf="!votingEnded && !voted && !noCandidates && !isRevote">
    <div class="row col s12">
      <div>
        <strong><span>Show Candidate That you voted? </span></strong><strong class="red-text"> *</strong>
      </div><br />
      <div class="col s6">
        <label>
          <input type="radio" class="with-gap" name="showVoting" (change)="onChangeShowVoting($event)" [value]=true required />
          <span><strong class="green-text">Yes</strong></span>
        </label>
      </div>
      <div class="col s6">
        <label>
          <input type="radio" class="with-gap" name="showVoting" (change)="onChangeShowVoting($event)" checked=false [value]=false
            required />
          <span><strong class="blue-text">No</strong></span>
        </label>
      </div>
    </div>

    <form [formGroup]="filterForm" (ngSubmit)="submitForm(filterForm.value)">
      <div class="row">
        <div class="input-field col s12 m8 ">
          <i class="material-icons prefix pink-text">account_circle</i>
          <input id="firstName" (keyup)="submitForm(filterForm.value)" type="text" formControlName="firstName"
            autocomplete="off" style="text-transform: capitalize;" />
          <label for="firstName">First Name</label>
        </div>
        <button class="btn green col s4 offset-s2 m2" type="submit">Find</button>
        <button class="btn red col s4 m2" (click)="clearSearchInput()">Clear</button>
      </div>
    </form>

    <!-- <p class="red-text">You can only vote once vote carefully</p> -->
    <div class="card col s12 m6 l4" *ngFor="let candidate of candidatesData">
      <div class="card-image">
        <div class="background-img">
          <div class="background-with-color">
            <div></div>
          </div>
          <div class="prof-img">
            <div *ngIf="!candidate.profileImgLink">
              <img class="background-img-avatar" src="../../../../../assets/img/profile.jpg"
                style="width: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
            <div *ngIf="candidate.profileImgLink">
              <img class="background-img-avatar" [src]="candidate.profileImgLink"
                style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <p>Name: {{candidate.GivenName}} {{candidate.LastName}}</p>
        <p>Date of Birth: {{candidate.DOB}}</p>
        <p>Sex: {{candidate.Sex}}</p>
        <p>Band: {{candidate.Band}}</p>
        <a class="btn col s12 m12 l12" [routerLink]="['/member/election-campaign', candidate.uid]">View candidate
          page</a><br /><br />
        <button class="btn col s12 m12 l12" (click)="submitVote(candidate.uid)">
          <i class="material-icons white-text">how_to_vote</i>Vote
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!votingEnded && isRevote && !noCandidates">
    <p>Updating Vote</p>
    <div class="row col s12">
      <div>
        <strong><span>Show Candidate That you voted? </span></strong><strong class="red-text"> *</strong>
      </div><br />
      <div class="col s6">
        <label>
          <input type="radio" class="with-gap" name="showVoting" (change)="onChangeShowVoting($event)" [value]=true required />
          <span><strong class="green-text">Yes</strong></span>
        </label>
      </div>
      <div class="col s6">
        <label>
          <input type="radio" class="with-gap" name="showVoting" (change)="onChangeShowVoting($event)" checked=false [value]=false
            required />
          <span><strong class="blue-text">No</strong></span>
        </label>
      </div>
    </div>
    
    <form [formGroup]="filterForm" (ngSubmit)="submitForm(filterForm.value)">
      <div class="row">
        <div class="input-field col s12 m8 ">
          <i class="material-icons prefix pink-text">account_circle</i>
          <input id="firstName" (keyup)="submitForm(filterForm.value)" type="text" formControlName="firstName"
            autocomplete="off" style="text-transform: capitalize;" />
          <label for="firstName">First Name</label>
        </div>
        <button class="btn green col s4 offset-s2 m2" type="submit">Find</button>
        <button class="btn red col s4 m2" (click)="clearSearchInput()">Clear</button>
      </div>
    </form>

    <!-- <p class="red-text">You can only vote once vote carefully</p> -->
    <div class="card col s12 m6 l4" *ngFor="let candidate of candidatesData">
      <div class="card-image">
        <div class="background-img">
          <div class="background-with-color" [ngStyle]="{'background-color': themeColor}">
            <div></div>
          </div>
          <div class="prof-img">
            <div *ngIf="!candidate.profileImgLink">
              <img class="background-img-avatar" src="../../../../../assets/img/profile.jpg"
                style="width: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
            <div *ngIf="candidate.profileImgLink">
              <img class="background-img-avatar" [src]="candidate.profileImgLink"
                style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;" />
            </div>
          </div>
        </div>
      </div>

      <div class="card-content">
        <p>Name: {{candidate.GivenName}} {{candidate.LastName}}</p>
        <p>Date of Birth: {{candidate.DOB}}</p>
        <p>Sex: {{candidate.Sex}}</p>
        <p>Band: {{candidate.Band}}</p>
        <a class="btn col s12 m12 l12" [routerLink]="['/member/election-campaign', candidate.uid]">View candidate
          page</a><br /><br />
        <button class="btn col s12 m12 l12" (click)="updateVote(candidate.uid)">
          <i class="material-icons white-text">how_to_vote</i>Vote
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>

  <div *ngIf="voted">
    <div class="card ">
      <div class="row">
        <div class="col s12 center-align">
          <p>You have already voted!</p>
          <button *ngIf="!votingEnded" class="btn orange" (click)="changeVote()">Change Vote</button>
          <button *ngIf="votingEnded" class="btn red">Voting Ended</button>
          <br /><br />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="noCandidates">
    <p>No candidates haven't chosen yet</p>
  </div>

  <div class="row">
    <button type="button" class="btn col s12 blue" (click)="backClicked()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
    </button>
  </div>
</div>