<div class="row">
  <div class="col s12 ">
    <div class="body-h">

      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12">
          <h4 class="">Chair Of The Board</h4>
          <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)" >
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
          </select>
        </div>
      </div>

      <main class="StickyContent white">
        <div class="row ">
          <div class="col s12 m12 l10 offset-l1">
            <div *ngIf="step1 && boardModel">
              <div class="row ">
                <div class="row">
                  <h6 class="sub-title-section bold-600"> Search In The Database</h6>

                  <div class="row">
                    <form [formGroup]="searchForm" (ngSubmit)="searchMember(searchForm.value)">

                      <div class="input-field col s12 m6">
                        <div class="label-input">First Name</div>
                        <input id="fName" type="text" formControlName='fName' class="validate" autocomplete="off"
                          placeholder="First Name" />
                      </div>

                      <div class="input-field col s12 m6">
                        <div class="label-input">Last Name</div>
                        <input id="lName" type="text" formControlName='lName' class="validate" autocomplete="off"
                          placeholder="Last Name" />
                      </div>

                      <div class="row col s12">
                        <button type="submit" class="col s3 btn waves-effect green right" style="margin-bottom: 15px;">
                          Search Member
                        </button>
                      </div>

                      <div *ngIf="searchResult">
                        <table class="striped centred">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              *ngFor="let item of searchResult | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;">
                              <td>{{item?.applicantID}}</td>
                              <td>{{item?.LastName}} {{item?.GivenName}}</td>
                              <td><button type="button" (click)="memberSelected(item);"
                                  class="btn-small blue right">Select</button></td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="row center">
                          <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div *ngIf="step2 && boardModel">
              <div class="row center">
                <img *ngIf="profile_pic" class="responsive-img " style="object-fit: contain; width: 50%; height:250px;"
                  [src]="profile_pic">
                <img *ngIf="!profile_pic" class="responsive-img " style="object-fit: contain; width: 50%; height:250px;"
                  src="../../../../assets/img/emptyProfilePic.png">
                <p *ngIf="showImageUpload">
                  <button class="btn-floating btn-small waves-effect waves-light blue"
                    (click)="hiddenfileinput.click()">
                    <i class="material-icons">add_a_photo</i>
                  </button>

                  <input class="file-field input-field col s12 center" type="file" style="display:none" accept="image/*"
                    (change)="handleFileInput($event.target.files)" #hiddenfileinput>
                </p>
                <p *ngIf="!showImageUpload">
                  <a class="btn-floating btn-small red" (click)="removeSavedFile()"><i
                      class="material-icons">delete</i></a>
                </p>
              </div>

              <form [formGroup]="chiefForm" (ngSubmit)="processFormBeforeSaving(chiefForm.value)">
                <div class="row">
                  <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">person</i>
                    <input id="firstName" type="text" formControlName='firstName' />
                    <label for="firstName">First Name :</label>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix cyan-text text-darken-2">person</i>
                    <input id="lastName" type="text" formControlName='lastName' />
                    <label for="lastName">Last Name :</label>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">person</i>
                    <input id="displayName" type="text" formControlName='displayName' />
                    <label for="firstName">Display Name :</label>
                  </div>

                  <div class="input-field class col s11">
                    <i class="material-icons prefix green-text">today</i>
                    <input id="appointmentDate" type="text" formControlName='appointmentDate' class="input-box"
                      autocomplete="off" angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
                      [options]="myDpOptions" #dp1="angular-mydatepicker"
                      (dateChanged)="onAppointmentDateChange($event)" />
                    <label for="appointmentDate">Elected On :</label>
                  </div>
                  <div class="col s1">
                    <div class="row center">
                      <br />
                      <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
                    </div>
                  </div>

                  <div class="input-field class col s11">
                    <i class="material-icons prefix red-text text-lighten-1">event</i>
                    <input formControlName="expiryDate" id="expiryDate" class="validate input-box" type="text"
                      autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
                      [options]="myDpOptions" #dp2="angular-mydatepicker" (dateChanged)="onExpiryDateChange($event)" />
                    <label for="expiryDate"> Mandate Ends On : </label>
                  </div>
                  <div class="col s1">
                    <div class="row center">
                      <br />
                      <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
                    </div>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix pink-text">email</i>
                    <input id="email" type="email" formControlName='email' (input)="showEmailPrivacyRB()"
                      class="validate" autocomplete="off" />
                    <label for="email">Email</label>
                    <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
                      <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
                      <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showEmailPrivacy">
                    <p>Show Email To :</p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value="Public" class="with-gap" formControlName="showEmail" />
                        <span class="text"><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value="Members" class="with-gap" formControlName="showEmail" />
                        <span class="blue-text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showEmail" />
                        <span class="red-text"><strong>Do Not Show</strong></span>
                      </label>
                    </div>

                    <p><strong>Allow Contact Us Messages </strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="allowContact" />
                        <span class="text"><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="allowContact" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="allowContact" />
                        <span class="red-text"><strong>Do Not Allow</strong></span>
                      </label>
                    </div>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">language</i>
                    <input id="website" formControlName='website' type="text" class="validate">
                    <label for="website">Website</label>
                    <div *ngIf="(website.dirty || website.touched) && website.invalid">
                      <small *ngIf="website.errors.pattern" class="red-text"> Invalid web address. </small>
                    </div>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix purple-text ">smartphone</i>
                    <!-- <input id="office_phone" type="text" (input)="showOfficeNoPrivacyRB()"
                      formControlName='office_phone' class="validate" autocomplete="off"
                      [textMask]="{mask: phoneNumberMask}" /> -->
                      <input id="office_phone" type="text" [inputMask]="phoneInputMask" (input)="showOfficeNoPrivacyRB()"
                      formControlName='office_phone' class="validate" autocomplete="off" />
                    <label for="office_phone">Office Phone :</label>
                    <div *ngIf="(getOffice_phone.dirty || getOffice_phone.touched) && getOffice_phone.invalid">
                      <small *ngIf="getOffice_phone.errors.required" class="red-text"> The Office Phone is required.
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showOfficePhonePrivacy">
                    <p><strong>Show Office Phone</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value="Yes" value='Public' class="with-gap" formControlName="showOfficePhone" />
                        <span class="text"><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value="No" value='Members' class="with-gap" formControlName="showOfficePhone" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>

                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showOfficePhone" />
                        <span class="red-text"><strong>Do Not Show</strong></span>
                      </label>
                    </div>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix teal-text text-darken-1">smartphone</i>
                    <!-- <input id="cell_phone" type="text" (input)="showMobileNoPrivacyRB()" formControlName='cell_phone'
                      class="validate" autocomplete="off" [textMask]="{mask: phoneNumberMask}" /> -->
                      <input id="cell_phone" type="text" [inputMask]="phoneInputMask" (input)="showMobileNoPrivacyRB()" formControlName='cell_phone'
                      class="validate" autocomplete="off"  />
                    <label for="cell_phone">Mobile Phone :</label>
                    <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
                      <small *ngIf="getCell_phone.errors.required" class="red-text"> The Mobile Phone is required.
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showMobilePrivacy">
                    <p><strong>Show Mobile Phone</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showMobile" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showMobile" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showMobile" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>

                  <div class="input-field col s12">
                    <i class="material-icons prefix orange-text">description</i>
                    <textarea id="chiefBio" type="text" formControlName='chiefBio'
                      class="materialize-textarea"> </textarea>
                    <label for="chiefBio">Biography</label>
                  </div>

                  <!--  facebook  -->
                  <div class="input-field col s12">
                    <i class="material-icons prefix " style="color: #3b5998;">facebook</i>
                    <input id="chiefFacebook" type="text" formControlName='chiefFacebookAcc'
                      (input)="showFaceBookPrivacyRB()" class="materialize-textarea">
                    <label for="chiefFacebook"> Facebook account</label>
                    <div
                      *ngIf="(getChiefFacebookAcc?.dirty || getChiefFacebookAcc?.touched) && getChiefFacebookAcc?.invalid">
                      <small *ngIf="getChiefFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url format
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showFacebookPrivacy">
                    <p><strong>Show Facebook Account</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showFacebookAcc" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showFacebookAcc" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showFacebookAcc" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>

                  <!--  instagram  -->
                  <div class="input-field col s12">
                    <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
                    <input id="chiefInsta" type="text" formControlName='chiefInstagramAcc'
                      (input)="showInstagramPrivacyRB()" class="materialize-textarea">
                    <label for="chiefInsta"> Instagram account</label>
                    <div
                      *ngIf="(getChiefInstagramAcc?.dirty || getChiefInstagramAcc?.touched) && getChiefInstagramAcc?.invalid">
                      <small *ngIf="getChiefInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showInstagramPrivacy">
                    <p><strong>Show Instagram Account</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showInstagramAcc" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showInstagramAcc" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showInstagramAcc" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>

                  <!--  twitter  -->
                  <div class="input-field col s12">
                    <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
                    <input id="chiefTwitter" type="text" formControlName='chiefTwitterAcc'
                      (input)="showTwitterPrivacyRB()" class="materialize-textarea">
                    <label for="chiefTwitter">Twitter account</label>
                    <div
                      *ngIf="(getChiefTwitterAcc?.dirty || getChiefTwitterAcc?.touched) && getChiefTwitterAcc?.invalid">
                      <small *ngIf="getChiefTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showTwitterPrivacy">
                    <p><strong>Show Twitter Account</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showTwitterAcc" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showTwitterAcc" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showTwitterAcc" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>

                  <!--  linked in  -->
                  <div class="input-field col s12">
                    <i class="fa fa-linkedin prefix " style="color: #0077b5;"></i>
                    <input id="chiefLinkedin" type="text" formControlName='chiefLinkedinAcc'
                      (input)="showLinkedInPrivacyRB()" class="materialize-textarea">
                    <label for="chiefLinkedin">linkedin account</label>
                    <div
                      *ngIf="(getChiefLinkedinAcc?.dirty || getChiefLinkedinAcc?.touched) && getChiefLinkedinAcc?.invalid">
                      <small *ngIf="getChiefLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showLinkedInPrivacy">
                    <p><strong>Show Linkedin Account</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showLinkedinAcc" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showLinkedinAcc" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showLinkedinAcc" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>

                  <!--  youtube  -->
                  <div class="input-field col s12">
                    <i class="fa fa-youtube prefix red-text"></i>
                    <input id="chiefYoutube" type="text" formControlName='chiefYoutubeAcc'
                      (input)="showYouTubePrivacyRB()" class="materialize-textarea">
                    <label for="chiefYoutube">Youtube account</label>
                    <div
                      *ngIf="(getChiefYoutubeAcc?.dirty || getChiefYoutubeAcc?.touched) && getChiefYoutubeAcc?.invalid">
                      <small *ngIf="getChiefYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format
                      </small>
                    </div>
                  </div>

                  <div class="input-field col s12" *ngIf="showYoutubePrivacy">
                    <p><strong>Show Youtube Account</strong></p>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Public' class="with-gap" formControlName="showYoutubeAcc" />
                        <span><strong>General Public</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Members' class="with-gap" formControlName="showYoutubeAcc" />
                        <span class="text"><strong>Members Only</strong></span>
                      </label>
                    </div>
                    <div class="col s12 m6 l4">
                      <label>
                        <input type="radio" value='Private' class="with-gap" formControlName="showYoutubeAcc" />
                        <span class="red-text"><strong>Do Not Show </strong></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row col s12">
                  <button type="submit" class="col s12 btn waves-effect green" title="Save"
                    [disabled]="!chiefForm.valid" style="margin-bottom: 15px;"><i
                      class="material-icons right">cloud_upload</i>
                    <span *ngIf="editMemberModel"> Update Board Member </span>
                    <span *ngIf="!editMemberModel"> Save Board Member </span>
                  </button>
                </div>

                <button *ngIf="editMemberModel" (click)="openDeleteModal();" type="button"
                  class="col s12 btn waves-effect red white-text" style="margin-bottom: 15px;">
                  <i class="material-icons right">remove</i> Delete Board Member
                </button>
                <button *ngIf="editMemberModel" (click)="archiveBoardMember();" type="button"
                  class="col s12 btn waves-effect orange white-text" style="margin-bottom: 15px;">
                  <i class="material-icons right">remove</i> Archive Board Member
                </button>
                <a (click)="backClicked();" [ngClass]="'col s12 btn waves-effect ' + themeColor">
                  <i class="material-icons left">arrow_back_ios</i>
                  Back To Previous Page </a>

              </form>
            </div>
          </div>

          <div class="col l1 "></div>
        </div>

        <div id="deleteBoardMemberConfirmation" class="modal">
          <div class="modal-content">
            <h6>Sure to delete member?</h6>
          </div>
          <div class="modal-footer">
            <button class="waves-effect waves-green btn-flat red white-text"
              (click)="deleteBoardMember();">DELETE</button>
            <button class="modal-close waves-effect waves-green green btn-flat white-text">CANCEL</button>
          </div>
        </div>

      </main>
    </div>
  </div>
</div>