<div class="section"></div>

<div class="row col s12 container">
  <div class="col l1"></div>

  <div class="col s12 l10">
    <app-firebase-loading *ngIf="showLoading"></app-firebase-loading>

    <div class="row">
      <div class="col s6">
        <a (click)="backClicked()">
          <div class="card hoverable white" [ngStyle]="{'border-color':themeColor}"
            style="height: 95px; border: 2px solid ;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons black-text">house</i><br />
                <strong class="black-text">Back</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6  ">
        <a [routerLink]="['/admin/news-events/new-card']">
          <div class="card hoverable " [ngStyle]="{'background-color':themeColor}" style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Create News</strong>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row black-text center" *ngIf="(!model || model?.length == 0) && !showLoading" style="opacity: 0.5;">
      <img src="../../assets/img/empty-news.png" height="450" width="450" class="responsive-img">
      <h6 class="bold-700">Sorry , {{ nationOfUse }} did not post any news!</h6>
    </div>

    <div class="row" *ngIf="model">

      <div *ngFor="let content of model | paginate: { itemsPerPage: 5, currentPage: p }; let i = index">

        <div class="row card news-card hoverable" [ngStyle]="{'background-color': content.backgroundColor}"
          style="cursor: pointer;">

          <!-- <span class="material-symbols-outlined drag-handle right" cdkDragHandle style="color:gray;  font-size: 20px;">
            open_with
          </span> -->
          <!-- 
          [routerLink]="['/admin/news-events/edit-card', content.id]" [ngStyle]="{'color':themeColor}" -->
          <span *ngIf="!content.pinned" class="material-symbols-outlined right tooltipped modal-trigger"
            data-position="top" data-target="pinModal" data-tooltip="Pin" (click)="onPin(i)"
            [ngStyle]="{'color': 'grey'}" style="padding: 10px">
            keep
          </span>

          <span *ngIf="content.pinned" class="material-symbols-outlined right tooltipped modal-trigger"
            data-position="top" data-target="unpinModal" data-tooltip="Unpin" (click)="onUnpin(i)"
            [ngStyle]="{'color': themeColor}" style="padding: 10px; font-variation-settings: 'FILL' 1;">
            keep
          </span>

          <div class=" col s12 card-content">

            <div class="col s12"
              [ngClass]="{'col l9 m8 s12': content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'}">
              <p class="date-content sub-title-section3 p-b-1">
                <span class="right">
                  <p *ngIf="content.visibility === 'Public'" class="green-text"><strong>Public</strong> </p>
                  <p *ngIf="content.visibility === 'Private'" class="red-text"><strong>Members Only</strong> </p>
                </span>
              </p>

              <p class="news-title">
                <a [routerLink]="['/admin/news-events/edit-card', content.id]" class="" [innerHTML]="content.title">
                </a>
              </p>

              <p *ngIf="content.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100"
                style="text-align: justify;">
                {{truncateHTML(content.body,300)}} </p>
              <p *ngIf="!content.imgLink" class="long-body-snippet sub-title-section3 p-t-1 bold-100"
                style="text-align: justify;">
                {{truncateHTML(content.body,300)}} </p>

              <p class="col l4 s12 date-content sub-title-section3 p-t-2">{{content.datePublished.toDate() | date: 'MMM
                dd, yyyy'}}</p>
              <p class="col l4 s12 date-content sub-title-section3 p-t-2">{{content.comments.length}} Comments</p>


              <p class="col l4 s12  sub-title-section3 p-t-2 right">
                <a [routerLink]="['/admin/news-events/edit-card', content.id]"
                  class="bold-900 sub-title-section3 date-content " style="display: flex; align-items: center">
                  Edit News<i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
                </a>
              </p>
            </div>

            <div class="col l3 m4 hide-on-small-only"
              *ngIf="content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'">

              <div class="card-image" *ngIf="content.type === 'Photo' && content.imgLink">
                <img [src]="content.imgLink" class="news-img">
              </div>

              <div class="col l12 s12" *ngIf="content.type === 'PDF' && content.videoLink">
                <iframe title="iframe-box2" class="responsive-pdf" [src]="content.videoLink | safeUrl"></iframe>
              </div>

              <div class="card-image" *ngIf="content.type === 'Video' && content.videoLink">
                <div class="video-container ">
                  <youtube-player [videoId]="embedVideo(content.videoLink)"></youtube-player>
                  <!-- <div [innerHTML]="embedVideo(content.videoLink)"></div> -->
                </div>
              </div>

              <div class="card-image" *ngIf="content.type === 'uploadVideo' && content.videoLink">
                <video class="responsive-video " controls>
                  <source [src]="content.videoLink" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row center">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>

    <div id="pinModal" class="modal center b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close red-text tooltipped" data-position="left"
          data-tooltip="Close">close</i><br />
        <div class="row center-content">

          <h6 class="title-section3 bold-400 black-text p-m-3"><span class="material-symbols-outlined p-r-1 ">
              keep
            </span>Pin to Top</h6>
          <!--<img src="https://img.icons8.com/?size=100&id=44089&format=png&color=000000" height="150px" /> -->

          <h6 class="sub-title-section1 bold-100 p-m-2">
            Are you sure you want to pin this news.
          </h6>

          <div class="row col s12 p-m-3">
            <button type="button" class="btn ll-m-5 col s5 b-n-1 right" [ngStyle]="{'background-color': themeColor}"
              (click)="pin()">Pin</button>
            <button type="button" class="btn modal-close b-n-1" style="background-color: white;"
              [ngStyle]="{'color': themeColor}">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div id="unpinModal" class="modal center b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close red-text tooltipped" data-position="left"
          data-tooltip="Close">close</i><br />
        <div class="row center-content">

          <h6 class="title-section3 bold-400 black-text p-m-3"><span class="material-symbols-outlined p-r-1 ">
              keep
            </span>Unpin from Top</h6>


          <h6 class="sub-title-section1 bold-100 p-m-2">
            Are you sure you want to unpin this news.
          </h6>

          <div class="row col s12 p-m-3">
            <button type="button" class="btn ll-m-5 col s5 b-n-1 right" [ngStyle]="{'background-color': themeColor}"
              (click)="unpin()">Unpin</button>
            <button type="button" class="btn modal-close b-n-1" style="background-color: white;"
              [ngStyle]="{'color': themeColor}">Cancel</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col l1"></div>
</div>