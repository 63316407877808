<div class="row" *ngIf="section">
    <div class="col l8 s12 m12 ">
        <h6 class="p-l-1 bold-700 icon-text"> <i class="material-symbols-outlined tiny bold-900 p-r-1"
                (click)="backClicked()"> arrow_back </i>
            <span *ngIf="!editMode">Create</span>
            <span *ngIf="editMode">Update</span>
            &nbsp;<span>{{section.displayName}}</span>
        </h6>
    </div>

    <div class="row">
        <form [formGroup]="contentForm" (ngSubmit)="saveForm(contentForm.value)">

            <!-- ----------------------------------------- Form --------------------------------->
            <div class="col l6 s12 m12 editor-content">
                <!--------------------------------- Title ---------------------------------------------->
                <div class="row card white" *ngIf="section.options.title">
                    <div class="card-content">
                        <p class="sub-title-section bold-700 p-b-1">Title<span class="red-text">*</span></p>
                        <div class="input-field">
                            <app-editor [title]=true [control]="title"></app-editor>
                            <div *ngIf="(title.dirty || title.touched) && title.invalid">
                                <small *ngIf="title.errors.required" class="red-text"> The Title is required. </small>
                            </div>
                        </div>
                    </div>
                </div>

                <!--------------------------------- Description ---------------------------------------------->
                <div class="row card white" *ngIf="section.options.description">
                    <div class="card-content">
                        <p class="sub-title-section bold-700 p-b-1">Description</p>
                        <div class="input-field">
                            <app-editor [control]="description"></app-editor>
                            <!-- <div *ngIf="(description.dirty || description.touched) && description.invalid">
                                <small *ngIf="description.errors.required" class="red-text"> The description is
                                    required.
                                </small>
                            </div> -->
                        </div>
                    </div>
                </div>

                <!-------------------------------- comment -------------------------------->
                <div class="row card white">
                    <div class="card-content">
                        <p class="row sub-title-section bold-700 p-b-1">
                            Allow Comments? <span class="red-text">*</span></p>
                        <p class="row sub-title-section2 bold-100 p-b-1">Would you want your users to be able to comment
                            on this post.</p>

                        <div class="row">
                            <div class="col s6">
                                <label>
                                    <input type="radio" name="comment" class="with-gap"
                                        [checked]="allowComments != null && allowComments"
                                        (click)="yesCommentClicked()" />
                                    <span><strong class="green-text">Yes</strong></span>
                                </label>
                            </div>
                            <div class="col s6">
                                <label>
                                    <input type="radio" name="comment" class="with-gap"
                                        [checked]="allowComments != null && !allowComments"
                                        (click)="noCommentClicked()" />
                                    <span><strong class="blue-text">No</strong></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!--------------------------------- Media ----------------------------------------->
                <div class="row card white">
                    <div class="card-content col s12 m12"
                        *ngIf="section.options.imageUpload || section.options.videoUpload || section.options.pdfUpload || section.options.youtubeLink">
                        <p class="sub-title-section bold-700">
                            Would you like to inlcude <span *ngIf="section.options.imageUpload">an Image,
                            </span><span *ngIf="section.options.videoUpload">video, </span><span
                                *ngIf="section.options.pdfUpload">PDF, </span> <span
                                *ngIf="section.options.youtubeLink">youtube video </span> ? <span
                                class="red-text">*</span>
                        </p>
                        <div class="row">
                            <div class="col s6">
                                <label>
                                    <input type="radio" class="with-gap" name="addMedia" [checked]="hasMedia" (click)="yesClicked()" />
                                    <span><strong class="green-text">Yes</strong></span>
                                </label>
                            </div>
                            <div class="col s6">
                                <label>
                                    <input type="radio" class="with-gap" name="addMedia" [checked]="hasNoMedia" (click)="noClicked()" />
                                    <span><strong class="blue-text">No</strong></span>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="card-content" *ngIf="addMedia && !singleMedia">
                        <p class="sub-title-section2 bold-700 p-b-1">Please Select a Media Type</p>

                        <!-- <div class="col s6" *ngIf="section.options.link">
                            <label>
                                <input type="radio" [checked]="isUrl" name="group2" (click)="linkClick()" />
                                <span><strong>Link/URL</strong></span>
                            </label>
                        </div> -->

                        <div class="col s6" *ngIf="section.options.imageUpload">
                            <label>
                                <input type="radio" class="with-gap" [checked]="isPhoto" name="type" (click)="photoClick()" />
                                <span><strong>Photo</strong></span>
                            </label>
                        </div>

                        <div class="col s6" *ngIf="section.options.videoUpload">
                            <label>
                                <input type="radio" class="with-gap" [checked]="isUpload" name="type" (click)="videoClick()" />
                                <span><strong>Upload Video</strong></span>
                            </label>
                        </div>


                        <div class="col s6" *ngIf="section.options.youtubeLink">
                            <label>
                                <input type="radio" class="with-gap" [checked]="isYoutube" name="type" (click)="youtubeClick()" />
                                <span><strong>Youtube Link</strong></span>
                            </label>
                        </div>

                        <div class="col s6" *ngIf="section.options.pdfUpload">
                            <label>
                                <input type="radio" class="with-gap" [checked]="isPdf" name="type" (click)="uploadPdf()" />
                                <span><strong>PDF File</strong></span>
                            </label>
                        </div>
                    </div>

                    <!------------------------------ Link/URL ----------------------------------------->
                    <!-- <div class="card-content" *ngIf="addMedia && mediaType === 'Link'">
                        <div class="col s12">
                            <p class="sub-title-section2 bold-700 p-b-1">Please Copy/Paste The Link Or Url</p>
                            <div class="input-field">
                                <input id="mediaLink" type="text" class='validate' autocomplete="off" />
                                <label for="mediaLink">URL </label>
                                <div *ngIf="(mediaLink.dirty || mediaLink.touched) && mediaLink.invalid">
                                    <small *ngIf="mediaLink.errors.required" class="red-text">The Link/URL is required.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!------------------------------ Photo -------------------------------------------->
                    <div class="card-content" *ngIf="mediaType && mediaType === 'Photo'">

                        <div *ngIf="!fileUploaded">
                            <p class="sub-title-section2 bold-700 p-b-1 col s12">
                                Please select an image from your library
                            </p>

                            <div class="card col s12 m6 l4" (change)="handlePhotoInput($event.target.files)">
                                <div class="center-align">
                                    <input type="file" formControlName="mediaLink" accept="image/*"
                                        (change)="handlePhotoInput($event.target.files)" />
                                    <i class="fa fa-cloud-upload p-t-2"></i>
                                    <h4 class="label-input p-b-2  bold-200">
                                        Drop & Drop file here or Click to Upload</h4>
                                </div>

                                <p class="red-text" *ngIf="imgErrorMsg">
                                    <strong><small><i class="material-icons">error_outline</i>
                                            {{imgErrorMsg}}</small></strong>
                                </p>
                            </div>
                        </div>

                        <div class="col s12 m6 l6 push-l1 img-wrap p-m-3" *ngIf="fileUploaded">
                            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
                                <i class="material-symbols-outlined">
                                    delete
                                </i>Delete</a>

                            <img *ngIf="uploadedFile" class="materialboxed responsive-img" [src]="uploadedFile"
                                width="100%" height="auto" />

                            <img *ngIf="fileUrl" class="materialboxed responsive-img" [src]="fileUrl" width="100%"
                                height="auto" />


                        </div>
                    </div>

                    <!-- -------------------------- upload video ----------------------------------------->
                    <div class="card-content" *ngIf="mediaType && mediaType === 'Video'">
                        <div class="card-content">
                            <div *ngIf="!fileUploaded">
                                <p class="sub-title-section2 col s12 bold-700 p-b-1">Please select a video from your
                                    library
                                </p>

                                <div class="card col l5 s12 m6" (change)="handleVideoInput($event.target.files)">
                                    <div class="center-align">
                                        <input type="file" formControlName="mediaLink" accept="video/*"
                                            (change)="handleVideoInput($event.target.files)" />
                                        <i class="fa fa-cloud-upload p-t-3"></i>
                                        <h4 class="label-input p-b-3  bold-200">
                                            Drop & Drop file here or Click to Upload</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="fileUploaded">
                                <a class="delete-link btn-floating waves-effect waves-light right"
                                    (click)="removeFile()">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>Delete</a>
                                <div class="center center-align" *ngIf="uploadedFile">
                                    <video controls class="uploadedVideo" width="100%" height="auto">
                                        <source id="video_here">
                                        Your browser does not support HTML5 video.
                                    </video>
                                </div>

                                <div class="center center-align" *ngIf="fileUrl">
                                    <video controls width="100%" height="auto">
                                        <source id="video_here" [src]="fileUrl" type="video/mp4">
                                        Your browser does not support HTML5 video.
                                    </video>
                                </div>
                            </div>

                            <!-- <div class="col s12 m6 l6 push-l1 img-wrap"
                                *ngIf="editMode && fileUploaded && uploadedFile">
                                <a class="delete-link btn-floating waves-effect waves-light right"
                                    (click)="removeFile()">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>Delete</a>
                                <video controls width="100%" height="auto">
                                    <source id="video_here" [src]="uploadedFile" type="video/mp4">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div> -->
                        </div>
                    </div>

                    <!-- ------------------------------------------youtube videos ------------------------------------------>
                    <div class="card-content" *ngIf="mediaType && mediaType === 'Youtube'">
                        <div class="card-content">

                            <div *ngIf="!fileUploaded">
                                <p class="sub-title-section2 col s12 bold-700 p-b-1">Please Copy/Paste the video link from
                                    YouTube
                                </p>

                                <div class="row col s12">
                                    <div class="input-field">
                                        <!-- <div class="label-input">YouTube Video Link </div> -->
                                        <input id="videoLink" type="text" formControlName='mediaLink' class='validate'
                                            autocomplete="off" placeholder="YouTube Video Link"
                                            (input)="youtubeLink($event)" />
                                        <div *ngIf="(mediaLink.dirty || mediaLink.touched) && mediaLink.invalid">
                                            <small *ngIf="mediaLink.errors.required" class="red-text">The Video Link is
                                                required. </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col s12 m6 l5 push-l1 img-wrap" *ngIf="fileUploaded">
                                <a class="delete-link btn-floating waves-effect waves-light right"
                                    (click)="removeFile()">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>Delete</a>
                                <div class="video-container">
                                    <div [innerHTML]="iframehtml"></div>
                                </div>

                                <!-- <div class="video-container">
                                    <div [innerHTML]="embedVideo(uploadedFile)"></div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <!-- -------------------------  PDF ----------------------------------------->
                    <div class="card-content" *ngIf="mediaType && mediaType === 'PDF'">
                        <div class="card-content">

                            <div class="row col s12" *ngIf="!fileUploaded">
                                <p class="sub-title-section2 bold-700 p-b-1">Please select a PDF file from your library
                                </p>
                                <div class="file-field input-field">
                                    <div class="btn white-text" [ngStyle]="{'background-color': themeColor}">
                                        <span>Browse</span>
                                        <input type="file" formControlName="mediaLink" accept=".pdf "
                                            (change)="handlePDFInput($event.target.files)" />
                                    </div>
                                    <div class="file-path-wrapper">
                                        <input formControlName="mediaLink" class="file-path validate" type="text"
                                            placeholder="Select PDF File From Library" />
                                    </div>
                                </div>
                            </div>

                            <div class="col s12 m6 l6 push-l1" *ngIf="fileUploaded">
                                <a class="delete-link btn-floating waves-effect waves-light right"
                                    (click)="removeFile()">
                                    <i class="material-symbols-outlined">
                                        delete
                                    </i>Delete</a>

                                <iframe id="pdfViewer" title="iframe-box2" class="responsive-pdf" 
                                    *ngIf="editMode && fileUrl" [src]="fileUrl | safeUrl"></iframe>

                                <!-- <iframe id="pdfViewer" style="width: 100%; height: 500px;" frameborder="0"
                                    *ngIf="uploadedFile"></iframe> -->

                                <p class="sub-title-section2 bold-700"><span 
                                        class="material-icons red-text">
                                        picture_as_pdf
                                    </span>File uploaded</p>
                            </div>

                        </div>`
                    </div>
                </div>

                <div class="row card white">
                    <div class="card-content">
                        <p class="row sub-title-section bold-700 p-b-1">
                            Share Post? <span class="red-text">*</span></p>
                        <p class="row sub-title-section2 bold-100 p-b-1">Do you want to publish this post for the public
                            or keep it within the members only?</p>

                        <div class="row">
                            <div class="col s6">
                                <label>
                                    <input type="radio" name="share" class="with-gap" [checked]="shareToPublic != null && shareToPublic"
                                        (click)="sharePublic()" />
                                    <span><strong class="green-text">Public</strong></span>
                                </label>
                            </div>
                            <div class="col s6">
                                <label>
                                    <input type="radio" name="share" class="with-gap" [checked]="shareToPublic != null && !shareToPublic"
                                        (click)="shareMember()" />
                                    <span><strong class="blue-text">Member</strong></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-b-6 p-t-6" *ngIf="!editMode">
                    <a type="button" class="col  l3 s9 push-s1 btn back-button white black-text hide-on-med-and-down"
                        (click)="backClicked()" [ngClass]="'btn ' + buttonType"
                        style="margin: 15px;  margin-right:90px;">
                        Back
                    </a>

                    <button [disabled]="!contentForm.valid" type="submit" class="col  l3 s9 push-s1 btn publish-button"
                        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
                        Save
                    </button>
                </div>

                <div class="row p-b-6 p-t-6" *ngIf="editMode">
                    <a type="button" class="col l3 s9 push-s1 btn back-button white black-text"
                        [ngClass]="'btn ' + buttonType" (click)="backClicked()"
                        style="margin: 15px;  margin-right:90px;">
                        Back
                    </a>

                    <button type="button" class="col l3 s9 push-s1 btn delete-button"
                        [ngClass]="'btn ' + buttonType" (click)="onDelete()"
                        style="margin: 15px; text-align: center;">
                        Delete
                    </button>

                    <button type="button" [disabled]="!contentForm.valid" class="col l3 s9 push-s1 btn update-button"
                        [ngClass]="'btn ' + buttonType" (click)="update(contentForm.value)" style="margin: 15px;">
                        Update
                    </button>
                </div>
            </div>

            <!------------------------------------------- preview ------------------------------->
            <div id="demoItem2" class="col l6 s12 m12 preview-content hide-on-med-and-down">
                <div class="preview-wrapper">
                    <input type="radio" name="size" class="size-select size1 with-gap" checked id="ss1"><label for="ss1"
                        title="Small (eg phone)">
                        <i class="material-symbols-outlined black-text">
                            smartphone
                        </i>
                    </label>

                    <input type="radio" name="size" class="size-select size2 with-gap" id="ss2">
                    <label for="ss2" title="Medium (eg tablet)">
                        <i class="material-symbols-outlined black-text">
                            tablet_android
                        </i>
                    </label>

                    <input type="radio" name="size" class="size-select size3 with-gap" id="ss3">
                    <label for="ss3" title="Large (eg desktop)">
                        <i class="material-symbols-outlined black-text">
                            airplay
                        </i>
                    </label>

                    <br />
                    <div class="col s12" id="viewer" name="Viewer">
                        <div class="row card">
                            <div class="card-content">
                                <div class="row">
                                    <h6 class="center" [innerHTML]="title.value"></h6>
                                    <div class="section"></div>

                                    <div *ngIf="mediaType === 'Photo' && fileUploaded">
                                        <div class="center center-align">
                                            <img *ngIf="uploadedFile" class="materialboxed responsive-img"
                                                [src]="uploadedFile" width="100%" height="auto" />

                                            <img *ngIf="fileUrl" class="materialboxed responsive-img" [src]="fileUrl"
                                                width="100%" height="auto" />


                                            <!-- <div *ngIf="editMode">
                                                <img *ngIf="uploadedFile" class="materialboxed responsive-img"
                                                    [src]="uploadedFile" width="100%" height="auto" />

                                                <img *ngIf="!uploadedFile && toBeUploaded"
                                                    class="materialboxed responsive-img" [src]="toBeUploaded"
                                                    width="100%" height="auto" />
                                            </div>

                                            <div *ngIf="!editMode">
                                                
                                            </div> -->

                                            <!-- <img class="responsive-img" [src]="uploadedFile" height="100%"
                                                width="100%" /> -->
                                        </div>
                                    </div>

                                    <div *ngIf="mediaType === 'Video' && fileUploaded">
                                        <div class="center center-align" *ngIf="uploadedFile">
                                            <video controls class="uploadedVideo" width="100%" height="auto">
                                                <source id="video_here">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>

                                        <div class="center center-align" *ngIf="fileUrl">
                                            <video controls width="100%" height="auto">
                                                <source id="video_here" [src]="fileUrl" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="mediaType === 'Youtube' && fileUploaded">
                                        <div class="video-container">
                                            <youtube-player [videoId]="iframehtml"></youtube-player>
                                            <!-- <div [innerHTML]="iframehtml"></div> -->
                                        </div>
                                    </div>

                                    <div *ngIf="mediaType === 'PDF' && fileUploaded"> 
                                        <iframe id="pdfViewer" style="width: 100%; height: 500px;" frameborder="0"
                                            *ngIf="uploadedFile"></iframe>

                                        <iframe id="pdfViewer" title="iframe-box2" class="responsive-pdf"
                                            *ngIf="fileUrl" [src]="fileUrl | safeUrl"></iframe>
                                    </div>

                                    <div class="row col s12">
                                        <span [innerHTML]="description.value"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="openDeleteModal">
                <app-modals [modalType]="'delete'" [data]="modalData" (confirm)="confirmHandler($event)"
                    (cancel)="cancelHandler($event)"></app-modals>
            </div>


        </form>
    </div>
</div>`