import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SMSModel } from 'src/app/models/SMSModel';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-send-msg',
  templateUrl: './send-msg.component.html',
  styleUrl: './send-msg.component.css'
})

export class SendMsgComponent implements OnInit {

  @Input() fbAdmin: any;
  @Input() user: any;
  @Input() phoneNoList: any;
  @Output() msgSent: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = false;
  SMSForm: FormGroup;
  msgSignature: any;
  adminInfo: any;

  mockAdminInfo = {
    uid: 1,
    FirstName: 'Mihret',
    LastName: 'Tamene',
    loginID: 'test1',
    password: 'password',
    userGroup: 'Socail',
    Active: 1,
    Email_Address: 'test@gmail.com',
    phone: '1234567890',
    ph_extension: '123',
    Cell: '0987654321',
    Title: 'Member',
    show_cell: 1,
    show_office: 1,
    show_title: 1,
    show_email: 1,
    default_band: 0
  };

  constructor(private fb: FormBuilder,
    private smsService: SMSService,
    private adminService: ManageAdminService,
    private appSetting: AppSettingsService) {
  }

  ngOnInit() {
    this.loading = true;

    this.appSetting.getAppSetting().valueChanges().subscribe(setting => {
      if (setting) {
        let appSetting: any = setting[0];
        this.msgSignature = appSetting.messageSignature;
        this.SMSForm = this.fb.group({
          subject: [''],
          body: ['', [Validators.required]],
        });

        if (this.fbAdmin?.id) {
          this.adminService.getAdmin(this.fbAdmin?.id).subscribe(admin => {
            if (admin && admin != 'No Result Found') this.adminInfo = admin;
            else {
              this.adminInfo = this.mockAdminInfo;
              //this.adminInfo = undefined;
              // console.log('Admin not exists:  ', this.adminInfo);
            }
            setTimeout(() => {
              $('.modal').modal();
              $('#smsModal').modal('open');
              M.updateTextFields();
              this.loading = false;
            });
          });
        } else {
          setTimeout(() => {
            $('.modal').modal();
            $('#smsModal').modal('open');
            M.updateTextFields();
            this.loading = false;
          });
        }
      }
    });
  }

  get body() { return this.SMSForm.get('body'); }

  send(values) {
    let msg = new SMSModel();
    msg.phoneNumberList = this.phoneNoList;
    if (this.msgSignature.greeting) msg.body = this.msgSignature.greeting + " " + this.user.firstName + ", \n";
    msg.body += values.body;
    if (this.msgSignature.closing) msg.body += "\n" + this.msgSignature.closing;
    if (this.msgSignature.signName) msg.body += "\n" + this.fbAdmin.FirstName + " " + this.fbAdmin.LastName;

    if (this.adminInfo) {
      // if (this.msgSignature.signName) msg.body += "\n" + this.adminInfo.FirstName + " " + this.adminInfo.LastName;
      if (this.adminInfo.show_title && this.adminInfo.Title) {
        msg.body += "\n" + this.adminInfo.Title + "\n";
      }
      if (this.adminInfo.show_email && this.adminInfo.Email_Address) msg.body += "Email: " + this.adminInfo.Email_Address;
      if (this.adminInfo.show_office && this.adminInfo.phone) msg.body += " Office: " + this.adminInfo.phone;
      if (this.adminInfo.show_cell && this.adminInfo.Cell) msg.body += " Cell: " + this.adminInfo.Cell;
    }
  
    // if (this.contactDetail?.firstName && this.contactDetail?.lastName) msg.name = this.contactDetail.firstName + " " + this.contactDetail.lastName;
    // if (this.contactDetail?.Email_Address) msg.Email_Address = this.contactDetail.Email;

    this.smsService.sendSMS(msg).subscribe(x => {
      toast({ html: 'Text Message Successfully Sent.', classes: 'green' });
      $('#SMSModal').modal('close');
      this.msgSent.emit(true);
    });

  }

  close() {
    this.msgSent.emit(false);
    $('.modal').modal('close');
  }

}
