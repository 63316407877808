import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployerMainComponent } from './employer-main/employer-main.component';
import { SearchSkillsComponent } from './search-skills/search-skills.component';
import { PostJobComponent } from './post-job/post-job.component';
import { HomeComponent } from './home/home.component';
import { MyContractsComponent } from './my-contracts/my-contracts.component';
import { JobUpdateComponent } from './my-contracts/job-update/job-update.component';
import { JobArchiveComponent } from './job-archive/job-archive.component';

const routes: Routes = [
  // {
  //   path: 'employer',
  //   component: EmployerMainComponent,
  // canActivate: [MemberAuthGuard],
  //children: [
  {
    path: 'home',
    component: HomeComponent,
    // canActivate: [MemberAuthGuard],
  },
  {
    path: 'search-skills',
    component: SearchSkillsComponent,
    // canActivate: [MemberAuthGuard],
  },
  {
    path: 'post-job',
    component: PostJobComponent,
    // canActivate: [MemberAuthGuard],
  },
  {
    path: 'my-contracts',
    component: MyContractsComponent,
    // canActivate: [MemberAuthGuard],
  },
  {
    path: 'my-contracts/job-update/:id',
    component: JobUpdateComponent,
  },
  {
    path: 'job-archived',
    component: JobArchiveComponent,
  },
  //]
  //}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EmployerRoutingModule { }
