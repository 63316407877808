<div>

  <div class="row center-align p-m-2">
    <h6><strong> Update Chief Profile </strong></h6>
  </div>

  <div>
    <div class="card-image" *ngIf="user">
      <div class="prof-img row center">
        <div *ngIf="!user.profileImgLink">
          <img class="row imgC center" src="../../../../../assets/img/profile.jpg"
            style="width: 120px; border: white solid 3px; border-radius: 50%;" />
        </div>
        <div *ngIf="user.profileImgLink">
          <img class="row imgC center" [src]="user.profileImgLink"
            style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;" />
        </div>
      </div>
    </div>
  </div>

  <div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 l10">
      <form [formGroup]="addChiefForm">
        <div class="row card-panel white">
          <br />
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Chief Information</strong></h6>
          <br />
          <div class="row">
            <div class="input-field col s12">
              <input id="firstName" disabled type="text" formControlName='firstName' />
              <label for="firstName">First Name :</label>
            </div>

            <div class="input-field col s12">
              <input id="lastName" disabled type="text" formControlName='lastName' />
              <label for="lastName">Last Name :</label>
            </div>

            <div class="input-field col s12">
              <input id="displayName" type="text" formControlName='displayName' />
              <label for="firstName">Display Name :</label>
            </div>

            <div class="input-field class col s11">
              <i class="material-icons prefix green-text">today</i>
              <input id="appointmentDate" type="text" formControlName='appointmentDate' class="input-box"
                autocomplete="off" angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
                [options]="myDpOptions" #dp1="angular-mydatepicker" (dateChanged)="onAppointmentDateChange($event)" />
              <label for="appointmentDate">Elected On :</label>
            </div>

            <div class="col s1">
              <div class="row center">
                <br />
                <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
              </div>
            </div>

            <div class="input-field class col s11">
              <i class="material-icons prefix red-text text-lighten-1">event</i>
              <input formControlName="expiryDate" id="expiryDate" disabled class="validate input-box" type="text"
                autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
                [options]="myDpOptions" #dp2="angular-mydatepicker" (dateChanged)="onExpiryDateChange($event)" />
              <label for="expiryDate"> Mandate Ends On : </label>
            </div>

            <div class="col s1">
              <div class="row center">
                <br />
                <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix pink-text">email</i>
              <input id="email" type="email" formControlName='email' class="validate" autocomplete="off" />
              <label for="email">Email</label>
              <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
                <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
                <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix purple-text">smartphone</i>
              <!-- <input id="office_phone" type="text" formControlName='office_phone' class="validate" autocomplete="off"
                [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="office_phone" type="text" [inputMask]="phoneInputMask" formControlName='office_phone'
                class="validate" autocomplete="off" />
              <label for="office_phone">Office Phone :</label>
              <div *ngIf="(getOffice_phone.dirty || getOffice_phone.touched) && getOffice_phone.invalid">
                <small *ngIf="getOffice_phone.errors.required" class="red-text"> The Office Phone is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix teal-text text-darken-1">smartphone</i>
              <!-- <input id="cell_phone" type="text" formControlName='cell_phone' class="validate" autocomplete="off"
                [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="cell_phone" type="text" [inputMask]="phoneInputMask" formControlName='cell_phone'
                class="validate" autocomplete="off" />
              <label for="cell_phone">Mobile Phone :</label>
              <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
                <small *ngIf="getCell_phone.errors.required" class="red-text"> The Mobile Phone is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix orange-text">description</i>
              <textarea id="chiefBio" type="text" formControlName='chiefBio' class="materialize-textarea"> </textarea>
              <label for="chiefBio">Chief's Bio</label>
            </div>

            <!--  facebook  -->
            <div class="input-field col s12">
              <i class="material-icons prefix " style="color: #3b5998;">facebook</i>
              <input id="chiefFacebook" type="text" formControlName='chiefFacebookAcc' class="materialize-textarea">
              <label for="chiefFacebook">Chief's Facebook account</label>
              <div *ngIf="(getChiefFacebookAcc?.dirty || getChiefFacebookAcc?.touched) && getChiefFacebookAcc?.invalid">
                <small *ngIf="getChiefFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url format </small>
              </div>
            </div>

            <!--  instagram  -->
            <div class="input-field col s12">
              <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
              <input id="chiefInsta" type="text" formControlName='chiefInstagramAcc' class="materialize-textarea">
              <label for="chiefInsta">Chief's Instagram account</label>
              <div
                *ngIf="(getChiefInstagramAcc?.dirty || getChiefInstagramAcc?.touched) && getChiefInstagramAcc?.invalid">
                <small *ngIf="getChiefInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format </small>
              </div>
            </div>

            <!--  twitter  -->
            <div class="input-field col s12">
              <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
              <input id="chiefTwitter" type="text" formControlName='chiefTwitterAcc' class="materialize-textarea">
              <label for="chiefTwitter">Chief's twitter account</label>
              <div *ngIf="(getChiefTwitterAcc?.dirty || getChiefTwitterAcc?.touched) && getChiefTwitterAcc?.invalid">
                <small *ngIf="getChiefTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format </small>
              </div>
            </div>

            <!--  linked in  -->
            <div class="input-field col s12">
              <i class="fa fa-linkedin prefix " style="color: #0077b5;"></i>
              <input id="chiefLinkedin" type="text" formControlName='chiefLinkedinAcc' class="materialize-textarea">
              <label for="chiefLinkedin">Chief's linkedin account</label>
              <div *ngIf="(getChiefLinkedinAcc?.dirty || getChiefLinkedinAcc?.touched) && getChiefLinkedinAcc?.invalid">
                <small *ngIf="getChiefLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format </small>
              </div>
            </div>

            <!--  youtube  -->
            <div class="input-field col s12">
              <i class="fa fa-youtube prefix red-text"></i>
              <input id="chiefYoutube" type="text" formControlName='chiefYoutubeAcc' class="materialize-textarea">
              <label for="chiefYoutube">Chief's youtube account</label>
              <div *ngIf="(getChiefYoutubeAcc?.dirty || getChiefYoutubeAcc?.touched) && getChiefYoutubeAcc?.invalid">
                <small *ngIf="getChiefYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format </small>
              </div>
            </div>
          </div>
        </div>

        <!-- contact setting -->
        <div class="row   card-panel white">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact Settings</strong></h6>
          <!-- mobile -->
          <div class="col s12">
            <p><strong>Show Mobile Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showMobile" class="with-gap" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showMobile" class="with-gap" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showMobile" class="with-gap" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!-- office number -->
          <div class="col s12">
            <p><strong>Show Office Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Yes" value='Public' formControlName="showOfficePhone" class="with-gap" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="No" value='Members' formControlName="showOfficePhone" class="with-gap" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>

            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showOfficePhone" class="with-gap" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <!-- emial address -->
          <div class="col s12">
            <p><strong>Show Email Address</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showEmail" class="with-gap" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showEmail" class="with-gap" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showEmail" class="with-gap" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <!-- contact us message -->
          <div class="col s12">
            <p><strong>Allow Contact Us Messages </strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="allowContact" class="with-gap"/>
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="allowContact" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="allowContact" class="with-gap"/>
                <span class="red-text"><strong>Do Not Allow</strong></span>
              </label>
            </div>
          </div>

          <!-- facebook -->
          <div class="input-field col s12">
            <p><strong>Show Facebook Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showFacebookAcc" class="with-gap"/>
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showFacebookAcc" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showFacebookAcc" class="with-gap"/>
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!-- instagram -->
          <div class="input-field col s12">
            <p><strong>Show Instagram Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showInstagramAcc" class="with-gap"/>
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showInstagramAcc" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showInstagramAcc" class="with-gap"/>
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!-- twitter -->
          <div class="input-field col s12">
            <p><strong>Show Twitter Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showTwitterAcc" class="with-gap"/>
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showTwitterAcc" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showTwitterAcc" class="with-gap"/>
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!-- linkedin -->
          <div class="input-field col s12">
            <p><strong>Show Linkedin Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showLinkedinAcc" class="with-gap"/>
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showLinkedinAcc" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showLinkedinAcc" class="with-gap"/>
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!-- youtube -->
          <div class="input-field col s12">
            <p><strong>Show Youtube Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showYoutubeAcc" class="with-gap"/>
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showYoutubeAcc" class="with-gap"/>
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showYoutubeAcc" class="with-gap"/>
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <div class="row">
            <br />
          </div>

        </div>
      </form>

      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Portfolio</strong></h6>
        <div class="row">
          <ul class="collection col s12" *ngIf="departmentsOptions">
            <li class="collection-item" *ngFor="let item of departmentsOptions">
              <div>
                <label>
                  <input type="checkbox" class="filled-in" [(ngModel)]="item.isSelected" value="item.id" />
                  <span [innerHTML]="item.name"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section"></div>
      <button class="col s12 btn waves-effect green" title="Save" [disabled]="!addChiefForm.valid"
        (click)="addChiefData()" style="margin-bottom: 15px;"><i class="material-icons right">cloud_upload</i>Update
        Chief</button>
      <button class="col s12 btn waves-effect blue" (click)="backClicked()" title="Cancel"
        style="margin-bottom: 15px;"><i class="material-icons left">arrow_back_ios</i> Back To Previous Page</button>
    </div>
    <div class="col l1 "></div>
  </div>
</div>

<div *ngIf="!isChief" class="center">
  <h5>You are not a Chief</h5>
</div>