<div class="row" *ngIf="invitation">
  <h5 class="center p-m-2">Welcome, <span [ngClass]="fontColor"> {{ invitation.lastName }} {{invitation.givenName}}
    </span></h5>
  <p class="center" *ngIf="step0"><strong> This is a secure site. Please help us verify your identity by answering the
      questions below. </strong></p>
  <p class="center" *ngIf="step1"><strong> Please Complete The Registration Form To Create Your Department Employee
      Account </strong></p>

  <div class="col m1 l2 "></div>
  <div class="col s12 m10 l8 ">
    <div class="row" *ngIf="step0">
      <form [formGroup]="validationForm" (ngSubmit)="validate(validationForm.value)">

        <div class="row card-panel white">
          <div class="row" *ngIf="invitation.validateSin">
            <div class="input-field col s12 l12">
              <i class="material-icons prefix">lock</i>
              <input id="sin" type="number" formControlName='sin' required class="validate"
                onKeyPress="if(this.value.length > 9 || this.value > 100000000) return false;" />
              <label for="sin">Social Insurance Number <span class="red-text">(9 Digits)</span></label>
              <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                <small *ngIf="sin.errors.required" class="red-text"> The SIN Is Required. </small>
                <small *ngIf="sin.errors.minlength || sin.errors.minlength" class="red-text"> SIN Must be 9 digits.
                </small>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="invitation.validateIsn">
            <div class="input-field col s11">
              <i class="material-icons prefix">lock</i>
              <input id="isn" type="number" formControlName='isn' required class="materialize-textarea validate"
                onKeyPress="if(this.value.length > 10 || this.value > 1000000000) return false;" />
              <label for="isn">Indian Status Number <span class="red-text">(10 Digits)</span></label>
              <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                <small *ngIf="isn.errors.required" class="red-text"> The ISN Is Required. </small>
                <small *ngIf="isn.errors.minlength || isn.errors.minlength" class="red-text"> SIN Must be 10 digits.
                </small>
              </div>
            </div>
            <div class="col s1">
              <div class="row center">
                <br />
                <i class="material-icons green-text modal-trigger" data-target="modal2">help</i>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="input-field col s12 center">
              <button type="submit" [disabled]="!validationForm.valid"
                [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">lock</i> Verify
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row" *ngIf="step1">
      <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)">
        <span style="color: blue;"></span>
        <div class="row card-panel white" *ngIf="invitation.isManager">
          <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>My Job Details</strong></h6>
          <div class="input-field col s12 l12">
            <i class="material-icons prefix pink-text">work</i>
            <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate" autocomplete="off" />
            <label for="jobTitle">Job Title</label>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix teal-text">call</i>
            <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}" required
              class="validate" autocomplete="off" /> -->
              <input id="workPhone" type="text" formControlName='workPhone' required 
              class="validate" autocomplete="off" />
            <label for="workPhone">Work Phone</label>
            <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
              <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix purple-text text-darken-2">stay_current_portrait</i>
            <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
              required class="validate" autocomplete="off" /> -->
              <input id="mobilePhone" type="text"  formControlName='mobilePhone' [inputMask]="phoneInputMask"
              required class="validate" autocomplete="off" />
            <label for="mobilePhone">Mobile Phone</label>
            <div *ngIf="(mobilePhone.dirty || mobilePhone.touched) && mobilePhone.invalid">
              <small *ngIf="mobilePhone.errors.required" class="red-text"> The Mobile Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix green-text">description</i>
            <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
              autocomplete="off"></textarea>
            <label for="description">Responibilities</label>
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> The Job Description is required. </small>
            </div>
          </div>
        </div>

        <div class="row card-panel white" *ngIf="invitation.isManager || invitation.manageContact">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>My Contact Settings</strong></h6>
          <div class="col s12">
            <p><strong>Show Mobile Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Public'" (click)="publicMobileClick()" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                  (click)="membersMobileClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                  (click)="privateMobileClick()" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Office Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                  (click)="publicOfficeClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                  (click)="membersOfficeClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                  (click)="privateOfficeClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Email Address</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Public'" (click)="publicEmailClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                  (click)="membersEmailClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                  (click)="privateEmailClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Allow Contact Us Messages </strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Public'"
                  (click)="publicContactClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Members'"
                  (click)="membersContactClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Private'"
                  (click)="privateContactClick()" />
                <span class="red-text"><strong>Do Not Allow</strong></span>
              </label>
            </div>
          </div>
          <div class="row">
            <br />
          </div>
        </div>

        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>My Login Information</strong></h6>
          <div class="input-field col s12 l12">
            <i class="material-icons prefix blue-text">email</i>
            <input id="email" type="text" [value]="invitation.email" disabled class="validate" />
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix orange-text text-lighten-2">lock</i>
            <input id="createPassword" type="password" formControlName='createPassword' required class="validate"
              minlength="4" maxlength="25" autocomplete="off" />
            <label for="createPassword">Create Password</label>
            <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
              <small *ngIf="createPassword.errors.required" class="red-text"> The Password Is Required.
              </small>
              <small *ngIf="createPassword.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                Characters . </small>
              <small *ngIf="createPassword.errors.maxlength" class="red-text"> Password Must Not Exceed 25
                Characters . </small>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix red-text text-lighten-1">lock</i>
            <input id="confirmPassword" type="password" required formControlName='confirmPassword' autocomplete="off"
              class="validate">
            <label for="confirmPassword">Confirm Password</label>
            <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
              <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is required. </small>
              <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match ! </small>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <button type="submit" [disabled]="!registrationForm.valid" [ngClass]="'col s12 btn waves-effect ' + themeColor">
          <i class="material-icons right">navigate_next</i> Next
        </button>
      </form>
    </div>

    <div *ngIf="step2">
      <div class="row card-panel white">
        <div class="row center">
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
          <h6 class="green-text"><strong> Nation Employee Account Successfully Created </strong></h6>
          <p><strong>
              Thank you for taking the time to
              fill out the registration form
            </strong></p>
          <br />
        </div>
        <a [routerLink]="['/auth/login']" class="btn col s12">Login To My Account</a>
      </div>
    </div>
  </div>
  <div class="col m1 l2 "></div>
</div>