<br/>
<div class="row center-align">
    <h6><strong> Approve Payments </strong></h6>
</div>


<div class="row col s12">
  <div class="col l "></div>
  <div class="col s12 m12 l10">


    <div class="card-panel white darken-2">
      <h6 class="center black-text"><i class="material-icons blue-text left">info</i>
        <strong> Once ready, this form will allow you to approve payments from your mobile app.</strong>
      </h6> </div>
<div class="section"></div>
      <a class="col s12 btn waves-effect" [ngStyle]="{'background-color': themeColor}" (click)="backClicked()"><i
        class="material-icons left">house</i> Back To Home Page</a>

  </div>
  <div class="col l1 "></div>
</div>
