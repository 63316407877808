<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4>Client Admin</h4>
            </div>
        </div>

        <main class="StickyContent white">

            <div *ngIf="!inviteAdmin">
                <!-- Search Admin-->
                <div class="row col s12 m12" *ngIf="searchMember">

                    <div *ngIf="!showDetail">
                        <!--filter form-->
                        <div class="col s12 m5 l4">
                            <form [formGroup]="filterForm" (ngSubmit)="search(filterForm.value)">
                                <div class="row card-panel white">
                                    <a class="right icon-text" (click)="clearFilters()" style="cursor: pointer;"><span
                                            class="material-icons-outlined">
                                            clear
                                        </span>Clear</a>
                                    <h5 class="sub-title-section bold-700">Search Members</h5>

                                    <!-- <small *ngIf="filterForm.invalid && (filterForm.dirty || filterForm.touched)" class="red-text">
                                        At least one field is required.
                                    </small> -->

                                    <div class="input-field col s12 m12">
                                        <div class="label-input">First Name</div>
                                        <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                                            (input)="search(filterForm.value)" style="text-transform: capitalize;"
                                            class="validate" placeholder="First Name" />
                                    </div>

                                    <div class="input-field col s12 m12">
                                        <div class="label-input">Last Name</div>
                                        <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                                            (input)="search(filterForm.value)" style="text-transform: capitalize;"
                                            class="validate" placeholder="Last Name" />
                                    </div>

                                    <div class="input-field col s12 m12">
                                        <div class="label-input">Client ID</div>
                                        <input id="lastName" type="number" formControlName='clientId' autocomplete="off"
                                            (input)="search(filterForm.value)" class="validate"
                                            placeholder="Client Id" />
                                    </div>

                                    <div class="input-field col s12 m12">
                                        <div class="label-input">ISN</div>
                                        <!-- <input id="ISN" type="text" class="validate" formControlName='ISN'
                                            autocomplete="off" (input)="search(filterForm.value)" placeholder="ISN"
                                            [textMask]="{mask: isnMask, guide: false}" /> -->
                                        <input id="ISN" type="text" class="validate" formControlName='ISN'
                                            [inputMask]="isnMask" autocomplete="off" (input)="search(filterForm.value)"
                                            placeholder="ISN" />
                                        <div
                                            *ngIf="(getFormControl('ISN', filterForm).dirty || getFormControl('ISN', filterForm).touched) && getFormControl('ISN', filterForm).invalid">
                                            <small
                                                *ngIf="getFormControl('ISN', filterForm).errors.minlength || getFormControl('ISN', filterForm).errors.maxlength"
                                                class="red-text"> The
                                                ISN must be 10
                                                Digits.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m12">
                                        <div class="label-input">Email</div>
                                        <input id="email" type="email" formControlName='email' class="validate"
                                            autocomplete="off" placeholder="Email" />
                                        <div
                                            *ngIf="(getFormControl('email', filterForm).dirty || getFormControl('email', filterForm).touched) && getFormControl('email', filterForm).invalid">
                                            <small *ngIf="getFormControl('email', filterForm).errors.email"
                                                class="red-text">
                                                Invalid email format. Please enter a valid email
                                                address.(example&#64;example.com).</small>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <button type="submit" class="btn-small col s12 green white-text"
                                                [ngClass]="'btn ' + buttonType" style="margin-bottom: 5px;"
                                                [disabled]="!filterForm.valid"
                                                [ngStyle]="{'background-color': themeColor}">
                                                <i class="material-icons right">search</i>Search</button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!--result list-->
                        <div class="col s12 m6 offset-m1 l7 offset-l1" style="border-radius: 10px;">
                            <div *ngIf="members && members.length >0">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>DOB</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            *ngFor="let member of members | paginate: { itemsPerPage: 10, currentPage: p }; let i=index;">
                                            <td>{{member.GivenName}}</td>
                                            <td>{{member.LastName}}</td>
                                            <td>{{member.DOB | date: 'dd/MMM/yyyy'}}</td>
                                            <td (click)="showMemberDetail(member)" style="cursor: pointer;"> <span
                                                    class="material-icons green-text">
                                                    arrow_circle_right
                                                </span></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="row center">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>

                            <!--empty result-->
                            <div class="row black-text center p-t-10 bold-700 card-panel"
                                *ngIf="(!members || members.length === 0)">

                                <div *ngIf="filterForm.touched">
                                    <img src="../../assets/img/empty-folder.png" height="150" width="150">
                                    <h6 class="bold-200">Sorry , your search did not match any results !!</h6>
                                </div>

                                <div class="row">
                                    <p *ngIf="!filterForm.touched">If the admin you want to invite is not a member you
                                        can
                                        add
                                        and invite it here.</p>
                                    <p class="sub-title-section bold-500">Would you like to invite an admin who is not a
                                        member?
                                    </p>
                                    <button class="btn btn-large center-align"
                                        [ngStyle]="{'background-color': themeColor}" (click)="onAddAdmin();">Add
                                        Admin</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="showDetail">
                        <!-- member Detail-->
                        <div class="row col s12">
                            <h5 class="icon-text bold-700 title-section3"><i class="material-symbols-outlined p-r-1"
                                    (click)="back()">
                                    arrow_back </i>Member Details</h5>
                            <form [formGroup]="updateForm" (ngSubmit)="updateMemberDate(updateForm.value)">
                                <div class="row card-panel white">

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">First Name</div>
                                        <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                                            style="text-transform: capitalize;" class="validate" />
                                        <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                                            <small *ngIf="firstName.errors.required" class="red-text"> The First Name is
                                                required. </small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Last Name</div>
                                        <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                                            style="text-transform: capitalize;" class="validate" />
                                        <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                            <small *ngIf="lastName.errors.required" class="red-text"> The Last Name is
                                                required. </small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Date of Birth (dd/mm/yyyy)</div>
                                        <!-- <input id="dob" [textMask]="{mask: dateMask, guide: true}"
                                            placeholder="dd/mm/yyyy" type="text" formControlName='dob'
                                            autocomplete="off" class="validate" /> -->
                                        <input id="dob" [inputMask]="dateInputMask" placeholder="dd/mm/yyyy" type="text"
                                            formControlName='dob' autocomplete="off" class="validate" />
                                        <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                                            <small *ngIf="dob.errors.invalidDate" class="red-text">The Date is invalid
                                            </small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Indian Status Number</div>
                                        <!-- <input id="isn" type="text" formControlName='isn' autocomplete="off"
                                            class="validate" placeholder="ISN"
                                            [textMask]="{mask: isnMask, guide: false}" /> -->
                                        <input id="isn" type="text" formControlName='isn' autocomplete="off"
                                            [inputMask]="isnMask" class="validate" placeholder="ISN" />
                                        <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                                            <small *ngIf="isn.errors.minlength || isn.errors.maxlength"
                                                class="red-text">
                                                The
                                                ISN must be 10
                                                Digits.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">SIN</div>
                                        <!-- <input id="sin" type="text" formControlName='sin' autocomplete="off"
                                            class="validate" [textMask]="{mask: SINMask, guide: false}" /> -->
                                        <input id="sin" type="text" formControlName='sin' autocomplete="off"
                                            [inputMask]="SINMask" class="validate" />
                                        <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                                            <small *ngIf="sin.errors.minlength || sin.errors.maxlength"
                                                class="red-text">The
                                                SIN
                                                must be 9
                                                Digits.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Email</div>
                                        <input id="Email" type="text" formControlName='Email' autocomplete="off"
                                            class="validate" />
                                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                            <small *ngIf="email.errors.email" class="red-text">Invalid email format.
                                                Please
                                                enter a valid email
                                                address.(example&#64;example.com). </small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Telephone</div>
                                        <!-- <input id="telephone" type="text"
                                            [textMask]="{mask: phoneNumberMask, guide: true}"
                                            formControlName='telephone' autocomplete="off" class="validate" /> -->
                                        <input id="telephone" type="text" [inputMask]="phoneInputMask"
                                            formControlName='telephone' autocomplete="off" class="validate" />

                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Cell Phone Number</div>
                                        <!-- <input id="cellPhoneNo" [textMask]="{mask: phoneNumberMask, guide: true}"
                                            type="text" formControlName='cellPhoneNo' autocomplete="off"
                                            class="validate" /> -->
                                        <input id="cellPhoneNo" [inputMask]="phoneInputMask" type="text"
                                            formControlName='cellPhoneNo' autocomplete="off" class="validate" />
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Mailing Address</div>
                                        <input id="mailAddress" type="text" formControlName='mailAddress'
                                            autocomplete="off" class="validate" />
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Postal Code</div>
                                        <!-- <input id="postalCode" [textMask]="{mask: postalCodeMask}" type="text"
                                            formControlName='postalCode' autocomplete="off" class="validate"
                                            style='text-transform:uppercase' /> -->
                                        <input id="postalCode" type="text" [inputMask]="postalCodeMask"
                                            formControlName='postalCode' autocomplete="off" class="validate"
                                            style='text-transform:uppercase' />
                                        <div *ngIf="(postalCode.dirty || postalCode.touched) && postalCode.invalid">
                                            <small *ngIf="postalCode.errors.pattern" class="red-text"> Invalid postal
                                                code
                                                format. Please ensure
                                                the postal code matches the format "A1A 1A1."</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">City</div>
                                        <input id="city" type="text" formControlName='city' autocomplete="off"
                                            class="validate" />
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Province</div>
                                        <select formControlName="province" class="validate">
                                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                                        </select>
                                    </div>

                                    <div class="section"></div>
                                </div>

                                <div class="row">
                                    <button class="btn col s12 m4 l3 right edit-button" type="submit"
                                        style="margin: 5px; font-weight: bold;" [ngClass]="'btn ' + buttonType">
                                        update</button>

                                    <button class="btn col s12 m4 l3 left save-button" type="button"
                                        style="margin: 5px;" (click)="invite()" [ngClass]="'btn ' + buttonType"
                                        [ngStyle]="{'background-color': themeColor}">Invite
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- New Admin Form-->
                <div class="row col s12" *ngIf="newAdmin">
                    <h5 class="icon-text bold-700 title-section3"><i class="material-symbols-outlined p-r-1"
                            (click)="back()">
                            arrow_back </i>Add a new Admin</h5>

                    <form [formGroup]="newAdminForm" (ngSubmit)="invite(newAdminForm.value)">
                        <div class="row card-panel white">

                            <div class="input-field col s12 m6">
                                <div class="label-input">First Name</div>
                                <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                                    style="text-transform: capitalize;" class="validate" />
                                <div
                                    *ngIf="(getFormControl('firstName', newAdminForm).dirty || getFormControl('firstName', newAdminForm).touched)&& getFormControl('firstName', newAdminForm).invalid">
                                    <small *ngIf="getFormControl('firstName', newAdminForm).errors.required"
                                        class="red-text"> The First Name is
                                        required. </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Last Name</div>
                                <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                                    style="text-transform: capitalize;" class="validate" />
                                <div
                                    *ngIf="(getFormControl('lastName', newAdminForm).dirty || getFormControl('lastName', newAdminForm).touched) && getFormControl('lastName', newAdminForm).invalid">
                                    <small *ngIf="getFormControl('lastName', newAdminForm).errors.required"
                                        class="red-text"> The Last Name is
                                        required. </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Email</div>
                                <input id="email" type="text" formControlName='email' autocomplete="off"
                                    class="validate" />
                                <div
                                    *ngIf="(getFormControl('email', newAdminForm).dirty || getFormControl('email', newAdminForm).touched) && getFormControl('email', newAdminForm).invalid">
                                    <small *ngIf="getFormControl('email', newAdminForm).errors.email"
                                        class="red-text">Invalid email format. Please
                                        enter a valid email
                                        address.(example&#64;example.com). </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Office Phone Number</div>
                                <!-- <input id="telephone" type="text" [textMask]="{mask: phoneNumberMask, guide: true}"
                                    formControlName='telephone' autocomplete="off" class="validate" /> -->
                                <input id="telephone" type="text" [inputMask]="phoneInputMask"
                                    formControlName='telephone' autocomplete="off" class="validate" />
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Cell Phone Number</div>
                                <!-- <input id="mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                    formControlName='mobile' autocomplete="off" class="validate" /> -->
                                <input id="mobile" type="text" [inputMask]="phoneInputMask" formControlName='mobile'
                                    autocomplete="off" class="validate" />
                            </div>
                        </div>

                        <div class="row">
                            <button class="btn col s12 m4 l3 left save-button" type="submit" style="margin: 5px;"
                                [ngClass]="'btn ' + buttonType" [disabled]="!newAdminForm.valid"
                                [ngStyle]="{'background-color': themeColor}">Add and Invite Admin
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Invite Member -->
            <div *ngIf="inviteAdmin">

                <!-- Admin detail-->
                <div class="row">
                    <h5 class="icon-text bold-700 title-section3"><i class="material-symbols-outlined p-r-1"
                            (click)="backToDetail()">
                            arrow_back </i>Invite</h5>

                    <div class="row col s12">
                        <div class="p-l-2 p-t-1 left">
                            <img class="responsive-img" *ngIf="ID_image" [src]="ID_image" alt="Invite image" />
                            <img src="../../../../../assets/img/emptyProfilePic.png"
                                [ngStyle]="{'border-color': themeColor}"
                                style="object-fit: contain; width: auto; height:200px;">
                            <p class="sub-title-section1 bold-700 center-align">{{adminInfo.firstName}}
                                {{adminInfo.lastName}}
                            </p>
                        </div>

                        <div class="left">
                            <div class="row" style="border: 1px black;">
                                <p class="col s12">
                                    <span class="right" *ngIf="adminInfo.email"><i class="material-icons-outlined left"
                                            [ngStyle]="{'color': themeColor}">
                                            email
                                        </i>{{adminInfo.email}}</span>
                                    <small class="right red-text" *ngIf="!adminInfo.email"><i
                                            class="material-icons-outlined left" [ngStyle]="{'color': themeColor}">
                                            email
                                        </i>Not Provided</small>
                                </p>
                                <button [disabled]="!adminInfo.email" class="btn right"
                                    [ngStyle]="{'background-color': themeColor}" (click)="onContact();">Send
                                    Email</button>
                            </div>

                            <div class="row" style="border: 1px black;">
                                <p class="col s12">
                                    <span class="right" *ngIf="adminInfo.mobile"><i class="material-icons-outlined left"
                                            [ngStyle]="{'color': themeColor}">
                                            email
                                        </i>{{adminInfo.mobile}}</span>
                                    <small class="right red-text" *ngIf="!adminInfo.mobile"><i
                                            class="material-icons-outlined left" [ngStyle]="{'color': themeColor}">
                                            email
                                        </i>Not Provided</small>
                                </p>
                                <button [disabled]="!adminInfo.mobile" class="btn right"
                                    [ngStyle]="{'background-color': themeColor}" (click)="onContact();">Send
                                    Text</button>
                            </div>
                        </div>

                        <div class="right p-r-1 p-t-2 col s12 m4" *ngIf="searchMember">
                            <p class="bold-500">{{member.applicantID}}</p>
                            <p class="sub-title-section1">Invite Status:
                                <span *ngIf="!invitationSent" class="bold-700"
                                    style="background-color: red; padding: 5px; color: white">Not Sent</span>
                                <span *ngIf="invitationSent" class="bold-700"
                                    style="background-color: yellow; padding: 5px;">Pending</span>
                            </p>
                        </div>

                    </div>
                </div>

                <div class="card-panel red lighten-1 white-text" *ngIf="emailExists">
                    <h5 class="sub-title-section bold-500">Email Already Registered.</h5>
                    <p>The email address ({{adminInfo.email}}) you provided is
                        already
                        associated with an account. Please use a different
                        email address if you want to create a new admin account.</p>
                </div>

                <div class="card-panel red lighten-1 white-text" *ngIf="invitationSent">
                    <h5 class="sub-title-section bold-500">Invitation Already Sent.</h5>
                    <p>It appears that an invitation has already been sent to this member. You have the
                        option to either resend the inviation or verify its status by clicking <a class="white-text"
                            style="cursor: pointer; text-decoration: underline"
                            [routerLink]="['/admin/dashboard-sub-menu/admins-list']">here.</a>.</p>
                </div>

                <!-- Set permission-->
                <div class="row card-panel white">
                    <div class="col l11 s9">
                        <h6 class="title-section3 bold-700">Grant Access</h6>
                        <p>Expand to set the prefered access you want to give for
                            this admin before sending a invitation.</p>
                    </div>

                    <div class="col l1 s3 p-t-3">
                        <a style="cursor: pointer;">
                            <span *ngIf="!expand" class="material-icons black-text center" (click)="toggleExpand()">
                                expand_more
                            </span>
                            <span *ngIf="expand" class="material-icons black-text center" (click)="toggleExpand()">
                                expand_less
                            </span>
                        </a>
                    </div>

                    <div *ngIf="expand">
                        <div *ngIf="adminMenus && adminMenus.length > 0">
                            <div *ngFor="let menu of adminMenus; let i = index">
                                <div class="col s12 l12 card-panel menu-cards" *ngIf="menu.name != 'Logout'">

                                    <div class="col s12">

                                        <label>
                                            <input name="menu" type="checkbox" class="filled-in"
                                                *ngIf="!adminMenus[i].submenus || adminMenus[i].submenus.length == 0"
                                                (click)='menuClicked(menu)' />
                                            <span class="icon-text"><span class="material-symbols-outlined p-r-1"
                                                    [ngStyle]="{'color': themeColor}">
                                                    {{menu.icon}}
                                                </span>{{menu.displayName}}</span>
                                        </label>

                                        <label class="right"
                                            *ngIf="adminMenus[i].submenus && adminMenus[i].submenus.length >0">
                                            <input type="checkbox" (click)="selectAll(menu.name)" />
                                            <span>Select All</span>
                                        </label>
                                    </div>

                                    <div *ngIf="adminMenus[i].submenus && adminMenus[i].submenus.length >0">
                                        <div class="row col s12" style="margin-top: 2.5vh;"
                                            *ngIf="adminMenus[i].submenus && adminMenus[i].submenus.length >0">
                                            <div *ngFor="let submenu of adminMenus[i].submenus; let s = index">
                                                <div class="col s11 m5 l3 offset-s1 offset-m1 offset-l1">
                                                    <label>
                                                        <input name="submenu" type="checkbox" class="filled-in"
                                                            [checked]="menuChecked.has(submenu.name)"
                                                            (click)='menuClicked(menu, submenu)' />
                                                        <span class="icon-text"><span
                                                                class="material-symbols-outlined p-r-1"
                                                                [ngStyle]="{'color': themeColor}">
                                                                {{submenu.icon}}
                                                            </span>{{submenu.displayName}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Select Sending method-->
                <div class="row card-panel white">
                    <h6 class="title-section3 bold-700">Send Invitation via</h6>
                    <p>Send Invitation via Email or phone numbere. A link will be sent to the selected
                        method/s.
                    </p>

                    <div class="row col s12">
                        <div class="left">
                            <h6 class="sub-title-section bold-500 icon-text">
                                <i class="material-icons-outlined p-r-1"
                                    [ngStyle]="{'border-color': themeColor, 'color': themeColor}">
                                    email
                                </i>
                                <span *ngIf="adminInfo.email" class="bold-500">{{adminInfo.email}}</span>
                                <span *ngIf="!adminInfo.email">None</span>
                            </h6>
                            <p class="sub-title-section2"> Would you like to send the invitation link via
                                email?
                            </p>
                            <p class="sub-title-section2" *ngIf="!adminInfo.email"> Please add an Email to
                                enable
                                this option?
                            </p>
                        </div>

                        <div class="switch col s6 m4 right">
                            <a class="addButton btn white right center" *ngIf="!adminInfo.email"
                                (click)="backToDetail()" [ngClass]="'btn ' + buttonType"
                                [ngStyle]="{'color': themeColor}">Add Email</a>

                            <div class="right row" *ngIf="adminInfo.email">
                                <p class="col s6">
                                    <label>
                                        <input name="viaEmail" type="radio" [checked]="viaEmail" class="with-gap"
                                            (change)="sendViaEmailYes()" />
                                        <span>Yes</span>
                                    </label>
                                </p>

                                <p class="col s6">
                                    <label>
                                        <input name="viaEmail" type="radio" [checked]="!viaEmail" class="with-gap"
                                            (change)="sendViaEmailNo()" />
                                        <span>No</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col s12">
                        <div class="left">
                            <h6 class="sub-title-section bold-500 icon-text">
                                <i class="material-icons-outlined p-r-1"
                                    [ngStyle]="{'border-color': themeColor, 'color': themeColor}">
                                    phone_iphone
                                </i>
                                <span *ngIf="adminInfo.mobile" class="bold-500">{{adminInfo.mobile}}</span>
                                <span *ngIf="!adminInfo.mobile">None</span>

                            </h6>
                            <p class="sub-title-section2" *ngIf="adminInfo.mobile"> Would you like to
                                send
                                the invitation link via text?
                            </p>
                            <p class="sub-title-section2" *ngIf="!adminInfo.mobile"> Please add a phone
                                number to enable this option?
                            </p>
                        </div>

                        <div class="switch col s6 m4 right">
                            <a class="addButton btn white right center" *ngIf="!adminInfo.mobile"
                                (click)="backToDetail()" [ngClass]="'btn ' + buttonType"
                                [ngStyle]="{'color': themeColor}">Add
                                Phone Number</a>
                            <div class="right" *ngIf="adminInfo.mobile">
                                <p class="col s6">
                                    <label>
                                        <input name="viaText" type="radio" [checked]="viaText" class="with-gap"
                                            (change)="sendViaTextYes()" />
                                        <span>Yes</span>
                                    </label>
                                </p>

                                <p class="col s6">
                                    <label>
                                        <input name="viaText" type="radio" [checked]="!viaText" class="with-gap"
                                            (change)="sendViaTextNo()" />
                                        <span>No</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Set Up Verfication Questions-->
                <div class="row card-panel white">
                    <div class="left">
                        <h6 class="title-section3 bold-700">Set Up Verfication</h6>
                        <p class="sub-title-section2"> You can add one or two Secret
                            Questions to verify the admin's identity?
                        </p>
                    </div>

                    <div class="switch col s6 m4 right" *ngIf="!secretQuestion2">
                        <a class="addButton btn white right center" (click)="addQuestion()"
                            [ngClass]="'btn ' + buttonType" [ngStyle]="{'color': themeColor}">Add
                            Question </a>
                    </div>

                    <div class="row" *ngIf="false">
                        <div class="col s12">
                            <h6 class="sub-title-section2 bold-700">Would you like to add one or two Secret
                                Questions?</h6>
                        </div>

                        <div class="col s12">
                            <p class="col s6">
                                <label>
                                    <input name="group1" type="radio" (click)="addSecretQuestionYes()" class="with-gap" />
                                    <span>Yes</span>
                                </label>
                            </p>
                            <p class="col s6">
                                <label>
                                    <input name="group1" type="radio" (click)="addSecretQuestionNo()" class="with-gap" />
                                    <span>No</span>
                                </label>
                            </p>
                        </div>
                    </div>

                    <div class="row">

                        <div class="row question" *ngIf="secretQuestion">
                            <h6 class="title-section3 bold-500 col l11 s9">Secret Question 1</h6>
                            <div class="col l1 s3 p-t-3">
                                <a class="red-text" style="cursor: pointer;" (click)="deleteQuestion(1)">
                                    <span class="material-icons center">
                                        delete
                                    </span>Delete
                                </a>
                            </div>
                            <div class="input-field col s12 m10">
                                <div class="label-input">Secret Question</div>
                                <input id="question" type="text" [(ngModel)]="question" autocomplete="off" />
                            </div>

                            <div class="input-field col s12 m10">
                                <div class="label-input">Answer</div>
                                <input id="answer" type="text" [(ngModel)]="answer" autocomplete="off" />
                            </div>

                        </div>

                        <div class="row question" *ngIf="secretQuestion2">
                            <h6 class="title-section3 bold-500 col l11 s9">Secret Question 2</h6>
                            <div class="col l1 s3 p-t-3">
                                <a class="red-text" style="cursor: pointer;" (click)="deleteQuestion(2)">
                                    <span class="material-icons red-text center">
                                        delete
                                    </span>Delete
                                </a>
                            </div>

                            <div class="input-field col s12 m10">
                                <div class="label-input">Secret Question</div>
                                <input id="question2" type="text" [(ngModel)]="question2" autocomplete="off" />
                            </div>

                            <div class="input-field col s12 m10">
                                <div class="label-input">Answer</div>
                                <input id="answer2" type="text" [(ngModel)]="answer2" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <button class="btn col s12 m4 l3 right save-button" style="margin: 5px;"
                        [disabled]="!viaEmail && !viaText" (click)="validateInvitation()"
                        [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}">
                        <span *ngIf="!invitationSent">Send</span><span *ngIf="invitationSent">Resend</span>
                        Invitation</button>
                </div>
            </div>

            <!--contact client-->
            <div *ngIf="contact">
                <app-contact-members [message]=message [contactDetail]="contactDetail"
                    (success)="contactHandler($event)">
                </app-contact-members>
            </div>

            <!--Modal-->
            <div id="invitationModal" class="modal">
                <div class="modal-content">
                    <div class="row">
                        <h5 class="bold-700 col s11">Sending an Invitation</h5>
                        <span class="modal-close col s1 material-icons right" style="cursor: pointer;;">
                            close
                        </span>
                    </div>

                    <div class="row red lighten-5 card-panel">
                        <i class="material-icons center red-text col s12" style="font-size: 83px;">
                            warning
                        </i>

                        <div *ngIf="noMethod" class="col s12">
                            <h6 class="bold-500">You need to choose a method to send the invitation link.</h6>
                            <hr />
                        </div>

                        <div *ngIf="noPermision" class="col s12">
                            <h6 class="bold-500">You have not set any permission. The admin will not have access to any
                                of the menus</h6>
                            <hr />
                        </div>

                        <div *ngIf="noVerification" class="col s12">
                            <h6 class="bold-500">It is recommonded that you set at least 1 verfication question to
                                verfiy the
                                admins identity.
                            </h6>
                            <hr />
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <a class="modal-close btn-flat red-text">Close</a>
                    <a (click)="saveInvitation()" class="modal-close btn" *ngIf="!noMethod"
                        [ngStyle]="{'background-color': themeColor}">Send Link</a>
                </div>
            </div>

        </main>

    </div>
</div>