import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { transition, trigger, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { environment } from 'src/environments/environment';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
declare var $: any;

@Component({
  selector: 'app-manage-department',
  templateUrl: './manage-department.component.html',
  styleUrls: ['./manage-department.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class ManageDepartmentComponent implements OnInit {

  nationOfUSe = environment.firstNation.name;
  id: any;
  dep: any;
  canAddEmployee = false;
  canPostNews = false;
  applicantId: any;

  constructor(private departmentService: DepartmentService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      //console.log('Department ID:', this.id);
    });
    this.applicantId = this.authService.applicantID;
    // get by member applicant id and department id
    this.departmentService.getDepartmentByID(this.id).valueChanges().subscribe(d => {
      if (d) {
        this.dep = d;
        //console.log('Department Data:', this.dep);
      }
    });
    this.departmentService.getDeptEmpByApplicantIdAndDeptId(this.applicantId, this.id).valueChanges().subscribe((emp: any) => {
      if (emp.length > 0) {
        if (emp[0].permissions.allowAddEmp) {
          this.canAddEmployee = true;
        }
        if (emp[0].permissions.allowDeptCreate) {
          this.canPostNews = true;
        }
      }
    });
    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);

    $(document).ready(function () {
      $('.pushpin').pushpin();
    });
  }

  viewDepartmentNews() {
    this.router.navigate(['/member/member-main-home/department-news-list', this.id]);
  }

  viewDepartmentInfo() {
    this.router.navigate(['/member/member-main-home/view-department-info', this.id]);
  }

  viewSubDepartments() {
    this.router.navigate(['/member/member-main-home/manage-sub-department', this.id]);
  }

  viewDepartmentContact() {
    this.router.navigate(['/member/member-main-home/contact-department-member', this.id]);
  }

  backClicked() {
    this.router.navigate(['/member/member-main-home/department']);
  }

  manageEmployee() {
    this.router.navigate(['/member/member-main-home/manage-department-members'], { state: { department_id: this.id } });
  }

  manageNews() {
    this.router.navigate(['/member/member-main-home/manage-department-news', this.id]);
  }
}
