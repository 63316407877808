<div class="row p-t-1">

  <div class="col l8 main-dash">
    <h4 class="title-section3-2 bold-800 black-text" *ngIf="currentUser"> Hello, <span
        *ngIf="currentUser?.GivenName">{{currentUser.GivenName}}</span>
      <span class="material-icons blue-text p-l-1" style="vertical-align: middle;" *ngIf="currentUser.verified">
        verified
      </span>
      <!-- <small class="blue-text bold-200" style="font-size: 12px;" *ngIf="currentUser.verified">Verified</small> -->
    </h4>

    <!-- ========== new updtae ============= -->

    <div class="col s12">

      <!-- ============= news ================ -->

      <div class="news-dashboard row">
        <h4 class="title-new-dashboard col l9 s8">Latest News</h4>
        <a class="see-all right-align bold-200 col l3 s4" [routerLink]="['/member/member-main-home/news']">See all News
          ></a>


        <div class="row" *ngIf="modelll" [model]="modelll" [isMember]="isMember">
          <div class="col l6 m12 s12 " *ngFor="let newsModel of modelll | slice:0:4 ">


            <div class="card  ">
              <div class="hoverable " [ngStyle]="{'background-color': themeColor }"
                [routerLink]="['/read-more',newsModel.id]">


                <div class="card-content">
                  <div class="col l8 m8 s8 container3">


                    <p class="date-content sub-title-section3 bold-500" [ngStyle]="{'color' : themeColor}">
                      {{newsModel.datePublished.toDate() | date: 'MMM dd, yyyy'}}</p>


                    <p class="news-title short-body-snippet ">
                      <a *ngIf="callerForm === 'News'" [routerLink]="['/read-more',newsModel.id]"
                        [innerHTML]="newsModel.title">
                      </a>
                    </p>


                    <p class="news-title short-body-snippet ">
                      <a *ngIf="callerForm === 'MemberNews'" [routerLink]="['/member/news-detail',newsModel.id]"
                        [innerHTML]="newsModel.title">
                      </a>
                    </p>
                    <p class="news-title short-body-snippet ">
                      <a *ngIf="callerForm === 'DepNews'" [routerLink]="['/member/view-department-news',newsModel.id]"
                        [innerHTML]="newsModel.title">
                      </a>
                    </p>
                    <p class="news-title short-body-snippet ">
                      <a *ngIf="callerForm === 'DepNews_Public'" [routerLink]="['/view-department-news',newsModel.id]"
                        [innerHTML]="newsModel.title">
                      </a>
                    </p>


                    <p *ngIf="newsModel.imgLink " class="middle-body-snippet sub-title-section3 p-t-1 bold-100 ">
                      {{truncateHTML(newsModel.body,300)}} </p>
                    <p *ngIf="!newsModel.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
                      {{truncateHTML(newsModel.body,300)}} </p>
                  </div>
                  <div class="col l3 m4 s4">
                    <div class="card-image" *ngIf="newsModel.imgLink">
                      <img [src]="newsModel.imgLink" class="news-img">
                    </div>
                    <div class="col l12 s12" *ngIf="newsModel.type === 'PDF' && newsModel.videoLink">
                      <iframe title="iframe-box2" class="responsive-pdf "
                        [src]="newsModel.videoLink | safeUrl"></iframe>
                    </div>


                    <div class="card-image" *ngIf="newsModel.type === 'Video'">
                      <div class="video-container ">
                        <div [innerHTML]="iframhtml"></div>
                      </div>
                    </div>
                    <div class="card-image " *ngIf="newsModel.type === 'uploadVideo'">
                      <video class="responsive-video " controls>
                        <source [src]="newsModel.videoLink" type="video/mp4">
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>


        <div *ngIf="!modelll" [model]="modelll" [isMember]="isMember">
          <div class="row black-text center p-t-5 no-data">
            <img src="../../assets/img/nodata.jpg" height="250" width="250">
            <h4 class="bold-100 grey-text sub-title-section2">Sorry, Our records indicate that no data was found for
              your request!</h4>
          </div>
        </div>
      </div>

      <!-- ============= department ================ -->
      <div class="events-dashboard row">
        <h4 class="title-event-dashboard col l8 s7"> Departments</h4>
        <a class="see-all right-align bold-200 col l4 s5" [routerLink]="['/member/member-main-home/department']">See all
          Department ></a>

        <div class="row" *ngIf="modell">
          <div class="col s12 l4 dep" *ngFor="let dep of modell | slice:0:3 ">

            <!-- [ngStyle]="{'background-color': dep.backgroundColor}"
            (click)="editDepartmentData($event, dep.id)" -->
            <ul class="card card-container" (click)="editDepartmentData(dep.id)">
              <li>
                <div class="row">
                  <div class="responsive-img2 image-data col l12 s4 card-image" *ngIf="dep.logo">
                    <img [src]="dep.logo" class="responsive-img">
                  </div>

                  <div class="responsive-img2 image-none col l12 s4" *ngIf="!dep.logo">
                    <div class="avatar-circle hide-on-med-and-up circle" [ngStyle]="{'background-color': themeColor}">
                      <span class="initials">{{getInitials(dep.name)}}</span>
                    </div>
                    <div class="avatar-circle2 hide-on-small-only show-on-medium-and-up circle"
                      [ngStyle]="{'background-color': themeColor}">
                      <span class="initials2">{{getInitials(dep.name)}}</span>
                    </div>
                  </div>
                  <!-- </div>
      <div class="col l8 s6 push-s1"> -->
                  <div class="col l12 s8 ">
                    <span [innerHTML]="dep.name" class="title-section4 bold-600 p-m-1"></span>
                    <p [innerHTML]="dep.mandate" class="description-section middle-body-snippet bold-100 p-m-0"></p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="!modell" [model]="modell" [isMember]="isMember">
          <div class="row black-text center p-t-5 no-data">
            <img src="../../assets/img/nodata.jpg" height="250" width="250">
            <h4 class="bold-100 grey-text sub-title-section2">Sorry, Our records indicate that no data was found for
              your request!</h4>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="row position-add-button">
      <div class="col s4">
        <button class="btn add-button" data-show=".news" [ngStyle]="{'color': themeColor}">News</button>
      </div>
      <div class="col s4">
        <button class="btn add-button" data-show=".events" [ngStyle]="{'color': themeColor}">Events</button>
      </div>
      <div class="col s4">
        <button class="btn add-button" data-show=".department" [ngStyle]="{'color': themeColor}">Department</button>
      </div>
    </div> -->

    <!-------------------------------- Bottom Main ----------------------------------------->

  </div>

  <!-- =============side events==================== -->
  <div class="col l3 event-list hide-on-med-and-down"
    style=" position: fixed; right: 0; margin-right: 2vh; padding: 1vh;">

    <h4 class="sub-title-section3 bold-600">Events </h4>
    <ul class="card-panel">
      <div *ngIf="model">
        <div #calendarRef></div>

        <li class="card" *ngFor="let event of events | slice:0:3">
          <div [routerLink]="['/events-detail', event.id]">

            <p class="bold-500 description-section" style="font-style: italic;">
              {{event.eventDate.split('/')[1]}},{{event.eventDate.split('/')[0]}},{{event.eventDate.split('/')[2]}}
            </p>

            <p class="bold-600 description-section" [ngStyle]="{'color':themeColor}">{{event.author}}</p>
            <p class="middle-body-snippet sub-title-section3 bold-100" [innerHTML]="event.body"></p>
            <p class="sub-title-section3 bold-100 ">
              <span class="material-symbols-outlined sub-title-section3 ">
                location_on
              </span>
              {{event.eventPlace}}
            </p>
          </div>

          <!-- <div [routerLink]="['/events-detail', event.id]">
            <div class="col s4 center black-text event-card">
              <ul>
                <li class="sub-title-section2 bold-600">{{event.eventDate.split('/')[0]}}</li>
                <li>{{event.eventDate.split('/')[1]}}</li>
                <li class="sub-title-section2 bold-600">{{event.eventDate.split('/')[2]}}</li>
              </ul>
            </div>

            <div class="col s8 ">
              <p class="middle-body-snippet sub-title-section3 bold-100" [innerHTML]="event.body"></p>
            </div>
          </div> -->
        </li>
      </div>
      <div *ngIf="!model" [model]="model" [isMember]="isMember">
        <div class="row black-text center p-t-10 ">
          <img src="../../assets/img/cal-nodata.jpg" height="250" width="300">
          <h4 class="bold-100 grey-text sub-title-section2">No Event!</h4>
        </div>
      </div>
    </ul>
  </div>

  <!-- 
    <div class="row back-menu" *ngIf="menus">
      <div *ngFor="let item of menus; let i=index">
        <div class="col s6 m4 l3" (click)="itemClicked(item)" *ngIf="item.isEnabled" style="cursor: pointer;">
          <div class="card   hoverable">
            <div class="card-content">
              <div class="row center">

                <i class="material-symbols-outlined">{{item.icon}}</i>
                <br />
                <p>{{item.displayName}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  -->

</div>

<!-- =============================modal ============================================ -->

<!-- <div id="switchModal" class="modal center b-n-1">
  <div class="modal-content">
    <i class="material-icons right modal-close">close</i><br />
    <img src="https://img.icons8.com/ultraviolet/120/000000/change-user-male.png" class="p-t-3" />
    <h6 class="title-section2 bold-600 black-text">Switch Account </h6>
    <p class="sub-title-section bold-100">Are you sure you want to switch to Member Account ? </p>
    <a class="modal-close  white-text  btn-flat col l8 s8 push-l2 push-s2 "
      [ngStyle]="{ 'background-color' : themeColor}" (click)="switchAccount()">Switch</a>
  </div>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h6><strong> Under Construction </strong></h6>
    <p>Once Completed you will be able to see all the jobs available within the Nation and you will be
      able to submit your application.
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat">Close</a>
  </div>
</div>

<div id="modal2" class="modal">
  <div class="modal-content">
    <h6><strong> Under Construction </strong></h6>
    <p>This function will allow the members to share ideas and discuss ongoing opportunities and challenges within the
      Nation
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat">Close</a>
  </div>
</div> -->

<div id="welcomeModal" class="modal">
  <div class="modal-content">
    <h6 class="green-text"><strong>Welcome To {{nationName}} Private App</strong></h6>
    <br>

    <div class="video-container">
      <iframe src="https://www.youtube.com/embed/MEybwqfKSe8" frameborder="0" allowfullscreen></iframe>
    </div>


  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat red darken-2 white-text" (click)="closeModal()">Close
      Video</a>
  </div>
</div>

<div id="settingModal" class="modal">
  <div class="modal-content">
    <h6><strong>My Preferences</strong></h6>

    <div class="row">
      <!-- <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="shareLocation" (ngModelChange)="onChange($event)"/>
          <span>Share My Geo Location</span>
        </label>
      </div> -->

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="shareLocation" />
          <span>Share my name and coordinate</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowEmail" />
          <span>Allow Emails</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowNotifications" />
          <span>Allow Notifications</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowChat" />
          <span>Allow Chat</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowContactByChiefAndCouncil" />
          <span>Allow Chief and Council to Contact me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="contactMe" />
          <span>Allow members to contact me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowSearch" />
          <span>Allow other members to search for my profile</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowNomination" id="allowNomination" />
          <span>Allow members to Nominate me for election</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowContactByEmployer" id="allowContactByEmployer" />
          <span>Allow Employers to Contact Me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowViewResume" id="allowViewResume" />
          <span>Allow Employers to See Resume</span>
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <a (click)="approvePreferences()" class="modal-close waves-effect waves-green btn-flat green">OK</a>
  </div>
</div>

<!-- <div id="prefConfirmationModal" class="modal">
  <div class="modal-content">
    <h6 class="green-text"><strong>My Preferences</strong></h6>
    <br>
    <p>
      <strong> You can edit your preferences anytime you want by clicking on my profile then settings</strong>
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat green white-text">Ok</a>
  </div>
</div> -->