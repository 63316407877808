<div class="row" *ngIf="step ==1">

    <form class="row col s12" [formGroup]="FundingForm" (ngSubmit)="submitFunding(FundingForm.value)">
        <div class="row grey lighten-3 black-text">
            <h6 class="center-align">Part I: Funding Request</h6>
        </div>
        <br>
        <div class="col s12 card-content">
            <div class="row card-panel">

                <div class="row">
                    <!-- deposit -->
                    <!--  <div class="input-field col s12 m6">
                    <span class="left"><strong>Damage Deposit</strong></span>
                </div>
            
                <div class="input-field col s12 m3">  
                    <input id="deposit_ytd" type="text" formControlName="deposit_ytd" class="capitalize validate" disabled>
                    <label for="deposit_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="deposit_budget" type="text" formControlName="deposit_budget" class="capitalize validate" disabled>
                    <label for="deposit_budget">Budget</label>         
                </div>
                -->

                    <div class="input-field col s12 m6" *ngIf="deposit_required">
                        <i class="material-icons prefix blue-text">attach_money</i>
                        <input id="deposit_amount" type="number" formControlName="deposit_amount"
                            class="capitalize validate">
                        <label for="deposit_amount">Damage Deposit</label>
                    </div>

                    <!-- tuition -->
                    <!-- 
                <div class="input-field col s12 m3">
                    <span class="left"><strong>Tuition & Student Fees</strong></span>
                </div>
            
                <div class="input-field col s12 m3">  
                    <input id="tuition_ytd" type="text" formControlName="tuition_ytd" class="capitalize validate" disabled>
                    <label for="tuition_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="tuition_budget" type="text" formControlName="tuition_budget" class="capitalize validate" disabled>
                    <label for="tuition_budget">Budget</label>         
                </div>
                -->

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">attach_money</i>
                        <input id="tuition_amount" type="number" formControlName="tuition_amount"
                            class="capitalize validate">
                        <label for="tuition_amount">Tuition & Student Fees</label>
                    </div>

                    <!-- Books and supplies -->
                    <!--
                <div class="input-field col s12 m3">
                    <span class="left"><strong>Books & Supplies</strong></span>
                </div>
                 
                <div class="input-field col s12 m3">  
                    <input id="books_ytd" type="text" formControlName="books_ytd" class="capitalize validate" disabled>
                    <label for="books_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="books_budget" type="text" formControlName="books_budget" class="capitalize validate" disabled>
                    <label for="books_budget">Budget</label>         
                </div>
                -->
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="books_amount" type="number" formControlName="books_amount"
                            class="capitalize validate">
                        <label for="books_amount">Books & Supplies</label>
                    </div>

                    <!-- rent -->
                    <!-- <div class="input-field col s12 m3">
                    <span class="left"><strong>Rent</strong></span>
                </div>
                        
                <div class="input-field col s12 m3">  
                    <input id="rent_ytd" type="text" formControlName="rent_ytd" class="capitalize validate" disabled>
                    <label for="rent_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="rent_budget" type="text" formControlName="rent_budget" class="capitalize validate" disabled>
                    <label for="rent_budget">Budget</label>         
                </div>
                -->

                    <div class="input-field col s12 m6" *ngIf="rent_required">
                        <i class="material-icons prefix orange-text">attach_money</i>
                        <input id="rent_amount" type="number" formControlName="rent_amount" class="capitalize validate">
                        <label for="rent_amount">Rent</label>
                    </div>

                    <!-- utilities -->

                    <!--<div class="input-field col s12 m3">
                    <span class="left"><strong>Utilities</strong></span>
                </div>
        
                <div class="input-field col s12 m3">  
                    <input id="utilities_ytd" type="text" formControlName="utilities_ytd" class="capitalize validate" disabled>
                    <label for="utilities_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="utilities_budget" type="text" formControlName="utilities_budget" class="capitalize validate" disabled>
                    <label for="utilities_budget">Budget</label>         
                </div>
                -->

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix black-text">attach_money</i>
                        <input id="utilities_amount" type="number" formControlName="utilities_amount"
                            class="capitalize validate">
                        <label for="utilities_amount">Utilities</label>
                    </div>

                    <!-- Living allowance -->

                    <!-- <div class="input-field col s12 m3">
                    <span class="left"><strong>Living Allowance</strong></span>
                </div>
      
                <div class="input-field col s12 m3">  
                    <input id="living_ytd" type="text" formControlName="living_ytd" class="capitalize validate" disabled>
                    <label for="living_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="living_budget" type="text" formControlName="living_budget" class="capitalize validate" disabled>
                    <label for="living_budget">Budget</label>         
                </div>
                -->

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix pink-text">attach_money</i>
                        <input id="living_amount" type="number" formControlName="living_amount"
                            class="capitalize validate">
                        <label for="living_amount">Living Allowance</label>
                    </div>

                    <!-- Child Care -->

                    <!-- <div class="input-field col s12 m3">
                    <span class="left"><strong>Child Care</strong></span>
                </div>

                <div class="input-field col s12 m3">  
                    <input id="childcare_ytd" type="text" formControlName="childcare_ytd" class="capitalize validate" disabled>
                    <label for="childcare_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="childcare_budget" type="text" formControlName="childcare_budget" class="capitalize validate" disabled>
                    <label for="childcare_budget">Budget</label>         
                </div>
                -->
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">attach_money</i>
                        <input id="childcare_amount" type="number" formControlName="childcare_amount"
                            class="capitalize validate">
                        <label for="childcare_amount">Child Care</label>
                    </div>

                    <!-- Other -->
                    <!-- <div class="input-field col s12 m3">
                    <span class="left"><strong>Other</strong></span>
                </div>
                 
                <div class="input-field col s12 m3">  
                    <input id="other_ytd" type="text" formControlName="other_ytd" class="capitalize validate" disabled>
                    <label for="other_ytd">YTD</label>      
                </div>

                <div class="input-field col s12 m3">       
                    <input id="other_budget" type="text" formControlName="other_budget" class="capitalize validate" disabled>
                    <label for="other_budget">Budget</label>         
                </div>
                -->
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix cyan-text">attach_money</i>
                        <input id="other_amount" type="number" formControlName="other_amount"
                            class="capitalize validate">
                        <label for="other_amount">Other</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <span><strong>Travel Information: </strong></span>
            </div>

            <div class="row card-panel">

                <div class="row">
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix orange-text">time_to_leave</i>
                        <input id="mileage" type="number" formControlName="mileage" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;">
                        <label for="mileage">Mileage (# of KM)</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">local_cafe</i>
                        <input id="breakfast" type="number" formControlName="breakfast" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="breakfast"># of Breakfast</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">local_dining</i>
                        <input id="dinner" type="number" formControlName="dinner" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="dinner"># of Dinner</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">local_hotel</i>
                        <input id="accommodation" type="number" formControlName="accommodation" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="accommodation">Accommodation (# of nights)</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">local_dining</i>
                        <input id="lunch" type="number" formControlName="lunch" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="lunch"># of Lunch</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="input-field col s12 m3 l3">
            <input id="funding_amt" type="number" formControlName="funding_amt" [(ngModel)]="t_funding_amount"
                class="validate" style="text-transform: capitalize;" disabled>
            <label for="funding_amt">Total Amount Requested: </label>
        </div>

        <button class="col s12 m5 offset-m2 m3 l3 btn right waves-effect blue" type="submit" style="margin-bottom: 15px"
            title="Save">Next <i class="material-icons right">arrow_forward</i></button>
    </form>
</div>


<div class="row" *ngIf="step ==2">
    <div class="row grey lighten-3 black-text">
        <h6 class="center-align">Part II: Supporting Documents</h6>
    </div>

    <form [formGroup]="otherInfoForm" (ngSubmit)="submitOtherInfo(otherInfoForm.value)">

        <div class="row col s12 card-panel white">
            <br>
            <p>Please provide any other information that might assist Post-Secondary selection committee in
                making the funding request decision (Attach letter)</p>
            <br>
            <p>Would You Like to Upload Supporting Documents with Your Request ?</p>
            <div class="row input-field col s12">
                <div class="row col s12">
                    <label class="col s6">
                        <input name="upload_documents" formControlName="upload_documents" type="radio" value="yes" class="with-gap"
                            (click)="onUplodYes($event)" />
                        <span class="text"><strong>Yes</strong></span>
                    </label>
                    <label class="col s6">
                        <input name="upload_documents" formControlName="upload_documents" type="radio" value="no" class="with-gap"
                            (click)="onUplodNo($event)" />
                        <span class="text"><strong>No</strong></span>
                    </label>
                </div>
            </div>
        </div>

        <div *ngIf="showUpload" class="row">
            <ul class="collection" *ngIf="documentsControls && documentsControls.length > 0">
                <h6 class="card-title">Attached Files</h6>
                <li *ngFor="let file of documentsControls.value; let i = index;" class="collection-item">
                    <div class="truncate"><i class="material-icons"
                            style="margin-right: 15px;">note_add</i>{{file.type}} - {{file.name}}
                        <a class="secondary-content" (click)="removeFile(i)"><i class="material-icons">close</i></a>
                        <!--  <a class="secondary-content" (click)="viewFile(i)"><i class="material-icons right ">open_in_browser </i></a> -->
                    </div>
                </li>

                <li class="collection-item">
                    <div class="center">
                        <button class=" btn-small blue" type="button" (click)="addAnotherFile()">Add Supporting
                            Document</button>
                    </div>
                </li>
            </ul>

            <!-- 
            <div class="row col s12 input-field">
                <div class="col s12" *ngIf="preview">
                  <img class="responsive-img"  [src]="preview">   
                  <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i class="material-icons">delete</i></a>
                </div>
            </div>
            -->
        </div>

        <div id="FileUploadModal" class="modal">
            <div class="modal-content">
                <h6>Add Supporting Document</h6>

                <div class="row">
                    <div class=" col s12">
                        <label for="document_type">Document Type<span class="red-text">*</span></label>
                        <select id="fileType" formControlName="document_type" class="validate browser-default"
                            (change)="showUploadInput()">
                            <option value="" disabled selected>Please Select</option>
                            <option *ngFor="let type of fileTypes" [value]="type">{{type}}</option>
                        </select>
                    </div>

                    <div class="col s12 file-field input-field" *ngIf="showUpload2">
                        <div class="btn">
                            <span>Browse <i class="material-icons">file_upload </i></span>
                            <input type="file" formControlName="document" accept="image/*, application/pdf"
                                (change)="handleFileInput($event.target.files)" />
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate" type="text" placeholder="No File Choosen" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <a class="modal-close btn red " (click)="cancelUploadFile()" style="margin-right: 5px;">Cancel</a>
                <a class="btn green" (click)="uploadFile(otherInfoForm.value)">Upload File</a>
            </div>
        </div>

        <div class="row">
            <div class="col s6">
                <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                    (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>
            </div>
            <div class="col s6">
                <button class=" btn right  green" type="submit"
                    [disabled]="!otherInfoForm.valid  && documentsControls.controls.length==0"
                    style="margin-bottom: 15px" title="Save">Send Request <i
                        class="material-icons right">save</i></button>
            </div>
        </div>
    </form>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div id="messageModal" class="modal">
    <div class="modal-content">
        <h5><strong><span class="blue-text">Funding Request Application</span></strong></h5>
        <br>
        <h6><strong> Thank you for using {{ this.nationName }} mobile App,
                The administrator will review your reqeust and get back to you shortly ! </strong></h6>
    </div>

    <div class="modal-footer">
        <button class="blue btn white-text col s12" style="margin-bottom: 15px;" (click)="backClicked2()">Close</button>
    </div>
</div>