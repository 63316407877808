<div class="row container">

    <div class="row card white">

        <div class="row col s12 center">
            <h5 class="cart-title center-align"><strong>{{nationName}} Student Contract </strong> </h5>
        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>1. What is a student contract?</strong></h6>

            <p>A student contract is an agreement between the funding agency and the student that includes any details
                relevant to the arrangement between the two parties. Examples of this are the terms of the agreement for
                the continuation of funding, the funding amounts provided and other related information. </p>
            <p>This Student Contract (the “Contract”) states the terms and conditions that govern the contractual
                agreement
                between the <span class="blue-text">{{nationName}}</span> Community Services Post-Secondary Support
                Program (PSSSP), having its principal
                place of business at <span class="blue-text">{{band_address}}</span>, and <span
                    class="blue-text">{{studentName}}</span> (the “student’) who agrees to
                be bound by this Contract.</p>
            <p>WHEREAS, the post-secondary support program is engaged in providing financial assistance for those
                pursuing
                a post-secondary education; and</p>
            <p>WHEREAS, the PSSP desires to financially assist the student according to the terms and conditions within
                this
                agreement.THEREFORE, in consideration of the agreements made by the parties, the PSSSP and the Student
                come to
                an understanding as follows:</p>

        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>2. TERM</strong></h6>
            <p>The term of this student contract shall commence on <span class="blue-text">{{start_date}}</span> and end
                on <span class="blue-text">{{end_date}}</span>. If further funding
                is required after the end date, another application is required.</p>
        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>3. STUDENT OBLIGATIONS</strong></h6>
            <p>The PSSSP shall provide funding for benefits as contained within the {{nationName}} Post-Secondary
                Student Support Program policy. The student accepts funding from the PSSSP, on the terms and conditions
                set forth
                in this Student Contract, and agrees to devote [his/her] time and attention to the performance of
                [his/her] duties
                under this Contract. In general, the student shall perform all the responsibilities as described below:
            </p>

            <ul class="left-align">
                <li>A. Read and become familiar with the content within the PSSSP policy </li>
                <li>B. Keep informed on application timelines and meet all deadlines within the PSSSP policy</li>
                <li>C. Communicate to the Employment Facilitator any dependent, accommodation or other relevant changes
                    that may have occurred after the application process</li>
                <li>D. Inform the EF if or when struggling with a course and requiring a tutor</li>
                <li>E. Attend classes regularly</li>
                <li>F. Do the utmost best to pass all courses in the program of studies</li>
                <li>G. Provide the Employment Facilitator with an official transcript of personal grades at the end of
                    the academic year.
                    Students attending institutions that do not normally provide transcripts at the end of a semester
                    must submit an official
                    transcript from the Registrar’s office </li>
                <li>H. Be aware that any additional expenses that were not identified and submitted during the
                    application process are the
                    financial responsibility of the student </li>
            </ul>

        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>4. EMPLOYEE OBLIGATIONS</strong></h6>
            <p>Perform administrative duties as outlined:</p>
            <ul class="left-align">
                <li>A. Coordinate intake procedures </li>
                <li>B. Monthly monitoring of student progress </li>
                <li>C. Submit monthly expenditures </li>
            </ul>
            <p>BENEFITS</p>
            <p>In consideration for following through on expectations within this document, the student shall be
                entitled to funding as described
                on Schedule A.</p>
        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>5. CONFIDENTIALITY</strong></h6>
            <p>The PSSSP shall not disclose to any third party any details regarding the student’s circumstances or
                performance within the
                program of studies unless requested to do so in writing by the client. The relationship is between the
                employee and the
                student and the employee is not free to divulge any information about the student file. PSSSP will not
                use confidential
                Information other than solely for the benefit of the client.</p>

        </div>

        <div class="col s12" style="text-align: justify;">
            <h6 class="blue-text text-darken-2"><strong>6. STUDENT REPRESENTATIONS AND WARRANTIES</strong></h6>
            <p>The Student agrees to the following:</p>
            <ul class="left-align">
                <li>A. There is no obligation that prevents the student from entering into this Contract or from
                    performing fully the
                    student’s duties under this contract </li>
                <li>B. The PSSSP is not responsible for performing duties for the student other than those specifically
                    described under
                    this Contract. </li>
            </ul>

            <p>IN WITNESS WHEREOF, each of the Parties has executed this Contract as of the day and year set forth
                below.</p>
        </div>

    </div>


    <form *ngIf="step == 1 && !showSignaturePad" class="row col s12 card white" [formGroup]="contractForm"
        (ngSubmit)="submitContract(contractForm.value)">
        <div class="row card-content">

            <div class="input-field col s12">
                <!-- [(ngModel)]="studentName"-->
                <input id="student" type="text" formControlName="student" class="validate"
                    style="text-transform: capitalize;">
                <label for="student">Student Name<span class="red-text">*</span></label>
            </div>

            <div class="row col s12 m6">
                <p class="col s12">Signature<span class="red-text">*</span></p>
                <a *ngIf="!signed" [ngClass]="'btn col s8 waves-effect ' + themeColor" (click)="sign()"
                    style="margin-top: 10px;">Sign</a>
                <a *ngIf="signed" class="col s4" style="margin-top: 10px;"><i
                        class="material-icons left green-text small">check_circle</i>Signed</a>
                <a *ngIf="signed" class="col s4 pink-text" style="margin-top: 10px;" (click)="clearSignature()"><i
                        class="material-icons left pink-text">clear</i>Clear</a>
            </div>

            <div class="input-field col s12 m6">
                <i class="material-icons prefix blue-text">calendar_today</i>
                <input id="date" formControlName="date" type="text" class="input-box" angular-mydatepicker name="mydate"
                    (click)="dp.toggleCalendar()" [options]="myDpOptions" #dp="angular-mydatepicker"
                    (dateChanged)="onDateChange($event)" />
                <label for="date">Date (dd/mm/yyyy)<span class="red-text">*</span></label>
            </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
            (click)="backClicked()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
            [disabled]="!contractForm.valid" style="margin-bottom: 15px" title="Save">Submit <i
                class="material-icons right">arrow_forward</i></button>
    </form>

    <div *ngIf="step == 2 && showSignaturePad" class="row card white" [formGroup]="contractForm"
        (ngSubmit)="submitSignature(contractForm.value)">

        <div class="row s12 card-panel grey lighten-4 center">
            <div class="row">
                <h6 class="blue-text text-darken-2 center"><strong>STUDENT SIGNATURE</strong></h6>
                <canvas></canvas>
            </div>
        </div>

        <div class="section"></div>

        <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="approveSignature()"
            style="margin-bottom: 15px;"><i class="material-icons left">check</i> Sign</a>
        <br>
        <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"
            style="margin-bottom: 15px;"><i class="material-icons left">loop</i> Clear Signature</a>
        <br>

        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onBack()"><i
                class="material-icons left">arrow_back_ios</i> Back To Previous Page </a>
    </div>

</div>