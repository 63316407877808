<div class="row container-pi1">
  <h4 class="title-section1 title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>Poll / Survey List</h4>

  <div class="row ">
    <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

    <div class="col s12 l12">

      <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
        <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Survey / Poll Found In the
            Database</strong> </p>
      </div>

      <table class="menu-items responsive-table">
        <thead>
          <tr class="">
            <th>Name</th>
            <th>End Date</th>
            <th>Open/Closed</th>
            <th>Result</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of model;let i=index;" class="card">
            <td>
              <a class="col s12" *ngIf="item.status.open" [routerLink]="['/member/survey-detail', item.id]"
                class="truncate black-text ">{{item.name}}</a>
              <a class="col s12" *ngIf="!item.status.open" (click)="closed()"
                class="truncate black-text ">{{item.name}}</a>
            </td>
            <td>
              <span class="col s12">{{item.status.endAt}}</span>
            </td>
            <td>
              <span *ngIf="item.status.open" class="green-text col s12">OPEN</span>
              <span *ngIf="!item.status.open" class="red-text col s12">CLOSED</span>
            </td>
            <td *ngIf="item.status.showResult"> <a class="center col btn-flat s12 blue lighten-4 icon-text"
                [routerLink]="['/member/member-main-home/survey-result', item.id]"> <span style="cursor: pointer;"><i
                    class="material-icons black-text" style="margin-right: 7px;">poll</i>
                  <strong class="black-text">View Results</strong></span> </a>
            </td>
            <td *ngIf="!item.status.showResult">
              <p class="red-text center ">Result Not Available</p>
            </td>
            <td>
              <a class="col s12" *ngIf="item.status.open" [routerLink]="['/member/member-main-home/survey-detail', item.id]"
                class="truncate black-text "> <i class="material-symbols-outlined">
                  edit
                </i></a>
              <a class="col s12" *ngIf="!item.status.open" (click)="closed()" class="truncate black-text "><i
                  class="material-symbols-outlined">
                  block
                </i></a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
    </button> -->
    </div>
  </div>
</div>