import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'app-admin-home-sub-menu',
  templateUrl: './admin-home-sub-menu.component.html',
  styleUrls: ['./admin-home-sub-menu.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class AdminHomeSubMenuComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  from: any;

  isDemo = false;
  user: any;
  isSuperAdmin = false;

  adminMenus: any[];
  menu: any;
  submenus: any[];
  permissions: any[];
  defaultMenus: any[];

  //Menus
  myNationMenu = false;
  manageMenu = false;
  newsAndEventsMenu = false;
  statisticsMenu = false;
  settingsMenu = false;
  pollsAndSurveyMenu = false;
  housingMenu = false;

  customSections: any[];

  selectedMenu = {
    name: '',
    icon: '',
  }

  constructor(private location: Location,
    private router: Router,
    private authService: AuthService,
    private settingsService: AppSettingsService,
    private dataService: DataService,
    private sectionService: SectionSettingService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.user = this.authService.getGlobalUser();

    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          else if (user.permissions) this.permissions = user.permissions;
          else {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
  }

  ngOnInit() {
    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.defaultMenus = this.dataService.getAdminMenus();
        this.adminMenus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }

      if (this.adminMenus) {
        if (localStorage.getItem('From')) {
          this.from = localStorage.getItem('From');
          if (this.from == 'MyNation') {
            this.myNationMenu = true;
            this.fromNationMenu();
          }
          if (this.from == 'Manage') {
            this.manageMenu = true;
            this.fromManageMenu();
          }
          if (this.from == 'Stats') {
            this.statisticsMenu = true;
            this.fromStatMenu();
          }
          if (this.from == 'Settings') {
            this.settingsMenu = true;
            this.fromSettingMenu();
          }
          if (this.from == 'Housing') {
            this.housingMenu = true;
            this.fromHousingMenu();
          }

          let menu = this.adminMenus.find(m => m.name == this.from);
          if (menu) {
            this.menu = menu;
            this.selectedMenu.name = this.menu.displayName;
            this.selectedMenu.icon = this.menu.icon;

            this.submenus = this.menu.submenus || [];

            this.sectionService.getSections().valueChanges().subscribe(sec => {
              if (sec && sec.length > 0) {
                let sections = sec.filter(s => s.addToAdmin != 'dashboard');
                if (sections && sections.length > 0) {
                  this.customSections = sections;
                  let cs = this.customSections.filter(s => s.addToAdmin == this.from);
                  if (cs && cs.length > 0) {
                    cs.forEach(sec => {
                      this.submenus.push(
                        {
                          name: sec.name,
                          displayName: sec.displayName,
                          isEnabled: true,
                          icon: sec.icon,
                          index: this.submenus.length,
                          id: sec.id
                        }
                      )
                    });
                  }
                }
              }
            });

            if (this.submenus && this.submenus.length > 0) {
              this.submenus = this.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
              if (this.submenus && this.submenus.length) this.submenus = this.submenus.filter(sm => sm.isEnabled);
            }
          }
        }
      }
      setTimeout(() => {
        $('.dropdown-trigger').dropdown({
          constrainWidth: false,
          isScrollable: true,
        });
        $('.tooltipped').tooltip();
        $('.modal').modal();
      }, 25);
    });
  }

  fromNationMenu() {
    const menuRoutes = {
      NationInfo: 'nation-settings',
      AboutUs: 'about-us',
      Departments: 'department',
      Governance: 'governance',
      AreaOfInterest: 'areas-of-interest',
      Board: 'manage-boards',
    };

    for (const item of Object.keys(menuRoutes)) {
      if (this.isEnabled('MyNation', item)) {
        this.router.navigate([`admin/dashboard-sub-menu/${menuRoutes[item]}`]);
        return;
      }
    }
  }

  fromManageMenu() {
    const menuItems = {
      Admins: 'dashboard-sub-menu/admins-list',
      Membership: 'members',
      Applications: 'dashboard-sub-menu/applications',
      DemoRequest: 'dashboard-sub-menu/demo-request-list',
      ClientAdmin: 'dashboard-sub-menu/client-admin',
    };

    for (const item of Object.keys(menuItems)) {
      if (this.isEnabled('Manage', item)) {
        this.router.navigate([`admin/${menuItems[item]}`]);
        return;
      }
    }
  }

  fromStatMenu() {
    const menuItems = {
      AppStatistics: 'app-statistics',
      DepartmentStats: 'dep-statistics',
      Demographics: 'demographics',
      Charts: null,
      Analytics: null,
      Reports: null,
    };

    for (const item of Object.keys(menuItems)) {
      if (menuItems[item]) {
        if (this.isEnabled('Stats', item)) {
          this.router.navigate([`admin/dashboard-sub-menu/stats/${menuItems[item]}`]);
          return; // Exit the loop when a route is found
        }
      } else {
        $('#underConstructionModal').modal('open');
        return;
      }
    }
  }

  fromSettingMenu() {
    const menuItems = {
      MenuSetting: 'app-settings',
      LandingPage: 'landing-page-settings',
      AppMenu: 'app-menu-settings',
      LoginAndSignup: 'login-signup-settings',
      AdminMenu: 'admin-menu-settings',
      MemberMenu: 'member-menu-settings',
      AppMenus: 'app-all-menu-settings',
    };

    for (const item of Object.keys(menuItems)) {
      if (this.isEnabled('Settings', item)) {
        this.router.navigate([`admin/dashboard-sub-menu/settings/${menuItems[item]}`]);
        return;
      }
    }
  }

  fromHousingMenu() {
    const menuItems = {
      Houses: 'housing',
      NewHouse: 'new-property',
      Repairs: 'repair-request',
      Renovations: 'renovation-request',
    };

    for (const item of Object.keys(menuItems)) {
      if (this.isEnabled('Housing', item)) {
        this.router.navigate([`admin/dashboard-sub-menu/${menuItems[item]}`]);
        return;
      }
    }
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  getPermission(menu, submenu?) {
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu == menu);
        if (p) {
          if (!submenu) return true;
          else if (p.submenus && p.submenus.length > 0) {
            let sp = p.submenus.includes(submenu);
            if (sp) return true;
          }
        }
      }
    }
    return false;
  }

  isEnabled(mn, smn?) {
    let menu = this.adminMenus.filter(m => m.name == mn)[0];
    let subMenus = menu.submenus;
    let submenu = subMenus.filter(m => m.name == smn)[0];
    if (submenu.isEnabled) return true;
    else return false;
  }

  // getSubmenuPermission(menu) {
  //   if (this.isSuperAdmin) return true;
  //   if (this.permissions && this.permissions.length > 0) {
  //     let p = this.permissions.find(p => p.menu == menu);
  //     if (p) {
  //       return p.submenus;
  //     }
  //   }
  //   return false;
  // }

  menuClicked(item: any) {
    this.selectedMenu.name = item.displayName;
    this.selectedMenu.icon = item.icon;
    if (this.getPermission(item.name)) {
      if (item.name === 'NewsAndEvents') this.router.navigate(['admin/news-events']);
      else if (item.name === 'Settings') this.router.navigate(['admin/settings']);
      else if (item.name === 'PollsAndSurveys') this.router.navigate(['admin/survey-list']);
      else if (item.name === 'Communication') this.router.navigate(['admin/messages']);
      else this.menu = item;
    }
    // else{
    //   $('#noPermissionModal').modal('open');
    //   return;
    // }
  }

  itemClicked(itemName, id?) {
    let menu = this.menu.submenus.filter(m => m.name == itemName);
    let item;
    if (menu && menu.length > 0) item = menu[0];

    if (item?.underConstruction) { $('.modal').modal(); $('#underConstructionModal').modal('open'); }
    if (this.getPermission(this.menu.name, itemName)) {
      if (this.myNationMenu) {
        if (itemName == 'NationInfo') this.router.navigate(['admin/dashboard-sub-menu/nation-settings']);
        else if (itemName == 'AboutUs') this.router.navigate(['admin/dashboard-sub-menu/about-us']);
        else if (itemName == 'Departments') this.router.navigate(['admin/dashboard-sub-menu/department']);
        else if (itemName == 'Governance') this.router.navigate(['admin/dashboard-sub-menu/governance']);
        else if (itemName == 'AreaOfInterest') this.router.navigate(['admin/dashboard-sub-menu/areas-of-interest']);
        else if (itemName == 'Board') this.router.navigate(['admin/dashboard-sub-menu/manage-boards']);
        else if (itemName == 'CustomSection') { this.router.navigate(['admin/custom-section/', id]); }
      }
      else if (this.manageMenu) {
        if (itemName == 'Admins') this.router.navigate(['admin/dashboard-sub-menu/admins-list']);
        else if (itemName == 'Membership') this.router.navigate(['admin/members']);
        else if (itemName == 'Applications') this.router.navigate(['admin/applications']);
        else if (itemName == 'DemoRequest') this.router.navigate(['admin/dashboard-sub-menu/demo-request-list']);
        else if (itemName == 'ClientAdmin') this.router.navigate(['admin/dashboard-sub-menu/client-admin']);
        else if (itemName == 'CustomSection') this.router.navigate(['admin/custom-section/', id]);
      }
      else if (this.statisticsMenu) {
        if (itemName == 'AppStatistics') this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
        else if (itemName == 'DepartmentStats') this.router.navigate(['admin/dashboard-sub-menu/stats/dep-statistics']);
        else if (itemName == 'Demographics') this.router.navigate(['admin/dashboard-sub-menu/stats/demographics']);
        else if (itemName == 'CustomSection') { this.router.navigate(['admin/custom-section/', id]); }
        //under construction
        // else if (item == 'Charts')this.router.navigate(['admin/']);
        // else if (item == 'Analytics') this.router.navigate(['admin/']);
        // else if (item == 'Reports') this.router.navigate(['admin/']);
      }
      else if (this.newsAndEventsMenu) {
        if (itemName == 'ManageNews') this.router.navigate(['admin/static-content/home-page']);
        else if (itemName == 'ManageEvents') this.router.navigate(['admin/static-content/event-list']);
        // else if (item.name == 'Archives') this.ArchiveClicked();
        else if (itemName == 'ArchivedNews') this.router.navigate(['admin/static-content/archived-post']);
        else if (itemName == 'ArchivedEvents') this.router.navigate(['admin/static-content/archived-post']);
        else if (itemName == 'CustomSection') { this.router.navigate(['admin/custom-section/', id]); }
      }
      else if (this.settingsMenu) {
        if (itemName == 'MenuSetting') this.router.navigate(['admin/dashboard-sub-menu/settings/app-settings']);
        else if (itemName == 'LandingPage') this.router.navigate(['admin/dashboard-sub-menu/settings/landing-page-settings']);
        else if (itemName == 'AppMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/app-menu-settings']);
        else if (itemName == 'LoginAndSignup') this.router.navigate(['admin/dashboard-sub-menu/settings/login-signup-settings']);
        else if (itemName == 'AdminMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/admin-menu-settings']);

        // else if (itemName == 'AllMenus') this.router.navigate(['admin/dashboard-sub-menu/settings/app-all-menu-settings']);
        
        else if (itemName == 'MemberMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/member-menu-settings']);
        else if (itemName == 'SectionSetting') this.router.navigate(['admin/dashboard-sub-menu/settings/section-settings']);
        else if (itemName == 'CustomSection') { this.router.navigate(['admin/custom-section/', id]); }
        else if (itemName == 'AllMenus') { this.router.navigate(['admin/dashboard-sub-menu/settings/app-all-menu-settings']); }
        else if (itemName == 'SocialMedia') { this.router.navigate(['admin/dashboard-sub-menu/settings/social-media/dashboard']); }
      }
      else if (this.housingMenu) {
        if (itemName == 'Houses') this.router.navigate(['admin/dashboard-sub-menu/housing']);
        else if (itemName == 'NewHouse') this.router.navigate(['admin/dashboard-sub-menu/new-property']);
        else if (itemName == 'Repairs') this.router.navigate(['admin/dashboard-sub-menu/repair-request']);
        else if (itemName == 'Renovations') this.router.navigate(['admin/dashboard-sub-menu/renovation-request']);
      }
    } else $('#permissionModal').modal('open');
  }

  navigate(item) {
    if (item == 'AboutUs') this.router.navigate(['admin/dashboard-sub-menu/about-us']);
    else if (item == 'NationInfo') this.router.navigate(['admin/dashboard-sub-menu/nation-settings']);
    else if (item == 'Departments') this.router.navigate(['admin/dashboard-sub-menu/department']);
    else if (item == 'Governance') this.router.navigate(['admin/dashboard-sub-menu/governance']);
    else if (item == 'AreaOfInterest') this.router.navigate(['admin/dashboard-sub-menu/areas-of-interest']);
    else if (item == 'Board') this.router.navigate(['admin/dashboard-sub-menu/manage-boards']);

    else if (item == 'Membership') this.router.navigate(['admin/members']);
    else if (item == 'Admins') this.router.navigate(['admin/dashboard-sub-menu/admins-list']);
    else if (item == 'Applications') this.router.navigate(['admin/dashboard-sub-menu/applications']);
    else if (item == 'DemoRequest') this.router.navigate(['admin/dashboard-sub-menu/demo-request-list']);
    else if (item == 'ClientAdmin') this.router.navigate(['admin/dashboard-sub-menu/client-admin']);

    else if (item == 'AppStatistics') this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
    else if (item == 'DepartmentStats') this.router.navigate(['admin/dashboard-sub-menu/stats/dep-statistics']);
    else if (item == 'Demographics') this.router.navigate(['admin/dashboard-sub-menu/stats/demographics']);
    else if (item == 'ManageNews') this.router.navigate(['admin/static-content/home-page']);
    else if (item == 'ManageEvents') this.router.navigate(['admin/static-content/event-list']);
    else if (item == 'ArchivedNews') this.router.navigate(['admin/static-content/archived-post']);

    else if (item == 'MenuSetting') this.router.navigate(['admin/dashboard-sub-menu/settings/app-settings']);
    else if (item == 'LandingPage') this.router.navigate(['admin/dashboard-sub-menu/settings/landing-page-settings']);
    else if (item == 'AppMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/app-menu-settings']);
    else if (item == 'LoginAndSignup') this.router.navigate(['admin/dashboard-sub-menu/settings/login-signup-settings']);
    else if (item == 'AdminMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/admin-menu-settings']);
    else if (item == 'MemberMenu') this.router.navigate(['admin/dashboard-sub-menu/settings/member-menu-settings']);
    else if (item == 'AllMenus') { this.router.navigate(['admin/dashboard-sub-menu/settings/app-all-menu-settings']); }


    else if (item == 'Houses') this.router.navigate(['admin/dashboard-sub-menu/housing']);
    else if (item == 'NewHouse') this.router.navigate(['admin/dashboard-sub-menu/new-property']);
    else if (item == 'Repairs') this.router.navigate(['admin/dashboard-sub-menu/repair-request']);
    else if (item == 'Renovations') this.router.navigate(['admin/dashboard-sub-menu/renovation-request']);
  }

  onMenuClicked(valueEmitted) {
    this.myNationMenu = false;
    this.manageMenu = false;
    this.statisticsMenu = false;
    this.settingsMenu = false;
    this.housingMenu = false;
    this.ngOnInit();
  }

  backClicked() {
    this.location.back();
  }

  
}