<div class="container-pi">
  <!-- <h4 *ngIf="dep" class="title-section1  title-member" [innerHTML]="dep.name"></h4> -->

  <div class="title-section1 title-member icon-texts">
    <button class="back-button" aria-label="Go back" (click)="backClicked()">
      <span class="material-symbols-outlined back-icon p-r-1">arrow_circle_left</span>
    </button>
    <h4 class="dep-name" [innerHTML]="dep.name"></h4>
  </div>
  

  <div class="row card-panel b-n-1 ">
    <div class="col s12">
      <ul class="tabs">
        <li class="tab col s4">
          <a href="#test1">
            <strong *ngIf="nationOfUSe === 'Batc'">Office Info</strong>
            <strong *ngIf="nationOfUSe !== 'Batc'">Department Info</strong>
          </a>
        </li>
        <li class="tab col s4">
          <a href="#test2">
            <strong *ngIf="nationOfUSe === 'Batc'">Office News</strong>
            <strong *ngIf="nationOfUSe !== 'Batc'">Department News</strong>
          </a>
        </li>

        <!-- <li class="tab col s2">
          <a href="#test3">
            <strong *ngIf="nationOfUSe === 'Batc'">Sub Offices</strong>
            <strong *ngIf="nationOfUSe !== 'Batc'">Sub Departments</strong>
          </a>
        </li> -->

        <li class="tab col s4">
          <a href="#test4">
            <strong>Contact</strong>
          </a>
        </li>

        <!-- <li class="tab col s2">
          <a href="#test5">
            <strong>Manage News/Event</strong>
          </a>
        </li> -->

        <!-- <li class="tab col s2">
          <a href="#test6">
            <strong>Manage Employee</strong>
          </a>
        </li> -->
      </ul>
    </div>

    <div id="test1" class="col s12">
      <app-view-department-info></app-view-department-info>
    </div>
    <div id="test2" class="col s12">
      <app-manage-dep-news></app-manage-dep-news>
    </div>
    <!-- <div id="test3" class="col s12">
      <app-view-subdepartment-info></app-view-subdepartment-info>
    </div> -->
    <div id="test4" class="col s12">
      <app-contact-department-member></app-contact-department-member>
    </div>
    <!-- <div id="test5" class="col s12">
      <app-manage-department></app-manage-department>
    </div> -->
    <!-- <div id="test6" class="col s12">
      <app-edit-department-employee></app-edit-department-employee>
    </div> -->
  </div>
</div>