<div class="col s12 ">

  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l12 m12 s12">
        <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined  bold-500 p-r-1 "
            (click)="backToDeartmentEmployees()"> arrow_back </i> Edit Employee Account</h4>
      </div>
    </div>

    <div class="StickyContent white">
      <div class="col s12 l10">
        <form [formGroup]="employeeForm" (ngSubmit)="updateEmployee(employeeForm.value)" *ngIf="model">

          <div class="row card-panel white">
            <h6 class="bold-900" style="margin-bottom: 25px; margin-top: 15px;">General Information</h6>

            <div class="input-field col s12 l6">
              <div class="label-input">First Name</div>
              <input id="fnme" type="text" [value]="model.givenName" disabled />
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">Last Name</div>
              <input id="lnme" type="text" [value]="model.lastName" disabled />
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">Head Office</div>
              <select formControlName="headOffice" class="validate" (change)="headOfficeChange($event.target.value)">
                <option value="" disabled selected>Please Select</option>
                <option *ngFor="let c of SelectChoice" [value]="c">{{c}}</option>
              </select>
            </div>

            <div class="input-field col s12 l6" *ngIf="communities && !isHead">
              <div class="label-input">Community</div>
              <select formControlName="community" class="validate browser-default">
                <option value="" disabled selected>Select</option>
                <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
              </select>
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">Email</div>
              <input id="email" type="text" [value]="model.email" disabled />
            </div>

            <div *ngIf="!isManager" class="input-field col s12 l6">
              <div class="label-input">Job Title</div>
              <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate"
                autocomplete="off" />
              <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
                <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required. </small>
              </div>
            </div>

            <div *ngIf="isManager" class="input-field col s12 l6">
              <div class="label-input">Job Title</div>
              <select formControlName="jobTitle" class="validate browser-default">
                <option value="" disabled selected>Please Select Title</option>
                <option *ngFor="let t of managerTitles" [value]="t">{{t}}</option>
              </select>
            </div>

            <div class="">
              <div class="input-field col m8 s7 ">
                <div class="label-input">Work Phone<span class="red-text">*</span></div>
                <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}"
                  required class="validate" autocomplete="off" /> -->
                <input id="workPhone" type="text" formControlName='workPhone' [inputMask]="phoneInputMask" required
                  class="validate" autocomplete="off" />
                <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
                  <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required. </small>
                </div>
              </div>

              <div class=" input-field col m4 s5">
                <div class="label-input">Ext :</div>
                <!-- <input id="ext" type="text" formControlName='ext' [textMask]="{mask: extention}" autocomplete="off" /> -->
                <input id="ext" type="text" formControlName='ext' autocomplete="off" />
              </div>

            </div>


            <div class="input-field col s12 l12">
              <div class="label-input">Mobile Phone</div>
              <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
                autocomplete="off" /> -->
              <input id="mobilePhone" type="text" formControlName='mobilePhone' [inputMask]="phoneInputMask"
                autocomplete="off" />
            </div>

            <div class="input-field col s12 l12">
              <div class="label-input">Responibilities</div>
              <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
                autocomplete="off"></textarea>
              <div *ngIf="(description.dirty || description.touched) && description.invalid">
                <small *ngIf="description.errors.required" class="red-text"> The Job Responsibilities is required.
                </small>
              </div>
            </div>

          </div>

          <div class="row card-panel white">
            <h6 class="bold-900" style="margin-bottom: 25px; margin-top: 15px;">Contact Settings</h6>

            <div class="col s12">

              <p><strong>Show Mobile Phone</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group1" class="with-gap" [checked]="showMobileNo === 'Public'"
                    (click)="publicMobileClick()" />
                  <span><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group1" class="with-gap" [checked]="showMobileNo === 'Members'"
                    (click)="membersMobileClick()" />
                  <span class="blue-text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group1" class="with-gap" [checked]="showMobileNo === 'Private'"
                    (click)="privateMobileClick()" />
                  <span class="red-text"><strong>Do Not Show </strong></span>
                </label>
              </div>

            </div>

            <div class="col s12">

              <p><strong>Show Office Phone</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value="Yes" name="group0" class="with-gap" [checked]="showOfficeNo === 'Public'"
                    (click)="publicOfficeClick()" />
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" value="No" name="group0" class="with-gap" [checked]="showOfficeNo === 'Members'"
                    (click)="membersOfficeClick()" />
                  <span class="blue-text"><strong>Members Only</strong></span>
                </label>
              </div>

              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group0" class="with-gap" [checked]="showOfficeNo === 'Private'"
                    (click)="privateOfficeClick()" />
                  <span class="red-text"><strong>Do Not Show</strong></span>
                </label>
              </div>
            </div>

            <div class="col s12">

              <p><strong>Show Email Address</strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group2" class="with-gap" [checked]="showEmailAdr === 'Public'"
                    (click)="publicEmailClick()" />
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group2" class="with-gap" [checked]="showEmailAdr === 'Members'"
                    (click)="membersEmailClick()" />
                  <span class="blue-text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group2" class="with-gap" [checked]="showEmailAdr === 'Private'"
                    (click)="privateEmailClick()" />
                  <span class="red-text"><strong>Do Not Show</strong></span>
                </label>
              </div>
            </div>

            <div class="col s12">
              <p><strong>Allow Contact Us Messages </strong></p>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group3" class="with-gap" [checked]="allowContact === 'Public'"
                    (click)="publicContactClick()" />
                  <span class="text"><strong>General Public</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group3" class="with-gap" [checked]="allowContact === 'Members'"
                    (click)="membersContactClick()" />
                  <span class="blue-text"><strong>Members Only</strong></span>
                </label>
              </div>
              <div class="col s12 m6 l4">
                <label>
                  <input type="radio" name="group3" class="with-gap" [checked]="allowContact === 'Private'"
                    (click)="privateContactClick()" />
                  <span class="red-text"><strong>Do Not Allow</strong></span>
                </label>
              </div>
            </div>
            <div class="row">
              <br />
            </div>
          </div>

          <div class="row card-panel white" *ngIf="appsInDepartment">
            <div class="row col s12">
              <p><strong>Assign to Application</strong></p>
              <div class="input-field col s12">
                <i class="material-icons prefix green-text">people_outline</i>
                <select multiple formControlName="applicationName" class="validate">
                  <option value="" disabled selected>Please Select Application</option>
                  <option *ngFor="let app of appsInDepartment" [value]="app">{{app}}</option>
                </select>
                <label for="applicationName">Applications </label>
              </div>
            </div>
            <div *ngIf="isManager" class="row card-panel white">

              <p><strong>Permissions</strong></p>
              <div formGroupName="permissions">
                <div class="col s12 m12 l6">
                  <label>
                    <input type="checkbox" formControlName="allowDeptCreate">
                    <span>Allow Creating Department News</span>
                  </label>
                </div>
                <div class="col s12 m12 l6">
                  <label>
                    <input type="checkbox" formControlName="allowAddEmp">
                    <span>Allow Adding Employee</span>
                  </label>
                </div>
              </div>

            </div>
          </div>
          <div class="col s12 l5"></div>

          <button type="submit" style="margin-top: 10px;" [disabled]="!employeeForm.valid" class="btn col s12 l3 green white-text">
            Save Changes <i class="material-icons white-text right">send</i>
          </button>

          <button type="button" style="margin-top: 10px;" (click)="openDeleteModal()"
            class="btn col s12 l3 push-l1 red white-text">
            Archive Employee <i class="material-icons white-text right">archive</i>
          </button>
<!-- 
          <button type="button" style="margin-top: 10px;" class="col s12 btn waves-effect"
            [ngStyle]="{'background-color': themeColor}" type="button" (click)="backToDeartmentEmployees()"><i
              class="material-icons left">arrow_back_ios</i> Back To Deartment Employees
          </button> -->
        </form>
      </div>
    </div>

    <div id="deleteEmployeeConfirmation" class="modal">
      <div class="modal-content">
        <h6>Sure to delete employee?</h6>
      </div>
      <div class="modal-footer">
        <button class="waves-effect waves-green btn-flat red white-text" (click)="deleteEmployee();">DELETE</button>
        <button class="modal-close waves-effect waves-green white-text green btn-flat">CANCEL</button>
      </div>
    </div>
  </div>
</div>