<div class="row center-align p-m-2">
  <h6><strong> Add Employee </strong></h6>
</div>

<div class="row col s12" *ngIf="!userExists">
  <div class="col l1 "></div>
  <div class="col s12 l10">

    <form autocomplete="off" [formGroup]="searchMemberForm" novalidate>
      <div class="row card-panel white darken-2" *ngIf="showSearch">
        <div class="row">
          <div class="input-field col s12 m6">
            <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off" />
            <label for="lastName">Last Name</label>
          </div>

          <div class="input-field col s12 m6">
            <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off" />
            <label for="firstName">First Name</label>
          </div>
        </div>
      </div>

      <div class="row card-panel white darken-2" *ngIf="showSearchTable">
        <button *ngIf="showSearchTable" title="Search" (click)="onSearchEmployees()"
          [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
          class="col s12 btn waves-effect green" style="margin-bottom: 15px;">
          <i class="material-icons right">cloud_upload</i> Search Member
        </button>

        <div class="card-body">
          <div class="table-responsive">
            <table class="row-border hover notification-table centered striped" width="100%">
              <thead>
                <tr>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of employeesListData; let i = index" class="employeesListData">
                  <td>{{item?.LastName}}</td>
                  <td>{{item?.GivenName}}</td>
                  <td><button class="btn-small" (click)="setFormDetails(item)">Select</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="!searchHasRecord" class="row card-panel red lighten-1">
        <h6 class="white-text"> <strong>
            No Member Found In The DataBase.</strong>
        </h6>
      </div>
    </form>

    <div class="row" *ngIf="showAddMemberToDepartmentForm">
      <form [formGroup]="addMemberToDepartmentForm" (ngSubmit)="AddEmployee(addMemberToDepartmentForm.value)">
        <div class="row card-panel white">
          <div class="input-field col s12">
            <div class="label-input">First Name </div>
            <input id="fnme" type="text" [value]="selectedMember.GivenName" disabled />
          </div>

          <div class="input-field col s12">
            <div class="label-input">Last Name </div>
            <input id="lnme" type="text" [value]="selectedMember.LastName" disabled />
          </div>

          <div class="input-field col s12 l12">
            <div class="label-input">Display Name</div>
            <input id="displayName" type="text" formControlName='displayName' />
          </div>

          <div class="input-field col s12">
            <div class="label-input">Head Office </div>
            <select formControlName="headOffice" class="validate browser-default" (change)="headOfficeChange($event.target.value)">
              <option value="" disabled selected>Please Select</option>
              <option *ngFor="let c of SelectChoice" [value]="c">{{c}}</option>
            </select>
          </div>

          <div class="input-field col s12" *ngIf="communities && !isHead">
            <div class="label-input">Head Office </div>
            <select formControlName="community" class="validate browser-default">
              <option value="" disabled selected>Select</option>
              <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
            </select>
            <label for="community">Community </label>
          </div>

          <div *ngIf="!isManager" class="input-field col s12 l12">
            <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate" autocomplete="off" />
            <label for="jobTitle">Job Title <span class="red-text">*</span></label>
            <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
              <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required. </small>
            </div>
          </div>

          <div *ngIf="isManager" class="input-field col s12">
            <div class="label-input">Head Office </div>
            <select formControlName="jobTitle" class="validate browser-default">
              <option value="" disabled selected>Please Select Title</option>
              <option *ngFor="let t of managerTitles" [value]="t">{{t}}</option>
            </select>
            <label for="jobTitle">Job Title </label>
          </div>

          <div class="input-field col s12">
            <div class="label-input">Head Office </div>
            <input id="email" type="text" formControlName='email' required class="validate" autocomplete="off" />
            <label for="email">Email <span class="red-text">*</span></label>
            <div *ngIf="(email.dirty || email.touched) && email.invalid">
              <small *ngIf="email.errors.required" class="red-text">The Email is required. </small>
              <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
            </div>
          </div>

          <div class="">
            <div class="label-input">Head Office </div>
            <div class="input-field col m8 s7">
              <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}"
                required class="validate" autocomplete="off" /> -->
                <input id="workPhone" type="text" formControlName='workPhone' 
                required class="validate" autocomplete="off" />
              <label for="workPhone">Work Phone <span class="red-text">*</span> </label>
            </div>

            <div class=" input-field col m4 s5">
              <!-- <input id="ext" type="text" formControlName='ext' [textMask]="{mask: extention}" autocomplete="off" /> -->
              <input id="ext" type="text" formControlName='ext'  autocomplete="off" />
              <label for="ext">Ext :</label>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <div class="label-input">Head Office </div>
            <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
              autocomplete="off" /> -->
              <input id="mobilePhone" type="text"  formControlName='mobilePhone'
              autocomplete="off" />
            <label for="mobilePhone">Mobile Phone</label>
          </div>

          <div class="input-field col s12 l12">
            <div class="label-input">Head Office </div>
            <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
              autocomplete="off"></textarea>
            <label for="description">Responibilities<span class="red-text">*</span> </label>
          </div>
        </div>

        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Employee Contact Settings</strong>
          </h6>
          <div class="col s12">
            <p><strong>Show Mobile Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Public'" (click)="publicMobileClick()" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                  (click)="membersMobileClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                  (click)="privateMobileClick()" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Office Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                  (click)="publicOfficeClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                  (click)="membersOfficeClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                  (click)="privateOfficeClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Email Address</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Public'" (click)="publicEmailClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                  (click)="membersEmailClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                  (click)="privateEmailClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Allow Contact Us Messages </strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Public'"
                  (click)="publicContactClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Members'"
                  (click)="membersContactClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Private'"
                  (click)="privateContactClick()" />
                <span class="red-text"><strong>Do Not Allow</strong></span>
              </label>
            </div>
          </div>
          <div class="row">
            <br />
          </div>
        </div>
        <div class="row card-panel white" *ngIf="appsInDepartment">
          <div class="row col s12">
            <p><strong>Assign to Application</strong></p>
            <div class="input-field col s12">
              <i class="material-icons prefix green-text">people_outline</i>
              <select multiple formControlName="applicationName" class="validate">
                <option value="" disabled selected>Please Select Application</option>
                <option *ngFor="let app of appsInDepartment" [value]="app">{{app}}</option>
              </select>
              <label for="applicationName">Applications </label>
            </div>
          </div>
        </div>

        <div *ngIf="isManager" class="row card-panel white">
          <p><strong>Permissions</strong></p>
          <div formGroupName="permissions">
            <div class="col s12 m12 l6">
              <label>
                <input type="checkbox" formControlName="allowDeptCreate">
                <span>Allow Creating Department News</span>
              </label>
            </div>
            <div class="col s12 m12 l6">
              <label>
                <input type="checkbox" formControlName="allowAddEmp">
                <span>Allow Adding Employee</span>
              </label>
            </div>
          </div>
        </div>

        <button type="submit" [disabled]="!addMemberToDepartmentForm.valid" class="btn col s12 blue white-text">
          Add Employee <i class="material-icons right">send</i>
        </button>

        <button style="margin-top: 15px;" class="col s12 btn waves-effect" [ngStyle]="{'background-color': themeColor}"
          type="button" (click)="backToDeartmentEmployees()"><i class="material-icons left">arrow_back_ios</i> Back To
          Department
        </button>
      </form>
    </div>

    <div class="section"></div>

    <button *ngIf="showSearch" title="Search" (click)="onSearchEmployees()"
      [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
      class="col s12 btn waves-effect green" style="margin-bottom: 15px;">
      <i class="material-icons right">cloud_upload</i> Search Member
    </button>

    <button *ngIf="showSearch" class="col s12 btn waves-effect green" title="Search" (click)="onClearSearchEmployees()"
      [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
      style="margin-bottom: 15px;">
      New Research
    </button>

    <button *ngIf="showSearch" class="col s12 btn waves-effect " [ngStyle]="{'background-color': themeColor}"
      type="button" (click)="backToDeartmentEmployees()"><i class="material-icons left">arrow_back_ios</i> Back To
      Department
    </button>
  </div>
  <div class="col l1 "></div>
</div>

<div *ngIf="userExists" style="min-height: 50px; width: 100%;">
  <div class="row">
    <div class="col s12 m6">
      <div class="card red lighten-4">
        <div class="card-content black-text center">
          <p>Employee Exists </p>
          <br />
          <div class="center">
            <button class="waves-effect waves-light btn" (click)="backToDeartmentEmployees()">Okay</button>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
  <a (click)="backToDeartmentEmployees();" style="width: 100%;" [ngClass]="'col s12 btn waves-effect ' + themeColor">
    <i class="material-icons left">arrow_back_ios</i>
    Back To Previous Page </a>
</div>