<body>
  <div class="row header">
    <div class="col l1 s1 cont" (click)="backClicked()">
      <span class="material-symbols-outlined"> arrow_back </span>Back
    </div>
    <div class="col s1 hide-on-small-only"></div>
    <div class="col l8 s6">
      <div class="switch">
        <label class="right">
          List View
          <input
            type="checkbox"
            [checked]="pageSections?.cardView"
            (change)="sectionViewChanged()"
          />
          <span class="lever"></span>
          Card View
        </label>
      </div>
    </div>
    <div class="col l1 cont" (click)="saveChanges()">
      <span class="material-symbols-outlined">Save</span>Save
    </div>

    <div class="col s1 cont" (click)="previewClicked()">
      <span class="material-symbols-outlined"> visibility </span>Preview
    </div>
  </div>

  <div class="wrapper">
    <!-- =========== left nav -->
    <aside class="hide-on-small-only">
      <h4>Pages</h4>
      <ul>
        <li
          *ngFor="let section of pageSections?.sections"
          (click)="editSection(section)"
        >
          <span class="material-symbols-outlined"> {{ section.icon }} </span>
          {{ section.displayName }}
        </li>
      </ul>
    </aside>

    <!-- =========== main contnet -->
    <main
      [style.flex]="
        rightNavState === 'closed' && rightAppState === 'apper-closed'
          ? '1 0 84%'
          : '1 0 70%'
      "
    >
      <div class="wrapper_inner">
        <div *ngIf="selectedSection">
          <div class="row header-wrapper">
            <h1 class="col s9">{{ selectedSection.displayName }}</h1>

            <div class="left responsive-view col s3" *ngIf="!cardView">
              <select
                class="browser-default"
                [(ngModel)]="selectedScreenSize"
                (change)="updatePreview()"
              >
                <option value="1">Desktop(1920 X 1080)</option>
                <option value="2">Tablet(1024 X 768)</option>
                <option value="3">Phone(411 X 731)</option>
              </select>
            </div>
          </div>

          <div
            class="card"
            [ngClass]="screenSizeClass"
            [style.transform]="'scale(' + zoomValue + '%)'"
          >
            <!-- ============ slider -->
            <ng-container *ngIf="selectedSection.name === 'Slider'">
              <app-landing-page-slider
                [fullscreenEnabled]="false"
              ></app-landing-page-slider>
            </ng-container>
            <!-- ============ login -->
            <ng-container *ngIf="selectedSection.name === 'Login'">
              <div
                class="col s12 m12 l12"
                *ngIf="!cardView"
                [ngStyle]="{ 'background-color': loginRef?.bgColor }"
              >
                <app-register-login-buttons></app-register-login-buttons>
              </div>

              <div class="center-align row" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': loginRef?.bgColor }"
                  >
                    <i
                      *ngIf="!loginRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': loginRef.displayNameColor,
                        color: loginRef.displayNameColor
                      }"
                    >
                      {{ loginRef.icon }}
                    </i>

                    <img
                      *ngIf="loginRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="loginRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: loginRef.displayNameColor }">
                      {{ loginRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>

            <!-- ============ News -->
            <ng-container *ngIf="selectedSection.name === 'News'">
              <div *ngIf="newsArray">
                <div
                  class="col s12 m12 l12"
                  *ngIf="!cardView"
                  [ngStyle]="{ 'background-color': newsRef?.bgColor }"
                >
                  <app-latest-news
                    (refreshPreview)="refreshPreview($event)"
                    [cardView]="newsRef?.cardView"
                  >
                  </app-latest-news>
                </div>

                <div class="row center-align" *ngIf="cardView">
                  <div class="col l4 s6 card-padding">
                    <a
                      class="card1"
                      [ngStyle]="{ 'background-color': newsRef?.bgColor }"
                    >
                      <i
                        *ngIf="!newsRef.logoUrl"
                        class="material-symbols-outlined"
                        [ngStyle]="{
                          'border-color': newsRef.displayNameColor,
                          color: newsRef.displayNameColor
                        }"
                        >{{ newsRef.icon }}</i
                      >
                      <img
                        *ngIf="newsRef.logoUrl"
                        class="activator responsive-img center circle"
                        [src]="newsRef.logoUrl"
                        style="
                          width: 100px;
                          height: 100px;
                          border-radius: 50%;
                          margin-left: auto;
                          margin-right: auto;
                          display: block;
                        "
                      />
                      <h3 [ngStyle]="{ color: newsRef.displayNameColor }">
                        {{ newsRef.displayName }}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- ============ Iset -->
            <ng-container *ngIf="selectedSection.name === 'ISETReg'">
              <div
                class="col s12 m12 l12"
                *ngIf="!cardView"
                [ngStyle]="{ 'background-color': isetRegRef?.bgColor }"
              >
                <app-register-login-buttons></app-register-login-buttons>
              </div>

              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': isetRegRef?.bgColor }"
                  >
                    <i
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': isetRegRef.displayNameColor,
                        color: isetRegRef.displayNameColor
                      }"
                      >{{ isetRegRef.icon }}</i
                    >
                    <h3 [ngStyle]="{ color: isetRegRef.displayNameColor }">
                      {{ isetRegRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ Events -->
            <ng-container *ngIf="selectedSection.name === 'Events'">
              <div
                class="col s12 m12 l12"
                [ngStyle]="{ 'background-color': eventRef?.bgColor }"
                *ngIf="!cardView"
              >
                <app-upcoming-events></app-upcoming-events>
              </div>

              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': eventRef?.bgColor }"
                  >
                    <i
                      *ngIf="!eventRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': eventRef.displayNameColor,
                        color: eventRef.displayNameColor
                      }"
                      >{{ eventRef.icon }}</i
                    >
                    <img
                      *ngIf="eventRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="eventRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: eventRef.displayNameColor }">
                      {{ eventRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ Department -->
            <ng-container *ngIf="selectedSection.name === 'Departments'">
              <div
                class="col s12 m12 l12"
                *ngIf="!cardView"
                [ngStyle]="{ 'background-color': departmentsRef?.bgColor }"
              >
                <app-department-list></app-department-list>
              </div>
              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': departmentsRef?.bgColor }"
                  >
                    <i
                      *ngIf="!departmentsRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': departmentsRef.displayNameColor,
                        color: departmentsRef.displayNameColor
                      }"
                      >{{ departmentsRef.icon }}</i
                    >
                    <img
                      *ngIf="departmentsRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="departmentsRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: departmentsRef.displayNameColor }">
                      {{ departmentsRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ Governance -->
            <ng-container *ngIf="selectedSection.name === 'Governance'">
              <div
                class="col s12 m12 l12"
                *ngIf="!cardView"
                [ngStyle]="{ 'background-color': governanceRef?.bgColor }"
              >
                <app-governance-list
                  [from]="'landingPage'"
                ></app-governance-list>
              </div>

              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': governanceRef?.bgColor }"
                  >
                    <i
                      *ngIf="!governanceRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': governanceRef.displayNameColor,
                        color: governanceRef.displayNameColor
                      }"
                      >{{ governanceRef.icon }}</i
                    >
                    <img
                      *ngIf="governanceRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="governanceRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />

                    <h3 [ngStyle]="{ color: governanceRef.displayNameColor }">
                      {{ governanceRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ Apple store  -->
            <ng-container *ngIf="selectedSection.name === 'AppStore'">
              <div
                class="col s12 m12 l12"
                [ngStyle]="{ 'background-color': appStoreRef?.bgColor }"
                *ngIf="!cardView"
              >
                <app-app-store></app-app-store>
              </div>

              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1 modal-trigger"
                    [ngStyle]="{ 'background-color': appStoreRef?.bgColor }"
                  >
                    <i
                      *ngIf="!appStoreRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': appStoreRef.displayNameColor,
                        color: appStoreRef.displayNameColor
                      }"
                      >{{ appStoreRef.icon }}</i
                    >
                    <img
                      *ngIf="appStoreRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="appStoreRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: appStoreRef.displayNameColor }">
                      {{ appStoreRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ================== Social Media ======== -->
            <ng-container *ngIf="selectedSection.name === 'SocialMedia'">
              <div
                class="col s12 m12 l12"
                [ngStyle]="{ 'background-color': socialMediaRef?.bgColor }"
                *ngIf="!cardView"
              >
                <app-social-media-links></app-social-media-links>
              </div>
              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1 modal-trigger"
                    [ngStyle]="{ 'background-color': socialMediaRef?.bgColor }"
                  >
                    <i
                      *ngIf="!socialMediaRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'border-color': socialMediaRef.displayNameColor,
                        color: socialMediaRef.displayNameColor
                      }"
                      >{{ socialMediaRef.icon }}</i
                    >
                    <img
                      *ngIf="socialMediaRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="socialMediaRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: socialMediaRef.displayNameColor }">
                      {{ socialMediaRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ About us -->
            <ng-container *ngIf="selectedSection.name === 'AboutUs'">
              <div
                class="col s12 m12 l12"
                [ngStyle]="{ 'background-color': aboutUsRef?.bgColor }"
                *ngIf="!cardView"
              >
                <app-about-us-section></app-about-us-section>
              </div>

              <div class="row center-align" *ngIf="cardView">
                <div class="col l4 s6 card-padding">
                  <a
                    class="card1"
                    [ngStyle]="{ 'background-color': aboutUsRef?.bgColor }"
                  >
                    <i
                      *ngIf="!aboutUsRef.logoUrl"
                      class="material-symbols-outlined"
                      [ngStyle]="{
                        'aboutUsRef-color': aboutUsRef.displayNameColor,
                        color: aboutUsRef.displayNameColor
                      }"
                      >{{ aboutUsRef.icon }}</i
                    >
                    <img
                      *ngIf="aboutUsRef.logoUrl"
                      class="activator responsive-img center circle"
                      [src]="aboutUsRef.logoUrl"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                      "
                    />
                    <h3 [ngStyle]="{ color: aboutUsRef.displayNameColor }">
                      {{ aboutUsRef.displayName }}
                    </h3>
                  </a>
                </div>
              </div>
            </ng-container>
            <!-- ============ Footer -->
            <ng-container *ngIf="selectedSection.name === 'Footer'">
              <footer
                class="page-footer col s12 m12 l12"
                [ngStyle]="{ 'background-color': footerRef.bgColor }"
              >
                <app-landing-page-footer></app-landing-page-footer>
              </footer>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!selectedSection">
          <div *ngIf="cardView">
            <div class="card" [style.transform]="'scale(' + zoomValue + '%)'">
              <app-card-view-landing-page
                [preview]="true"
                [sections]="pageSections"
              >
              </app-card-view-landing-page>
            </div>
          </div>

          <div class="border" *ngIf="!cardView">
            <div class="card" [style.transform]="'scale(' + zoomValue + '%)'">
              <div
                class="row col s12 p-t-1"
                *ngFor="let section of pageSections?.sections"
              >
                <div [ngSwitch]="section.name">
                  <!-- ******************** Slider ************************** -->
                  <div *ngSwitchCase="'Slider'">
                    <app-landing-page-slider
                      [fullscreenEnabled]="false"
                    ></app-landing-page-slider>
                  </div>

                  <!-- ******************** Login and Register buttons ************************** -->
                  <div *ngSwitchCase="'Login'">
                    <app-register-login-buttons></app-register-login-buttons>
                  </div>

                  <!-- ******************** News ************************** -->
                  <div *ngSwitchCase="'News'">
                    <app-latest-news></app-latest-news>
                  </div>

                  <!-- ******************** Events ************************** -->
                  <div *ngSwitchCase="'Events'">
                    <app-upcoming-events></app-upcoming-events>
                  </div>

                  <!-- ******************** Download App ************************** -->
                  <div *ngSwitchCase="'AppStore'">
                    <app-app-store></app-app-store>
                  </div>
                  <!-- ******************** ******* ************************** -->

                  <!-- ******************** Governance ************************** -->
                  <div *ngSwitchCase="'Governance'">
                    <app-governance-list
                      [from]="'landingPage'"
                    ></app-governance-list>
                  </div>
                  <!-- ******************** ******* ************************** -->

                  <!-- ******************** DEPARTMENTS ************************** -->
                  <div *ngSwitchCase="'Departments'">
                    <app-department-list></app-department-list>
                  </div>
                  <!-- ******************** ******* ************************** -->

                  <!-- ******************** Social Media ************************** -->
                  <div *ngSwitchCase="'SocialMedia'">
                    <app-social-media-links></app-social-media-links>
                  </div>
                  <!-- ******************** ******* ************************** -->

                  <!-- ******************** About Me ************************** -->
                  <div *ngSwitchCase="'AboutUs'">
                    <app-about-us-section></app-about-us-section>
                  </div>
                  <!-- ******************** ******* ************************** -->

                  <!-- ******************** Footer ************************** -->
                  <div *ngSwitchCase="'Footer'">
                    <app-landing-page-footer></app-landing-page-footer>
                  </div>
                  <!-- ******************** ******* ************************** -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="zoom-controls-container left">
        <div class="zoom-controls">
          <button (click)="zoomOutPreview()">-</button>
          <input
            id="zoomSlider"
            type="range"
            min="50"
            max="200"
            step="10"
            [(ngModel)]="zoomValue"
          />
          <button (click)="zoomInPreview()">+</button>
          <span>{{ zoomValue }}%</span>
          <button (click)="resetZoom()">
            <span class="material-symbols-outlined bold-600 sub-title-section3">
              zoom_out_map
            </span>
          </button>
        </div>
      </div>

      <!-- ============= Modallll=========== -->

      <div
        id="sliderModal"
        class="modal white bottom-sheet modal-fixed-footer"
        *ngIf="
          sliderSettings &&
          sliderRef &&
          sliderRef.options.length > 0 &&
          sliderRef.options[slideRef]
        "
      >
        <div class="modal-content" *ngIf="sliderRef.options[slideRef]">
          <div class="slider-modal-header">
            <h4 class="col s11" *ngIf="!editSlider">Add new slider</h4>
            <h4 class="col s11" *ngIf="editSlider">Edit slider</h4>
            <span
              class="col s1 modal-close material-symbols-outlined"
              [ngStyle]="{ color: themeColor }"
              (click)="closeSliderModal()"
              style="cursor: pointer"
              >close</span
            >
          </div>
          <div class="row">
            <div class="col s12 m6 l6 modal-card">
              <div class="card">
                <div class="center-align">
                  <div class="white">
                    <i
                      class="material-symbols-outlined p-t-2"
                      *ngIf="!sliderRef.options[slideRef].imgUrl"
                      >add_photo_alternate</i
                    >

                    <i
                      class="material-symbols-outlined p-t-2"
                      *ngIf="sliderRef.options[slideRef].imgUrl"
                      >image</i
                    >

                    <!-- ======== add image and update image Slider========= -->

                    <input
                      name="file"
                      type="file"
                      accept="image/*, video/*"
                      id="fileField"
                      required
                      (change)="
                        handleFileInput(
                          $event.target.files,
                          sliderRef.options[slideRef]
                        )
                      "
                    />

                    <h3
                      class="p-b-2"
                      *ngIf="!sliderRef.options[slideRef].imgUrl"
                    >
                      Drop your image/video here, or
                      <u class="green-text text-lighten-1">Browse</u>
                      <span class="red-text">*</span>
                    </h3>
                    <h3
                      class="p-b-2"
                      *ngIf="sliderRef.options[slideRef].imgUrl"
                    >
                      Drop your image/video here, or
                      <u class="green-text text-lighten-1">Browse</u
                      ><span class="red-text">*</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col s12 m6 l6">
              <div class="card hoverable center-align">
                <div class="card-image">
                  <img
                    [src]="sliderRef?.options[slideRef]?.imgUrl"
                    *ngIf="sliderRef?.options[slideRef]?.type == 'Image'"
                  />
                  <video
                    controls
                    autoplay
                    loop
                    *ngIf="sliderRef.options[slideRef].type == 'Video'"
                    width="200"
                    height="150"
                  >
                    <source
                      [src]="sliderRef.options[slideRef].imgUrl"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div class="col s12">
            <h5 class="section-name">Select Position</h5>
            <ng-container
              *ngFor="let option of sliderRef.options; let i = index"
            >
              <button
                class="btn waves-effect button-with-padding {{
                  sliderRef.options[slideRef].index === i ? 'green' : ''
                }}"
                (click)="positionChanged(i)"
              >
                {{ i + 1 }}
              </button>
            </ng-container>
          </div>

          <div class="row p-t-1">
            <div class="input-field col s12 m12 l12">
              <div class="section-name">Title</div>
              <app-editor
                [title]="true"
                [model]="sliderRef.options[slideRef].title"
                [styler]="false"
                (onModelChange)="sliderTitleStyleChanged($event, slideRef)"
                [type]="true"
                [showChatGpt]="false"
              >
              </app-editor>
              <div class="white p-m-1 b-m-1 right">
                <a
                  (click)="openChatGptModal(slideRef, 'title')"
                  class="white-text btn white-text"
                  [ngStyle]="{ 'background-color': themeColor }"
                >
                  <i class="material-icons right">check</i>Use Chatgpt</a
                >
              </div>
            </div>

            <div class="input-field col s12 m8 l8">
              <div class="section-name">Text Position</div>
              <select
                class="browser-default"
                [(ngModel)]="sliderRef.options[slideRef].textPosition"
              >
                <option value="top-right">Top Right</option>
                <option value="top-left">Top Left</option>
                <option value="Center-k">Center</option>
                <option value="bottom-right">Bottom Right</option>
                <option value="bottom-left">Bottom Left</option>
              </select>
            </div>
          </div>
        </div>

        <div class="modal-footer white left">
          <button
            class="btn-small col s3 l2 save-button"
            [disabled]="!sliderRef.options[slideRef].imgUrl"
            (click)="saveSliderChanges(sliderRef.options[slideRef])"
            [ngClass]="'btn ' + buttonType"
            [ngStyle]="{ 'background-color': themeColor }"
          >
            Save
          </button>

          <button
            class="btn-small col s3 l2 cancel-button"
            *ngIf="editSlider"
            [ngClass]="'btn ' + buttonType"
            (click)="deleteSlider(sliderRef.options[slideRef])"
          >
            Delete
          </button>
        </div>
      </div>

      <div
        id="imageCropperModal"
        class="modal modal-open bottom-sheet modal-fixed-footer"
        [style.display]="showImageCropperModal ? 'block' : 'none'"
        #imageCropperModal
      >
        <div class="modal-content">
          <div class="slider-modal-header">
            <h4 class="col s11">Edit your Image</h4>
            <span
              class="col s1 modal-close material-symbols-outlined"
              [ngStyle]="{ color: themeColor }"
              style="cursor: pointer"
              type="button"
              (click)="closeCroppingModal()"
              >close</span
            >
          </div>

          <div class="row">
            <div class="col s12 img-cropper">
              <div class="image-cropper-container">
                <app-loading></app-loading>
                <image-cropper
                  *ngIf="!useBase64"
                  class="ngx-ic-cropper"
                  [imageFile]="imageChangedEvent"
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="21 / 12"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  [allowMoveImage]="true"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
                >
                </image-cropper>
                <image-cropper
                  *ngIf="useBase64"
                  class="ngx-ic-cropper"
                  [imageFile]="imageChangedEvent"
                  [imageBase64]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [containWithinAspectRatio]="containWithinAspectRatio"
                  [aspectRatio]="21 / 12"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  [allowMoveImage]="true"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (cropperReady)="cropperReady($event)"
                  (loadImageFailed)="loadImageFailed()"
                >
                </image-cropper>
              </div>
            </div>

            <div class="col s12">
              <div class="card row">
                <h5 class="section-name">Rotate & Flip</h5>

                <div class="col l3 m3 s3">
                  <button (click)="rotateLeft()" class="btn">
                    <i class="material-icons float-right">rotate_left</i>
                  </button>
                </div>
                <div class="col l3 m3 s3">
                  <button (click)="rotateRight()" class="btn">
                    <i class="material-icons float-right">rotate_right</i>
                  </button>
                </div>
                <div class="col l3 m3 s3">
                  <button (click)="flipHorizontal()" class="btn">
                    <i class="material-icons">flip</i>
                  </button>
                </div>
                <div class="col l3 m3 s3">
                  <button (click)="flipVertical()" class="btn">
                    <i class="material-icons flipped-icon">flip</i>
                  </button>
                </div>
              </div>

              <div class="card row">
                <h5 class="section-name">Zoom</h5>

                <div (click)="zoomOut()" class="col s12 l2 m2">
                  <i class="material-icons">zoom_out</i>
                </div>
                <input
                  type="range"
                  min="1"
                  max="30"
                  (change)="zoomFromRange()"
                  [(ngModel)]="zoomScale"
                  value="10"
                  class="range range-xs col s12 l8 m8"
                />
                <div (click)="zoomIn()" class="col s12 l2 m2">
                  <i class="material-icons">zoom_in</i>
                </div>
              </div>

              <div class="card row">
                <div class="col l6 s6 m6">
                  <h5 class="section-name">Aspect Ratio</h5>
                  <button
                    (click)="toggleContainWithinAspectRatio()"
                    class="btn"
                  >
                    {{ containWithinAspectRatio ? "Fill" : "Contain" }}
                  </button>
                </div>
                <div class="col l6 s6 m6">
                  <h5 class="section-name">Reset Image</h5>
                  <button (click)="resetImage()" class="btn">Reset</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer right">
          <button
            (click)="saveCroppedImage()"
            [disabled]="!croppedImage"
            class="btn-small col s3 l2 save-button"
            [ngStyle]="{ 'background-color': themeColor }"
          >
            Save
          </button>

          <button
            class="btn-small col s3 l2 cancel-button modal-close"
            [ngStyle]="{ color: themeColor }"
            (click)="closeCroppingModal()"
          >
            Cancel
          </button>
        </div>
      </div>

      <div id="confirmationModal" class="modal" *ngIf="selectedSlider">
        <div class="col s12 modal-content">
          <div class="slider-modal-header">
            <h4 class="col s11">Delete Slider</h4>
            <i
              class="material-symbols-outlined col s1"
              style="cursor: pointer"
              (click)="cancelDelete()"
              >close</i
            >
          </div>

          <div class="valign-wrapper card-panel red lighten-5">
            <i
              class="material-icons left red-text"
              style="font-size: 40px; font-weight: 100"
            >
              warning_amber
            </i>
            <div class="modal-delete">
              <h6 class="red-text bold-800">Are You sure?</h6>
              <h6 class="bold-300">
                You are about to delete a slider image. This action will take
                the image out of the slider.
              </h6>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="row col s12">
            <button
              class="modal-close btn-small col l3 right white-text red"
              style="margin-bottom: 5px; font-weight: bold"
              (click)="removeSlider()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>

      <app-chat-gpt
        *ngIf="openChatGpt"
        [inputText]="inputText"
        [theInput]="selectedInput"
        (onCloseModal)="closeModalChatGptModal()"
        (onMessageSelect)="updateInputValue($event)"
      >
      </app-chat-gpt>

      <div id="iconPickerModal" class="modal modal-fixed-footer">
        <div class="modal-content">
          <div class="slider-modal-header">
            <h4 class="col s11">Icon Selector</h4>
            <span
              class="col s1 modal-close material-symbols-outlined"
              [ngStyle]="{ color: themeColor }"
              style="cursor: pointer"
              type="button"
              >close</span
            >
          </div>
          <!-- Inside your modal content -->
          <div class="row">
            <div class="input-field col s12">
              <input
                id="iconSearch"
                type="text"
                [(ngModel)]="searchQuery"
                placeholder="Search icons"
                (input)="filterIcons()"
              />
            </div>
          </div>

          <!-- Inside your modal content -->
          <div class="row">
            <ul>
              <li
                *ngFor="let icon of filteredIcons"
                (click)="selectIcon(icon)"
                class="col l6"
              >
                <label class="icon-container">
                  <input
                    class="with-gap"
                    name="group1"
                    type="radio"
                    value="yes"
                  />
                  <span class="icon-text">
                    <i class="material-symbols-outlined bold-300 p-r-1">{{
                      icon
                    }}</i>
                    {{ icon }}
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="modal-footer right">
          <button
            class="btn-small col s1 l1 save-button"
            (click)="saveSelectedIcon(selectedIcon); closeModal()"
            [ngStyle]="{ 'background-color': themeColor }"
          >
            Save
          </button>

          <button
            class="btn-small col s1 l1 cancel-button modal-close"
            [ngStyle]="{ color: themeColor }"
            (click)="closeModal()"
          >
            Cancel
          </button>
        </div>
      </div>

      <div id="applyStyleModal" class="modal modal-fixed-footer">
        <div class="modal-content">
          <div class="row">
            <div class="col s10">
              <h5 class="title-section3 bold-700">Apply Stlye</h5>
              <p>Apply stlye to all sections?</p>
            </div>
            <i
              class="right modal-close material-icons-outlined red-text"
              (click)="closeStyleModal()"
            >
              close</i
            >
          </div>
          <div *ngIf="previewBefore">
            <app-card-view-landing-page
              [preview]="true"
              [sections]="previewSection"
              [onlyCards]="true"
            >
            </app-card-view-landing-page>
          </div>
        </div>
        <div class="section p-t-10"></div>
        <div class="modal-footer">
          <button
            class="modal-close btn right white green-text bold-700"
            (click)="applyToAll()"
          >
            Apply Changes
          </button>
          <button class="modal-close btn left white red-text bold-700">
            Cancel
          </button>
        </div>
      </div>
    </main>

    <!-- =========== right nav == managment -->
    <div
      class="right-nav"
      *ngIf="selectedSection"
      [ngClass]="{ closed: rightNavState === 'closed' }"
    >
      <div
        class="right-nav-header"
        (click)="toggleRightNav()"
        [style.height]="rightNavState === 'closed' ? '50vh' : '2vh'"
      >
        <i class="material-symbols-outlined">
          {{ rightNavState === "open" ? "expand_less" : "expand_more" }}
        </i>
        <span *ngIf="rightNavState === 'closed'">Landing Page Managment</span>
      </div>
      
      <div class="right-nav-content" [@rightNavState]="rightNavState">
        <h4>Landing Page Managment</h4>
        <!-- ============= slider -->
        <ng-container *ngIf="selectedSection.name === 'Slider'">
          <ul [formGroup]="sliderForm">
            <li class="row" *ngIf="!sliderRef.options.length > 0">
              <h5 class="section-name">
                No slides have been added yet. To start, create a new slide.
              </h5>
            </li>
            <li class="row">
              <h5 class="section-name">Interval between images (seconds)</h5>
              <div class="input-field">
                <input
                  id="interval"
                  onkeypress="isNumberKey(event)"
                  type="number"
                  min="1"
                  class="validate"
                  [value]="intervalValue"
                  (input)="intervalChange($event.target.value)"
                />
              </div>
            </li>
            <li class="row">
              <div
                class="col s6 m6 l6"
                *ngFor="let option of sliderRef.options; let i = index"
                style="padding: 0px"
              >
                <div class="slider-upload" (click)="sliderClicked(i)">
                  <div class="slider-preview">
                    <div class="card">
                      <div class="card-image">
                        <img
                          [src]="option?.imgUrl"
                          *ngIf="option?.type == 'Image'"
                          class="responsive-img"
                        />
                        <video
                          class="video-fluid"
                          controls
                          autoplay
                          loop
                          *ngIf="option.type == 'Video'"
                        >
                          <source [src]="option?.imgUrl" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>

                  <div class="gallery-text">
                    <h3 class="sub-title-section3">Click to edit.</h3>
                  </div>
                </div>
              </div>
            </li>
            <li class="row">
              <div class="col s12 m12 l12">
                <button class="btn-up" (click)="addSlider()">
                  Add Slider Image
                </button>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= login -->
        <ng-container *ngIf="selectedSection.name === 'Login'">
          <ul>
            <!-- ================== Name  -->
            <li class="row" *ngIf="cardView">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="loginRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li class="row" *ngIf="!cardView">
              <h5 class="section-name">Button Title</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="loginRef.button.title"
                  type="text"
                  placeholder="Button Title"
                />
              </div>
            </li>
            <!-- ================== Color  -->
            <li class="section-color row" *ngIf="cardView">
              <div class="input-field col s3">
                <input [(ngModel)]="loginRef.displayNameColor" type="color" />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input [(ngModel)]="loginRef.bgColor" type="color" />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  class="validate"
                  [(ngModel)]="loginRef.button.bgColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Button Backgound Color</h5>
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input [(ngModel)]="loginRef.button.titleColor" type="color" />
              </div>
              <h5 class="section-name col s9">Button Title Color</h5>
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input [(ngModel)]="loginRef.button.borderColor" type="color" />
              </div>
              <h5 class="section-name col s9">Button Border Color</h5>
            </li>

            <!-- ================== Image / Icons  -->

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>

              <div *ngIf="bgImage" class="sectionImage">
                <div (change)="handlelogoInput($event.target.files, 'Login')">
                  <input
                    type="file"
                    id="upload-file"
                    hidden
                    (change)="handlelogoInput($event.target.files)"
                  />

                  <!-- Show the "Select Image" button only if there is no image -->
                  <button
                    *ngIf="!loginRef.logoUrl"
                    class="btn-up"
                    (click)="triggerFileInput()"
                  >
                    Select Image
                  </button>

                  <div *ngIf="loginRef.logoUrl">
                    <img
                      class="materialboxed responsive-img"
                      [src]="loginRef.logoUrl"
                      width="100px"
                      height="auto"
                    />
                    <button
                      class="btn-small red deleteBtn"
                      type="button"
                      (click)="deleteImage('Login')"
                    >
                      Delete Image
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(loginRef)"
                >
                  Select Icon
                </button>
              </div>

              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ loginRef.icon }}
                </i>
                {{ loginRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(1)"> Default </a>
              </div>
            </li>

            <!-- ================== Image / Icons  -->

            <li>
              <ul class="collapsible">
                <li>
                  <div class="collapsible-header section-name">
                    <i class="material-symbols-outlined bold-200"
                      >keyboard_arrow_down</i
                    >Advanced Settings
                  </div>
                  <div class="collapsible-body">
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          class="filled-in small icon-text"
                          checked="checked"
                        />
                        <span class="section-checkbox-label"
                          >Add Menu to the Header</span
                        >
                      </label>
                    </p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </ng-container>
        <!-- ============= news -->
        <ng-container *ngIf="selectedSection.name === 'News'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="newsRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>
            <li class="section-radio row" *ngIf="!cardView">
              <h5 class="section-name">Number of News Cards to be Displayed</h5>
              <div class="input-field">
                <input
                  id="title"
                  [(ngModel)]="newsRef.displayLimit"
                  type="text"
                  class="validate"
                  onkeypress="isNumberKey(event)"
                  maxlength="1"
                  (input)="newsNumberChange($event.target.value)"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="newsRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="newsRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-radio row" *ngIf="!cardView">
              <h5 class="section-name col s12">Contents</h5>
              <label class="col l4">
                <input name="group1" type="radio" checked class="with-gap" />
                <span>Image</span>
              </label>

              <label class="col l4">
                <input name="group1" type="radio" class="with-gap" />
                <span>Video</span>
              </label>

              <label class="col l4">
                <input name="group1" type="radio" class="with-gap" />
                <span>None</span>
              </label>
            </li>

            <li class="section-radio row" *ngIf="!cardView">
              <h5 class="section-name col s12">Content View</h5>
              <label class="col l12">
                <input
                  name="group1"
                  type="checkbox"
                  [checked]="newsRef?.cardView"
                  (click)="viewChange()"
                />
                <span> Card View</span>
              </label>

              <label class="col l12">
                <input
                  name="group1"
                  type="checkbox"
                  [checked]="!newsRef?.cardView"
                  (click)="viewChange()"
                />
                <span>List View</span>
              </label>
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div *ngIf="bgImage" class="sectionImage">
                <div (change)="handlelogoInput($event.target.files, 'News')">
                  <input
                    type="file"
                    id="upload-file"
                    hidden
                    (change)="handlelogoInput($event.target.files)"
                  />

                  <!-- Show the "Select Image" button only if there is no image -->
                  <button
                    *ngIf="!newsRef.logoUrl"
                    class="btn-up"
                    (click)="triggerFileInput()"
                  >
                    Select Image
                  </button>

                  <div *ngIf="newsRef.logoUrl">
                    <img
                      class="materialboxed responsive-img"
                      [src]="newsRef.logoUrl"
                      width="100px"
                      height="auto"
                    />
                    <button
                      class="btn-small red deleteBtn"
                      type="button"
                      (click)="deleteImage('News')"
                    >
                      Delete Image
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(newsRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ newsRef.icon }}
                </i>
                {{ newsRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(2)"> Default </a>
              </div>
            </li>

            <li>
              <ul class="collapsible">
                <li>
                  <div class="collapsible-header section-name">
                    <i class="material-symbols-outlined bold-200"
                      >keyboard_arrow_down</i
                    >Advanced Settings
                  </div>
                  <div class="collapsible-body">
                    <p>
                      <label>
                        <input
                          type="checkbox"
                          class="filled-in small icon-text"
                          checked="checked"
                        />
                        <span class="section-checkbox-label"
                          >Add Menu to the Header</span
                        >
                      </label>
                    </p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </ng-container>

        <!-- ============= ISET -->
        <ng-container *ngIf="selectedSection.name === 'ISETReg'">
          <ul>
            <li class="row" *ngIf="cardView">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="isetRegRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li class="row" *ngIf="!cardView">
              <h5 class="section-name">Button Title</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="isetRegRef.button.title"
                  type="text"
                />
              </div>
            </li>

            <li class="section-color row" *ngIf="cardView">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="isetRegRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  class="validate"
                  [(ngModel)]="isetRegRef.button.titleColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Button Title Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="isetRegRef.bgColor"
                  (change)="setBgColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  class="validate"
                  [(ngModel)]="isetRegRef.button.bgColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Button Background Color</h5>
            </li>

            <li class="section-color row" *ngIf="!cardView">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  class="validate"
                  [(ngModel)]="isetRegRef.button.borderColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Button border Color</h5>
            </li>

            <li class="section-radio row">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'ISETReg')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!isetRegRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="isetRegRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="isetRegRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('ISETReg')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(isetRegRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ isetRegRef.icon }}
                </i>
                {{ isetRegRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(8)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Events -->
        <ng-container *ngIf="selectedSection.name === 'Events'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="eventRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li *ngIf="!cardView">
              <h5 class="section-name">
                Number of Event Cards to be Displayed
              </h5>
              <div class="input-field">
                <input
                  id="title"
                  [(ngModel)]="eventRef.displayLimit"
                  type="text"
                  class="validate"
                  onkeypress="isNumberKey(event)"
                  maxlength="1"
                  (input)="eventNumberChange($event.target.value)"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="eventRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="eventRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'Events')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!eventRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="eventRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="eventRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('Events')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(eventRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ eventRef.icon }}
                </i>
                {{ eventRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(3)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Department -->
        <ng-container *ngIf="selectedSection.name === 'Departments'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="departmentsRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>
            <li *ngIf="!cardView">
              <h5 class="section-name">
                Number Of Department cards to be Displayed :
              </h5>
              <div class="input-field">
                <input
                  id="title"
                  [(ngModel)]="departmentsRef.displayLimit"
                  class="validate"
                  type="text"
                  onkeypress="isNumberKey(event)"
                  maxlength="1"
                  (input)="limitChange($event.target.value, 'departments')"
                  placeholder="Number Of Displayed News"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="departmentsRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  [(ngModel)]="departmentsRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'Departments')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!departmentsRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="departmentsRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="departmentsRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('Departments')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(departmentsRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ departmentsRef.icon }}
                </i>
                {{ departmentsRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(6)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Governance -->
        <ng-container *ngIf="selectedSection.name === 'Governance'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="governanceRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>
            <li *ngIf="!cardView">
              <h5 class="section-name">
                Number of Councillors to be displayed :
              </h5>
              <div class="input-field">
                <input
                  id="title"
                  [(ngModel)]="governanceRef.councillorsLimit"
                  type="text"
                  onkeypress="isNumberKey(event)"
                  maxlength="1"
                  (input)="limitChange($event.target.value, 'governance')"
                  placeholder="Number Of Councillor to show"
                  class="validate"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="governanceRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="governanceRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="governanceRef.councillorColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Councillors Title Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input [(ngModel)]="governanceRef.chiefColor" type="color" />
              </div>
              <h5 class="section-name col s9">Chief Title Color :</h5>
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'Governance')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!governanceRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="governanceRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="governanceRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('Governance')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(governanceRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ governanceRef.icon }}
                </i>
                {{ governanceRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(7)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Apple store -->
        <ng-container *ngIf="selectedSection.name === 'AppStore'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="appStoreRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="appStoreRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="appStoreRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="row">
              <h5 class="section-name">Google Play Url</h5>
              <div class="input-field">
                <input
                  id="googleUrl"
                  [(ngModel)]="appStoreRef.googlePlayUrl"
                  type="text"
                  class="validate"
                  placeholder="Google Play Url"
                />
              </div>
            </li>

            <li class="row">
              <h5 class="section-name">Apple Store Url</h5>
              <div class="input-field">
                <input
                  id="appleUrl"
                  [(ngModel)]="appStoreRef.appleStoreUrl"
                  type="text"
                  class="validate"
                  placeholder="Apple Store Url"
                />
              </div>
            </li>

            <li class="row" *ngIf="!cardView">
              <h5 class="section-name">Description</h5>
              <div class="input-field">
                <app-editor
                  id="description"
                  [title]="false"
                  [model]="appStoreRef.description"
                  [styler]="false"
                  [type]="true"
                  (onModelChange)="descriptionChange($event)"
                >
                </app-editor>
              </div>
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'AppStore')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!appStoreRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="appStoreRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="appStoreRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('AppStore')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(appStoreRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ appStoreRef.icon }}
                </i>
                {{ appStoreRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(4)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Social Media -->
        <ng-container *ngIf="selectedSection.name === 'SocialMedia'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="socialMediaRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="socialMediaRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="socialMediaRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'SocialMedia')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!socialMediaRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="socialMediaRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="socialMediaRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('SocialMedia')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(socialMediaRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ socialMediaRef.icon }}
                </i>
                {{ socialMediaRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(5)"> Default </a>
              </div>
            </li>

            <li>
              <ul class="collapsible social-media">
                <li>
                  <div class="collapsible-header section-name">
                    <i class="material-symbols-outlined bold-200"
                      >keyboard_arrow_down</i
                    >Social Medias
                  </div>
                  <div class="collapsible-body" style="padding: 0px">
                    <form
                      (ngSubmit)="submitForm(socialForm.value)"
                      [formGroup]="socialForm"
                    >
                      <!-- ================= facebook ================= -->
                      <li class="row">
                        <div class="input-field row">
                          <i class="fa fa-facebook prefix blue-text"></i>
                          <input
                            formControlName="facebook"
                            id="facebook"
                            class="validate"
                            type="text"
                            maxlength="250"
                            placeholder="Facebook URL"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              (facebook.dirty || facebook.touched) &&
                              facebook.invalid
                            "
                          >
                            <small
                              *ngIf="facebook.errors.pattern"
                              class="red-text"
                            >
                              Please enter a valid Url.
                            </small>
                          </div>
                        </div>
                      </li>

                      <!-- ================= twitter ================= -->
                      <li class="row">
                        <div class="input-field row">
                          <i
                            class="fa fa-twitter prefix blue-text text-lighten-2"
                          ></i>
                          <input
                            formControlName="twitter"
                            id="twitter"
                            class="validate"
                            type="text"
                            maxlength="250"
                            placeholder="Twitter URL"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              (twitter.dirty || twitter.touched) &&
                              twitter.invalid
                            "
                          >
                            <small
                              *ngIf="twitter.errors.pattern"
                              class="red-text"
                            >
                              Please enter a valid Url.
                            </small>
                          </div>
                        </div>
                      </li>

                      <!-- ================= Instagram ================= -->
                      <li class="row">
                        <div class="input-field row">
                          <i
                            class="fa fa-instagram prefix"
                            style="color: #3f729b"
                          ></i>
                          <input
                            formControlName="instagram"
                            id="instagram"
                            class="validate"
                            type="text"
                            maxlength="250"
                            placeholder="Instagram URL"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              (instagram.dirty || instagram.touched) &&
                              instagram.invalid
                            "
                          >
                            <small
                              *ngIf="instagram.errors.pattern"
                              class="red-text"
                            >
                              Please enter a valid Url.
                            </small>
                          </div>
                        </div>
                      </li>

                      <!-- ================= youtube ================= -->
                      <li class="row">
                        <div class="input-field row">
                          <i class="fa fa-youtube prefix red-text"></i>
                          <input
                            formControlName="youtube"
                            id="youtube"
                            class="validate"
                            type="text"
                            maxlength="250"
                            placeholder="Youtube URL"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              (youtube.dirty || youtube.touched) &&
                              youtube.invalid
                            "
                          >
                            <small
                              *ngIf="youtube.errors.pattern"
                              class="red-text"
                            >
                              Please enter a valid Url.
                            </small>
                          </div>
                        </div>
                      </li>

                      <li class="row" *ngIf="!hasSocial">
                        <button class="btn-icon" type="submit">
                          Save Social Media Links
                        </button>
                      </li>
                      <li class="row" *ngIf="hasSocial">
                        <button class="btn-icon" type="submit">
                          Update Social Media Links
                        </button>
                      </li>
                    </form>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </ng-container>
        <!-- ============= About us -->
        <ng-container *ngIf="selectedSection.name === 'AboutUs'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="aboutUsRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>
            <li *ngIf="!cardView">
              <h5 class="section-name col s12">Number of Aboutus cards</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="aboutUsRef.displayLimit"
                  type="text"
                  onkeypress="isNumberKey(event)"
                  maxlength="1"
                  (input)="aboutusNumberChange($event.target.value)"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="aboutUsRef.displayNameColor"
                  (change)="setTextColor($event.target.value)"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  [(ngModel)]="aboutUsRef.bgColor"
                  type="color"
                  (change)="setBgColor($event.target.value)"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-button row" *ngIf="cardView">
              <a
                class="apply-to-all-btn"
                (click)="applyStyle()"
                style="cursor: pointer"
                [ngStyle]="{ color: themeColor }"
                >Apply style to all cards
                <i class="material-symbols-outlined left">colors</i></a
              >
            </li>

            <li class="section-radio row" *ngIf="cardView">
              <h5 class="section-name col s12">Image</h5>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="yes"
                  [checked]="bgImage"
                  (click)="bgImageYes()"
                />
                <span>Yes</span>
              </label>
              <label class="col l4">
                <input
                  class="with-gap"
                  name="group1"
                  type="radio"
                  value="no"
                  [checked]="!bgImage"
                  (click)="bgImageNo()"
                />
                <span>No</span>
              </label>
              <div
                *ngIf="bgImage"
                class="sectionImage"
                (change)="handlelogoInput($event.target.files, 'AboutUs')"
              >
                <input
                  type="file"
                  id="upload-file"
                  hidden
                  (change)="handlelogoInput($event.target.files)"
                />

                <!-- Show the "Select Image" button only if there is no image -->
                <button
                  *ngIf="!aboutUsRef.logoUrl"
                  class="btn-up"
                  (click)="triggerFileInput()"
                >
                  Select Image
                </button>

                <div *ngIf="aboutUsRef.logoUrl">
                  <img
                    class="materialboxed responsive-img"
                    [src]="aboutUsRef.logoUrl"
                    width="100px"
                    height="auto"
                  />
                  <button
                    class="btn-small red deleteBtn"
                    type="button"
                    (click)="deleteImage('AboutUs')"
                  >
                    Delete Image
                  </button>
                </div>
              </div>

              <div *ngIf="!bgImage">
                <button
                  class="btn-icon modal-trigger"
                  (click)="openIconPickerModal(aboutUsRef)"
                >
                  Select Icon
                </button>
              </div>
              <div *ngIf="!bgImage" class="card icon-text">
                <i class="material-symbols-outlined bold-300">
                  {{ aboutUsRef.icon }}
                </i>
                {{ aboutUsRef.icon }} |
                <a class="p-l-2" (click)="setDefaultIcons(10)"> Default </a>
              </div>
            </li>
          </ul>
        </ng-container>
        <!-- ============= Footer -->
        <ng-container *ngIf="selectedSection.name === 'Footer'">
          <ul>
            <li class="row">
              <h5 class="section-name">Section Name</h5>
              <div class="input-field">
                <input
                  id="title"
                  class="validate"
                  [(ngModel)]="footerRef.displayName"
                  type="text"
                  placeholder="Section Name"
                />
              </div>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  [(ngModel)]="footerRef.textColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Section Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  [(ngModel)]="footerRef.bgColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Background Color</h5>
            </li>

            <li class="section-color row">
              <div class="input-field col s3">
                <input
                  id="iconColor"
                  [(ngModel)]="footerRef.linkColor"
                  type="color"
                />
              </div>
              <h5 class="section-name col s9">Link Color</h5>
            </li>

            <!-- <li class="row">
                        <h5 class="section-name">Address</h5>
                        <div class="input-field">
                            <input id="address" [(ngModel)]="footerRef.address" [value]="footerRef.address.textContent"
                                type="text" placeholder="Address">
                        </div>
                    </li>

                    <li class="row">
                        <h5 class="section-name">Postal Code</h5>
                        <div class="input-field">
                            <input id="pcode" [textMask]="{mask: postalCodeMask}" [(ngModel)]="footerRef.postalCode"
                                type="text" placeholder="Postal Code">
                        </div>
                    </li>

                    <li class="row">
                        <h5 class="section-name">City</h5>
                        <div class="input-field">
                            <input id="city" [(ngModel)]="footerRef.city" type="text" placeholder="City">
                        </div>
                    </li>
                    <li class="row">
                        <h5 class="section-name">Province</h5>
                        <div class="input-field">
                            <input id="prov" [(ngModel)]="footerRef.province" type="text" placeholder="Province">
                        </div>
                    </li>
                    <li class="row">
                        <h5 class="section-name">Info / Support Email</h5>
                        <div class="input-field">
                            <input id="email" [(ngModel)]="footerRef.contactEmail" type="text"
                                placeholder="Info / Support Email">
                        </div>
                    </li>
                    <li class="row">
                        <h5 class="section-name">Phone</h5>
                        <div class="input-field">
                            <input id="phone" [textMask]="{mask: phoneNumberMask}" [(ngModel)]="footerRef.phone"
                                type="text" placeholder="Phone">
                        </div>
                    </li>
                    <li class="row">
                        <h5 class="section-name">Fax</h5>
                        <div class="input-field">
                            <input id="fax" [textMask]="{mask: phoneNumberMask}" [(ngModel)]="footerRef.fax" type="text"
                                placeholder="Fax">
                        </div>
                    </li>

                    <li class="row">
                        <h5 class="section-name">Toll Free</h5>
                        <div class="input-field">
                            <input id="tollfree" [textMask]="{mask: phoneNumberMask}" [(ngModel)]="footerRef.tollFree"
                                type="text" placeholder="Toll Free">
                        </div>
                    </li> -->
          </ul>
        </ng-container>
      </div>
    </div>

    <!-- ============== right nav === apperance -->

    <div
      class="right-nav-another"
      *ngIf="cardView && !selectedSection"
      [ngClass]="{
        'apper-closed': rightAppState === 'apper-closed',
        open: rightNavState === 'open'
      }"
    >
      <div
        class="right-app-header"
        (click)="toggleRightApp()"
        [style.height]="rightAppState === 'apper-closed' ? '50vh' : '3vh'"
      >
        <i class="material-symbols-outlined">
          {{ rightAppState === "open" ? "expand_less" : "expand_more" }}
        </i>
        <span *ngIf="rightAppState === 'apper-closed'"
          >Card View Apperance</span
        >
      </div>
      <div class="right-app-content" [@rightAppState]="rightAppState">
        <h4>Card View Appearance</h4>

        <ul class="collapsible">
          <li class="coll-apperance">
            <div class="collapsible-header section-name">
              <i class="material-symbols-outlined bold-200"
                >keyboard_arrow_down</i
              >Image Style
            </div>
            <div class="collapsible-body">
              <li class="row section-radio">
                <label>
                  <input
                    class="radioBtn with-gap"
                    type="radio"
                    name="imageShape"
                    [value]="imageOption == 'yes'"
                    [checked]="imageOption == 'yes'"
                    (click)="imageOptionClicked('yes')"
                  />
                  <span>
                    <div class="card-paddingg">
                      <a
                        class="card-option b-n-1"
                        [ngStyle]="{
                          'background-image':
                            'url(../../../assets/img/backgrounds/login-back.jpeg)',
                          'background-position': 'center',
                          'background-repeat': 'no-repeat',
                          'background-size':
                            model?.imageCover === 'Contain'
                              ? 'contain'
                              : 'cover',
                          height: '15vh'
                        }"
                      >
                        <div class="card-content">
                          <div class="vertical-center">
                            <h3 class="display-name white-text bold-500">
                              displayName
                            </h3>
                            <div class="overlay"></div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </span>
                </label>
              </li>

              <li class="row section-radio">
                <label>
                  <input
                    class="radioBtn with-gap"
                    type="radio"
                    name="imageShape"
                    [value]="imageOption == 'no'"
                    [checked]="imageOption == 'no'"
                    (click)="imageOptionClicked('no'); saveChanges()"
                  />
                  <span>
                    <div class="card-paddingg">
                      <a
                        class="card-option b-n-1"
                        [ngStyle]="{ 'background-color': themeColor }"
                      >
                        <img
                          class="responsive-img center circle"
                          src="../../../assets/img/backgrounds/login-back.jpeg"
                          style="
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            margin: 0 auto;
                            display: block;
                          "
                        />
                        <h3 class="white-text bold-500 display-name">
                          displayName
                        </h3>
                      </a>
                    </div>
                  </span>
                </label>
              </li>

              <li class="row section-radio" *ngIf="imageOption == 'yes'">
                <div>
                  <h4 class="section-name">Image Content Option</h4>
                  <label>
                    <input
                      class="col s12 radioBtn with-gap"
                      type="radio"
                      name="imageCover"
                      [value]="'Contain'"
                      [checked]="imageCoverOption == 'Contain'"
                      (click)="
                        imageCoverOptionClicked('Contain'); saveChanges()
                      "
                    />
                    <span>Contain</span>
                  </label>
                  <br />

                  <label>
                    <input
                      class="col s12 radioBtn with-gap"
                      type="radio"
                      name="imageCover"
                      [value]="'Cover'"
                      [checked]="imageCoverOption == 'Cover'"
                      (click)="imageCoverOptionClicked('Cover'); saveChanges()"
                    />
                    <span>Cover</span>
                  </label>
                </div>
              </li>
            </div>
          </li>

          <li class="coll-apperance">
            <div class="collapsible-header section-name">
              <i class="material-symbols-outlined bold-200"
                >keyboard_arrow_down</i
              >Matrix
            </div>
            <div class="collapsible-body">
              <li class="row">
                <h5 class="section-name">Desktop Screen</h5>
                <div class="input-field">
                  <select
                    class="browser-default"
                    [(ngModel)]="desktopGridSize"
                    (change)="
                      gridNoChanged($event.target.value, 'desktop');
                      saveChanges()
                    "
                  >
                    <option value="" disabled selected>
                      Choose your option
                    </option>
                    <option value="l6">2 x 2</option>
                    <option value="l4">3 x 3</option>
                    <option value="l3">4 x 4</option>
                    <option value="l2">6 x 6</option>
                  </select>
                </div>
              </li>

              <li class="row">
                <h5 class="section-name">Mobile Screen</h5>
                <div class="input-field">
                  <select
                    class="browser-default"
                    [(ngModel)]="mobileGridSize"
                    (change)="
                      gridNoChanged($event.target.value, 'mobile');
                      saveChanges()
                    "
                  >
                    <option value="" disabled selected>
                      Choose your option
                    </option>
                    <option value="s12">1 x 1</option>
                    <option value="s6">2 x 2</option>
                    <option value="s4">3 x 3</option>
                    <option value="s3">4 x 4</option>
                  </select>
                </div>
              </li>
            </div>
          </li>
          <!-- ========================= card shape  -->
          <li class="coll-apperance">
            <div class="collapsible-header section-name">
              <i class="material-symbols-outlined bold-200"
                >keyboard_arrow_down</i
              >Card Shape
            </div>
            <div class="collapsible-body">
              <li class="section-radio row">
                <h5 class="section-name">Card Shape</h5>

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardShape"
                    value="cardOption == 'Rectangle'"
                    [checked]="cardOption == 'Rectangle'"
                    (click)="cardOptionClicked('Rectangle'); saveChanges()"
                  />
                  <span>Rectangular Button</span>
                </label>
                <br />

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardShape"
                    value="cardOption == 'Curved'"
                    [checked]="cardOption == 'Curved'"
                    (click)="cardOptionClicked('Curved'); saveChanges()"
                  />
                  <span>Curved Button</span>
                </label>
                <br />
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardShape"
                    value="cardOption == 'Round'"
                    [checked]="cardOption == 'Round'"
                    (click)="cardOptionClicked('Round'); saveChanges()"
                  />
                  <span>Round Button</span>
                </label>
                <br />
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardShape"
                    value="cardOption == 'Hexagon'"
                    [checked]="cardOption == 'Hexagon'"
                    (click)="cardOptionClicked('Hexagon'); saveChanges()"
                  />
                  <span>Hexagon Button</span>
                </label>
                <br />

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardShape"
                    value="cardOption == 'None'"
                    [checked]="cardOption == 'None'"
                    (click)="cardOptionClicked('None'); saveChanges()"
                  />
                  <span>No Button</span>
                </label>
              </li>

              <li class="section-radio row">
                <h5 class="section-name">Icon / Image Shape</h5>
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardIconShape"
                    value="cardIconOption == 'Rectangle'"
                    [checked]="cardIconOption == 'Rectangle'"
                    (click)="cardIconOptionClicked('Rectangle'); saveChanges()"
                  />
                  <span>Rectangular </span>
                </label>
                <br />
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardIconShape"
                    value="cardIconOption == 'Curved'"
                    [checked]="cardIconOption == 'Curved'"
                    (click)="cardIconOptionClicked('Curved'); saveChanges()"
                  />
                  <span>Curved </span>
                </label>
                <br />
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardIconShape"
                    value="cardIconOption == 'Round'"
                    [checked]="cardIconOption == 'Round'"
                    (click)="cardIconOptionClicked('Round'); saveChanges()"
                  />
                  <span>Round </span>
                </label>
                <br />
                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardIconShape"
                    value="cardIconOption == 'Hexagon'"
                    [checked]="cardIconOption == 'Hexagon'"
                    (click)="cardIconOptionClicked('Hexagon'); saveChanges()"
                  />
                  <span>Hexagon </span>
                </label>
                <br />

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="cardIconShape"
                    value="cardIconOption == 'None'"
                    [checked]="cardIconOption == 'None'"
                    (click)="cardIconOptionClicked('None'); saveChanges()"
                  />
                  <span>No Button</span>
                </label>
                <br />
              </li>
            </div>
          </li>

          <!-- ================================== Font Size ========= -->

          <li class="coll-apperance">
            <div class="collapsible-header section-name">
              <i class="material-symbols-outlined bold-200"
                >keyboard_arrow_down</i
              >Font Size
            </div>
            <div class="collapsible-body">
              <li class="row font-size section-radio">
                <h5 class="section-name">Font Size</h5>
                <label>
                  <input
                    class="col s3 radioBtn with-gap"
                    type="radio"
                    name="cardSize"
                    value="cardSizeOption == 'Small'"
                    [checked]="cardSizeOption == 'Small'"
                    (click)="cardSizeOptionClicked('Small'); saveChanges()"
                  />
                  <span class="small-t">A </span>
                </label>

                <label>
                  <input
                    class="col s3 radioBtn with-gap"
                    type="radio"
                    name="cardSize"
                    value="cardSizeOption == 'Medium'"
                    [checked]="cardSizeOption == 'Medium'"
                    (click)="cardSizeOptionClicked('Medium'); saveChanges()"
                  />
                  <span class="medium-t">A </span>
                </label>

                <label>
                  <input
                    class="col s3 radioBtn with-gap"
                    type="radio"
                    name="cardSize"
                    value="cardSizeOption == 'Large'"
                    [checked]="cardSizeOption == 'Large'"
                    (click)="cardSizeOptionClicked('Large'); saveChanges()"
                  />
                  <span class="large-t">A </span>
                </label>
              </li>
            </div>
          </li>

          <!-- ================================== Font Position========= -->
          <li class="coll-apperance">
            <div class="collapsible-header section-name">
              <i class="material-symbols-outlined bold-200"
                >keyboard_arrow_down</i
              >Font Postion
            </div>

            <div class="collapsible-body">
              <li class="row section-radio">
                <h5 class="section-name">Font Postion</h5>

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="titlePosition"
                    value="titlePositionOption == 'Top'"
                    [checked]="titlePositionOption == 'Top'"
                    (click)="titlePositionOptionClicked('Top'); saveChanges()"
                  />
                  <span>Top</span>
                </label>

                <label>
                  <input
                    class="col s12 radioBtn with-gap"
                    type="radio"
                    name="titlePosition"
                    value="titlePositionOption == 'Bottom'"
                    [checked]="titlePositionOption == 'Bottom'"
                    (click)="
                      titlePositionOptionClicked('Bottom'); saveChanges()
                    "
                  />
                  <span> Bottom </span>
                </label>
              </li>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</body>
