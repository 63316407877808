import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { MemberAuthGuard } from './guards/member-auth.guard';
import { EmployeeAuthGuard } from './guards/employee-auth.guard';
// import { EmployerOnlyComponent } from './comp/app/Employer/employer-only/employer-only.component';
// import { SkillsDatabankComponent } from './comp/app/Employer/skills-databank/skills-databank.component';
// import { CreateAccountComponent } from './comp/app/Employer/create-account/create-account.component';
// import { RegistrationRequestComponent } from './comp/app/registration-request/registration-request.component';
// import { HomePageComponent } from './comp/app/home/home-page/home-page.component';
// import { ReadMoreComponent } from './comp/app/home/read-more/read-more.component';
// import { DepartmentsComponent } from './comp/app/departments/departments.component';
// import { TermsConditionsComponent } from './comp/app/terms-conditions/terms-conditions.component';
// import { PrivacyPolicyComponent } from './comp/app/privacy-policy/privacy-policy.component';
// import { EulaComponent } from './comp/app/eula/eula.component';
// import { NewsComponent } from './comp/app/news/news.component';
// import { HomeSubMenuComponent } from './comp/app/home/home-sub-menu/home-sub-menu.component';
// import { GovernanceComponent } from './comp/app/governance/governance.component';
// import { AboutUsComponent } from './comp/app/about-us/about-us.component';
// import { ManageSubDepartmentComponent } from './comp/app/departments/manage-sub-department/manage-sub-department.component';
// import { ViewDepartmentInfoComponent } from './comp/app/departments/view-department-info/view-department-info.component';
// import { ViewSubdepartmentInfoComponent } from './comp/app/departments/view-subdepartment-info/view-subdepartment-info.component';
// import { ViewAboutSectionComponent } from './comp/app/about-us/view-about-section/view-about-section.component';
// import { DepNewsListComponent } from './comp/app/departments/department-news/dep-news-list/dep-news-list.component';
// import { ReadDepNewsComponent } from './comp/app/departments/department-news/read-dep-news/read-dep-news.component';
// import { DepSubMenuComponent } from './comp/app/departments/dep-sub-menu/dep-sub-menu.component';
// import { ContactNationComponent } from './comp/app/contact-nation/contact-nation.component';
// import { ContactDepartmentPublicComponent } from './comp/app/departments/contact-department-public/contact-department-public.component';
// import { EventCalendarComponent } from './comp/app/events/event-calendar/event-calendar.component';
// import { EventDetailsComponent } from './comp/app/events/event-details/event-details.component';
// import { LandingComponent } from './comp/app/landing/landing.component';
// import { GovMemberDetailComponent } from './comp/app/governance/gov-member-detail/gov-member-detail.component';
// import { GovChiefDetailComponent } from './comp/app/governance/gov-chief-detail/gov-chief-detail.component';
// import { MoreMenuComponent } from './comp/app/more-menu/more-menu.component';
// import { IncomeSupportApplicationComponent } from './comp/app/income-support-application/income-support-application.component';
// import { CustomizableIncomeFormComponent } from './comp/app/customizable-income-form/customizable-income-form.component';
// import { CreateApplicationComponent } from './comp/app/create-application/create-application.component';
// import { PollsListComponent } from './comp/app/polls/polls-list/polls-list.component';
// import { PollResultComponent } from './comp/app/polls/poll-result/poll-result.component';
// import { BoardsListPublicComponent } from './comp/app/boards/boards-list-public/boards-list-public.component';
// import { BoardsDetailComponent } from './comp/app/boards/boards-detail/boards-detail.component';
// import { SurveyListComponent } from './comp/app/survey/survey-list/survey-list.component';
// import { SurveyResultsComponent } from './comp/app/survey/survey-results/survey-results.component';
// import { ElectionResultComponent } from './comp/app/election/election-result/election-result.component';
// import { LangingPageComponent } from './comp/app/landing/langing-page/langing-page.component';
// import { MoreComponent } from './comp/navigation/more/more.component';
// import { IsetRegistrationFormComponent } from './comp/app/iset-registration-form/iset-registration-form.component';
// import { OptionalLandingPageComponent } from './comp/app/landing/optional-landing-page/optional-landing-page.component';
// import { TestComponent } from './comp/admin/test/test.component';
// import { CustomSectionComponent } from './comp/app/custom-section/custom-section.component';
// import { SectionDetailComponent } from './comp/app/custom-section/section-detail/section-detail.component';

// const routes: Routes = [
//   {
//     path: '',
//     component: LangingPageComponent
//   },
//   {
//     path: 'home',
//     component: LangingPageComponent
//   },
//   {
//     path: 'more',
//     component: MoreMenuComponent
//   },
//   {
//     path: 'home-sub-menu',
//     component: HomeSubMenuComponent
//   },
//   {
//     path: 'read-more/:id',
//     component: ReadMoreComponent
//   },
//   {
//     path: 'news',
//     component: NewsComponent
//   },
//   {
//     path: 'events-calendar',
//     component: EventCalendarComponent
//   },
//   {
//     path: 'events-detail/:id',
//     component: EventDetailsComponent
//   },
//   {
//     path: 'employers-only',
//     component: EmployerOnlyComponent
//   },
//   {
//     path: 'skills-databank',
//     component: SkillsDatabankComponent
//   },
//   {
//     path: 'create-account',
//     component: CreateAccountComponent
//   },

//   {
//     path: 'registration-request',
//     component: RegistrationRequestComponent
//   },

//   {
//     path: 'iset-registration-form',
//     component: IsetRegistrationFormComponent
//   },

//   {
//     path: 'terms-conditions',
//     component: TermsConditionsComponent
//   },
//   {
//     path: 'privacy-policy',
//     component: PrivacyPolicyComponent
//   },
//   {
//     path: 'eula',
//     component: EulaComponent
//   },
//   {
//     path: 'contact-nation',
//     component: ContactNationComponent
//   },
//   {
//     path: 'governance',
//     component: GovernanceComponent
//   },
//   {
//     path: 'governance-detail/:id',
//     component: GovMemberDetailComponent
//   },
//   {
//     path: 'governance-chief',
//     component: GovChiefDetailComponent
//   },
//   {
//     path: 'about-us',
//     component: AboutUsComponent
//   },
//   {
//     path: 'view-about-us-section/:id',
//     component: ViewAboutSectionComponent
//   },

//   {
//     path: 'income-support-app',
//     component: IncomeSupportApplicationComponent
//   },
//   {
//     path: 'customizable-form/:name',
//     component: CustomizableIncomeFormComponent
//   },
//   {
//     path: 'create-form',
//     component: CreateApplicationComponent
//   },
//   // {
//   //   path: 'polls-list',
//   //   component: PollsListComponent
//   // },
//   // {
//   //   path: 'poll-result/:id',
//   //   component: PollResultComponent
//   // },
//   {
//     path: 'survey-list',
//     component: SurveyListComponent
//   },
//   {
//     path: 'poll-result/:id',
//     component: PollResultComponent
//   },
//   {
//     path: 'boards-list',
//     component: BoardsListPublicComponent
//   },
//   {
//     path: 'board-detail/:id',
//     component: BoardsDetailComponent
//   },
//   {
//     path: 'survey-result/:id',
//     component: SurveyResultsComponent
//   },
//   {
//     path: 'election-result/:id',
//     component: ElectionResultComponent
//   },
//   {
//     path: 'more-sidenav',
//     component: MoreComponent
//   },
//   {
//     path: 'departments',
//     component: DepartmentsComponent
//   },
//   {
//     path: 'optional-landing-page',
//     component: OptionalLandingPageComponent
//   },
//   {
//     path: 'test',
//     component: TestComponent
//   },

//   {
//     path: 'department-sub-menu/:id',
//     component: DepSubMenuComponent,
//     children: [

//       {
//         path: 'contact-department-public/:id',
//         component: ContactDepartmentPublicComponent
//       },
//       {
//         path: 'department-news/:id',
//         component: DepNewsListComponent
//       },
//       {
//         path: 'department-news/:id',//see
//         component: DepNewsListComponent
//       },
//       {
//         path: 'manage-sub-department/:id',
//         component: ManageSubDepartmentComponent
//       },

//       {
//         path: 'view-department-info/:id',
//         component: ViewDepartmentInfoComponent
//       },
//       {
//         path: 'view-subdepartment-info/:id2',
//         component: ViewSubdepartmentInfoComponent
//       },
//       {
//         path: 'departments',
//         component: DepartmentsComponent
//       }




//     ]



//   },

//   {
//     path: 'custome-section/:id',
//     component: CustomSectionComponent
//   },
//   {
//     path: 'section-detail/:id',
//     component: SectionDetailComponent
//   },



// ];

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./comp/public/public.module').then(m => m.PublicModule)
  }, // Lazy load public module
  {
    path: 'auth',
    loadChildren: () => import('./comp/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./comp/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminAuthGuard]
  }, // Lazy load Admin module
  {
    path: 'member',
    loadChildren: () => import('./comp/member/member.module').then(m => m.MemberModule),
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'employee',
    loadChildren: () => import('./comp/employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [EmployeeAuthGuard]
  },
  {
    path: 'employer',
    loadChildren: () => import('./comp/employer/employer.module').then(m => m.EmployerModule),
    // canActivate: [MemberAuthGuard] 
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
