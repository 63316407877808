export class BandMemberFilterModel {
  firstName = '';
  lastName = '';
  gender = '';
  community = '';
  numberOfDays = 0;
 //startDate = '';
  //endDate = '';
  startAge = 0;
  endAge=0;
  onReserve: boolean;
  isMember: boolean;
  eduLevel = '';
  treatyNo = '';
}

export class MemberSearchModel {
  firstName = '';
  lastName = '';
  community = '';
  startAge = 0;
  endAge=0;
  isn = '';
}
