import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-card-view-landing-page',
  templateUrl: './card-view-landing-page.component.html',
  styleUrls: ['./card-view-landing-page.component.css']
})
export class CardViewLandingPageComponent implements OnInit {

  @Input() preview = false;
  @Input() onlyCards = false;
  @Input() sections: any;

  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  setting: any;
  model: any;
  loading = false;
  hasISET = environment.firstNation.ISET;
  hasSocial = environment.firstNation.SOCIAL;
  sliderSection: any;
  loginSection: any;
  newsSection: any;
  eventSection: any;
  governanceSection: any;
  departmentSection: any;
  appStoreSection: any;
  socialMediaSection: any;
  contactSection: any;
  demoSection: any;
  footerSection: any;
  customeSection: any[];

  desktopGridSize: any;
  cardShape: any;
  imageShape: any;
  imageCover: any;

  constructor(
    private appSettingService: AppSettingsService,
    private settingsService: AppSettingsService,
    private sectionService: SectionSettingService,
    private router: Router) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit(): void {
    if (this.preview && this.sections) {
      this.model = this.sections;
      this.setupSection();
      $(document).ready(function () {
        $('.modal').modal();
      });
    } else {
      this.loading = true;
      this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
        if (hp) {
          if (hp.length > 0) {
            this.model = hp[0];

            this.sectionService.getSections().valueChanges().subscribe(sec => {
              if (sec && sec.length > 0) {
                let sections = sec.filter(s => s.addToHomePage);
                if (sections && sections.length > 0) {
                  this.customeSection = [];
                   sections.forEach(sec => {
                    this.customeSection.push(
                      {
                        id: sec.id,
                        addToHeader: sec.addToMenu,
                        displayName: sec.displayName,
                        bgColor: sec.bgColor,
                        displayNameColor: sec.displayNameColor,
                        icon: sec.icon,
                        isEnabled: true,
                        index: this.model.sections.length,
                        name: sec.name,
                        link: '/custome-section/'+ sec.id,
                        logoUrl: sec.logoUrl
                      }
                    )
                  });
                 // this.model.sections = this.model.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
                }
              }
            });

            this.setupSection();
            $(document).ready(function () {
              $('.modal').modal();
            });
          }
        }
      });
    }
  }

  setupSection() {
    if (this.model) {
      this.model.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      // && s.isEnabled
      const slider = this.model.sections.find(s => s.name === 'Slider');
      if (slider) this.sliderSection = slider;

      const newsSection = this.model.sections.find(s => s.name === 'News');
      if (newsSection) this.newsSection = newsSection;

      const eventSection = this.model.sections.find(s => s.name === 'Events');
      if (eventSection) this.eventSection = eventSection;

      const governanceSection = this.model.sections.find(s => s.name === 'Governance');
      if (governanceSection) this.governanceSection = governanceSection;

      const departmentsSection = this.model.sections.find(s => s.name === 'Departments');
      if (departmentsSection) this.departmentSection = departmentsSection;

      const appStoreSection = this.model.sections.find(s => s.name === 'AppStore');
      if (appStoreSection) this.appStoreSection = appStoreSection;

      const socialSection = this.model.sections.find(s => s.name === 'SocialMedia');
      if (socialSection) this.socialMediaSection = socialSection;

      const contactSection = this.model.sections.find(s => s.name === 'ContactUs');
      if (contactSection) this.socialMediaSection = contactSection;

      const demoSection = this.model.sections.find(s => s.name === 'Demo');
      if (demoSection) this.demoSection = demoSection;

      // const customeSection = this.model.sections.filter(s => s.name === 'CustomSection');
      // if (customeSection && customeSection.length > 0) this.customeSection = customeSection;

      const footer = this.model.sections.find(s => s.name === 'Footer');
      if (footer) this.footerSection = footer;


    }
  }
  slider() {
    this.router.navigate(['/app-store']);
  }
  events() {
    this.appSettingService.setCurrentMenu(this.eventSection.displayName);
    this.router.navigate(['/events-calendar']);
  }
  social() {
    this.appSettingService.setCurrentMenu(this.socialMediaSection.displayName);
    this.router.navigate(['/admin/dashboard']);
  }
  news() {
    this.appSettingService.setCurrentMenu(this.newsSection.displayName);
    this.router.navigate(['/news']);
  }
  department() {
    this.appSettingService.setCurrentMenu(this.departmentSection.displayName);
    this.router.navigate(['/departments']);
  }
  download() {
    this.router.navigate(['/admin/dashboard']);
  }
  chief() {
    this.router.navigate(['/governance']);
  }
  login() {
    this.router.navigate(['/auth/login']);
  }
  ISETRegistration() {
    this.router.navigate(['/iset-registration-form']);
  }
  CheckIfRegistered() {
    this.router.navigate(['/isRegistered']);
  }
  aboutUs() {
    this.router.navigate(['/about-us']);
  }
  contactUs(){
    this.router.navigate(['/contact-nation']);
  }
  demoAccount(){
     this.router.navigate(['/auth/request-demo']);
  }

  custome(id){
    this.router.navigate(['/custome-section', id]);
  }
}
