import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toast } from 'materialize-css';
import { PseRegistrationService } from 'src/app/services/api/pse-registration.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { PseCoursesModel } from 'src/app/models/PseCoursesModel';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-course',
  templateUrl: './pse-course.component.html',
  styleUrls: ['./pse-course.component.css']
})
export class PseCourseComponent implements OnInit {

  courseYears = [];
  courseTerms = ['FALL', 'WINTER', 'SPRING', 'SUMMER'];
  public yearMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  courseForm: UntypedFormGroup;
  coursesModel: PseCoursesModel[];

  addCourse = false;
  displayCourse = false;
  emptyCourse = false;

  selected_year = "";
  selected_term = '';
  applicantID = 0;

  hasRegisteredCourses = false;
  RegisteredCourses: any = [];

  course_completed = false;
  description_required = false;
  EIDT_COURSE = false;
  edit_course_index = -1;
  //edit_course_required = false;
  reason_required = false;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private pseRegistrationService: PseRegistrationService,
    private location: Location) { }

  ngOnInit(): void {
    this.selected_year = (new Date().getFullYear()) + "-" + (new Date().getFullYear() + 1);
    let current_month = new Date().getMonth();
    if (current_month === 12 || current_month === 1 || current_month === 2) {
      this.selected_term = 'WINTER';
    } else if (current_month === 3 || current_month === 4 || current_month === 5) {
      this.selected_term = 'SPRING';
    } else if (current_month === 6 || current_month === 7 || current_month === 8) {
      this.selected_term = 'SUMMER';
    } else if (current_month === 9 || current_month === 10 || current_month === 11) {
      this.selected_term = 'FALL';
    }

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.courseYears.push((new Date().getFullYear()) + "-" + (new Date().getFullYear() + 1));
    this.courseYears.push((new Date().getFullYear() + 1) + "-" + (new Date().getFullYear() + 2));

    this.courseForm = this.fb.group({
      course: this.fb.group({
        add_course: ['', [Validators.required]],
        courses: this.fb.array([]),
        fiscal_year: [''],
        term: [''],
        course: [''],
        start_date: [''],
        end_date: [''],
        tuition: [''],
        books: [''],
        tutoring: [''],
        other: [''],
        description: [''],
        completed: [''],
        completion_date: [''],
        mark: [''],
        reason: [''],
        status: ['']
      }),
    });

    this.courseForm.get('course.add_course').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.courseForm.get('course.course').setValidators([Validators.required, Validators.minLength(1)]);
        this.courseForm.get('course.fiscal_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]);
        this.courseForm.get('course.term').setValidators(Validators.required);
        this.courseForm.get('course.start_date').setValidators(Validators.required);
        this.courseForm.get('course.end_date').setValidators(Validators.required);
        // this.courseForm.get('course.completed').setValidators(Validators.required);
      } else {
        this.courseForm.get('course.course').clearValidators();
        this.courseForm.get('course.fiscal_year').clearValidators();
        this.courseForm.get('course.term').clearValidators();
        this.courseForm.get('course.start_date').clearValidators();
        this.courseForm.get('course.end_date').clearValidators();
        // this.courseForm.get('course.completed').clearValidators();
      }
      this.courseForm.get('course.course').updateValueAndValidity();
      this.courseForm.get('course.fiscal_year').updateValueAndValidity();
      this.courseForm.get('course.term').updateValueAndValidity();
      this.courseForm.get('course.start_date').updateValueAndValidity();
      this.courseForm.get('course.end_date').updateValueAndValidity();
      // this.courseForm.get('course.completed').updateValueAndValidity();
    });

    this.RegisteredCourses = [];
    this.pseRegistrationService.getCourses(this.applicantID).subscribe(p => {
      if (p.length > 0) {

        (<UntypedFormGroup>this.courseForm.controls['course']).controls['add_course'].patchValue('yes');

        var startdate = "";
        var enddate = "";
        var completed_date = "";
        var iscompleted = "";
        p.forEach(clg => {

          if (clg.start_date) { startdate = this.formatDates(clg.start_date); } else { startdate = ""; }
          if (clg.end_date) { enddate = this.formatDates(clg.end_date); } else { enddate = ""; }
          if (clg.completed == true) {
            iscompleted = "yes"
          } else {
            if (clg.completed == false) { iscompleted = "no" } else { iscompleted = "" }
          };
          if (clg.completion_date) { completed_date = this.formatDates(clg.completion_date); } else { completed_date = ""; }

          let tmpFromgroup = this.fb.group({
            student_aid: clg.aid,
            course_id: clg.course_id,
            fiscal_year: clg.fiscal_year,
            term: clg.term,
            course: clg.course,
            start_date: startdate,
            end_date: enddate,
            tuition: clg.tuition,
            books: clg.books,
            tutoring: clg.tutoring,
            other: clg.other,
            description: clg.description,
            completed: iscompleted,
            completion_date: completed_date,
            mark: clg.final_mark,
            reason: clg.reason,
            status: clg.status,
          });
          this.courseControls.push(tmpFromgroup);

          let aCourse = new PseCoursesModel();
          aCourse.student_aid = clg.aid;
          aCourse.course_id = clg.course_id;
          aCourse.fiscal_year = clg.fiscal_year;
          aCourse.term = clg.term;
          aCourse.course = clg.course;
          aCourse.completed = iscompleted;
          this.RegisteredCourses.push(aCourse);
        });

        this.emptyCourse = false;
        this.displayCourse = true;
        this.addCourse = false;
        this.hasRegisteredCourses = true;

        setTimeout(() => {
          $('.collapsible').collapsible();
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      } else {
        (<UntypedFormGroup>this.courseForm.controls['course']).controls['add_course'].patchValue('no');
        this.onAddCourseNo();
        this.hasRegisteredCourses = false;
        this.RegisteredCourses = [];
      }
    });

    this.coursesModel = [];

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  get courses() { return this.courseForm.get('course.courses'); }
  get fiscal_year() { return this.courseForm.get('course.fiscal_year'); }
  get term() { return this.courseForm.get('course.term'); }
  get course() { return this.courseForm.get('course.course'); }
  get start_date() { return this.courseForm.get('course.start_date'); }
  get end_date() { return this.courseForm.get('course.end_date'); }
  get tuition() { return this.courseForm.get('course.tuition'); }
  get books() { return this.courseForm.get('course.books'); }
  get tutoring() { return this.courseForm.get('course.tutoring'); }
  get other() { return this.courseForm.get('course.other'); }
  get description() { return this.courseForm.get('course.description'); }
  get completed() { return this.courseForm.get('course.completed'); }
  get completion_date() { return this.courseForm.get('course.completion_date'); }
  get mark() { return this.courseForm.get('course.mark'); }
  get reason() { return this.courseForm.get('course.reason'); }
  get status() { return this.courseForm.get('course.status'); }
  get courseControls() { return this.courseForm.get('course.courses') as UntypedFormArray; }

  formatDates(dateValue) {
    if (dateValue) {
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  formatDates1(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('/');
      let year = dateArray[2];
      let month = dateArray[0];   //- 1;
      let day = dateArray[1];
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  onAddCourseYes() {
    this.addCourse = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyCourse = false;
    this.courseForm.get('course.fiscal_year').reset();
    this.courseForm.get('course.term').reset();
    this.courseForm.get('course.course').reset();
    this.courseForm.get('course.start_date').reset();
    this.courseForm.get('course.end_date').reset();
    this.courseForm.get('course.tuition').reset();
    this.courseForm.get('course.books').reset();
    this.courseForm.get('course.tutoring').reset();
    this.courseForm.get('course.other').reset();
    this.courseForm.get('course.description').reset();
    this.courseForm.get('course.completed').reset();
    this.courseForm.get('course.completion_date').reset();
    this.courseForm.get('course.mark').reset();
    this.courseForm.get('course.reason').reset();
    this.courseForm.get('course.status').reset();
  }

  onAddCourseNo() {
    const courses1 = this.RegisteredCourses.filter(b => b.completed == "yes");
    if (courses1.length > 0) {
      toast({ html: '<p>There are courses completed, unable to remove it !</p> ', classes: 'red' });
      return;
    }

    this.addCourse = false;
    const courses = this.courseControls;

    while (courses.length !== 0) {
      courses.removeAt(0);
    }
    this.emptyCourse = true;
    this.displayCourse = false;
    courses.setValue([]);
  }

  addMyCourse(values: any): void {
    const courses = this.courseControls;
    const tmp = values.course;
    if (!tmp || tmp == '') {
      toast({ html: 'Please Enter a Course !', classes: 'red' });
      return;
    }

    if (!values.start_date || values.start_date == '') {
      toast({ html: 'Please Select Start Date !', classes: 'red' });
      return;
    }
    if (!values.end_date || values.end_date == '') {
      toast({ html: 'Please Select End Date !', classes: 'red' });
      return;
    }

    //check duplicated course added 
    const courses1 = this.RegisteredCourses.filter(b => b.fiscal_year == values.fiscal_year);
    const courses2 = courses1.filter(a => a.term == values.term);
    const courses3 = courses2.filter(a => a.course == values.course);

    if (courses3.length > 0) {
      toast({ html: '<p>The Course is Already in the List !</p> ', classes: 'red' });
      return;
    } else {

      var completion_date = "";
      if (values.completion_date) { completion_date = values.completion_date.singleDate.formatted; } else { completion_date = ""; }
      let tmpFromgroup = this.fb.group({
        student_aid: this.applicantID,
        course_id: 0,
        fiscal_year: [values.fiscal_year],
        term: [values.term],
        course: [values.course.trim()],
        start_date: values.start_date.singleDate.formatted,
        end_date: values.end_date.singleDate.formatted,
        tuition: [values.tuition],
        books: [values.books],
        tutoring: [values.tutoring],
        other: [values.other],
        description: [values.description],
        completed: 'no',    //[values.completed],
        completion_date: completion_date,
        mark: [values.mark],
        reason: [values.reason],
        status: "in-progress",    //[values.status],
      });
      courses.push(tmpFromgroup);

      let aCourse = new PseCoursesModel();
      aCourse.student_aid = this.applicantID;
      aCourse.course_id = 0;
      aCourse.fiscal_year = values.fiscal_year;
      aCourse.term = values.term;
      aCourse.course = values.course.trim();
      aCourse.completed = values.completed;
      this.RegisteredCourses.push(aCourse);

      this.hasRegisteredCourses = true;
      this.emptyCourse = false;
      this.displayCourse = true;
      this.addCourse = false;
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
      toast({ html: 'Course Successfully Registered!', classes: 'green' });
    }
  }

  updateMyCourse(values: any): void {

    if (this.edit_course_index == -1) {
      toast({ html: 'Please Click a Course to Edit !', classes: 'red' });
      return;
    }
    let index = this.edit_course_index;

    const courses = this.courseControls;
    const tmp = values.course;
    if (!tmp || tmp == '') {
      toast({ html: 'Please Enter a Course !', classes: 'red' });
      return;
    }

    if (!values.start_date || values.start_date == '') {
      toast({ html: 'Please Select Start Date !', classes: 'red' });
      return;
    }
    if (!values.end_date || values.end_date == '') {
      toast({ html: 'Please Select End Date !', classes: 'red' });
      return;
    }

    this.courseControls.value[index].course = values.course;
    this.courseControls.value[index].term = values.term;
    this.courseControls.value[index].fiscal_year = values.fiscal_year;

    var start_date1 = values.start_date.singleDate.jsDate;
    var start_date2 = new Date(start_date1.getFullYear(), start_date1.getMonth(), start_date1.getDate());
    this.courseControls.value[index].start_date = this.formatDates1(start_date2.toLocaleDateString());
    var end_date1 = values.end_date.singleDate.jsDate;
    var end_date2 = new Date(end_date1.getFullYear(), end_date1.getMonth(), end_date1.getDate());
    this.courseControls.value[index].end_date = this.formatDates1(end_date2.toLocaleDateString());

    this.courseControls.value[index].tuition = values.tuition;
    this.courseControls.value[index].books = values.books;
    this.courseControls.value[index].tutoring = values.tutoring;
    this.courseControls.value[index].other = values.other;
    this.courseControls.value[index].description = values.description;

    this.courseControls.value[index].completed = values.completed;

    if (values.completion_date) {
      var comp_date1 = values.completion_date.singleDate.jsDate;
      var comp_date2 = new Date(comp_date1.getFullYear(), comp_date1.getMonth(), comp_date1.getDate());
      this.courseControls.value[index].completion_date = this.formatDates1(comp_date2.toLocaleDateString());
    }
    else { this.courseControls.value[index].completion_date = ""; }

    this.courseControls.value[index].mark = values.mark;
    this.courseControls.value[index].reason = values.reason;

    if (values.completed == 'yes') { this.courseControls.value[index].status = 'completed'; } else { this.courseControls.value[index].status = values.status; }

    const cid = this.courseControls.value[index].course_id;
    this.RegisteredCourses.forEach(c => {
      if (c.course_id == cid) {
        c.fiscal_year = values.fiscal_year;
        c.term = values.term;
        c.course = values.course.trim();
        c.completed = values.completed;
      }
    });

    this.hasRegisteredCourses = true;
    this.emptyCourse = false;
    this.displayCourse = true;
    this.addCourse = false;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    toast({ html: 'Course Successfully Updated !', classes: 'green' });
  }

  addAnotherCourse() {
    this.addCourse = true;
    this.EIDT_COURSE = false;
    this.reason_required = false;

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    //this.emptyCourse = false;
    // this.courseForm.get('course.fiscal_year').reset();
    this.courseForm.get('course.term').reset();
    this.courseForm.get('course.course').reset();
    this.courseForm.get('course.start_date').reset();
    this.courseForm.get('course.end_date').reset();
    this.courseForm.get('course.tuition').reset();
    this.courseForm.get('course.books').reset();
    this.courseForm.get('course.tutoring').reset();
    this.courseForm.get('course.other').reset();
    this.courseForm.get('course.description').reset();
    this.courseForm.get('course.completed').reset();
    this.courseForm.get('course.completion_date').reset();
    this.courseForm.get('course.mark').reset();
    this.courseForm.get('course.reason').reset();
    this.courseForm.get('course.status').reset();
  }

  onDeleteCourse(index) {
    const courses = this.courseControls;
    const completed = courses.value[index].completed
    if (completed == 'yes') {
      toast({ html: 'This Course is Completed, Unable to Remove it!', classes: 'red' });
      return
    }

    this.RegisteredCourses = [];
    this.edit_course_index = -1;

    courses.removeAt(index);
    if (courses.length == 0) {
      this.addCourse = false;
      this.displayCourse = false;
      this.emptyCourse = true;
      this.hasRegisteredCourses = false;
      this.courseForm.patchValue({ 'course': { 'add_course': 'no' } });

    } else {
      for (let c of this.courseControls.controls) {
        let aCourse = new PseCoursesModel();
        aCourse.student_aid = c.value.student_aid;
        aCourse.course_id = c.value.course_id;
        aCourse.fiscal_year = c.value.fiscal_year;
        aCourse.term = c.value.term;
        aCourse.course = c.value.course;
        aCourse.completed = c.value.completed;
        this.RegisteredCourses.push(aCourse);
      }
    }

    toast({ html: 'Course deleted!', classes: 'red' });
  }

  closeAddCourse() {
    this.addCourse = false;
    this.edit_course_index = -1;
    this.reason_required = false;

    this.courseForm.get('course.fiscal_year').clearValidators();
    this.courseForm.get('course.term').clearValidators();
    this.courseForm.get('course.course').clearValidators();
    this.courseForm.get('course.start_date').clearValidators();
    this.courseForm.get('course.end_date').clearValidators();

    if (this.courseControls.length == 0) {
      this.courseForm.patchValue({ 'course': { 'add_course': 'no' } });
    }
  }

  isCompletedYes() {
    this.course_completed = true;
    this.reason_required = false;
    this.courseForm.get('course.reason').reset();
  }

  isCompletedNo() {
    this.course_completed = false;
    this.reason_required = true;
    this.courseForm.get('course.completion_date').reset();
    this.courseForm.get('course.mark').reset();
  }

  onEditCourse(index) {
    const courses = this.courseControls;
    const completed = courses.value[index].completed
    if (completed == 'yes') {
      this.closeAddCourse();
      toast({ html: 'This Course is Completed, Unable to Edit !', classes: 'red' });
      return;
    }

    this.addCourse = true;
    this.EIDT_COURSE = true;
    this.edit_course_index = index;

    this.courseForm.get('course.fiscal_year').reset();
    this.courseForm.get('course.term').reset();
    this.courseForm.get('course.course').reset();
    this.courseForm.get('course.start_date').reset();
    this.courseForm.get('course.end_date').reset();
    this.courseForm.get('course.tuition').reset();
    this.courseForm.get('course.books').reset();
    this.courseForm.get('course.tutoring').reset();
    this.courseForm.get('course.other').reset();
    this.courseForm.get('course.description').reset();
    this.courseForm.get('course.completed').reset();
    this.courseForm.get('course.completion_date').reset();
    this.courseForm.get('course.mark').reset();
    this.courseForm.get('course.reason').reset();
    this.courseForm.get('course.status').reset();

    this.courseForm.patchValue({ 'course': { 'course': courses.value[index].course } });
    this.courseForm.patchValue({ 'course': { 'term': courses.value[index].term } });
    this.courseForm.patchValue({ 'course': { 'fiscal_year': courses.value[index].fiscal_year } });

    const start_date = this.changeDates(courses.value[index].start_date);
    let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: start_date }, dateRange: null };
    this.courseForm.patchValue({ 'course': { 'start_date': model1 } });

    const end_date = this.changeDates(courses.value[index].end_date);
    let model2: IMyDateModel = { isRange: false, singleDate: { jsDate: end_date }, dateRange: null };
    this.courseForm.patchValue({ 'course': { 'end_date': model2 } });

    if (courses.value[index].tuition) { this.courseForm.patchValue({ 'course': { 'tuition': courses.value[index].tuition } }); }
    else { this.courseForm.patchValue({ 'course': { 'tuition': '' } }); }

    if (courses.value[index].books) { this.courseForm.patchValue({ 'course': { 'books': courses.value[index].books } }); }
    else { this.courseForm.patchValue({ 'course': { 'books': '' } }); }

    if (courses.value[index].tutoring) { this.courseForm.patchValue({ 'course': { 'tutoring': courses.value[index].tutoring } }); }
    else { this.courseForm.patchValue({ 'course': { 'tutoring': '' } }); }

    this.description_required = false;
    if (courses.value[index].other) {
      this.courseForm.patchValue({ 'course': { 'other': courses.value[index].other } });
      if (Number(courses.value[index].other) > 0) { this.description_required = true; } else { this.description_required = false; }
    } else { this.courseForm.patchValue({ 'course': { 'other': '' } }); }

    if (courses.value[index].description) { this.courseForm.patchValue({ 'course': { 'description': courses.value[index].description } }); }

    if (courses.value[index].completion_date) {
      const completion_date = this.changeDates(courses.value[index].completion_date);
      let model3: IMyDateModel = { isRange: false, singleDate: { jsDate: completion_date }, dateRange: null };
      this.courseForm.patchValue({ 'course': { 'completion_date': model3 } });
    } else { this.courseForm.patchValue({ 'course': { 'completion_date': '' } }); }

    // this.courseForm.patchValue({ 'course': { 'completed': courses.value[index].completed } });
    if (courses.value[index].completed) {
      this.courseForm.patchValue({ 'course': { 'completed': courses.value[index].completed } });
      if (courses.value[index].completed == "yes") {
        this.reason_required = false;
        this.course_completed = true;
        this.courseForm.patchValue({ 'course': { 'reason': "" } });
        this.courseForm.patchValue({ 'course': { 'status': "" } });
      } else {
        this.reason_required = true;
        this.course_completed = false;
        if (courses.value[index].reason) { this.courseForm.patchValue({ 'course': { 'reason': courses.value[index].reason } }); }
        else { this.courseForm.patchValue({ 'course': { 'reason': "" } }); }

        if (courses.value[index].status) { this.courseForm.patchValue({ 'course': { 'status': courses.value[index].status } }); }
        else { this.courseForm.patchValue({ 'course': { 'status': "" } }); }
      }
    } else {
      this.courseForm.patchValue({ 'course': { 'completed': "" } });
      this.reason_required = false;
      this.course_completed = false;
      this.courseForm.patchValue({ 'course': { 'reason': "" } });
      this.courseForm.patchValue({ 'course': { 'status': "" } });
    }
    if (courses.value[index].mark) {
      this.courseForm.patchValue({ 'course': { 'mark': courses.value[index].mark } });
    } else { this.courseForm.patchValue({ 'course': { 'mark': "" } }); }

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  submitCourses(values: any) {
    this.coursesModel = values.courses;

    this.coursesModel.forEach(c => {
      c.start_date = this.changeDates(c.start_date).toDateString();
      c.end_date = this.changeDates(c.end_date).toDateString();
      if (c.completion_date) { c.completion_date = this.changeDates(c.completion_date).toDateString(); } else { c.completion_date = ""; }
      if (c.completed) { c.completed = c.completed; } else { c.completed = ""; }
    });
    console.log("courses2: ", this.coursesModel);

    this.pseRegistrationService.saveCourses(this.coursesModel).subscribe(x => {
      if (x) {
        toast({ html: 'Courses Successfully Saved!', classes: 'green' });
        this.location.back();
      }
    });
  }

  onDateChange1(event: IMyDateModel): void {
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['start_date'].patchValue(event.singleDate.formatted);
  }

  onDateChange2(event: IMyDateModel): void {
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['end_date'].patchValue(event.singleDate.formatted);
  }

  onDateChange3(event: IMyDateModel): void {
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['completion_date'].patchValue(event.singleDate.formatted);
  }

  setTuitionIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['tuition'].patchValue(amount);
  }

  setBooksIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['books'].patchValue(amount);
  }

  setTutoringIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['tutoring'].patchValue(amount);
  }

  setOtherIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.courseForm.controls['course']).controls['other'].patchValue(amount);
    if (Number(amount) > 0) {
      this.description_required = true;
    } else {
      this.description_required = false;
      (<UntypedFormGroup>this.courseForm.controls['course']).controls['description'].patchValue("");
    }
  }

  onBack() {
    this.location.back();
  }
}