<div class="body-h row container-pi">

  <!-- <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Add New Work Experience </h4>
    </div>
  </div> -->

  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
  </span>Add New Work Experience</h4>

  <main class="StickyContent white row">
    <div id="step0" *ngIf="step0" class="col s12 l10">
      <form (ngSubmit)="submitForm(addEmploymentForm.value)" [formGroup]="addEmploymentForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6 ">
            <div class="label-input"> Job Title <strong class="red-text">*</strong> </div>
            <input formControlName="Name" id="Name" type="text" maxlength="75" autocomplete="off" required
              class="capitalize validate" placeholder="Job Title">
            <div *ngIf="(Name.dirty || Name.touched) && Name.invalid">
              <small *ngIf="Name.errors.required" class="red-text"> Please enter the job title
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Description <strong class="red-text">*</strong> </div>
            <input formControlName="description" id="description" type="text" maxlength="100" autocomplete="off"
              required class="capitalize validate" placeholder="Description">
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> Please enter the description.
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6 ">
            <div class="label-input">Employer Name<strong class="red-text">*</strong> </div>
            <input formControlName="employer" id="employer" type="text" maxlength="100" autocomplete="off" required
              class="capitalize validate" placeholder="Employer Name">
            <div *ngIf="(employer.dirty || employer.touched) && employer.invalid">
              <small *ngIf="employer.errors.required" class="red-text"> Please enter the employer name.
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6 ">
            <div class="label-input">City<strong class="red-text">*</strong> </div>
            <input formControlName="City" id="City" type="text" maxlength="100" autocomplete="off" required
              class="capitalize validate" placeholder="City">
            <div *ngIf="(City.dirty || City.touched) && City.invalid">
              <small *ngIf="City.errors.required" class="red-text"> Please enter the employer city.
              </small>
            </div>
          </div>

          <div class="row input-field col s12 l6 ">
            <div class="label-input">Province </div>
            <select formControlName="Prov" required class="validate browser-default">
              <option [value]="province" disabled selected>{{province}} </option>
              <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
            </select>
          </div>

          <div class="input-field class col s12 l6 ">
            <div class="label-input">Start Date<strong class="red-text">*</strong> </div>
            <input id="start_date" formControlName="start_date" type="text" class="input-box" angular-mydatepicker
              name="mydate" (click)="dp1.toggleCalendar()" [options]="myDpOptions" #dp1="angular-mydatepicker"
              (dateChanged)="onDateChange1($event)" placeholder="Start Date" />
          </div>

          <div class="row input-field col s12 l6 ">
            <div class="label-input">Is this your Current Employer ?</div>
            <div class="row col s12">
              <label class="col s6">
                <input class="with-gap" formControlName="iscurrent" name="iscurrent" type="radio" checked="checked"
                  value="yes" (change)="onCurrentEmploymentYes($event)" />
                <span>Yes</span>
              </label>
              <label class="col s6">
                <input class="with-gap" formControlName="iscurrent" name="iscurrent" type="radio" value="no"
                  (change)="onCurrentEmploymentNo($event)" />
                <span>No</span>
              </label>
            </div>
          </div>

          <div class="input-field class col s12 l6 " *ngIf="showEndDate">
            <div class="label-input">End Date<strong class="red-text">*</strong> </div>
            <input id="end_date" formControlName="end_date" type="text" class="input-box" angular-mydatepicker
              name="mydate" (click)="dp2.toggleCalendar()" [options]="myDpOptions" #dp2="angular-mydatepicker"
              (dateChanged)="onDateChange2($event)" placeholder="End Date" />
          </div>

          <div class="row input-field col s12 l12 " *ngIf="showEndDate">
            <div class="label-input"> Reason for Leaving</div>
            <div class="md-form mb-4 pink-textarea active-pink-textarea">
              <textarea id="reason" formControlName="reason" class="md-textarea form-control" rows="3" maxlength="90"
                placeholder="Reason for leaving"></textarea>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addEmploymentForm.valid">
            <i class="material-icons right">keyboard_arrow_right</i> Submit
          </button>

          <button [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Work Experience List
          </button>
        </div>
      </form>
    </div>

    <div id="step2" *ngIf="step2" class="row s12 l10">
      <div class="row">
        <div class="col s12">
          <div class="card-panel">
            <div class="row center">
              <h5 class="green-text">Thank You!</h5>
              <div class="section"></div>
              <h6>Work experience successfully added</h6>
              <div class="section"></div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div class="section"></div>
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
        <i class="material-icons left">keyboard_arrow_left</i> Back To Work Experience List
      </button>
    </div>
  </main>
</div>