import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/api/community.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { toast } from 'materialize-css';
import { FindMemberModel } from 'src/app/models/FindMemberModel';
import { UpdateProfileService } from 'src/app/services/firebase/update-profile.service';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { DataService } from 'src/app/services/data.service';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  @Input() model: any;
  @Input() dependant = false;
  @Input() fromCandidates = false;
  @Output() addressUpdated: EventEmitter<string> = new EventEmitter<string>();

  nationName = environment.firstNation.displayName;
  defaultProvince = environment.firstNation.province;
  isDemo = false;
  isLL = false;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  updateAddressForm: UntypedFormGroup;
 
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  loggedUser: any;

  updateProfileForm: UntypedFormGroup;
  maritalStatus: any = ['Single', 'Married', 'Separated', 'Widowed', "Common Law"];
  //provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick','Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provincesCode = ['AB', 'BC', "MB", 'NB', "NL", 'NS', 'ON', 'PE', 'QC', 'SK'];

  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public healthCareNoMask = [/ \d+ /];
  // public treatynoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9'
  });

  SINMask = createMask({
    mask: '999 999 999',
    placeholderChar: '',
    maskChar: null,
  });

  treatyNo = '';
  bands: any[];
  bandUse: any[];
  bandName = '';
  provinceCode: any;
  bandList: any[];
  showSpouseInfo = false;
  maritalStatusSelected = false;
  profile: any;
  hasSIN = false;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
    closeSelectorOnDateSelect: false,
  };

  findMemberModel: FindMemberModel;
  hasBD = false;
  contactForm: UntypedFormGroup;

  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalInputMask = createMask<string>( {
    mask: 'A0A 0A0',
    guide: true,
    showMaskTyped: true,
  });

  constructor(
    private fb: UntypedFormBuilder,
    private memberService: MemberService,
    private authService: AuthService,
    private communityService: CommunityService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private updateProfileService: UpdateProfileService,
    private nationSettingsService: NationSettingsService,
    private dataService: DataService
  ) {
    if (this.nationName === 'Demo') { this.isDemo = true; }
    if (this.nationName === 'LongLake') { this.isLL = true; }
    this.loggedUser = this.authService.getGlobalUser();

    this.dataService.getAppName().subscribe(appName => {
      if (appName) this.nationName = appName;
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
          }
        });
      }
    });
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      cell1: ['', [Validators.required]],
    });

    this.profile = {
      applicantID: this.model.applicantID,
      MaritalStatus: this.model.MaritalStatus,
      MailAddress: this.model.MailAddress,
      city: this.model.city,
      province: this.model.province,
      PostalCode: this.model.PostalCode,
      HouseNumber: this.model.HouseNumber,
      Telephone: this.model.Telephone ? this.model.Telephone : '',
      Fax: this.model.Fax,  // value.fax,
      cell_phone_num: this.model.cell_phone_num, //value.cell,
      HealthCareNo: this.model.HealthCareNo,
      SIN: this.model.SIN,
      Email: this.model.Email,
      spouse_aid: 0,
    };

    if (localStorage.getItem('SocialOK') === 'YES') {

      this.memberService.checkIfBDLast2Month(this.profile).subscribe(cnt => {
        if (cnt > 0) {
          this.hasBD = true;
        }
      });
    }

    if (this.model.MaritalStatus) { this.maritalStatusSelected = true; }
    if (this.model.MaritalStatus == 'Married' || this.model.MaritalStatus == "Common Law") {
      this.showSpouseInfo = true;
    } else {
      this.showSpouseInfo = false;
    }
    if (this.model.SIN && this.model.SIN !== '') {
      this.hasSIN = true;
    }

    this.updateProfileForm = this.fb.group({
      //band: [this.bandName],
      //treatyno: [this.treatyNo, [Validators.minLength(3), Validators.maxLength(3), Validators.pattern('^[0-9]*$')]], 
      band: ['', []],
      treatyno: ['448', [Validators.minLength(3), Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
      marital_status: ['', [Validators.required]],
      house_number: ['', []],
      mailAddress: ['', []],
      city: ['', []],
      province: [this.defaultProvince, [Validators.required]],
      postalCode: ['', []],

      telephone: ['', []],
      fax: ['', []],
      cell: ['', []],
      health_care_no: ['', []],
      SIN: ['', [Validators.minLength(9), Validators.maxLength(9)]],
      email: ['', [Validators.email]],
    });

    this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
      if (t) {
        this.treatyNo = t;
        this.communityService.getBands().subscribe(band => {
          if (band) {
            this.bands = band;
            this.getBandListFromProvinceCode(this.defaultProvince);
            this.bandUse = this.bands.filter(b => b.treatyno == this.treatyNo);
            if (this.bandUse && this.bandUse.length > 0) {
              this.bandName = this.bandUse[0].Band;
            }
            this.updateProfileForm.patchValue({ 'marital_status': this.model.MaritalStatus });
            this.updateProfileForm.patchValue({ 'house_number': this.model.HouseNumber });
            this.updateProfileForm.patchValue({ 'mailAddress': this.model.MailAddress });
            this.updateProfileForm.patchValue({ 'city': this.model.city });

            if (!this.model.province || this.model.province === '') {
              this.updateProfileForm.patchValue({ 'province': this.defaultProvince });
            } else {
              this.updateProfileForm.patchValue({ 'province': this.model.province });
            }

            this.updateProfileForm.patchValue({ 'postalCode': this.model.PostalCode });
            this.updateProfileForm.patchValue({ 'telephone': this.model.Telephone });
            this.updateProfileForm.patchValue({ 'fax': this.model.Fax });
            this.updateProfileForm.patchValue({ 'cell': this.model.cell_phone_num });
            this.updateProfileForm.patchValue({ 'health_care_no': this.model.HealthCareNo });
            this.updateProfileForm.patchValue({ 'SIN': this.model.SIN });
            this.updateProfileForm.patchValue({ 'email': this.model.Email });

            setTimeout(() => {
              $('select').formSelect();
              $('.modal').modal();
              M.updateTextFields();
            }, 25);
          }
        });
      }
    });
  }

  updateNumber(val: any) {
    $('#MobileModal').modal('close');
    this.memberService.updateCellPhone(val.cell1, this.authService.getGlobalUser().applicantID)
      .subscribe(up => {
        if (up) {
          M.toast({ html: 'Mobile Number Successfully Updated.', classes: 'green' });
        }
      });
  }

  get marital_status() { return this.updateProfileForm.get('marital_status'); }
  get house_number() { return this.updateProfileForm.get('house_number'); }
  get mailAddress() { return this.updateProfileForm.get('mailAddress'); }
  get city() { return this.updateProfileForm.get('city'); }
  get province() { return this.updateProfileForm.get('province'); }
  get postalCode() { return this.updateProfileForm.get('postalCode'); }
  get telephone() { return this.updateProfileForm.get('telephone'); }
  get fax() { return this.updateProfileForm.get('fax'); }
  get cell() { return this.updateProfileForm.get('cell'); }
  get health_care_no() { return this.updateProfileForm.get('health_care_no'); }
  get SIN() { return this.updateProfileForm.get('SIN'); }
  get email() { return this.updateProfileForm.get('email'); }
  get spouse_first_name() { return this.updateProfileForm.get('spouse.spouse_first_name'); }
  get spouse_last_name() { return this.updateProfileForm.get('spouse.spouse_last_name'); }
  get spouse_dob() { return this.updateProfileForm.get('spouse.spouse_dob'); }
  get resident_band() { return this.updateProfileForm.get('resident_band'); }
  get resident() { return this.updateProfileForm.get('resident'); }

  maritalStatusChange(value: any) {
    this.maritalStatusSelected = true;
    if (value == 'Married' || value == "Common Law") {
      this.showSpouseInfo = true;
    } else {
      this.showSpouseInfo = false;
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  provinceChange(value: any) {
    this.getBandListFromProvinceCode(value);
    this.updateProfileForm.get('band').updateValueAndValidity();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  getBandListFromProvinceCode(province) {
    const index = this.provinces.indexOf(province);
    const provinceCode = this.provincesCode[index];
    if (this.bands) {
      const bandInProvince = this.bands.filter(band => provinceCode === this.getProvinceCodeFromAddress(band.address));
      const bandList = [];
      bandInProvince.forEach(b => {
        bandList.push(b.band);
      });
      this.bandList = bandList;
    }
  }

  getProvinceCodeFromAddress(address: any) {
    if (!address || address.length === 0) {
      return '';
    } else {
      let addr = address.substring(address.length - 11);
      addr = addr.substring(0, 2);
      return addr;
    }
  }

  onTreatyNoChange(e) {
    const isn = e.toString().slice(0, 3)
    const isnArray = isn.split('');
    if (isnArray[0] != '_' && isnArray[1] != '_' && isnArray[2] != '_') {
      this.bandUse = this.bands.filter(b => b.treatyno == isn);
      if (this.bandUse && this.bandUse.length > 0) {
        this.bandName = this.bandUse[0].band;
        this.updateProfileForm.patchValue({ 'band': this.bandName });
        this.updateProfileForm.get('band').updateValueAndValidity();
      } else {
        this.updateProfileForm.patchValue({ 'band': '' });
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  bandChange(e) {
    const theBand = e.toString().trim();
    const tretynoUse = this.bands.filter(b => b.band == theBand);
    if (tretynoUse && tretynoUse.length > 0) {
      this.updateProfileForm.patchValue({ 'treatyno': tretynoUse[0].treatyno });
      this.updateProfileForm.get('treatyno').updateValueAndValidity();
    } else {
      this.updateProfileForm.patchValue({ 'treatyno': '' });
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  SubmitProfile(value: any) {
    let phone = '';
    if (value.telephone) {
      phone = value.telephone;
      phone = phone.replace("(", "");
      phone = phone.replace(")", "");
      phone = phone.replace("-", "");
      phone = phone.replace(" ", "");
      phone = phone.replace(" ", "");
      phone = phone.replace(" ", "");
    }

    let cell = '';
    if (value.cell) {
      cell = value.cell;
      cell = cell.replace("(", "");
      cell = cell.replace(")", "");
      cell = cell.replace("-", "");
      cell = cell.replace(" ", "");
      cell = cell.replace(" ", "");
      cell = cell.replace(" ", "");
    }

    let fax = '';
    if (value.fax) {
      fax = value.fax;
      fax = fax.replace("(", "");
      fax = fax.replace(")", "");
      fax = fax.replace("-", "");
      fax = fax.replace(" ", "");
      fax = fax.replace(" ", "");
      fax = fax.replace(" ", "");
    }

    const profile = {
      applicantID: this.model.applicantID,
      MaritalStatus: value.marital_status,
      MailAddress: value.mailAddress,
      city: value.city,
      province: value.province,
      PostalCode: value.postalCode,
      HouseNumber: value.house_number,
      Telephone: phone,
      Fax: fax,   // value.fax,
      cell_phone_num: cell, //value.cell,
      HealthCareNo: value.health_care_no,
      SIN: value.SIN,
      Email: value.email,
      spouse_aid: 0,
    };

    var updates = [];

    if (this.profile.MaritalStatus != profile.MaritalStatus) updates.push({ name: "Marital Status", new_value: profile.MaritalStatus, old_value: this.profile.MaritalStatus, });
    if (this.profile.MailAddress != profile.MailAddress) updates.push({ name: 'Mail Address', new_value: profile.MailAddress, old_value: this.profile.MailAddress });
    if (this.profile.city != profile.city) updates.push({ name: 'City', new_value: profile.city, old_value: this.profile.city });
    if (this.profile.province != profile.province) updates.push({ name: 'Province', new_value: profile.province, old_value: this.profile.province });
    if (this.profile.PostalCode != profile.PostalCode) updates.push({ name: 'Postal Code', new_value: profile.PostalCode, old_value: this.profile.PostalCode });
    if (this.profile.HouseNumber != profile.HouseNumber) updates.push({ name: 'House Number', new_value: profile.HouseNumber, old_value: this.profile.HouseNumber });
    if (this.profile.Telephone != profile.Telephone) updates.push({ name: 'Telephone', new_value: profile.Telephone, old_value: this.profile.Telephone });
    if (this.profile.Fax != profile.Fax) updates.push({ name: 'Fax', new_value: profile.Fax, old_value: this.profile.Fax });
    if (this.profile.cell_phone_num != profile.cell_phone_num) updates.push({ name: 'Mobile Number', new_value: profile.cell_phone_num, old_value: this.profile.cell_phone_num });
    if (this.profile.HealthCareNo != profile.HealthCareNo) updates.push({ name: 'Health Care Number', new_value: profile.HealthCareNo, old_value: this.profile.HealthCareNo });
    if (this.profile.SIN != profile.SIN) updates.push({ name: 'SIN', new_value: profile.SIN, old_value: this.profile.SIN });
    if (this.profile.Email != profile.Email) updates.push({ name: 'Email', new_value: profile.Email, old_value: this.profile.Email });

    const profileUpdates = {
      applicantID: this.model.applicantID,
      GivenName: this.model.GivenName,
      LastName: this.model.LastName,
      type: 'Personal Info',
      changes: updates,
      date: new Date(),
    }

    this.memberService.checkIfBDLast2Month(profile).subscribe(cnt => {
      if (cnt > 0) {
        toast({ html: 'This Member has B&D in the last two months and unable to update profile !', classes: 'red' });
        return;
      }

      this.memberService.updateMemberProfile(profile).subscribe(x => {
        if (x) {
          toast({ html: 'Member Profile Updated Successfully !', classes: 'green' });
          this.memberService.getMember(this.model.applicantID).subscribe(x => {
            this.model = x[0];
          });

          if (profileUpdates.changes) {
            if (profileUpdates.changes.length > 0) this.updateProfileService.saveUpdates(profileUpdates);
          }
          return;
        }
      });
    });
  }
}
