<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="">Edit Application</h4>

                <p class="bold-100 sub-title-section3">This is the menu that the general public will have
                    access to on your mobile app</p>
            </div>
        </div>

        <main class="StickyContent white ">

            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row center-align card white" *ngIf="!application && !loading">
                <i class="large material-icons blue-text">warning</i>
                <p class="red-text"><strong>No Application Found.</strong></p>
            </div>

            <div class="row " *ngIf="application">
                <div class="col s12 l11" *ngIf="step == 0 && templates">
                    <div class="row card white">
                        <div class="col s12 ">
                            <p class="bold-600">Please select the sections you want to include in your applications.</p>
                            <p class="bold-400">You will be able edit and customize each section.</p>
                            <P class="bold-100">If you want to add a new section click on the new section button located
                                at the bottom
                                right of the
                                screen.
                            </P>
                        </div>

                        <div class="row col s12">
                            <p><strong>Select one or more section to get started.</strong></p>
                            <ul class="collection">
                                <li class="collection-item" *ngFor="let section of templates;let i=index">
                                    <div>{{section.title}}
                                        <a class="secondary-content">
                                            <label>
                                                <input type="checkbox" [checked]="sectionSelected(section.title, i)"
                                                    (click)="selectSection(i)" />
                                                <span></span>
                                            </label>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                            <button class="waves-effect waves-light btn col col s12 m3 l3 right"
                                [ngStyle]="{'background-color':themeColor}" (click)="next()"
                                style="margin-bottom: 15px;">Next <i class="material-symbols-outlined right">
                                    arrow_forward_ios
                                </i></button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row" *ngIf="step == 1">
                <div class="col l11 s12" *ngIf="step == 1">
                    <form class="row " [formGroup]="appForm" (ngSubmit)="submitAppForm(appForm.value)">

                        <div class="card white col s12 ">
                            <div class="input-field col s12 l6">
                                <div class="label-input">Application Name<span class="red-text">*</span></div>
                                <input id="name" type="text" class="validate" formControlName="name" autocomplete="off"
                                    style="text-transform: capitalize;" placeholder="Application Name">
                                <div *ngIf="(appName.dirty || appName.touched) && appName.invalid">
                                    <small *ngIf="appName.errors.required" class="red-text errorMsg">Application Name is
                                        required.
                                    </small>
                                </div>
                            </div>
                            <div class="input-field col s12 l6 ">
                                <div class="label-input">Department<span class="red-text">*</span></div>
                                <select formControlName="department" class="browser-default" placeholder="Department">
                                    <option value="" disabled selected>Select Department</option>
                                    <option *ngFor="let item of departmentList" [value]='item'>{{item}}</option>
                                </select>
                            </div>

                            <div class="input-field col s12 l8">
                                <div class="label-input">Description</div>
                                <textarea id="description" type="text" class="validate" formControlName="description"
                                    style="height: 250px; text-transform: capitalize;" autocomplete="off"
                                    placeholder="Description"></textarea>
                            </div>
                        </div>

                        <div class="col s12 file-field input-field card">
                            <div class="btn">
                                <span>Browse <i class="material-icons">file_upload
                                    </i></span>
                                <input type="file" (change)="uploadLogo($event.target.files)" formControlName="logo"
                                    accept="image/*" />
                            </div>
                            <div class="file-path-wrapper">
                                <input class="file-path validate" type="text" placeholder="Choose a Logo" />
                            </div>

                            <div class="row col s6" *ngIf="logoSrc">
                                <a class="waves-effect waves-light btn-flat tooltipped" data-tooltip="Clear Image"
                                    (click)="clearLogo()"><i class="material-icons red-text">clear</i></a>
                                <img [src]="logoSrc" width="100px;" class="left">
                            </div>
                        </div>

                        <div class="col s12 card white">
                            <div class="input-field  ">
                                <h6 class="left bold-800">Customize Menu</h6>
                                <p class="right">
                                    <label>
                                        <input type="checkbox" [checked]="appButton" (click)="addAppButton()" />
                                        <span>Customize Button</span>
                                    </label>
                                </p>
                            </div>

                            <div class="row col s12" *ngIf="appButton" formGroupName="menu">

                                <div class="input-field col s12">
                                    <p class="label-input">Choose a Menu Color</p>
                                    
                                    <a class="col s11 btn grey lighten-3 black-text" style="margin-bottom: 15px;">
                                        <strong>Select Color <input formControlName="color" type="color"></strong> </a>

                                    <i class="material-icons col s1 small"
                                        [ngStyle]="{'color': appForm.get('menu.color').value}">
                                        circle
                                    </i>

                                </div>
                               
                                <div class="input-field col s12 l7">
                                    <div class="label-input">Icon</div>
                                    <select formControlName="icon" class="validate browser-default" id="icon">
                                        <option value="" disabled selected>Choose your option</option>
                                        <option *ngFor="let icon of icons" [value]="icon">
                                            <span>{{icon}}<i class="material-icons left teat-text">{{icon}}</i></span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row col s12">
                            <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green"
                                [disabled]="!appForm.valid">
                                <i class="material-icons right">navigate_next</i> Next
                            </button>
                            <button class="col s12 btn wave-effect" (click)="back()">
                                <i class="material-icons left">arrow_back</i>Back To Previous Page
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row valign-wrapper" *ngIf="step==2">
                <div class="col s12 l12">
                    <form [formGroup]="appDetail" (ngSubmit)="submitAppDetails(appDetail.value)">
                        <div class="col s11">
                            <div formArrayName="sections" *ngFor="let section of sectionControls.controls;let si=index">

                                <div class="row col s12" [formGroupName]='si'
                                    [ngStyle]="{'display': (currentSection == si)? 'block' : 'none'}">
                                    <div class="fixed-action-btn"
                                        style="position:relative; float:right; top:10px; right:10px; margin-bottom: 25px;">
                                        <a class="btn-floating white">
                                            <i class="large material-icons red-text">menu</i>
                                        </a>
                                        <ul>
                                            <li><a class="btn-floating tooltipped blue" data-position="top"
                                                    data-tooltip="Save as Template" (click)="saveSection(si)"><i
                                                        class="material-icons">save</i></a></li>

                                            <li><a class="btn-floating tooltipped red" data-position="top"
                                                    [ngClass]="{'disabled': (sectionControls.controls.length == 1)}"
                                                    data-tooltip="Delete Section" (click)="deleteSection(si)"><i
                                                        class="material-icons">delete</i></a></li>

                                            <li><a class="btn-floating tooltipped purple lighten-2" data-position="top"
                                                    [ngClass]="{'disabled': (sectionControls.controls.length == 1)}"
                                                    data-tooltip="Move Section"><i
                                                        class="material-icons">move_up</i></a></li>

                                            <li><a class="btn-floating tooltipped yellow darken-1" data-position="top"
                                                    data-tooltip="Duplicate Section" (click)="duplicateSection(si)"><i
                                                        class="material-icons">content_copy</i></a>
                                            </li>
                                            <li><a class="btn-floating tooltipped green" data-position="top"
                                                    data-tooltip="New Section" (click)="onAddSection()"><i
                                                        class="material-icons">add</i></a></li>
                                        </ul>
                                    </div>

                                    <div class="row card white">
                                        <h6 class="col s12 center-align bold-800">Section {{si+1}} of
                                            {{sectionControls.controls.length}}</h6>

                                        <div class="input-field col s12">
                                            <div class="label-input">Title<span class="red-text">*</span></div>
                                            <input id="title" type="text" class="validate" formControlName="title"
                                                placeholder="Title" autocomplete="off"
                                                style="text-transform: capitalize;">
                                        </div>

                                        <div class="input-field col s12">
                                            <div class="label-input">Description</div>
                                            <textarea id="statement" class="validate" rows="3"
                                                style="height: 150px; text-transform: capitalize;" type="text"
                                                formControlName="statement" placeholder="Description"></textarea>
                                        </div>

                                        <div class="input-field col s12">
                                            <i class="material-icons prefix black-text">storage</i>
                                            <select formControlName="data" class="validate" id="icon">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let data of tables" [value]="data"> {{data}}</option>
                                            </select>
                                            <label class="label-input">Get Data From</label>

                                        </div>

                                        <div class="input-field col s12">
                                            <div class="label-input">Accept Multiple Values</div>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input name="multipleEntry" type="radio" class="with-gap"
                                                        formControlName="multipleEntry" [value]="true" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input name="multipleEntry" type="radio" class="with-gap"
                                                        formControlName="multipleEntry" [value]="false" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <!------------------------------------------fields--------------------------------------------------->
                                    <div [sortablejs]="getSectionFields(si).controls" [sortablejsOptions]="options">
                                        <div class="row" formArrayName="fields">
                                            <div class="col s12 card"
                                                *ngFor="let field of getSectionFields(si).controls; let fi=index">

                                                <div [formGroupName]="fi" style="margin-top: 20px;">

                                                    <div class="row col s12">

                                                        <i class="small material-icons blue-text right"
                                                            *ngIf="!showFieldDetail[fi]" (click)="onShowFieldDetail(fi)"
                                                            style="cursor: pointer;">expand_more</i>

                                                        <i class="small material-icons blue-text right"
                                                            *ngIf="showFieldDetail[fi]" (click)="onShowFieldDetail(fi)"
                                                            style="cursor: pointer; ">expand_less</i>

                                                        <i class="small material-icons red-text right"
                                                            (click)="deleteField(si, fi)">delete</i>

                                                    </div>

                                                    <div class="input-field col s12 ">
                                                        <div class="label-input">Label<span class="red-text">*</span>
                                                        </div>
                                                        <input id="label" type="text" class="validate"
                                                            formControlName="label" placeholder="Untitled Field"
                                                            autocomplete="off" style="text-transform: capitalize;">
                                                    </div>

                                                    <div *ngIf="showFieldDetail[fi]">
                                                        <div class="input-field col s12 m6">
                                                            <div class="label-input">default_value</div>
                                                            <input id="default_value" type="text"
                                                                formControlName="default_value" class="validate" placeholder="default_value"
                                                                autocomplete="off" style="text-transform: capitalize;">
                                                        </div>

                                                        <div class="input-field col s12 m6">
                                                            <div class="label-input">Icon</div>
                                                            <select formControlName="icon"
                                                                class="validate browser-default" id="icon"
                                                                (change)="iconSelected(fi)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let icon of icons" [value]="icon">
                                                                    <span>{{icon}}<i
                                                                            class="material-icons left teat-text">{{icon}}</i></span>
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div class="input-field col s12">
                                                            <i class="material-icons prefix black-text">list</i>
                                                            <select formControlName="field_type" class="validate"
                                                                id="field_type"
                                                                (change)="fieldTypeSelected($event.target.value,si,fi)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let type of fieldTypes"
                                                                    [value]="type.value">
                                                                    {{type.name}}
                                                                </option>
                                                            </select>
                                                            <label class="label-input">Field Type<span
                                                                    class="red-text">*</span></label>
                                                        </div>

                                                        <div
                                                            *ngIf="fieldType == 'Select' || fieldType == 'Radio' || fieldType == 'Checkbox'">
                                                            <p>field type selected</p>
                                                            <div class="row col s12" formArrayName="options">
                                                                <p>Please enter options for the dropdown</p>
                                                                <div class="row col s12"
                                                                    *ngFor="let option of getOptions(si, fi)?.controls; let oi=index">
                                                                    <div class="input-field col s10">
                                                                        <input [id]="oi" [formControlName]="oi"
                                                                            type="text" class="validate">
                                                                        <label [for]="oi">Value</label>
                                                                    </div>
                                                                    <i *ngIf="oi == 0"
                                                                        class="small material-icons right prefix blue-text"
                                                                        (click)="addOption(si, fi)">add_box</i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="fieldType == 'Scale'" formGroupName="scales">
                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix green-text">linear_scale</i>
                                                                <select formControlName="start" class="validate">
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                </select>
                                                                <label for="start">Start At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix green-text">label</i>
                                                                <input id="startLabel" type="text"
                                                                    formControlName='startLabel' class="validate" />
                                                                <label for="startLabel">Label</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix blue-text">linear_scale</i>
                                                                <select formControlName="end" class="validate">
                                                                    <option *ngFor="let s of scales" [value]="s">{{s}}
                                                                    </option>
                                                                </select>
                                                                <label for="end">End At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix blue-text">label</i>
                                                                <input id="endLabel" type="text"
                                                                    formControlName='endLabel' class="validate" />
                                                                <label for="endLabel">Label</label>
                                                            </div>
                                                        </div>

                                                        <div class="input-field col s12">
                                                            <i class="material-icons prefix black-text">feed</i>
                                                            <select formControlName="data_type" class="validate"
                                                                id="data_type"
                                                                (change)="dataTypeSelected($event.target.value, f)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let type of dataTypes" [value]="type">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                            <label class="label-input">Data Type<span
                                                                    class="red-text">*</span></label>
                                                        </div>


                                                        <div class="row input-field col s12">
                                                            <div class="row col s12">
                                                                <label class="col s6">
                                                                    <input type="radio" [value]="true" class="with-gap"
                                                                        formControlName="required" />
                                                                    <span>Required</span>
                                                                </label>
                                                                <label class="col s6">
                                                                    <input type="radio" [value]="false" class="with-gap"
                                                                        formControlName="required" />
                                                                    <span>Optional</span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngIf="field.multipleEntry">
                                                        <button type="button" style="margin-bottom: 15px;">
                                                            <i
                                                                class="material-icons right black-text">add</i><strong>Add</strong>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12 card-action">
                                <button type="button"
                                    class="col l4 m5 s12 left btn waves-effect blue lighten-4 black-text"
                                    style="margin-bottom: 15px;" (click)="onAddField()">
                                    <i class="material-icons right black-text">add</i><strong> Add Field</strong>
                                </button>

                                <button *ngIf="currentSection == sectionControls.controls.length-1" type="button"
                                    style="margin-bottom: 15px;"
                                    class="col l4 m5 s12 right btn waves-effect green lighten-4 black-text"
                                    (click)="onAddSection()">
                                    <i class="material-icons right black-text">library_add</i><strong> New
                                        Section</strong>
                                    <!-- [disabled]="!appDetail.get('sections').controls[si].valid" -->
                                </button>

                                <button *ngIf="currentSection < sectionControls.controls.length-1" type="button"
                                    (click)="nextSection()" style="margin-bottom: 15px;"
                                    class="col l4 m5 s12 right btn waves-effect green lighten-4 black-text">
                                    <i class="material-icons right black-text">navigate_next</i><strong> Next
                                        Section</strong>
                                </button>
                            </div>

                            <div class="row col s12">
                                <div class="row center-align" *ngIf="sectionControls.controls.length > 1">
                                    <ul class="pagination">
                                        <li *ngIf="currentSection > 0" class="waves-effect" (click)="previousSection()">
                                            <a><i class="material-icons">chevron_left</i></a>
                                        </li>
                                        <li *ngFor="let section of sectionControls.controls;let i=index"
                                            (click)="goto(i)" [ngClass]="{'active': (currentSection == i)}">
                                            <a>{{i+1}}</a>
                                        </li>

                                        <li *ngIf="currentSection < sectionControls.controls.length-1"
                                            class="waves-effect" (click)="nextSection()"><a><i
                                                    class="material-icons">chevron_right</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      
                        <div class="row">
                            <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green">
                                <i class="material-icons right">save</i> Update Application
                            </button>

                            <button class="col s12 btn wave-effect" (click)="back()" type="button">
                                <i class="material-icons left">arrow_back</i>Back To Previous Page
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row card white" *ngIf="step == 3">
                <div class="card-content col l11 s12">
                    <div class="row center">
                        <i style="font-size: 100px;" class="material-icons green-text">
                            check_circle
                        </i>
                        <h5 class="green-text"> <strong>Application Successfully Updated!</strong></h5>
                        <br />

                        <div class="row col s12" style="margin-top: 25px;">

                            <button type="button" [routerLink]="['/admin/applications/preview', model.name]"
                                class="col s12 btn waves-effect green white-text" style="margin-bottom: 15px;">
                                <i class="material-icons right white-text">pageview</i><strong>Preview
                                    Application</strong>
                            </button>

                            <button type="button" (click)="editApp()" style="margin-bottom: 15px;"
                                class="col s12 right btn waves-effect blue" style="margin-bottom: 15px;">
                                <i class="material-icons right white-text">edit</i><strong>Edit Application</strong>
                            </button>

                            <button [ngClass]="'col s12 btn waves-effect ' + themeColor"
                                (click)="backToPreviousePage()">
                                <i class="material-icons left">home</i> Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </main>
    </div>
</div>