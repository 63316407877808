<div class="row">
    <div class="col s12 ">
        <div class="body-h">

            <div class="header-setting b-m-2 ">
                <div class="col l10 m10 s12">
                    <h4 *ngIf="!editName">
                        <span *ngIf="sectionName">{{sectionName}}</span>
                        <span *ngIf="!sectionName">Governance</span>
                        <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
                    </h4>
                    <div class="input-field" *ngIf="editName">
                        <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
                        <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}" (click)="saveName()">Save</button>
                    </div>
                    <p class="col s12 bold-300 sub-title-section3">This is where you can add {{chiefTitle}} and {{councillorTitle}}
                        and manage thier profile. You can add, update, and maintain information related to the {{chiefTitle}} and
                        {{councillorTitle}}' profiles, ensuring their accurate representation</p>
                </div>
            </div>

            <main class="StickyContent ">
                <div class="row">
                    <div class="col s12 m6 l5">
                        <div class="row card-panel white darken-2">
                            <div class="col s8">
                                <h5 *ngIf="!editChief">
                                    <span *ngIf="chiefTitle">{{chiefTitle}}</span>
                                    <span *ngIf="!chiefTitle">Chief</span>
                                    <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}"
                                        (click)="editTitle('chief')">edit</i>
                                </h5>
                                <div class="input-field" *ngIf="editChief">
                                    <input type="text" [(ngModel)]="chiefTitle" class="left col s6 validate"
                                        autocomplete="off" />
                                    <button type="button" class="btn ll-m-5"
                                        [ngStyle]="{'background-color': themeColor}"
                                        (click)="saveTitle('chief')">Save</button>
                                </div>
                                <!-- <h5 class="title-section3">{{chiefTitle}} </h5> -->
                                <!-- <i class="material-icons" [ngStyle]="{'color': themeColor}" (click)="editTitle('chief')">edit</i> -->
                            </div>
                            <div class="col s1 offset-s1">
                                <button class="btn add-button1 white " title=" Add Chief" (click)="gotoAddChief()"
                                    *ngIf="chiefs?.length === 0" [ngStyle]="{'color': themeColor}">
                                    <i class="material-icons right">add_circle</i> Add {{chiefTitle}}
                                </button>
                            </div>

                            <table class="centered striped">
                                <thead>
                                    <tr>
                                        <th>{{chiefTitle}} Name</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="chiefs?.length > 0">
                                    <tr class="chief-name" *ngFor="let chief of chiefs" (click)="editChiefData($event)">
                                        <td><strong class="blue-text text-darken-2 truncate">{{ (chief.displayName) ?
                                                chief.displayName
                                                : chief.firstName + ' ' + chief.lastName }} </strong></td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="chiefs?.length == 0">
                                    <tr>
                                        <td class="no-data-available red-text"><strong> {{chiefTitle}} Not Added Yet. </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="section"></div>
                        </div>
                    </div>

                    <div class="col s12 m6 l5 offset-l1">
                        <div class="row card-panel white darken-2">
                            <div class="col s8">
                                <h5 *ngIf="!editCouncillor">
                                    <span *ngIf="councillorTitle">{{councillorTitle}}</span>
                                    <span *ngIf="!councillorTitle">Councillor</span>
                                    <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}"
                                        (click)="editTitle('councillor')">edit</i>
                                </h5>
                                <div class="input-field" *ngIf="editCouncillor">
                                    <input type="text" [(ngModel)]="councillorTitle" class="left col s6 validate"
                                        autocomplete="off" />
                                    <button type="button" class="btn ll-m-5"
                                        [ngStyle]="{'background-color': themeColor}" (click)="saveTitle()">Save</button>
                                </div>
                                <!-- <h5 class="title-section3">{{councillorTitle}} </h5> -->
                            </div>

                            <div class="col s3 offset-s1">
                                <button class="btn add-button1 white " title=" Add Councillor"
                                    (click)="gotoAddCouncillor()" [ngStyle]="{'color': themeColor}">
                                    <i class="material-icons">add_circle</i> Add {{councillorTitle}}
                                </button>
                            </div>

                            <table class="centered striped">
                                <thead>
                                    <tr>
                                        <th>{{councillorTitle}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="councillors?.length > 0">
                                    <tr class="councillor-name" *ngFor="let councillor of councillors"
                                        (click)="editCouncillorData($event, councillor.applicantID)">
                                        <td><strong class="blue-text text-darken-2 truncate">{{ (councillor.displayName)
                                                ?
                                                councillor.displayName : councillor.firstName + ' ' +
                                                councillor.lastName }}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="councillors?.length == 0">
                                    <tr>
                                        <td class="no-data-available red-text"><strong> No {{councillorTitle}} Found In The
                                                Database.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="section"></div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    </div>
</div>