<div class="col s12">
  <div class="body-h">
    <div class="header-setting">
      <h4>Registration Requests</h4>
    </div>

    <main class="StickyContent white ">
      <div class="row">
        <div class="col s12 m12 l10" *ngIf="!showDetail && reqFiters">
          <div class="row">
            <button class="btn-flat right blue-text grey lighten-3" (click)="refreshPage()"><i
                class="material-icons right">
                refresh
              </i><strong>Refresh</strong></button>
          </div>

          <div *ngIf="!showInvited">
            <div class="row card-panel white darken-2" *ngIf="requests && requests.length > 0"
              style="min-width: fit-content;">
              <h5>
                <span *ngIf="reqFiters.pending">New Requests</span>
                <span *ngIf="reqFiters.declined">Declined Requests</span>
              </h5>
              <table datatable [dtOptions]="dtOptions" class="row-border hover">
                <thead>
                  <tr>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Request Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of requests">
                    <td *ngIf="!SQLDB" style="cursor: pointer;"><a (click)="showDetails(m.id)"> {{ m?.lastName }} </a>
                    </td>
                    <td *ngIf="SQLDB" style="cursor: pointer;"><a (click)="showDetails(m.ID)"> {{ m?.Lname }} </a></td>
                    <td *ngIf="!SQLDB" style="cursor: pointer;"><a (click)="showDetails(m.id)"> {{m?.firstName}} </a>
                    </td>
                    <td *ngIf="SQLDB" style="cursor: pointer;"><a (click)="showDetails(m.ID)"> {{m?.Fname}} </a></td>
                    <td *ngIf="!SQLDB">{{m?.requestDate.toDate() | date: 'dd/MMM/yyyy'}} </td>
                    <td *ngIf="SQLDB">{{m?.datetime | date: 'dd/MMM/yyyy'}} </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="requests?.length == 0 && registrationRequests?.length > 0"
              class="row card-panel blue lighten-3">
              <h6 class="white-text"> <strong>
                  No Request found with these status.</strong>
              </h6>
            </div>

            <div *ngIf="!registrationRequests || registrationRequests?.length === 0"
              class="row card-panel red lighten-3">
              <h6 class="white-text"> <strong>
                  No Registration Request Found In The Database.</strong>
              </h6>
            </div>
          </div>

          <div *ngIf="showInvited">
            <div class="row card-panel white darken-2" *ngIf="invitations && invitations.length > 0">
              <h5>Invited Members</h5>
              <table datatable [dtOptions]="dtOptions" class="row-border hover">
                <thead>
                  <tr>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>DOB</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of invitations">
                    <td style="cursor: pointer;"><a (click)="showDetails(m.applicantID)"> {{ m?.LastName ? m.LastName :
                        '' }} </a></td>
                    <td style="cursor: pointer;"><a (click)="showDetails(m.applicantID)"> {{m?.GivenName ? m.GivenName:
                        ''}} </a></td>
                    <td>{{m?.DOB | date: 'dd/MMM/yyyy'}}</td>
                    <td class="center"> <span *ngIf="m.Email">{{m.Email}}</span>
                      <span *ngIf="!m.Email">-</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="!invitations || invitations?.length === 0" class="row card-panel red lighten-3">
              <h6 class="white-text"> <strong>
                  No Invited Members Found In The Database.</strong>
              </h6>
            </div>
          </div>

          <div class="section"></div>
<!-- 
          <button class="col s12 white" style="border: 1px solid;"
            [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
            [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">house</i>Back To Previous Page</button> -->
        </div>

        <div class="col s10 m12 l10" *ngIf="showDetail">
          <span>
            <app-request-detail [id]="id" [SQLDB]="SQLDB" [invited]="showInvited" (back)="back()"></app-request-detail>
          </span>
        </div>
      </div>
    </main>
  </div>