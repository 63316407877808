<div class="col s12">
    <div class=" body-h">

        <div class="header-setting b-m-0">
            <div class="col l9 m9 s12">
                <h4 class="{{ sectionName ? '' : 'app-menu-title' }}">{{ sectionTitle }}</h4>
                <!-- <p class="bold-300 sub-title-section3">This is the menu that the general public will have
                    access to on your mobile app</p> -->
            </div>
            <div class="col s12 l3 m3">
                <div class="input-field">
                    <select class="browser-default" [(ngModel)]="selectedMenu" (change)="onMenuChange()">
                        <option value="" disabled selected>Type Menu:</option>
                        <option value="admin">Admin Menu</option>
                        <option value="member">Member Menu</option>
                        <option value="public">Public Menu</option>
                        <option value="all">All Menu</option>
                    </select>
                </div>
            </div>


        </div>

        <div class="row second-menu">
            <div class="col l3 s7 p-l-4" style="align-items: center;">
                <a class="sub-sections-menu edit-btn bold-500 b-n-1 btn white  modal-trigger" (click)="editCard();"
                    *ngIf="selectedMenu === 'public' "
                    style="color: green;  display: flex; align-items: center;  justify-content: center;">
                    <span class="material-symbols-outlined p-r-1"
                        style="color: green; display: flex; align-items: center;">
                        palette
                    </span>
                    Edit the Cards
                </a>
            </div>
            <div class="col l6"></div>
            <div class="col l2 s5" style="padding:0px 30px;">
                <div [ngStyle]="{'background-color': isActiveReorder ? themeColor : 'white'}"
                    class="col l6 s6 right-tab" (click)="onReorderClick()">
                    <span [ngStyle]="{'color': isActiveReorder ? 'white' : 'black'}" class="material-symbols-outlined">
                        reorder
                    </span>
                </div>
                <div [ngStyle]="{'background-color': isActiveCozy ? themeColor : 'white'}" class="col l6 s6 left-tab"
                    (click)="onViewCozyClick()">
                    <span [ngStyle]="{'color': isActiveCozy ? 'white' : 'black'}" class="material-symbols-outlined">
                        dashboard
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedMenu === 'all'">
            <div class="search-container col l4 right">
                <input type="text" [(ngModel)]="searchQuery" placeholder="Search by Display Name"
                    (input)="filterMenus()">
            </div>
        </div>


        <!-- ========================================= ADMIN ============================================== -->
        <main class="StickyContent " *ngIf="selectedMenu === 'admin' || selectedMenu === 'all'">
            <div class="col l11 m12 s12">


                <div [class]="cardV ? 'col s12 m6 l4' : ''" *ngFor="let section of menu; let i=index;">


                    <!-- =================== card view ================ -->
                    <div class="card-panel" *ngIf="cardV">
                        <div class="row admin-header">
                            <div class="col s7 p-b-1">
                                <a class="" *ngIf="selectedMenu === 'all'">
                                    <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                        Admin
                                    </span>
                                </a>
                            </div>
                            <div class="col s5 admin-move-section right">
                                <div class="move-btn" *ngIf="selectedMenu !=='all' ">
                                    <button class="btn white"
                                        [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                                        (click)="moveAdminSection(section, -1);"> <i
                                            class="material-icons-outlined black-text">chevron_left</i></button>
                                    <button class="btn white"
                                        [disabled]="!section.isEnabled || section.name === 'Logout' ||  section.index >= section.length - 2"
                                        (click)="moveAdminSection(section, 1);"> <i
                                            class="material-icons-outlined black-text">chevron_right</i></button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s3 ">
                                <i class="material-symbols-outlined white-text bold-300"
                                    [ngStyle]="{'background-color':  section.isEnabled ? themeColor : 'gray'}"
                                    style="font-size: 30px; border-radius:10px;  padding: 10px;">{{section.icon}}</i>
                            </div>
                            <div class="col s9">
                                <input id="" type="text" autocomplete="off" [(ngModel)]="section.displayName"
                                    class="validate" />
                            </div>
                        </div>
                        <div class="row p-t-1" style="border-top:1px solid rgb(226, 226, 226) ;">
                            <div class="col s9">
                                <a class="" (click)="openModal(i)">

                                    <span class="sub-sections-menu"
                                        [ngStyle]="{'color': !section.submenus || section.submenus.length === 0 ? 'grey' : themeColor}">
                                        {{ !section.submenus ? 'No Sub-Section' : 'Sub-Sections(' +
                                        (section.submenus?.length || 'NULL') + ')' }}
                                    </span>
                                </a>
                            </div>
                            <div class="col s3">
                                <div class="switch color">
                                    <label>
                                        <input type="checkbox" [checked]="section.isEnabled"
                                            (change)="visibilityChanged(section)">
                                        <span class="lever"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ======================list view =================== -->

                    <div *ngIf="!cardV">
                        <div class="collapsibles-header">
                            <table class="menu-items">
                                <tbody>
                                    <tr
                                        [ngClass]="{'disabled': !section.isEnabled || section.name === 'Logout', 'notCollapsible': !section.submenus || section.submenus.length === 0}">

                                        <td class="hide-on-small-only">
                                            <i class="material-symbols-outlined section-icons  white-text bold-300"
                                                [ngStyle]="{'background-color':  section.isEnabled ? themeColor : 'gray'}">{{section.icon}}</i>
                                        </td>

                                        <td>
                                            <div class="input-field">
                                                <input id="" type="text" autocomplete="off" class="validate"
                                                    [(ngModel)]="section.displayName" />
                                            </div>
                                        </td>

                                        <td>
                                            <div class="switch ">
                                                <label>
                                                    <input type="checkbox" [checked]="section.isEnabled" dis
                                                        (change)="visibilityChanged(section)">
                                                    <span class="lever"></span>
                                                    <span class="hide-on-small-only bold-700 black-text">{{
                                                        section.isEnabled ? 'SHOW' : 'HIDE' }}</span>

                                                </label>
                                            </div>
                                        </td>

                                        <td class="move-btn admin-move-section" *ngIf="selectedMenu !=='all' ">
                                            <button class="btn white"
                                                [disabled]="!section.isEnabled || section.name === 'Logout' ||  section.index >= section.length - 2"
                                                (click)="moveAdminSection(section, 1);"> <i
                                                    class="material-icons-outlined black-text">expand_more</i></button>
                                            <button class="btn white"
                                                [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                                                (click)="moveAdminSection(section, -1);"> <i
                                                    class="material-icons-outlined black-text">expand_less</i></button>
                                        </td>

                                        <td>
                                            <a class="" (click)="openModal(i)">
                                                <span class="sub-sections-menu"
                                                    [ngStyle]="{'color': !section.submenus || section.submenus.length === 0 ? 'grey' : themeColor}">
                                                    {{ !section.submenus ? 'No Sub-Section' : 'Sub-Sections(' +
                                                    (section.submenus?.length || 'NULL') + ')' }}
                                                </span>
                                            </a>
                                        </td>
                                        <td>
                                            <a class="" *ngIf="selectedMenu === 'all'">
                                                <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                                    Admin
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- ============= admin modal =============== -->
                    <div id="modal{{i}}" class="modal bottom-sheet modal-fixed-footer"
                        *ngIf="section.submenus && section.submenus.length > 0">
                        <div class="modal-content modal-sub">
                            <div class="admin-modal-header">
                                <h4 class="col s11">Admin Sub-Menu</h4>
                                <span class="col s1 modal-close material-symbols-outlined"
                                    [ngStyle]="{'color': themeColor}" style="cursor: pointer;">close</span>
                            </div>

                            <div class="sub-menu-items" *ngFor="let menu of section.submenus">
                                <div class="col s12">
                                    <table class="menu-items">
                                        <tbody>
                                            <tr class="table-container">

                                                <td>
                                                    <i class="material-icons-outlined bold-100"
                                                        [ngStyle]="{'color': themeColor}">{{menu.icon}}</i>
                                                </td>
                                                <td>
                                                    <div class="input-field">
                                                        <input id="" type="text" autocomplete="off" class="validate"
                                                            [(ngModel)]="menu.displayName" />
                                                    </div>
                                                </td>

                                                <td class="p-r-2">
                                                    <div class="switch ">
                                                        <label>
                                                            <input type="checkbox" [checked]="menu.isEnabled" dis
                                                                (change)="visibilityChanged(menu)">
                                                            <span class="lever"></span>
                                                            <span class="hide-on-small-only bold-700 black-text">{{
                                                                menu.isEnabled ? 'SHOW' : 'HIDE' }}</span>
                                                        </label>
                                                    </div>
                                                </td>

                                                <td class="move-btn">
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled ||  menu.index >= section.submenus.length - 1"
                                                        (click)="moveAdminSubmenu(i, menu, 1);"> <i
                                                            class="material-icons-outlined black-text">expand_more</i></button>
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled || menu.index === 0"
                                                        (click)="moveAdminSubmenu(i, menu, -1);"> <i
                                                            class="material-icons-outlined black-text">expand_less</i></button>



                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button class="save-button btn-small col s3 l2" [ngStyle]="{'background-color': themeColor}"
                                (click)="saveChanges();">Save</button>
                            <button class="modal-close cancel-button btn-small col s3 l2"
                                [ngStyle]="{'color': themeColor}">Cancel</button>
                        </div>
                    </div>
                </div>


            </div>

        </main>
        <!-- ========================================= MEMBER ============================================== -->
        <main class="StickyContent" *ngIf="selectedMenu === 'member' || selectedMenu === 'all'">
            <div class="row col l11 m11 s12">
                <div [class]="cardV ? 'col s12 m6 l4' : ''" *ngFor="let sections of menus; let i=index;">
                    <!-- =================== card view ================ -->

                    <div class="card-panel" *ngIf="cardV">
                        <div class="row admin-header">
                            <div class="col s7">
                                <a class="" *ngIf="selectedMenu === 'all'">
                                    <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                        Member
                                    </span>
                                </a>
                            </div>
                            <div class="col s5 admin-move-section right">
                                <div class="move-btn" *ngIf="selectedMenu !=='all' ">
                                    <button class="btn white"
                                        [disabled]="!sections.isEnabled || sections.name === 'Logout' || sections.index === 0"
                                        (click)="moveMemberSection(sections, -1);"> <i
                                            class="material-icons-outlined black-text">chevron_left</i></button>
                                    <button class="btn white"
                                        [disabled]="!sections.isEnabled || sections.name === 'Logout' ||  sections.index >= sections.length - 2"
                                        (click)="moveMemberSection(sections, 1);"> <i
                                            class="material-icons-outlined black-text">chevron_right</i></button>
                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col s3 ">
                                <i class="material-symbols-outlined white-text bold-300"
                                    [ngStyle]="{'background-color':  sections.isEnabled ? themeColor : 'gray'}"
                                    style="font-size: 30px; border-radius:10px;  padding: 10px;">{{sections.icon}}</i>
                            </div>

                            <div class="col s9">
                                <input id="" type="text" autocomplete="off" [(ngModel)]="sections.displayName"
                                    class="validate" />
                            </div>
                        </div>
                        <div class="row p-t-1" style="border-top:1px solid rgb(226, 226, 226) ;">
                            <div class="col s9">
                                <a class="" (click)="openModal(i)">
                                    <span class="sub-sections-menu"
                                        [ngStyle]="{'color': sections.submenus.length === 0 ? 'grey' : themeColor}">
                                        {{ sections.submenus.length === 0 ? 'No Sub-Section' : 'Sub-Sections(' +
                                        sections.submenus.length + ')' }}
                                    </span>
                                </a>
                            </div>
                            <div class="col s3">
                                <div class="switch color">
                                    <label>
                                        <input type="checkbox" [checked]="sections.isEnabled"
                                            (change)="visibilityChanged(sections)">
                                        <span class="lever"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ======================list view =================== -->
                    <div *ngIf="!cardV">
                        <div class="collapsibles-header">
                            <table class="menu-items">
                                <tbody>
                                    <tr
                                        [ngClass]="{'disabled': !sections.isEnabled || sections.name === 'Logout', 'notCollapsible': !sections.submenus || sections.submenus.length === 0}">

                                        <td class="hide-on-small-only">
                                            <i class="material-symbols-outlined section-icons  white-text bold-300"
                                                [ngStyle]="{'background-color':  sections.isEnabled ? themeColor : 'gray'}">{{sections.icon}}</i>
                                        </td>

                                        <td>
                                            <div class="input-field">
                                                <input id="" type="text" autocomplete="off" class="validate"
                                                    [(ngModel)]="sections.displayName" />
                                            </div>
                                        </td>

                                        <td>
                                            <div class="switch ">
                                                <label>
                                                    <input type="checkbox" [checked]="sections.isEnabled" dis
                                                        (change)="visibilityChanged(section)">
                                                    <span class="lever"></span>
                                                    <span class="hide-on-small-only bold-700 black-text">{{
                                                        sections.isEnabled ? 'SHOW' : 'HIDE' }}</span>

                                                </label>
                                            </div>
                                        </td>

                                        <td class="move-btn admin-move-section " *ngIf="selectedMenu !== 'all' ">
                                            <button class="btn white"
                                                [disabled]="!sections.isEnabled || sections.name === 'Logout' ||  sections.index >= sections.length - 2"
                                                (click)="moveMemberSection(sections, 1);"> <i
                                                    class="material-icons-outlined black-text">expand_more</i></button>
                                            <button class="btn white"
                                                [disabled]="!sections.isEnabled || sections.name === 'Logout' || sections.index === 0"
                                                (click)="moveMemberSection(sections, -1);"> <i
                                                    class="material-icons-outlined black-text">expand_less</i></button>
                                        </td>

                                        <td>
                                            <a class="" (click)="openModal(i)">
                                                <span class="sub-sections-menu"
                                                    [ngStyle]="{'color': sections.submenus.length === 0 ? 'grey' : themeColor}">
                                                    {{ sections.submenus.length === 0 ? 'No Sub-Section' :
                                                    'Sub-Sections(' +
                                                    sections.submenus.length + ')' }}
                                                </span>
                                            </a>
                                        </td>
                                        <td>
                                            <a class="" *ngIf="selectedMenu === 'all'">
                                                <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                                    Member
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- ============= memeber modal =============== -->
                    <div id="modal{{i}}" class="modal bottom-sheet modal-fixed-footer"
                        *ngIf="sections.submenus && sections.submenus.length > 0">
                        <div class="modal-content modal-sub">
                            <div class="member-modal-header">
                                <h4 class="col s11">Member Sub-Menu</h4>
                                <span class="col s1 modal-close material-symbols-outlined"
                                    [ngStyle]="{'color': themeColor}" style="cursor: pointer;">close</span>
                            </div>
                            <div class="sub-menu-items" *ngFor="let menu of sections.submenus">
                                <div class="col s12">
                                    <table class="menu-items">
                                        <tbody>
                                            <tr class="table-container">
                                                <td>
                                                    <i class="material-icons-outlined bold-100"
                                                        [ngStyle]="{'color': themeColor}">{{menu.icon}}</i>
                                                </td>
                                                <td>
                                                    <div class="input-field">
                                                        <input id="" type="text" autocomplete="off" class="validate"
                                                            [(ngModel)]="menu.displayName" />
                                                    </div>
                                                </td>
                                                <td class="p-r-2">
                                                    <div class="switch">
                                                        <label>
                                                            <input type="checkbox" [checked]="menu.isEnabled"
                                                                (change)="visibilityChanged(menu)">
                                                            <span class="lever"></span>
                                                            <span class="hide-on-small-only bold-700 black-text">{{
                                                                menu.isEnabled ? 'SHOW' : 'HIDE'
                                                                }}</span>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td class="move-btn">
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled || menu.index >= sections.submenus.length - 1"
                                                        (click)="moveSubmenu(i, menu, 1);">
                                                        <i class="material-icons-outlined black-text">expand_more</i>
                                                    </button>
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled || menu.index === 0"
                                                        (click)="moveSubmenu(i, menu, -1);">
                                                        <i class="material-icons-outlined black-text">expand_less</i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="save-button btn-small col s3 l2" [ngStyle]="{'background-color': themeColor}"
                                (click)="saveChanges();">Save</button>
                            <button class="modal-close cancel-button btn-small col s3 l2"
                                [ngStyle]="{'color': themeColor}">Cancel</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="row ">
                    <button class="col s12 l3 m3 offset-l9 offset-m9  btn save-button p-m-3"
                        style="margin-bottom: 15px;" (click)="saveChanges();">Save
                        Changes</button>
                </div> -->
            </div>

        </main>
        <!-- ========================================= PUBLIC ============================================== -->

        <main class="StickyContent" *ngIf="selectedMenu === 'public' || selectedMenu === 'all'">
            <div class="col l11 m11 s12">
                <div [class]="cardV ? 'col s12 m6 l4' : ''" *ngFor="let sectionL of pageSections.sections">

                    <div class="card-panel" *ngIf="cardV">
                        <div class="row admin-header">
                            <div class="col l7 s8">
                                <a class="" *ngIf="selectedMenu === 'all'">
                                    <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                        Public
                                    </span>
                                </a>
                            </div>
                            <div class="col l5 s4 admin-move-section right">
                                <div class="move-btn" *ngIf="selectedMenu !=='all' ">
                                    <button class="btn white"
                                        [disabled]="!sectionL.isEnabled || sectionL.name === 'Footer' || sectionL.index === 0"
                                        (click)="moveLandingSection(sectionL, -1);">
                                        <i
                                            class="material-icons-outlined black-text  hide-on-small-only">chevron_left</i>
                                        <i
                                            class="material-icons-outlined black-text  hide-on-med-and-up">expand_more</i>
                                    </button>
                                    <button class="btn white"
                                        [disabled]="!sectionL.isEnabled || sectionL.name === 'Footer' ||  sectionL.index >= sectionL.length - 2"
                                        (click)="moveLandingSection(sectionL, 1);">
                                        <i
                                            class="material-icons-outlined black-text  hide-on-small-only">chevron_right</i>
                                        <i
                                            class="material-icons-outlined black-text  hide-on-med-and-up">expand_less</i>

                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s3 ">
                                <i class="material-symbols-outlined white-text bold-300"
                                    [ngStyle]="{'background-color':  sectionL.isEnabled ? themeColor : 'gray'}"
                                    style="font-size: 30px; border-radius:10px;  padding: 10px;">{{sectionL.icon}}</i>
                            </div>
                            <div class="col s9">
                                <input id="" type="text" autocomplete="off" [(ngModel)]="sectionL.displayName"
                                    class="validate" />
                            </div>
                        </div>
                        <div class="row p-t-1" style="border-top:1px solid rgb(226, 226, 226) ;">
                            <div class="col s9">
                                <a class="" (click)="openPublicModal(sectionL)">
                                    <span class="sub-sections-menu" [ngStyle]="{'color':  themeColor}">
                                        Edit Section
                                    </span>
                                </a>
                            </div>
                            <div class="col s3">
                                <div class="switch color">
                                    <label>
                                        <input type="checkbox" [checked]="sectionL.isEnabled"
                                            (change)="visibilityChanged(sectionL)">
                                        <span class="lever"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!cardV">
                        <div class="collapsibles-header">
                            <table class="menu-items">
                                <tbody>
                                    <tr
                                        [ngClass]="{'disabled': !sectionL.isEnabled || sectionL.name === 'Logout', 'notCollapsible': !sectionL.submenus || sectionL.submenus.length === 0}">

                                        <td class="hide-on-small-only">
                                            <i class="material-symbols-outlined section-icons  white-text bold-300"
                                                [ngStyle]="{'background-color':  sectionL.isEnabled ? themeColor : 'gray'}">{{sectionL.icon}}</i>
                                        </td>

                                        <td>
                                            <div class="input-field">
                                                <input id="" type="text" autocomplete="off" class="validate"
                                                    [(ngModel)]="sectionL.displayName" />
                                            </div>
                                        </td>

                                        <td>
                                            <div class="switch ">
                                                <label>
                                                    <input type="checkbox" [checked]="sectionL.isEnabled" dis
                                                        (change)="visibilityChanged(section)">
                                                    <span class="lever"></span>
                                                    <span class="hide-on-small-only bold-700 black-text">{{
                                                        sectionL.isEnabled ? 'SHOW' : 'HIDE' }}</span>

                                                </label>
                                            </div>
                                        </td>

                                        <td class="move-btn admin-move-section" *ngIf="selectedMenu !=='all' ">
                                            <button class="btn white"
                                                [disabled]="!sectionL.isEnabled || sectionL.name === 'Logout' ||  sectionL.index >= sectionL.length - 2"
                                                (click)="moveMemberSection(sectionL, 1);"> <i
                                                    class="material-icons-outlined black-text">expand_more</i></button>
                                            <button class="btn white"
                                                [disabled]="!sectionL.isEnabled || sectionL.name === 'Logout' || sectionL.index === 0"
                                                (click)="moveMemberSection(sectionL, -1);"> <i
                                                    class="material-icons-outlined black-text">expand_less</i></button>
                                        </td>

                                        <td>
                                            <a class="" (click)="openPublicModal(sectionL)">
                                                <span class="sub-sections-menu" [ngStyle]="{'color':  themeColor}">
                                                    Edit Section
                                                </span>
                                            </a>
                                        </td>
                                        <td>
                                            <a class="" *ngIf="selectedMenu === 'all'">
                                                <span class="sub-sections-menu" [ngStyle]="{'color':  'green'}">
                                                    Public
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id="modal-Public" class="modal bottom-sheet modal-fixed-footer">
                        <div class="modal-content">
                            <div class="member-modal-header">
                                <h4 class="col s11 ">Public Menu</h4>
                                <span class="col s1 modal-close material-symbols-outlined center"
                                    (click)=" closeModal() " [ngStyle]="{'color': themeColor}"
                                    style="cursor: pointer;">close</span>
                            </div>

                            <div class="col l6 s12 right p-t-3">
                                <div class="switch">
                                    <label class="right">
                                        Edit List View
                                        <input type="checkbox" [checked]="pageSections?.cardView"
                                            (change)="sectionViewChanged()" />
                                        <span class="lever"></span>
                                        Edit Card View
                                    </label>
                                </div>
                            </div>
                            <div class="public-menu-modal" *ngIf="selectedSection">
                                <div class="col s12">
                                    <!-- ============= slider -->
                                    <ng-container *ngIf="selectedSection.name === 'Slider'">
                                        <div class="row p-t-3">
                                            <div class="">
                                                <div class="col s12" *ngIf="!sliderRef.options.length > 0">
                                                    <h5 class="bold-500 sub-title-section2 black-text">
                                                        No slides have been added yet. To start, create a new slide.
                                                    </h5>
                                                </div>

                                                <div class="row">
                                                    <div class="col s12 l4">
                                                        <div class="input-field">
                                                            <div class="label-input">
                                                                Interval between images (seconds)
                                                            </div>
                                                            <input id="interval" onkeypress="isNumberKey(event)"
                                                                type="number" min="1" class="validate"
                                                                [value]="intervalValue"
                                                                (input)="intervalChange($event.target.value)" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col s6 m6 l2"
                                                    *ngFor="let option of sliderRef.options; let i = index">
                                                    <div class="slider-upload" (click)="sliderClicked(i)">
                                                        <div class="slider-preview">
                                                            <div class="card">
                                                                <div class="card-image">
                                                                    <img [src]="option?.imgUrl"
                                                                        *ngIf="option?.type == 'Image'" />
                                                                    <video class="video-fluid" controls autoplay loop
                                                                        *ngIf="option.type == 'Video'">
                                                                        <source [src]="option?.imgUrl"
                                                                            type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="gallery-text">
                                                            <h3 class="sub-title-section">Click to edit.</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col s6 m6 l2 add-card">
                                                    <div class="card hoverable center-align tooltipped  slide-tooltip"
                                                        data-tooltip="Add Slider" data-position="top"
                                                        (click)="addSlider()">
                                                        <i class="small material-icons p-b-3 p-t-3">add</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel1" *ngIf="sliderRef.options.length > 0">
                                            <div [ngStyle]="{ 'background-color': backgroundColorSelected }">
                                                <h5 class="bold-600 sub-title-section2 black-text">Preview</h5>
                                                <div class="row">
                                                    <div class="col s12">
                                                        <app-landing-page-slider [interval]="interval"
                                                            [fullscreenEnabled]="false"
                                                            (slideRef)="sliderClicked($event)">
                                                        </app-landing-page-slider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- ================== modal ================= -->

                                    <div id="sliderModal" class=" modal bottom-sheet modal-fixed-footer"
                                        *ngIf="sliderSettings && sliderRef && sliderRef.options.length > 0 && sliderRef.options[slideRef] ">
                                        <div class="slider-modal-header">
                                            <div class="col s11 p-t-1">
                                                <h4 *ngIf="!editSlider">Add new slider</h4>
                                                <h4 *ngIf="editSlider">Edit slider</h4>
                                            </div>

                                            <div class="col s1 p-t-1">
                                                <span class="center modal-close material-icons-outlined"
                                                    (click)="closeSliderModal()">
                                                    close</span>
                                            </div>
                                        </div>
                                        <div class="modal-content" *ngIf="sliderRef.options[slideRef]">
                                            <div class="col s12 m6 modal-card">
                                                <div class="card">
                                                    <div class="center-align">
                                                        <div class="white">
                                                            <i class="material-symbols-outlined p-t-2"
                                                                *ngIf="!sliderRef.options[slideRef].imgUrl">add_photo_alternate</i>
                                                            <i class="material-symbols-outlined p-t-2"
                                                                *ngIf="sliderRef.options[slideRef].imgUrl">image</i>

                                                            <!-- ======== add image and update image Slider========= -->
                                                            <input name="file" type="file" accept="image/*, video/*"
                                                                id="fileField" required
                                                                (change)="handleFileInput($event.target.files, sliderRef.options[slideRef])" />
                                                            <h4 class="p-b-2"
                                                                *ngIf="!sliderRef.options[slideRef].imgUrl">
                                                                Drop your image/video here, or
                                                                <u class="green-text text-lighten-1">Browse</u>
                                                                <span class="red-text">*</span>
                                                            </h4>
                                                            <h4 class="p-b-2"
                                                                *ngIf="sliderRef.options[slideRef].imgUrl">
                                                                Drop your image/video here, or
                                                                <u class="green-text text-lighten-1">Browse</u><span
                                                                    class="red-text">*</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s12 m6">
                                                <div class="card hoverable center-align">
                                                    <div class="card-image">
                                                        <img [src]="sliderRef?.options[slideRef]?.imgUrl"
                                                            *ngIf="sliderRef?.options[slideRef]?.type == 'Image'" />
                                                        <video controls autoplay loop
                                                            *ngIf="sliderRef.options[slideRef].type == 'Video'"
                                                            width="200" height="150">
                                                            <source [src]="sliderRef.options[slideRef].imgUrl"
                                                                type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s12">
                                                <div class="label-input">Select Position</div>
                                                <div>
                                                    <ng-container
                                                        *ngFor="let option of sliderRef.options; let i = index">
                                                        <button class="btn waves-effect button-with-padding {{
                  sliderRef.options[slideRef].index === i ? 'green' : ''
                }}" (click)="positionChanged(i)">
                                                            {{ i + 1 }}
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div class="row col s12">
                                                <div class="input-field col s12 m12 l12">
                                                    <div class="label-input">Title</div>
                                                    <app-editor [title]="true"
                                                        [model]="sliderRef.options[slideRef].title" [styler]="false"
                                                        (onModelChange)="sliderTitleStyleChanged($event, slideRef)"
                                                        [type]="true" [showChatGpt]="false">
                                                    </app-editor>
                                                    <div class="white p-m-1 b-m-1 right">
                                                        <a class="p-r-1 chatgpt-tooltip tooltipped"
                                                            [ngStyle]="{ color: themeColor }" data-position="bottom"
                                                            data-tooltip="<p style='color: white;margin: 0px;'>Empower Your Creativity with Aritificail Intellignce Assist: Your Personal Content Assistant.</p>">&#63;
                                                        </a>
                                                        <a (click)="openChatGptModal(slideRef, 'title')"
                                                            class="white-text btn white-text"
                                                            [ngStyle]="{ 'background-color': themeColor }">
                                                            <i class="material-icons right">check</i>Use Artificial
                                                            Intelligence</a>
                                                    </div>
                                                </div>

                                                <div class="input-field col s12 m8 l8">
                                                    <div class="label-input">Text Position</div>
                                                    <select class="browser-default"
                                                        [(ngModel)]="sliderRef.options[slideRef].textPosition">
                                                        <option value="top-right">Top Right</option>
                                                        <option value="top-left">Top Left</option>
                                                        <option value="Center-k">Center</option>
                                                        <option value="bottom-right">Bottom Right</option>
                                                        <option value="bottom-left">Bottom Left</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal-footer">
                                            <button class="btn-small col s3 l2 save-button"
                                                [disabled]="!sliderRef.options[slideRef].imgUrl"
                                                (click)="saveSliderChanges(sliderRef.options[slideRef])"
                                                [ngClass]="'btn ' + buttonType"
                                                [ngStyle]="{ 'background-color': themeColor }">
                                                Save
                                            </button>

                                            <button class="btn-small col s3 l2 delete-button" *ngIf="editSlider"
                                                [ngClass]="'btn ' + buttonType"
                                                (click)="deleteSlider(sliderRef.options[slideRef])">
                                                Delete
                                            </button>
                                        </div>
                                    </div>

                                    <app-chat-gpt *ngIf="openChatGpt" [inputText]="inputText" [theInput]="selectedInput"
                                        (onCloseModal)="closeModalChatGptModal()"
                                        (onMessageSelect)="updateInputValue($event)">
                                    </app-chat-gpt>

                                    <div id="confirmationModal" class="modal modal_max" *ngIf="selectedSlider">
                                        <div class="title-nation p-l-2 p-t-2">
                                            <h4 class="col s11">Delete Slider</h4>
                                            <i class="material-icons right col s1" style="cursor: pointer"
                                                (click)="cancelDelete()">close</i>
                                        </div>

                                        <div class="col s12 modal-content">
                                            <div class="valign-wrapper card-panel red lighten-5 row col s12">
                                                <i class="material-icons left red-text"
                                                    style="font-size: 40px; font-weight: 100">
                                                    warning_amber
                                                </i>
                                                <div class="modal-delete">
                                                    <h6>You are about to delete a slider</h6>
                                                    <h6 class="red-text">
                                                        <strong>This will remove the image from the slider.</strong>
                                                    </h6>
                                                    <h6 class="red-text"><strong> Are You sure?</strong></h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal-footer col s10 push-s1">
                                            <div class="row col s12">
                                                <div class="col s11"></div>
                                                <div class="col s1"></div>
                                                <button class="modal-close btn-flat col s12 l2 left white-text red"
                                                    style="margin-bottom: 5px; font-weight: bold"
                                                    (click)="removeSlider()">
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- ========================================================= modal resizer ========================== -->
                                    <div id="imageCropperModal" class="modal modal-open bottom-sheet modal-fixed-footer"
                                        [style.display]="showImageCropperModal ? 'block' : 'none'" #imageCropperModal>
                                        <div class="modal-content">
                                            <div class="slider-modal-header">
                                                <h4 class="col s11">Edit your Image</h4>
                                                <span class="col s1 modal-close material-symbols-outlined"
                                                    [ngStyle]="{ color: themeColor }" style="cursor: pointer"
                                                    type="button" (click)="closeCroppingModal()">close</span>
                                            </div>

                                            <div class="row">
                                                <div class="col s12 img-cropper">
                                                    <div class="image-cropper-container">
                                                        <app-loading></app-loading>
                                                        <image-cropper *ngIf="!useBase64" class="ngx-ic-cropper"
                                                            [imageFile]="imageChangedEvent"
                                                            [imageChangedEvent]="imageChangedEvent"
                                                            [maintainAspectRatio]="true"
                                                            [containWithinAspectRatio]="containWithinAspectRatio"
                                                            [aspectRatio]="21 / 12" [onlyScaleDown]="true"
                                                            [roundCropper]="false" [canvasRotation]="canvasRotation"
                                                            [transform]="transform" [alignImage]="'left'"
                                                            [style.display]="showCropper ? null : 'none'"
                                                            [allowMoveImage]="true"
                                                            (imageCropped)="imageCropped($event)"
                                                            (imageLoaded)="imageLoaded()"
                                                            (cropperReady)="cropperReady($event)"
                                                            (loadImageFailed)="loadImageFailed()">
                                                        </image-cropper>
                                                        <image-cropper *ngIf="useBase64" class="ngx-ic-cropper"
                                                            [imageFile]="imageChangedEvent"
                                                            [imageBase64]="imageChangedEvent"
                                                            [maintainAspectRatio]="true"
                                                            [containWithinAspectRatio]="containWithinAspectRatio"
                                                            [aspectRatio]="21 / 12" [onlyScaleDown]="true"
                                                            [roundCropper]="false" [canvasRotation]="canvasRotation"
                                                            [transform]="transform" [alignImage]="'left'"
                                                            [style.display]="showCropper ? null : 'none'"
                                                            [allowMoveImage]="true"
                                                            (imageCropped)="imageCropped($event)"
                                                            (imageLoaded)="imageLoaded()"
                                                            (cropperReady)="cropperReady($event)"
                                                            (loadImageFailed)="loadImageFailed()">
                                                        </image-cropper>
                                                    </div>
                                                </div>

                                                <div class="col s12">
                                                    <div class="card row">
                                                        <h5 class="section-name">Rotate & Flip</h5>

                                                        <div class="col l3 m3 s3">
                                                            <button (click)="rotateLeft()" class="btn">
                                                                <i class="material-icons float-right">rotate_left</i>
                                                            </button>
                                                        </div>
                                                        <div class="col l3 m3 s3">
                                                            <button (click)="rotateRight()" class="btn">
                                                                <i class="material-icons float-right">rotate_right</i>
                                                            </button>
                                                        </div>
                                                        <div class="col l3 m3 s3">
                                                            <button (click)="flipHorizontal()" class="btn">
                                                                <i class="material-icons">flip</i>
                                                            </button>
                                                        </div>
                                                        <div class="col l3 m3 s3">
                                                            <button (click)="flipVertical()" class="btn">
                                                                <i class="material-icons flipped-icon">flip</i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="card row">
                                                        <h5 class="section-name">Zoom</h5>

                                                        <div (click)="zoomOut()" class="col s12 l2 m2">
                                                            <i class="material-icons">zoom_out</i>
                                                        </div>
                                                        <input type="range" min="1" max="30" (change)="zoomFromRange()"
                                                            [(ngModel)]="zoomScale" value="10"
                                                            class="range range-xs col s12 l8 m8" />
                                                        <div (click)="zoomIn()" class="col s12 l2 m2">
                                                            <i class="material-icons">zoom_in</i>
                                                        </div>
                                                    </div>

                                                    <div class="card row">
                                                        <div class="col l6 s6 m6">
                                                            <h5 class="section-name">Aspect Ratio</h5>
                                                            <button (click)="toggleContainWithinAspectRatio()"
                                                                class="btn">
                                                                {{ containWithinAspectRatio ? "Fill" : "Contain" }}
                                                            </button>
                                                        </div>
                                                        <div class="col l6 s6 m6">
                                                            <h5 class="section-name">Reset Image</h5>
                                                            <button (click)="resetImage()" class="btn">Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal-footer right">
                                            <button (click)="saveCroppedImage()" [disabled]="!croppedImage"
                                                class="btn-small col s3 l2 save-button"
                                                [ngStyle]="{ 'background-color': themeColor }">
                                                Save
                                            </button>

                                            <button class="btn-small col s3 l2 cancel-button modal-close"
                                                [ngStyle]="{ color: themeColor }" (click)="closeCroppingModal()">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                    <!-- ========================================================= modal resizer ========================== -->

                                    <!-- Modal Icon -->

                                    <div id="iconPickerModal" class="modal modal-fixed-footer">
                                        <div class="modal-content">
                                            <div class="slider-modal-header row">
                                                <h4 class="col s11">Icon Selector</h4>
                                                <span class="col s1 modal-close material-symbols-outlined"
                                                    [ngStyle]="{ color: themeColor }" style="cursor: pointer"
                                                    type="button">close</span>
                                            </div>
                                            <!-- Inside your modal content -->
                                            <div class="row">
                                                <div class="input-field col s12">
                                                    <input id="iconSearch" type="text" class="validate"
                                                        [(ngModel)]="searchQuery" placeholder="Search icons"
                                                        (input)="filterIcons()" />
                                                </div>
                                            </div>

                                            <!-- Inside your modal content -->
                                            <div class="row">
                                                <ul>
                                                    <li *ngFor="let icon of filteredIcons" (click)="selectIcon(icon)"
                                                        class="col l6 s6">
                                                        <label class="icon-container">
                                                            <input class="with-gap" name="group1" type="radio"
                                                                value="yes" />
                                                            <span class="icon-text">
                                                                <i class="material-symbols-outlined bold-300 p-r-1">{{
                                                                    icon
                                                                    }}</i>
                                                                {{ icon }}
                                                            </span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="modal-footer left">
                                            <button class="btn-small col s1 l1  save-button"
                                                (click)="saveSelectedIcon(selectedIcon); closeIModal()"
                                                [ngStyle]="{ 'background-color': themeColor }">
                                                Save
                                            </button>

                                            <button class="btn-small col s1 l1 push-l1 cancel-button modal-close"
                                                [ngStyle]="{ color: themeColor }" (click)="closeIModal()">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <!-- ======================= modal ==================== -->
                                    <!-- ============= login -->
                                    <ng-container *ngIf="selectedSection.name === 'Login'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- ============= cardView ============= -->

                                                <!-- section title -->
                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" class="validate"
                                                            [(ngModel)]="loginRef.displayName" type="text" />
                                                    </div>
                                                </div>

                                                <!-- ============= not cardView ============= -->
                                                <!-- Button title -->
                                                <div class="row card-panel1" *ngIf="!cardView">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Button Title
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" class="validate"
                                                            [(ngModel)]="loginRef.button.title" type="text" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ loginRef.displayName }}'s Color Setup
                                                        </h5>

                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left">colors</i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color both cV and !cV ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">
                                                                Background Color
                                                            </h4>
                                                            <span>The card located in the
                                                                {{ loginRef.displayName }} section will display on the
                                                                applied background color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="loginRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== buttonbg Color ============ -->
                                                    <div class="col l3 s12 card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">
                                                                Button Background Color
                                                            </h4>
                                                            <span>The Button located in the section will displayed on
                                                                the
                                                                Button Background color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" class="validate"
                                                                    [(ngModel)]="loginRef.button.bgColor" type="color"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== Button title Color ============ -->
                                                    <div class="col l3 s12 card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">
                                                                Button Title Color
                                                            </h4>
                                                            <span>The Title located in the
                                                                {{ loginRef.displayName }} section will display on the
                                                                applied Button Title color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" class="validate"
                                                                    [(ngModel)]="loginRef.button.titleColor"
                                                                    type="color" class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== section Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container" *ngIf="cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ loginRef.displayName }} section will be displayed on
                                                                the
                                                                applied Section color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" class="validate col s4"
                                                                    [(ngModel)]="loginRef.displayNameColor"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    type="color" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== border button Color ============ -->
                                                    <div class="col l3 s12 card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">
                                                                Button Border Color
                                                            </h4>
                                                            <span>The Button Border located in the
                                                                {{ loginRef.displayName }} section will display on the
                                                                Button Border color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" class="validate"
                                                                    [(ngModel)]="loginRef.button.borderColor"
                                                                    type="color" class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image for
                                                        {{ loginRef.displayName }}?
                                                    </h6>
                                                    <label class="col l4">
                                                        <input name="group1" class="with-gap" type="radio"
                                                            (click)="bgImageYes()" [checked]="loginRef.logoUrl" />
                                                        <span>Yes</span>
                                                    </label>
                                                    <label class="col l4">
                                                        <input name="group1" class="with-gap" type="radio"
                                                            (click)="bgImageNo()" [checked]="!loginRef.logoUrl" />
                                                        <span>No</span>
                                                    </label>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'Login')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-0"></i>
                                                            <h4 class="label-input p-b-0 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col s12 m6 l6" *ngIf="loginRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('Login')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="loginRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>


                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ loginRef.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(loginRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ loginRef.icon }}
                                                            </i>
                                                            {{ loginRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(1)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel1">
                                            <div class="title-nation col l6">
                                                <h6>Preview</h6>
                                            </div>

                                            <div *ngIf="loginRef">
                                                <div class="col s12 m12 l12" *ngIf="!cardView"
                                                    [ngStyle]="{ 'background-color': loginRef.bgColor }">
                                                    <app-register-login-buttons></app-register-login-buttons>
                                                </div>

                                                <div class="col s12 m12 l12 center-align" *ngIf="cardView">
                                                    <div class="col l4 s12 card-padding">
                                                        <a class="card1"
                                                            [ngStyle]="{ 'background-color': loginRef.bgColor }">
                                                            <i *ngIf="!loginRef.logoUrl"
                                                                class="material-symbols-outlined" [ngStyle]="{
                                                        'border-color': loginRef.displayNameColor,
                                                        color: loginRef.displayNameColor
                                                      }"> {{ loginRef.icon }}</i>
                                                            <img *ngIf="loginRef.logoUrl"
                                                                class="activator responsive-img center circle"
                                                                [src]="loginRef.logoUrl" style="
                                                        width: 100px;
                                                        height: 100px;
                                                        border-radius: 50%;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                        display: block;
                                                      " />
                                                            <h3 [ngStyle]="{ color: loginRef.displayNameColor }">
                                                                {{ loginRef.displayName }}
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= news -->
                                    <ng-container *ngIf="selectedSection.name === 'News'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="newsRef.displayName" type="text"
                                                            class="validate" />
                                                    </div>
                                                </div>
                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ newsRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>
                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the {{ newsRef.displayName }}
                                                                section
                                                                will display the applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="newsRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ newsRef.displayName }} section will be displayed on
                                                                the
                                                                applied
                                                                Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="newsRef.displayNameColor" type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- image upload -->
                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image for
                                                        {{ newsRef.displayName }} ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" class="with-gap" type="radio"
                                                                (click)="bgImageYes()" [checked]="newsRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4 s6">
                                                            <input name="group1" class="with-gap" type="radio"
                                                                (click)="bgImageNo()" [checked]="!newsRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'News')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="newsRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('News')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="newsRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>



                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ newsRef.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(newsRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ newsRef.icon }}
                                                            </i>
                                                            {{ newsRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(2)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div *ngIf="!cardView">
                                                    <div class="row card-panel1">
                                                        <div class="col s12 l7 valign-wrapper">
                                                            <h6 class="bold-600 sub-title-section2 black-text">
                                                                Number of News Cards to be Displayed
                                                            </h6>
                                                            <div class="input-field col s5 center-align">
                                                                <input id="title" [(ngModel)]="newsRef.displayLimit"
                                                                    type="text" class="validate"
                                                                    onkeypress="isNumberKey(event)" maxlength="1"
                                                                    (input)="newsNumberChange($event.target.value)" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row card-panel1">
                                                        <div class="col s12 valign-wrapper">
                                                            <h6 class="left bold-600 sub-title-section2 black-text">
                                                                Contents
                                                            </h6>
                                                            <label class="col l4">
                                                                <input name="group1" class="with-gap" type="radio"
                                                                    checked />
                                                                <span>Image</span>
                                                            </label>

                                                            <label class="col l4">
                                                                <input name="group1" class="with-gap" type="radio" />
                                                                <span>Video</span>
                                                            </label>

                                                            <label class="col l4">
                                                                <input name="group1" type="radio" class="with-gap" />
                                                                <span>None</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class="row card-panel1">
                                                        <div class="col s12 ">
                                                            <h6
                                                                class="left bold-600 sub-title-section2 black-text col l4 s8">
                                                                Content View
                                                            </h6>
                                                            <label class="col l4 s6 icon-text">
                                                                <input name="group1" type="checkbox"
                                                                    [checked]="newsRef.cardView"
                                                                    (click)="viewChange()" />
                                                                <span><i class="material-icons">
                                                                        grid_view </i>Card
                                                                    View</span>
                                                            </label>
                                                            <label class="col l4 s6 icon-text">
                                                                <input name="group1" type="checkbox"
                                                                    [checked]="!newsRef.cardView"
                                                                    (click)="viewChange()" />
                                                                <span><i class="material-icons"> list
                                                                    </i>List
                                                                    View</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel1">
                                            <div class="col l6">
                                                <h6 class="bold-600 sub-title-section2 black-text">Preview</h6>
                                            </div>

                                            <div *ngIf="newsArray">
                                                <div class="col s12 m12 l12" *ngIf="!cardView"
                                                    [ngStyle]="{ 'background-color': newsRef.bgColor }">
                                                    <app-latest-news (refreshPreview)="refreshPreview($event)"
                                                        [cardView]="newsRef.cardView">
                                                    </app-latest-news>
                                                </div>

                                                <div class="col s12 m12 l12 center" *ngIf="cardView">
                                                    <div class="col l4 s12 card-padding">
                                                        <a class="card1"
                                                            [ngStyle]="{ 'background-color': newsRef.bgColor }">
                                                            <i *ngIf="!newsRef.logoUrl"
                                                                class="material-symbols-outlined" [ngStyle]="{
                                                        'border-color': newsRef.displayNameColor,
                                                        color: newsRef.displayNameColor
                                                      }"> {{ newsRef.icon }} </i>
                                                            <img *ngIf="newsRef.logoUrl"
                                                                class="activator responsive-img center circle"
                                                                [src]="newsRef.logoUrl" style="
                                                        width: 100px;
                                                        height: 100px;
                                                        border-radius: 50%;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                        display: block;
                                                      " />

                                                            <h3 [ngStyle]="{ color: newsRef.displayNameColor }">
                                                                {{ newsRef.displayName }}
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- ============= ISET -->
                                    <ng-container *ngIf="selectedSection.name === 'ISETReg'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12" *ngIf="cardView">
                                                        <input id="title" class="validate"
                                                            [(ngModel)]="isetRegRef.displayName" type="text" />
                                                    </div>
                                                    <div class="input-field col l6 s12" *ngIf="!cardView">
                                                        <input id="title" class="validate"
                                                            [(ngModel)]="isetRegRef.button.title" type="text" />
                                                    </div>
                                                </div>

                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ isetRegRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left">colors</i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color both cV and !cV ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">
                                                                Background Color
                                                            </h4>
                                                            <span>The card located in the
                                                                {{ isetRegRef.displayName }} section will display the
                                                                applied background color.</span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="isetRegRef.bgColor"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    type="color" class="col s4 validate" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container" *ngIf="cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ isetRegRef.displayName }} section will display the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="isetRegRef.displayNameColor"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    type="color" class="col s4 validate" />
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <!-- =========== bg title Color ============ -->
                                                    <div class="col l3 s12 card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Button Title Color
                                                            </h4>
                                                            <span>The Button located in the
                                                                {{ isetRegRef.displayName }} section will display the
                                                                applied Button Title color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="isetRegRef.button.titleColor"
                                                                    type="color" class="col s4 validate" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg btn Color ============ -->
                                                    <div class="col l3 s12  card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Button Background
                                                                Color</h4>
                                                            <span>The Button located in the
                                                                {{ isetRegRef.displayName }} section will display the
                                                                applied Button Background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="isetRegRef.button.bgColor" type="color"
                                                                    class="col s4 validate" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg border Color ============ -->
                                                    <div class="col l3 s12 card-container" *ngIf="!cardView">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Button Border Color
                                                            </h4>
                                                            <span>The Button located in the
                                                                {{ isetRegRef.displayName }} section will display the
                                                                applied Button Border color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="isetRegRef.button.borderColor"
                                                                    type="color" class="col s4 validate" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="row card-panel2">
                                            <div class="border-content2">
                                                <div class="title-nation col l6">
                                                    <h6>Preview</h6>
                                                </div>
                                                <div *ngIf="isetRegRef">
                                                    <div class="col s12 m12 l12" *ngIf="!cardView"
                                                        [ngStyle]="{ 'background-color': isetRegRef.bgColor }">
                                                        <app-register-login-buttons></app-register-login-buttons>
                                                    </div>
                                                    <div class="col s12 m12 l12 center-align" *ngIf="cardView">
                                                        <div class="col l4 s12 card-padding">
                                                            <a class="card1"
                                                                [ngStyle]="{ 'background-color': isetRegRef.bgColor }">
                                                                <i class="material-symbols-outlined" [ngStyle]="{
                                                          'border-color': isetRegRef.displayNameColor,
                                                          color: isetRegRef.displayNameColor
                                                        }">power_settings_new</i>
                                                                <h3 [ngStyle]="{ color: isetRegRef.displayNameColor }">
                                                                    {{ isetRegRef.displayName }}
                                                                </h3>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Events -->
                                    <ng-container *ngIf="selectedSection.name === 'Events'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="eventRef.displayName" type="text"
                                                            class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ eventRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l4 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the {{ eventRef.displayName }}
                                                                section
                                                                will display the applied background color.
                                                            </span>
                                                        </div>

                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="eventRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l4 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ eventRef.displayName }} section will display the
                                                                applied Section color.
                                                            </span>
                                                        </div>

                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="eventRef.displayNameColor" type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- image upload -->
                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()" [checked]="eventRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>

                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()" [checked]="!eventRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'Events')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col s12 m6 l6" *ngIf="eventRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('Events')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="eventRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>



                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ eventRef.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(eventRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ eventRef.icon }}
                                                            </i>
                                                            {{ eventRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(3)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="row card-panel1" *ngIf="!cardView">
                                                    <div class="col s12 valign-wrapper">
                                                        <h6 class="bold-600 sub-title-section2 black-text">
                                                            Number of Event Cards to be Displayed :
                                                        </h6>
                                                        <div class="input-field col s3 center-align">
                                                            <input id="title" [(ngModel)]="eventRef.displayLimit"
                                                                type="text" class="validate"
                                                                onkeypress="isNumberKey(event)" maxlength="1"
                                                                (input)="eventNumberChange($event.target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel1">
                                            <div class="border-content2">
                                                <div class="title-nation col l6">
                                                    <h6>Preview</h6>
                                                </div>

                                                <div class="col s12 m12 l12"
                                                    [ngStyle]="{ 'background-color': eventRef.bgColor }"
                                                    *ngIf="!cardView">
                                                    <app-upcoming-events></app-upcoming-events>
                                                </div>

                                                <div class="col s12 m12 l12" *ngIf="cardView">
                                                    <div class="col l4 s12 card-padding">
                                                        <a class="card1"
                                                            [ngStyle]="{ 'background-color': eventRef.bgColor }">
                                                            <i *ngIf="!eventRef.logoUrl"
                                                                class="material-symbols-outlined" [ngStyle]="{
                                                        'border-color': eventRef.displayNameColor,
                                                        color: eventRef.displayNameColor
                                                      }">{{ eventRef.icon }} </i>
                                                            <img *ngIf="eventRef.logoUrl"
                                                                class="activator responsive-img center circle"
                                                                [src]="eventRef.logoUrl" style="
                                                        width: 100px;
                                                        height: 100px;
                                                        border-radius: 50%;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                        display: block;
                                                      " />

                                                            <h3 [ngStyle]="{ color: eventRef.displayNameColor }">
                                                                {{ eventRef.displayName }}
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Department -->
                                    <ng-container *ngIf="selectedSection.name === 'Departments'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="departmentsRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ departmentsRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ departmentsRef.displayName }} section will display
                                                                the
                                                                applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="departmentsRef.bgColor" type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ departmentsRef.displayName }} section will be
                                                                displayed
                                                                on the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="departmentsRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- image upload -->
                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()"
                                                                [checked]="departmentsRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>

                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()"
                                                                [checked]="!departmentsRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'Departments')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="departmentsRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('Departments')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="departmentsRef.logoUrl" width="200px"
                                                            height="auto" />
                                                    </div>
                                                </div>



                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ departmentsRef.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(departmentsRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ departmentsRef.icon }}
                                                            </i>
                                                            {{ departmentsRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(6)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>



                                                <div class="row card-panel1" *ngIf="!cardView">
                                                    <div class="col s12 valign-wrapper">
                                                        <h6 class="bold-600 sub-title-section2 black-text">
                                                            Number Of Department cards to be Displayed :
                                                        </h6>
                                                        <div class="input-field col s3 center-align">
                                                            <input id="title" [(ngModel)]="departmentsRef.displayLimit"
                                                                class="validate" type="text"
                                                                onkeypress="isNumberKey(event)" maxlength="1"
                                                                (input)="limitChange($event.target.value, 'departments')"
                                                                placeholder="Number Of Displayed News" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel2">
                                            <div class="border-content2">
                                                <div class="title-nation col l6">
                                                    <h6>Preview</h6>
                                                </div>

                                                <div *ngIf="departmentsArray">
                                                    <div class="col s12 m12 l12" *ngIf="!cardView"
                                                        [ngStyle]="{ 'background-color': departmentsRef.bgColor }">
                                                        <app-department-list></app-department-list>
                                                    </div>
                                                    <div class="col s12 m12 l12" *ngIf="cardView">
                                                        <div class="col l4 s12 card-padding">
                                                            <a class="card1"
                                                                [ngStyle]="{ 'background-color': departmentsRef.bgColor }">
                                                                <i *ngIf="!departmentsRef.logoUrl"
                                                                    class="material-symbols-outlined" [ngStyle]="{
                                                          'border-color': departmentsRef.displayNameColor,
                                                          color: departmentsRef.displayNameColor
                                                        }"> {{ departmentsRef.icon }}</i>
                                                                <img *ngIf="departmentsRef.logoUrl"
                                                                    class="activator responsive-img center circle"
                                                                    [src]="departmentsRef.logoUrl" style="
                                                          width: 100px;
                                                          height: 100px;
                                                          border-radius: 50%;
                                                          margin-left: auto;
                                                          margin-right: auto;
                                                          display: block;
                                                        " />

                                                                <h3
                                                                    [ngStyle]="{ color: departmentsRef.displayNameColor }">
                                                                    {{ departmentsRef.displayName }}
                                                                </h3>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Governance -->
                                    <ng-container *ngIf="selectedSection.name === 'Governance'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="governanceRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ governanceRef?.displayName }}'s Color Setup
                                                        </h5>

                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ governanceRef?.displayName }} section will display
                                                                the
                                                                applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="governanceRef.bgColor" type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ governanceRef?.displayName }} section will display
                                                                the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="governanceRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()"
                                                                [checked]="governanceRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()"
                                                                [checked]="!governanceRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'Governance')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col s12 m6 l6" *ngIf="governanceRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('Governance')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="governanceRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>



                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ governanceRef?.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(governanceRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ governanceRef.icon }}
                                                            </i>
                                                            {{ governanceRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(7)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>



                                                <div *ngIf="!cardView">
                                                    <div class="row card-panel1">
                                                        <div class="col s12 valign-wrapper">
                                                            <h6 class="bold-600 sub-title-section2 black-text">
                                                                Number of {{councillorTitle}}s2 to be displayed :
                                                            </h6>
                                                            <div class="input-field col s5 center-align">
                                                                <input id="title"
                                                                    [(ngModel)]="governanceRef.councillorsLimit"
                                                                    type="text" onkeypress="isNumberKey(event)"
                                                                    maxlength="1"
                                                                    (input)="limitChange($event.target.value, 'governance')"
                                                                    placeholder="Number Of Councillor to show"
                                                                    class="validate" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row card-panel1">
                                            <div class="col l6">
                                                <h6 class="bold-600 sub-title-section2 black-text">Preview</h6>
                                            </div>
                                            <div *ngIf="governanceArray">
                                                <div class="col s12 m12 l12" *ngIf="!cardView"
                                                    [ngStyle]="{ 'background-color': governanceRef.bgColor }">
                                                    <app-governance-list [from]="'landingPage'"></app-governance-list>
                                                </div>

                                                <div class="col s12 m12 l12" *ngIf="cardView">
                                                    <div class="col l4 s12 card-padding">
                                                        <a class="card1"
                                                            [ngStyle]="{ 'background-color': governanceRef.bgColor }">
                                                            <i *ngIf="!governanceRef.logoUrl"
                                                                class="material-symbols-outlined" [ngStyle]="{
                                                        'border-color': governanceRef.displayNameColor,
                                                        color: governanceRef.displayNameColor
                                                      }"> {{ governanceRef.icon }}</i>
                                                            <img *ngIf="governanceRef.logoUrl"
                                                                class="activator responsive-img center circle"
                                                                [src]="governanceRef.logoUrl" style="
                                                        width: 100px;
                                                        height: 100px;
                                                        border-radius: 50%;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                        display: block;
                                                      " />

                                                            <h3 [ngStyle]="{ color: governanceRef.displayNameColor }">
                                                                {{ governanceRef?.displayName }}
                                                            </h3>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Apple store -->
                                    <ng-container *ngIf="selectedSection.name === 'AppStore'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="appStoreRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>
                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ appStoreRef?.displayName }}'s Color Setup
                                                        </h5>

                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ appStoreRef?.displayName }} section will display the
                                                                applied background color.
                                                            </span>
                                                        </div>

                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="appStoreRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ appStoreRef?.displayName }} section will display the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="appStoreRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1">
                                                    <div class="col l6 s12">
                                                        <!-- <h6 class="sub-title-section bold-700">Google Play Url</h6> -->
                                                        <div class="input-field">
                                                            <div
                                                                class="label-input bold-600 sub-title-section2 black-text">
                                                                Google Play Url</div>
                                                            <input id="googleUrl"
                                                                [(ngModel)]="appStoreRef.googlePlayUrl" type="text"
                                                                class="validate" placeholder="Google Play Url" />
                                                        </div>
                                                    </div>

                                                    <div class="col l6 s12">
                                                        <!-- <h6 class="sub-title-section bold-700">Apple Store Url</h6> -->
                                                        <div class="input-field">
                                                            <div
                                                                class="label-input bold-600 sub-title-section2 black-text">
                                                                Apple Store Url</div>
                                                            <input id="appleUrl" [(ngModel)]="appStoreRef.appleStoreUrl"
                                                                type="text" class="validate"
                                                                placeholder="Apple Store Url" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1">
                                                    <div class="col s12">
                                                        <!-- <h6 class="sub-title-section bold-700">Description</h6> -->
                                                        <div class="input-field">
                                                            <div class="label-input">Description</div>
                                                            <app-editor id="description" [title]="false"
                                                                [model]="appStoreRef.description" [styler]="false"
                                                                [type]="true"
                                                                (onModelChange)="descriptionChange($event)">
                                                            </app-editor>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()"
                                                                [checked]="appStoreRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()"
                                                                [checked]="!appStoreRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'AppStore')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="col s12 m6 l6" *ngIf="appStoreRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('AppStore')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="appStoreRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>



                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ appStoreRef?.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(appStoreRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ appStoreRef.icon }}
                                                            </i>
                                                            {{ appStoreRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(4)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="row card-panel1">
                                                    <div class="border-content2">
                                                        <div class="title-nation col l6">
                                                            <h6>Preview</h6>
                                                        </div>
                                                        <div class="col s12 m12 l12"
                                                            [ngStyle]="{ 'background-color': appStoreRef.bgColor }"
                                                            *ngIf="!cardView">
                                                            <app-app-store></app-app-store>
                                                        </div>
                                                        <div class="col s12 m12 l12" *ngIf="cardView">
                                                            <div class="col l4 s12 card-padding">
                                                                <a class="card1 modal-trigger"
                                                                    [ngStyle]="{ 'background-color': appStoreRef.bgColor }">
                                                                    <i *ngIf="!appStoreRef.logoUrl"
                                                                        class="material-symbols-outlined" [ngStyle]="{
                                                            'border-color': appStoreRef.displayNameColor,
                                                            color: appStoreRef.displayNameColor
                                                          }"> {{ appStoreRef.icon }}</i>
                                                                    <img *ngIf="appStoreRef.logoUrl"
                                                                        class="activator responsive-img center circle"
                                                                        [src]="appStoreRef.logoUrl" style="
                                                            width: 100px;
                                                            height: 100px;
                                                            border-radius: 50%;
                                                            margin-left: auto;
                                                            margin-right: auto;
                                                            display: block;
                                                          " />

                                                                    <h3
                                                                        [ngStyle]="{ color: appStoreRef.displayNameColor }">
                                                                        {{ appStoreRef?.displayName }}
                                                                    </h3>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Social Media -->
                                    <ng-container *ngIf="selectedSection.name === 'SocialMedia'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">

                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="socialMediaRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ socialMediaRef?.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ socialMediaRef?.displayName }} section will display
                                                                the
                                                                applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="socialMediaRef.bgColor" type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ socialMediaRef?.displayName }} section will display
                                                                the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="socialMediaRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- image upload -->
                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()"
                                                                [checked]="socialMediaRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>

                                                        <label class="col l4 s6">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()"
                                                                [checked]="!socialMediaRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'SocialMedia')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="socialMediaRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('SocialMedia')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="socialMediaRef.logoUrl" width="200px"
                                                            height="auto" />
                                                    </div>
                                                </div>


                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ socialMediaRef?.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(socialMediaRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ socialMediaRef.icon }}
                                                            </i>
                                                            {{ socialMediaRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(5)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div class="row center col s12 m6 l4 right">
                                                    <div class="section"></div>
                                                    <!-- <button [routerLink]="[
                                                    '/admin/dashboard-sub-menu/settings/social-media'
                                                  ]" class="btn col s12 center update-button">
                                                        Update Social Media Links
                                                        <i class="material-icons right">edit</i>
                                                    </button> -->

                                                    <button href="#socialMediaModal"
                                                        class="btn col s12 center update-button modal-trigger">
                                                        Update Social Media Links
                                                        <i class="material-icons right">edit</i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row card-panel1">
                                            <div class="col l6">
                                                <h6 class="bold-600 sub-title-section2 black-text">Preview</h6>
                                            </div>
                                            <div class="col s12 m12 l12"
                                                [ngStyle]="{ 'background-color': socialMediaRef.bgColor }"
                                                *ngIf="!cardView">
                                                <app-social-media-links></app-social-media-links>
                                            </div>
                                            <div class="col s12 m12 l12" *ngIf="cardView">
                                                <div class="col l4 s6 card-padding">
                                                    <a class="card1 modal-trigger"
                                                        [ngStyle]="{ 'background-color': socialMediaRef.bgColor }">
                                                        <i *ngIf="!socialMediaRef.logoUrl"
                                                            class="material-symbols-outlined" [ngStyle]="{
                                                      'border-color': socialMediaRef.displayNameColor,
                                                      color: socialMediaRef.displayNameColor
                                                    }"> {{ socialMediaRef.icon }}</i>
                                                        <img *ngIf="socialMediaRef.logoUrl"
                                                            class="activator responsive-img center circle"
                                                            [src]="socialMediaRef.logoUrl" style="
                                                      width: 100px;
                                                      height: 100px;
                                                      border-radius: 50%;
                                                      margin-left: auto;
                                                      margin-right: auto;
                                                      display: block;
                                                    " />

                                                        <h3 [ngStyle]="{ color: socialMediaRef.displayNameColor }">
                                                            {{ socialMediaRef?.displayName }}
                                                        </h3>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="row col s12 center" *ngIf="!socialMediaModel">
                                                <div class="row col s12 card-panel">
                                                    <p class="center">Please Update Your Social Media Links !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Contact us -->
                                    <ng-container *ngIf="selectedSection.name === 'ContactUs'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="contactUsRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ contactUsRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ contactUsRef.displayName }} section will display the
                                                                applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="contactUsRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ contactUsRef.displayName }} section will display the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="contactUsRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" (click)="bgImageYes()"
                                                                class="with-gap" [checked]="contactUsRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" (click)="bgImageNo()"
                                                                class="with-gap" [checked]="!contactUsRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'ContactUs')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="contactUsRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('ContactUs')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="contactUsRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>


                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ contactUsRef.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(contactUsRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ contactUsRef.icon }}
                                                            </i>
                                                            {{ contactUsRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(11)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div class="row card-panel1">
                                                    <div class="border-content2">
                                                        <div class="title-nation col l6">
                                                            <h6>Preview</h6>
                                                        </div>

                                                        <div class="col s12 m12 l12"
                                                            [ngStyle]="{ 'background-color': contactUsRef.bgColor }"
                                                            *ngIf="!cardView">
                                                            <app-about-us-section></app-about-us-section>
                                                        </div>

                                                        <div class="col s12 m12 l12" *ngIf="cardView">
                                                            <div class="col l4 s12 card-padding">
                                                                <a class="card1"
                                                                    [ngStyle]="{ 'background-color': contactUsRef.bgColor }">
                                                                    <i *ngIf="!contactUsRef.logoUrl"
                                                                        class="material-symbols-outlined" [ngStyle]="{
                                                            'contactUsRef-color': contactUsRef.displayNameColor,
                                                            color: contactUsRef.displayNameColor
                                                          }"> {{ contactUsRef.icon }}</i>
                                                                    <img *ngIf="contactUsRef.logoUrl"
                                                                        class="activator responsive-img center circle"
                                                                        [src]="contactUsRef.logoUrl" style="
                                                            width: 100px;
                                                            height: 100px;
                                                            border-radius: 50%;
                                                            margin-left: auto;
                                                            margin-right: auto;
                                                            display: block;
                                                          " />

                                                                    <h3
                                                                        [ngStyle]="{ color: contactUsRef.displayNameColor }">
                                                                        {{ contactUsRef.displayName }}
                                                                    </h3>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ---=================== demo======================= -- -->
                                    <ng-container *ngIf="selectedSection.name === 'Demo'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name
                                                    </h5>
                                                    <div class="input-field col l6 s12">
                                                        <input id="title" [(ngModel)]="demoAccountRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ demoAccountRef.displayName }}'s Color Setup
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the
                                                                {{ demoAccountRef.displayName }} section will display
                                                                the
                                                                applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="demoAccountRef.bgColor" type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ demoAccountRef.displayName }} section will display
                                                                the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="demoAccountRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" (click)="bgImageYes()"
                                                                class="with-gap" [checked]="demoAccountRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" (click)="bgImageNo()"
                                                                class="with-gap" [checked]="!demoAccountRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'Demo')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="demoAccountRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('Demo')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="demoAccountRef.logoUrl" width="200px"
                                                            height="auto" />
                                                    </div>
                                                </div>

                                                <div class="row card-panel1">
                                                    <div class="border-content2">
                                                        <div class="title-nation col l6">
                                                            <h6>Preview</h6>
                                                        </div>

                                                        <div class="col s12 m12 l12"
                                                            [ngStyle]="{ 'background-color': demoAccountRef.bgColor }"
                                                            *ngIf="!cardView">
                                                            <!-- <app-about-us-section></app-about-us-section> -->
                                                            <app-demo-signup></app-demo-signup>
                                                        </div>

                                                        <div class="col s12 m12 l12" *ngIf="cardView">
                                                            <div class="col l4 s6 card-padding">
                                                                <a class="card1"
                                                                    [ngStyle]="{ 'background-color': demoAccountRef.bgColor }">
                                                                    <i *ngIf="!demoAccountRef.logoUrl"
                                                                        class="material-symbols-outlined" [ngStyle]="{
                                                            'demoAccountRef-color':
                                                              demoAccountRef.displayNameColor,
                                                            color: contactUsRef.displayNameColor
                                                          }">calendar_today</i>
                                                                    <img *ngIf="demoAccountRef.logoUrl"
                                                                        class="activator responsive-img center circle"
                                                                        [src]="demoAccountRef.logoUrl" style="
                                                            width: 100px;
                                                            height: 100px;
                                                            border-radius: 50%;
                                                            margin-left: auto;
                                                            margin-right: auto;
                                                            display: block;
                                                          " />

                                                                    <h3
                                                                        [ngStyle]="{ color: demoAccountRef.displayNameColor }">
                                                                        {{ demoAccountRef.displayName }}
                                                                    </h3>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= About us =================== -->
                                    <ng-container *ngIf="selectedSection.name === 'AboutUs'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <!-- section title -->
                                                <div class="row card-panel1">
                                                    <h5 class="bold-600 sub-title-section2 black-text">
                                                        Section Name :
                                                    </h5>
                                                    <div class="input-field col l5 s12">
                                                        <input id="title" [(ngModel)]="aboutUsRef.displayName"
                                                            type="text" class="validate" />
                                                    </div>
                                                </div>

                                                <!-- section colors -->
                                                <div class="row card-panel1">
                                                    <div class="row">
                                                        <h5
                                                            class="bold-600 sub-title-section2 black-text p-b-1 col l8 s12">
                                                            {{ aboutUsRef?.displayName }}'s Color Setup :
                                                        </h5>
                                                        <!-- apply to all  -->
                                                        <div class="col l4 s12" *ngIf="cardView">
                                                            <a class="right btn apply-to-all-btn" (click)="applyStyle()"
                                                                style="cursor: pointer"
                                                                [ngStyle]="{ color: themeColor }">Apply style to all
                                                                cards
                                                                <i class="material-symbols-outlined left"> colors
                                                                </i></a>
                                                        </div>
                                                    </div>

                                                    <!-- =========== theme Color ============ -->
                                                    <div class="col l3 s12 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600 p-b-1">Backgound
                                                                Color
                                                            </h4>
                                                            <span>
                                                                The card located in the {{ aboutUsRef?.displayName }}
                                                                section
                                                                will display the applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="aboutUsRef.bgColor"
                                                                    type="color"
                                                                    (change)="setBgColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- =========== bg Color ============ -->
                                                    <div class="col l3 s12 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600 p-b-1">Section Color
                                                            </h4>
                                                            <span>The Title located in the
                                                                {{ aboutUsRef?.displayName }} section will display the
                                                                applied Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor"
                                                                    [(ngModel)]="aboutUsRef.displayNameColor"
                                                                    type="color"
                                                                    (change)="setTextColor($event.target.value)"
                                                                    class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView">
                                                    <h6 class="bold-600 sub-title-section2 black-text p-b-1">
                                                        Would you like to upload an image ?
                                                    </h6>
                                                    <div class="row col s12">
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageYes()" [checked]="aboutUsRef.logoUrl" />
                                                            <span>Yes</span>
                                                        </label>
                                                        <label class="col l4">
                                                            <input name="group1" type="radio" class="with-gap"
                                                                (click)="bgImageNo()" [checked]="!aboutUsRef.logoUrl" />
                                                            <span>No</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && bgImage">
                                                    <div class="card col s12 m6 l4"
                                                        (change)="handlelogoInput($event.target.files, 'AboutUs')">
                                                        <div class="center-align">
                                                            <input type="file" accept="image/*"
                                                                (change)="handlelogoInput($event.target.files)" />
                                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                                            <h4 class="label-input p-b-2 bold-200">
                                                                Drop & Drop to Upload file <br />
                                                                or <br />
                                                                <u class="bold-200">Click to Upload</u>
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    <div class="col s12 m6 l6" *ngIf="aboutUsRef.logoUrl">
                                                        <button class="btn red" type="button"
                                                            (click)="deleteImage('AboutUs')">
                                                            Delete Image
                                                        </button>
                                                        <img class="materialboxed responsive-img"
                                                            [src]="aboutUsRef.logoUrl" width="200px" height="auto" />
                                                    </div>
                                                </div>

                                                <div class="row card-panel1" *ngIf="cardView && !bgImage">

                                                    <h5 class="bold-600 sub-title-section2 black-text p-b-1 col l4 s12">
                                                        Select Icon for {{ aboutUsRef?.displayName }}
                                                    </h5>

                                                    <div class="col s6 l2">
                                                        <button class="btn-icon modal-trigger"
                                                            (click)="openIconPickerModal(aboutUsRef)">
                                                            Select Icon
                                                        </button>
                                                    </div>

                                                    <div class="col s6 l6" style=" padding: 5px 0px;">
                                                        <div class="icon-text">
                                                            <i class="material-symbols-outlined bold-300">
                                                                {{ aboutUsRef.icon }}
                                                            </i>
                                                            {{ aboutUsRef.icon }} |
                                                            <a class="p-l-2" (click)="setDefaultIcons(10)"> Default </a>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div *ngIf="!cardView">
                                                    <div class="row card-panel1">
                                                        <div class="border-content2">
                                                            <div class="col l7 s12 valign-wrapper">
                                                                <h6 class="bold-600 sub-title-section2 black-text">
                                                                    Number of About us Cards :
                                                                </h6>
                                                                <div class="input-field col l5 s4 center-align">
                                                                    <input id="title"
                                                                        [(ngModel)]="aboutUsRef.displayLimit"
                                                                        type="text" onkeypress="isNumberKey(event)"
                                                                        maxlength="1" class="validate"
                                                                        (input)="aboutusNumberChange($event.target.value)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row card-panel1">
                                                    <div class="border-content2">
                                                        <div class="title-nation col l6">
                                                            <h6>Preview :</h6>
                                                        </div>

                                                        <div class="col s12 m12 l12"
                                                            [ngStyle]="{ 'background-color': aboutUsRef.bgColor }"
                                                            *ngIf="!cardView">
                                                            <app-about-us-section></app-about-us-section>
                                                        </div>

                                                        <div class="col s12 m12 l12" *ngIf="cardView">
                                                            <div class="col l4 s12 card-padding">
                                                                <a class="card1"
                                                                    [ngStyle]="{ 'background-color': aboutUsRef.bgColor }">
                                                                    <i *ngIf="!aboutUsRef.logoUrl"
                                                                        class="material-symbols-outlined" [ngStyle]="{
                                                        'aboutUsRef-color': aboutUsRef.displayNameColor,
                                                        color: aboutUsRef.displayNameColor
                                                      }">{{ aboutUsRef.icon }}</i>
                                                                    <img *ngIf="aboutUsRef.logoUrl"
                                                                        class="activator responsive-img center circle"
                                                                        [src]="aboutUsRef.logoUrl" style="
                                                        width: 100px;
                                                        height: 100px;
                                                        border-radius: 50%;
                                                        margin-left: auto;
                                                        margin-right: auto;
                                                        display: block;
                                                      " />

                                                                    <h3
                                                                        [ngStyle]="{ color: aboutUsRef.displayNameColor }">
                                                                        {{ aboutUsRef.displayName }}
                                                                    </h3>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- ============= Footer ======================-->
                                    <ng-container *ngIf="selectedSection.name === 'Footer'">
                                        <div class="row p-t-3">
                                            <div class="col l12 m12 s12">
                                                <div class="row card-panel1">
                                                    <div class="col s12">
                                                        <p class="sub-title-section2 bold-600 col l6 s6">
                                                            You can set the format and styles of texts being displayed
                                                            by
                                                            clicking the set style button.
                                                        </p>
                                                        <div class="input-field col l6 s6 center">
                                                            <a class="btn-flat modal-trigger" data-target="styleModal"
                                                                style="cursor: pointer"><i
                                                                    class="material-icons left">text_format</i>Set
                                                                Style</a>
                                                            <p class="center" [innerHTML]="footerRef.style"></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- bg color -->
                                                <div class="row card-panel1">
                                                    <div class="col s12 l3 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Backgound Color</h4>
                                                            <span>
                                                                The card located in the {{ footerRef?.displayName }}
                                                                section
                                                                will display the applied background color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="footerRef.bgColor"
                                                                    type="color" class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- section color -->
                                                    <div class="col s12 l3 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Section Color</h4>
                                                            <span>The Title located in the
                                                                {{ footerRef?.displayName }} section will display the
                                                                applied
                                                                Section color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="footerRef.textColor"
                                                                    type="color" class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- link /color -->
                                                    <div class="col s12 l3 offset-l1 card-container">
                                                        <div class="col s12">
                                                            <h4 class="sub-title-section2 bold-600">Link Color</h4>
                                                            <span>The Link located in the {{ footerRef?.displayName }}
                                                                section
                                                                will display the applied Link color.
                                                            </span>
                                                        </div>
                                                        <div class="col s12">
                                                            <div class="color-type row">
                                                                <p class="col s8">Choose Color</p>
                                                                <input id="iconColor" [(ngModel)]="footerRef.linkColor"
                                                                    type="color" class="col s4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="!nationSetting" class="row card-panel1">
                                                    <div class=" col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="sub-title-section2 bold-600 label-input">Address</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Address</div>
                                                                <input id="address" [(ngModel)]="footerRef.address"
                                                                    [value]="footerRef.address.textContent"
                                                                    class="validate" type="text"
                                                                    placeholder="Address" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col l6 s12">
                                                        <div class=" ">
                                                            <!-- <h6 class="sub-title-section2 bold-600">Postal Code</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Postal Code</div>
                                                                <!-- <input id="pcode" [textMask]="{ mask: postalCodeMask }"
                                                                    [(ngModel)]="footerRef.postalCode" class="validate"
                                                                    type="text" placeholder="Postal Code" /> -->
                                                                <input id="pcode" [inputMask]="postalCodeMask"
                                                                    [(ngModel)]="footerRef.postalCode" class="validate"
                                                                    type="text" placeholder="Postal Code" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class=" col l6 s12">
                                                        <div class=" ">
                                                            <!-- <h6 class="sub-title-section2 bold-600">City</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">City</div>
                                                                <input id="city" [(ngModel)]="footerRef.city"
                                                                    type="text" class="validate" placeholder="City" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class=" col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="left sub-title-section2 bold-600">Province</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Province</div>
                                                                <input id="prov" [(ngModel)]="footerRef.province"
                                                                    type="text" class="validate"
                                                                    placeholder="Province" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="left sub-title-section2 bold-600">
                                                                Info / Support Email
                                                            </h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Info / Support Email</div>
                                                                <input id="email" [(ngModel)]="footerRef.contactEmail"
                                                                    type="text" class="validate"
                                                                    placeholder="Info / Support Email" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="left sub-title-section2 bold-600">Phone</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Phone</div>
                                                                <!-- <input id="phone" [textMask]="{ mask: phoneNumberMask }"
                                                                    [(ngModel)]="footerRef.phone" type="text"
                                                                    class="validate" placeholder="Phone" /> -->
                                                                <input id="phone" [inputMask]="phoneInputMask"
                                                                    [(ngModel)]="footerRef.phone" type="text"
                                                                    class="validate" placeholder="Phone" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="left sub-title-section2 bold-600">Fax</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Fax</div>
                                                                <!-- <input id="fax" [textMask]="{ mask: phoneNumberMask }"
                                                                    [(ngModel)]="footerRef.fax" type="text"
                                                                    class="validate" placeholder="Fax" /> -->
                                                                <input id="fax" [inputMask]="phoneInputMask"
                                                                    [(ngModel)]="footerRef.fax" type="text"
                                                                    class="validate" placeholder="Fax" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class=" col l6 s12">
                                                        <div class="">
                                                            <!-- <h6 class="left sub-title-section2 bold-600">Toll Free</h6> -->
                                                            <div class="input-field col l10 s12">
                                                                <div class="label-input">Toll Free</div>
                                                                <!-- <input id="tollfree"
                                                                    [textMask]="{ mask: phoneNumberMask }"
                                                                    [(ngModel)]="footerRef.tollFree" type="text"
                                                                    class="validate" placeholder="Toll Free" /> -->
                                                                <input id="tollfree" [inputMask]="phoneInputMask"
                                                                    [(ngModel)]="footerRef.tollFree" type="text"
                                                                    class="validate" placeholder="Toll Free" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Modal Structure -->
                                        <div id="styleModal" class="modal" style="z-index: index 200;">
                                            <div class="modal-content">
                                                <div class="row">
                                                    <h4 class="col s11">Set Style</h4>
                                                    <i class="material-icons col s1 modal-close">close</i>
                                                </div>
                                                <app-editor [styler]="true" [model]="footerRef.style" [styler]="true"
                                                    [editable]="false" (onModelChange)="styleChanged($event)"
                                                    [type]="true">
                                                </app-editor>
                                            </div>

                                            <div class="modal-footer row col s12">
                                                <a class="modal-close green btn-flat">Save</a>
                                            </div>
                                        </div>

                                        <div class="row card-panel2">
                                            <div class="border-content2">
                                                <div class="title-nation col l6">
                                                    <h6>Preview</h6>
                                                </div>
                                                <footer class="page-footer col s12 m12 l12"
                                                    [ngStyle]="{ 'background-color': footerRef.bgColor }">
                                                    <app-landing-page-footer></app-landing-page-footer>
                                                </footer>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button class="save-button btn-small col s3 l2" [ngStyle]="{'background-color': themeColor}"
                                (click)="saveChanges();">Save</button>
                            <button class="modal-close cancel-button btn-small col s3 l2" (click)=" closeModal()"
                                [ngStyle]="{'color': themeColor}">Cancel</button>
                        </div>

                    </div>
                    <!-- =============== apply to all modal =============== -->
                    <div id="applyStyleModal" class="modal modal-fixed-footer">
                        <div class="modal-content">
                            <div class="row">
                                <div class="col s10">
                                    <h5 class="title-section3 bold-700">Apply Stlye</h5>
                                    <p>Apply stlye to all sections?</p>
                                </div>
                                <i class="right modal-close material-icons-outlined red-text"
                                    (click)="closeStyleModal()">
                                    close</i>
                            </div>
                            <div *ngIf="previewBefore">
                                <p class="right bold-700 col s12">Preview Changes</p>
                                <app-card-view-landing-page [preview]="true" [sections]="previewSection"
                                    [onlyCards]="true">
                                </app-card-view-landing-page>
                            </div>
                        </div>
                        <div class="section p-t-10"></div>
                        <div class="modal-footer">
                            <button class="modal-close btn right white green-text bold-700" (click)="applyToAll()">
                                Apply Changes
                            </button>
                            <button class="modal-close btn left white red-text bold-700">Cancel</button>
                        </div>
                    </div>

                    <div id="socialMediaModal" class="modal">
                        <div class="modal-content">
                            <div class="col s12">
                                <div class="body-h">
                                    <div class="header-setting b-m-2">
                                        <div class="col l12 m12 s12">
                                            <h4>Social Media</h4>
                                            <p class="bold-300 sub-title-section3">Please Add or update your social
                                                media links.</p>
                                        </div>
                                    </div>

                                    <form (ngSubmit)="submitForm(socialForm.value)" [formGroup]="socialForm">
                                        <div class="input-field class col s12 l10">
                                            <i class="fa fa-facebook prefix blue-text "></i>
                                            <input formControlName="facebook" id="facebook" class="validate "
                                                type="text" maxlength="250" placeholder="Facebook URL"
                                                autocomplete="off">
                                            <div *ngIf="(facebook.dirty || facebook.touched) && facebook.invalid">
                                                <small *ngIf="facebook.errors.pattern" class="red-text"> Please enter a
                                                    valid Url.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field class col s12 l10">
                                            <i class="fa fa-twitter prefix blue-text text-lighten-2"></i>
                                            <input formControlName="twitter" id="twitter" class="validate" type="text"
                                                maxlength="250" placeholder="Twitter URL" autocomplete="off">
                                            <div *ngIf="(twitter.dirty || twitter.touched) && twitter.invalid">
                                                <small *ngIf="twitter.errors.pattern" class="red-text"> Please enter a
                                                    valid Url.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field class col s12 l10">
                                            <i class="fa fa-instagram prefix" style="color: #3f729b;"></i>
                                            <input formControlName="instagram" id="instagram" class="validate"
                                                type="text" maxlength="250" placeholder="Instagram URL"
                                                autocomplete="off">
                                            <div *ngIf="(instagram.dirty || instagram.touched) && instagram.invalid">
                                                <small *ngIf="instagram.errors.pattern" class="red-text"> Please enter a
                                                    valid Url.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field class col s12 l10">
                                            <i class="fa fa-youtube prefix red-text"></i>
                                            <input formControlName="youtube" id="youtube" class="validate" type="text"
                                                maxlength="250" placeholder="Youtube URL" autocomplete="off">
                                            <div *ngIf="(youtube.dirty || youtube.touched) && youtube.invalid">
                                                <small *ngIf="youtube.errors.pattern" class="red-text"> Please enter a
                                                    valid Url.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div *ngIf="!hasSocial" [disabled]="!socialForm.valid"
                                                class="col s12 l3 m3 offset-l9 offset-m9 p-m-3">
                                                <button type="submit" class="col s12 btn save-button"
                                                    [ngClass]="'btn ' + buttonType" style="margin-bottom: 15px;">Save
                                                    Social Media Links</button>
                                            </div>
                                            <div *ngIf="hasSocial" [disabled]="!socialForm.valid"
                                                class="col s12 l3 m3 offset-l9 offset-m9 p-m-3">
                                                <button type="submit" class="col s12 btn save-button"
                                                    [ngClass]="'btn ' + buttonType" style="margin-bottom: 15px;">Update
                                                    Social Media Links</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- -----------===================== card edit =============-------------------- -->
                    <div id="card-edit" class="modal bottom-sheet modal-fixed-footer">
                        <div class="modal-content">
                            <div class="member-modal-header">
                                <h4 class="col s11 ">Public Menu</h4>
                                <span class="col s1 modal-close material-symbols-outlined center"
                                    [ngStyle]="{'color': themeColor}" style="cursor: pointer;">close</span>
                            </div>


                            <!-- ========================================== Appearance ================================================================= -->

                            <div class="white collap-design">
                                <ul class="collapsible">
                                    <!-- ================ image shape ========== -->
                                    <li class="row image-oo">
                                        <div class="collapsible-header">
                                            <i class="material-symbols-outlined">image</i>
                                            Image Style
                                        </div>

                                        <div class="collapsible-body">
                                            <div class="row">
                                                <label class="col s12 l5">
                                                    <input class="radioBtn with-gap" type="radio" name="imageShape"
                                                        value="imageOption == 'yes'" [checked]="imageOption == 'yes'"
                                                        (click)="imageOptionClicked('yes');" />
                                                    <span>
                                                        <div class="card-padding">
                                                            <a class="card-option b-n-1" [ngStyle]="{
                                            'background-image':
                                              'url(../../../../assets/img/backgrounds/login-back.jpeg)',
                                            'background-position': 'center',
                                            'background-repeat': 'no-repeat',
                                            'background-size':
                                              model?.imageCover === 'Contain'
                                                ? 'contain'
                                                : 'cover',
                                            height: '15vh'
                                          }">
                                                                <div class="card-content">
                                                                    <div class="vertical-center">
                                                                        <h3 class="display-name white-text bold-500">
                                                                            displayName
                                                                        </h3>
                                                                        <div class="overlay"></div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </span>
                                                </label>

                                                <label class="col s12 l5">
                                                    <input class="radioBtn with-gap " type="radio" name="imageShape"
                                                        value="imageOption == 'no'" [checked]="imageOption == 'no'"
                                                        (click)="imageOptionClicked('no'); " />
                                                    <span>
                                                        <div class="card-padding">
                                                            <a class="card-option b-n-1"
                                                                [ngStyle]="{ 'background-color': themeColor }">
                                                                <img class="responsive-img center circle"
                                                                    alt="all menu image"
                                                                    src="../../../../assets/img/backgrounds/login-back.jpeg"
                                                                    style="
                                              width: 60px;
                                              height: 60px;
                                              border-radius: 50%;
                                              margin-left: auto;
                                              margin-right: auto;
                                              display: block;
                                            " />
                                                                <h3 class="white-text bold-500 display-name">
                                                                    displayName
                                                                </h3>
                                                            </a>
                                                        </div>
                                                    </span>
                                                </label>

                                                <!-- ================ -->
                                                <label class="col s12 l3">
                                                    <div *ngIf="imageOption == 'yes'">
                                                        <h4 class="bold-400 title-section4">
                                                            Image Content Option
                                                        </h4>
                                                        <label>
                                                            <input class="col s12 l6 radioBtn with-gap" type="radio"
                                                                name="imageCover" value="imageCoverOption == 'Contain'"
                                                                [checked]="imageCoverOption == 'Contain'" (click)="
                                            imageCoverOptionClicked('Contain');
                                            
                                          " />
                                                            <span>Contain</span>
                                                        </label>
                                                        <br />

                                                        <label>
                                                            <input class="col s12 l6 radioBtn with-gap" type="radio"
                                                                name="imageCover" value="imageCoverOption == 'Cover'"
                                                                [checked]="imageCoverOption == 'Cover'" (click)="
                                            imageCoverOptionClicked('Cover');
                                           
                                          " />
                                                            <span>Cover</span>
                                                        </label>
                                                    </div>
                                                </label>
                                                <!-- ================ -->
                                            </div>
                                        </div>
                                    </li>

                                    <!-- ===========================Card Grid Option================================ -->
                                    <li class="row">
                                        <div class="collapsible-header">
                                            <i class="material-symbols-outlined">apps</i>Matrix
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="col s12">
                                                <div class="col s12 m6 l6">
                                                    <div class="input-field">
                                                        <div class="label-input">Desktop Screen</div>
                                                        <select class="browser-default" [(ngModel)]="desktopGridSize"
                                                            (change)="
                                          gridNoChanged($event.target.value, 'desktop');
                                         
                                        ">
                                                            <option value="" disabled selected>
                                                                Choose your option
                                                            </option>
                                                            <option value="l6">2 x 2</option>
                                                            <option value="l4">3 x 3</option>
                                                            <option value="l3">4 x 4</option>
                                                            <option value="l2">6 x 6</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col s12 m6 l6">
                                                    <div class="input-field">
                                                        <div class="label-input">Mobile Screen</div>
                                                        <select class="browser-default" [(ngModel)]="mobileGridSize"
                                                            (change)="
                                          gridNoChanged($event.target.value, 'mobile');
                                       
                                        ">
                                                            <option value="" disabled selected>
                                                                Choose your option
                                                            </option>
                                                            <option value="s12">1 x 1</option>
                                                            <option value="s6">2 x 2</option>
                                                            <option value="s4">3 x 3</option>
                                                            <option value="s3">4 x 4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- ===========================Card Shape Option================================ -->
                                    <li class="row">
                                        <div class="collapsible-header">
                                            <i class="material-symbols-outlined">interests</i>Shape
                                        </div>
                                        <div class="collapsible-body">
                                            <div class="row card-shape">
                                                <!-- ============================== card option====================== -->
                                                <div class="col s12 m5 card">
                                                    <h6 class="col s12 sub-title-section bold-700 p-b-1">
                                                        Card Shape
                                                    </h6>
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardShape" value="cardOption == 'Rectangle'"
                                                            [checked]="cardOption == 'Rectangle'" (click)="cardOptionClicked('Rectangle'); 
                                        " />
                                                        <span>Rectangular Button</span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardShape" value="cardOption == 'Curved'"
                                                            [checked]="cardOption == 'Curved'" (click)=" cardOptionClicked('Curved');  
                                        " />
                                                        <span>Curved Button</span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardShape" value="cardOption == 'Round'"
                                                            [checked]="cardOption == 'Round'" (click)="cardOptionClicked('Round');  
                                        " />
                                                        <span>Round Button</span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardShape" value="cardOption == 'Hexagon'"
                                                            [checked]="cardOption == 'Hexagon'" (click)="cardOptionClicked('Hexagon');  
                                        " />
                                                        <span>Hexagon Button</span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardShape" value="cardOption == 'None'"
                                                            [checked]="cardOption == 'None'" (click)=" cardOptionClicked('None');  
                                        " />
                                                        <span>No Button</span>
                                                    </label>
                                                    <br />
                                                </div>

                                                <!-- ============================== icon option====================== -->
                                                <div class="col s12 m5 offset-m1 card" *ngIf="imageOption == 'no'">
                                                    <h6 class="col s12 sub-title-section bold-700 p-b-1">
                                                        Icon / Image Shape
                                                    </h6>

                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardIconShape" value="cardIconOption == 'Rectangle'"
                                                            [checked]="cardIconOption == 'Rectangle'" (click)="cardIconOptionClicked('Rectangle');
                                         
                                        " />
                                                        <span>Rectangular </span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardIconShape" value="cardIconOption == 'Curved'"
                                                            [checked]="cardIconOption == 'Curved'" (click)="cardIconOptionClicked('Curved');  
                                        " />
                                                        <span>Curved </span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardIconShape" value="cardIconOption == 'Round'"
                                                            [checked]="cardIconOption == 'Round'" (click)="cardIconOptionClicked('Round'); 
                                        " />
                                                        <span>Round </span>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardIconShape" value="cardIconOption == 'Hexagon'"
                                                            [checked]="cardIconOption == 'Hexagon'" (click)="cardIconOptionClicked('Hexagon');
                                         
                                        " />
                                                        <span>Hexagon </span>
                                                    </label>
                                                    <br />

                                                    <label>
                                                        <input class="col s12 radioBtn with-gap" type="radio"
                                                            name="cardIconShape" value="cardIconOption == 'None'"
                                                            [checked]="cardIconOption == 'None'" (click)="cardIconOptionClicked('None'); 
                                        " />
                                                        <span>No Button</span>
                                                    </label>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- ===========================Card Font Option================================ -->
                                    <li class="row">
                                        <div class="collapsible-header">
                                            <i class="material-symbols-outlined">format_size</i>Font
                                        </div>
                                        <div class="collapsible-body">
                                            <!-- ================ font size ============ -->
                                            <div class="col s12 font-size">
                                                <h4 class="title-section3 bold-300">Font Size</h4>
                                                <label>
                                                    <input class="col s3 radioBtn with-gap" type="radio" name="cardSize"
                                                        value="cardSizeOption == 'Small'"
                                                        [checked]="cardSizeOption == 'Small'" (click)="cardSizeOptionClicked('Small'); 
                                      " />
                                                    <span class="small-t">A </span>
                                                </label>
                                                <label>
                                                    <input class="col s3 radioBtn with-gap" type="radio" name="cardSize"
                                                        value="cardSizeOption == 'Medium'"
                                                        [checked]="cardSizeOption == 'Medium'" (click)="cardSizeOptionClicked('Medium'); 
                                      " />
                                                    <span class="medium-t">A </span>
                                                </label>
                                                <label>
                                                    <input class="col s3 radioBtn with-gap" type="radio" name="cardSize"
                                                        value="cardSizeOption == 'Large'"
                                                        [checked]="cardSizeOption == 'Large'" (click)="cardSizeOptionClicked('Large'); 
                                      " />
                                                    <span class="large-t">A </span>
                                                </label>
                                            </div>
                                            <!-- ================ font positon  ============ -->
                                            <div class="col s12 font-size">
                                                <h4 class="title-section3 bold-300">Font Postion</h4>
                                                <label>
                                                    <input class="col s3 radioBtn with-gap" type="radio"
                                                        name="titlePosition" value="titlePositionOption == 'Top'"
                                                        [checked]="titlePositionOption == 'Top'" (click)="titlePositionOptionClicked('Top');  
                                      " />
                                                    <span>Top</span>
                                                </label>
                                                <label>
                                                    <input class="col s3 radioBtn with-gap" type="radio"
                                                        name="titlePosition" value="titlePositionOption == 'Bottom'"
                                                        [checked]="titlePositionOption == 'Bottom'" (click)="titlePositionOptionClicked('Bottom');
                                       
                                      " />
                                                    <span> Bottom </span>
                                                </label>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- ===========================Preview================================ -->
                                    <li class="row">
                                        <div class="collapsible-header">
                                            <i class="material-symbols-outlined">preview</i>Preview
                                        </div>
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="">
                                                    <app-card-view-landing-page [preview]="true"
                                                        [sections]="previewSection" [onlyCards]="true">
                                                    </app-card-view-landing-page>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <a href="#!" class="waves-effect waves-blue btn-flat" (click)="saveChangess()">Save</a>

                            <a href="#!" class="modal-close waves-effect waves-green btn-flat"
                                (click)="closeEditCard()">Cancel</a>
                        </div>
                    </div>

                    <!-- -----------===================== card Icon =============-------------------- -->

                    <div id="iconPickerModal" class="modal modal-fixed-footer">
                        <div class="modal-content">
                            <div class="slider-modal-header row">
                                <h4 class="col s11">Icon Selector</h4>
                                <span class="col s1 modal-close material-symbols-outlined"
                                    [ngStyle]="{ color: themeColor }" style="cursor: pointer" type="button">close</span>
                            </div>
                            <!-- Inside your modal content -->
                            <div class="row">
                                <div class="input-field col s12">
                                    <input id="iconSearch" type="text" class="validate" [(ngModel)]="searchQuery"
                                        placeholder="Search icons" (input)="filterIcons()" />
                                </div>
                            </div>

                            <!-- Inside your modal content -->
                            <div class="row">
                                <ul>
                                    <li *ngFor="let icon of filteredIcons" (click)="selectIcon(icon)" class="col l6 s6">
                                        <label class="icon-container">
                                            <input class="with-gap" name="group1" type="radio" value="yes" />
                                            <span class="icon-text">
                                                <i class="material-symbols-outlined bold-300 p-r-1">{{icon}}</i>
                                                {{ icon }}
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="modal-footer left">
                            <button class="btn-small col s1 l1  save-button"
                                (click)="saveSelectedIcon(selectedIcon); closeIModal()"
                                [ngStyle]="{ 'background-color': themeColor }">
                                Save
                            </button>

                            <button class="btn-small col s1 l1 push-l1 cancel-button modal-close"
                                [ngStyle]="{ color: themeColor }" (click)="closeIModal()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- ========================================= ALL ============================================== -->
        <div class="row ">
            <button class="col s12 l2 m2 offset-l9 offset-m9  btn save-button p-m-3" style="margin-bottom: 15px;"
                (click)="saveChanges();">Save
                Changes</button>
        </div>

    </div>
</div>