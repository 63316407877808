<div class="section p-m-2"></div>

<div class="row container">
    <form class="row col s12" [formGroup]="courseForm" (ngSubmit)="submitCourses(courseForm.controls.course.value)">
        <div class="row col s12 teal lighten-2 white-text">
            <h5 class="center-align">Courses Registration</h5>
        </div>
        <br>

        <section></section>
        <div formGroupName="course">
            <div class="col s12 card white">
                <br>
                <div class="row col s12">Do you want to Register Course ?</div>
                <div class="row input-field col s12">
                    <div class="row col s12">
                        <label class="col s6">
                            <input type="radio" value="yes" formControlName="add_course" (change)="onAddCourseYes()" class="with-gap" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input type="radio" value="no" formControlName="add_course" (change)="onAddCourseNo()" class="with-gap" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>
            <br>

            <div *ngIf="emptyCourse" class="center-align">
                <span>No Course Added.</span>
                <i class="col s12 material-icons medium">inventory</i>
            </div>

            <div *ngIf="displayCourse" class="row col s12 card white">
                <h6 class="card-title">Courses Added</h6>

                <div formArrayName="courses" *ngFor="let c of courseControls.controls; let i = index;">
                    <ul class="collapsible">

                        <li>
                            <div class="row collapsible-header"><i class="material-icons">class</i>
                                <span class="col s4"><strong>Course: </strong>
                                    {{courseControls.controls[i].controls.course.value}}</span>
                                <span class="col s4"><strong>Fiscal Year: </strong>
                                    {{courseControls.controls[i].controls.fiscal_year.value}}</span>
                                <span class="col s2"><strong>Term: </strong>
                                    {{courseControls.controls[i].controls.term.value}}</span>

                                <span class="col s1">
                                    <i class="material-icons blue-text small" (click)="onEditCourse(i)"
                                        style="cursor: pointer;">edit</i>
                                </span>
                                <span class="col s1">
                                    <i class="material-icons red-text small" (click)="onDeleteCourse(i)"
                                        style="cursor: pointer;">delete</i>
                                </span>
                            </div>

                            <div class="row collapsible-body">
                                <span class="col s6"> <strong>Start Date : </strong>
                                    {{courseControls.controls[i].controls.start_date.value}}</span>
                                <span class="col s6"> <strong>End Date : </strong>
                                    {{courseControls.controls[i].controls.end_date.value}}</span>
                                <span class="col s6"> <strong>Tuition : </strong>
                                    {{courseControls.controls[i].controls.tuition.value}}</span>
                                <span class="col s6"> <strong>Books : </strong>
                                    {{courseControls.controls[i].controls.books.value}}</span>
                                <span class="col s6"> <strong>Tutoring : </strong>
                                    {{courseControls.controls[i].controls.tutoring.value}}</span>
                                <span class="col s6"> <strong>Other AMT.: </strong>
                                    {{courseControls.controls[i].controls.other.value}}</span>
                                <span class="col s6"> <strong>Description: </strong>
                                    {{courseControls.controls[i].controls.description.value}}</span>
                                <span class="col s6"> <strong>Completed: </strong>
                                    {{courseControls.controls[i].controls.completed.value}}</span>
                                <span class="col s6"> <strong>Completion Date: </strong>
                                    {{courseControls.controls[i].controls.completion_date.value}}</span>
                                <span class="col s6"> <strong>Final Mark: </strong>
                                    {{courseControls.controls[i].controls.mark.value}}</span>
                                <span class="col s6"> <strong>Status: </strong>
                                    {{courseControls.controls[i].controls.status.value}}</span>
                                <span class="col s6"> <strong>Reason Not Completed: </strong>
                                    {{courseControls.controls[i].controls.reason.value}}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row col s12 " style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addCourse">
                    <p class="center-align">Do You wish to Add Another Course ?</p>
                    <button class="col s12 btn green" type="button" (click)="addAnotherCourse()"><i
                            class="material-icons">add</i>Add Another Course</button>
                </div>
            </div>

            <div class="section"></div>

            <div *ngIf="addCourse" class="row col s12 card white">

                <div class="col s12 card-content">
                    <span class="material-icons right" (click)="closeAddCourse()" style="cursor: pointer;">close</span>
                    <span class="card-title center-align col s12" *ngIf="!EIDT_COURSE">Add Course </span>
                    <span class="card-title center-align col s12" *ngIf="EIDT_COURSE">Edit Course </span>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix red-text">class</i>
                        <input id="course" type="text" formControlName="course" class="validate"
                            style="text-transform: capitalize;" maxlength="50">
                        <label for="course">Course<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix pink-text">pin</i>
                        <select formControlName="fiscal_year" [(ngModel)]='selected_year' class="validate">
                            <option *ngFor="let y of courseYears" [value]="y">{{y}}</option>
                        </select>
                        <label for="fiscal_year">Fiscal Year<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix blue-text">tab</i>
                        <select formControlName="term" class="validate" [(ngModel)]='selected_term'>
                            <option *ngFor="let t of courseTerms" [value]="t">{{t}}</option>
                        </select>
                        <label for="term">Term<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field class col s12">
                        <i class="material-icons prefix orange-text">calendar_today</i>
                        <input id="start_date" formControlName="start_date" type="text" class="input-box"
                            angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()" [options]="myDpOptions"
                            #dp1="angular-mydatepicker" (dateChanged)="onDateChange1($event)" />
                        <label for="start_date">Start Date <span class="red-text">*</span></label>
                    </div>

                    <div class="input-field class col s12">
                        <i class="material-icons prefix blue-text text-darken-3">calendar_today</i>
                        <input id="end_date" formControlName="end_date" type="text" class="input-box"
                            angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()" [options]="myDpOptions"
                            #dp2="angular-mydatepicker" (dateChanged)="onDateChange2($event)" />
                        <label for="end_date">End Date <span class="red-text">*</span></label>
                    </div>
                    <div class="input-field col s12 m3 l3">
                        <i class="material-icons prefix green-text">paid</i>
                        <input id="tuition" type="number" formControlName="tuition"
                            (change)="setTuitionIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                            step="0.25" value="0.00">
                        <label for="tuition">Tuition $</label>
                    </div>

                    <div class="input-field col s12 m3 l3">
                        <i class="material-icons prefix green-text">paid</i>
                        <input id="books" type="number" formControlName="books"
                            (change)="setBooksIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                            step="0.25" value="0.00">
                        <label for="books">Books $</label>
                    </div>

                    <div class="input-field col s12 m3 l3">
                        <i class="material-icons prefix green-text">paid</i>
                        <input id="tutoring" type="number" formControlName="tutoring"
                            (change)="setTutoringIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                            step="0.25" value="0.00">
                        <label for="tutoring">Tutoring $</label>
                    </div>


                    <div class="input-field col s12 m3 l3">
                        <i class="material-icons prefix green-text">paid</i>
                        <input id="other" type="number" formControlName="other"
                            (change)="setOtherIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                            step="0.25" value="0.00">
                        <label for="other">Other $</label>
                    </div>


                    <div class="input-field col s12 " *ngIf="description_required">
                        <i class="material-icons prefix red-text">class</i>
                        <input id="description" type="text" formControlName="description" class="validate"
                            style="text-transform: capitalize;" maxlength="100">
                        <label for="description">Description for Other</label>
                    </div>

                    <div class="input-field col s12 " *ngIf="EIDT_COURSE">
                        <p> Completed: </p>
                        <div class="row col s12">
                            <label class="col s6">
                                <!-- disabled="true"-->
                                <input formControlName="completed" name="completed" type="radio" checked="checked" class="with-gap"
                                    (change)="isCompletedYes()" value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="completed" name="completed" type="radio" class="with-gap"
                                    (change)="isCompletedNo()" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>

                    <div class="input-field class col s12" *ngIf="course_completed">
                        <i class="material-icons prefix blue-text text-darken-3">calendar_today</i>
                        <input id="completion_date" formControlName="completion_date" type="text" class="input-box"
                            angular-mydatepicker name="mydate" (click)="dp3.toggleCalendar()" [options]="myDpOptions"
                            #dp3="angular-mydatepicker" (dateChanged)="onDateChange3($event)" />
                        <label for="completion_date">Completion Date </label>
                    </div>

                    <div class="input-field col s12 " *ngIf="course_completed">
                        <i class="material-icons prefix green-text">pin</i>
                        <input id="mark" type="text" formControlName="mark" class="validate"
                            style="text-transform: capitalize;" maxlength="10">
                        <label for="mark">Final Mark</label>
                    </div>

                    <div class="input-field col s12 " *ngIf="reason_required">

                        <p>Current Status: </p>
                        <div class="row col s12">
                            <label class="col s4">
                                <input formControlName="status" name="status" type="radio" checked="checked" class="with-gap"
                                    value="in-progress" />
                                <span>In Progress</span>
                            </label>
                            <label class="col s4">
                                <input formControlName="status" name="status" type="radio" value="failed" class="with-gap" />
                                <span>Failed</span>
                            </label>

                            <label class="col s4">
                                <input formControlName="status" name="status" type="radio" value="dropped-out" class="with-gap" />
                                <span>Dropped Out</span>
                            </label>
                        </div>
                    </div>

                    <div class="input-field col s12 " *ngIf="reason_required">
                        <i class="material-icons prefix red-text">text_fields</i>
                        <input id="reason" type="text" formControlName="reason" class="validate"
                            style="text-transform: capitalize;">
                        <label for="reason">Reason</label>
                    </div>
                </div>

                <button class="col s12 btn green" type="button" style="margin-bottom: 15px;" *ngIf="!EIDT_COURSE"
                    (click)="addMyCourse(courseForm.controls.course.value)">Add Course </button>
                <!--  [disabled]="!courseForm.controls.course.valid"-->

                <button class="col s12 btn green" type="button" style="margin-bottom: 15px;" *ngIf="EIDT_COURSE"
                    (click)="updateMyCourse(courseForm.controls.course.value)">Update Course </button>
                <!-- [disabled]="!courseForm.controls.course.valid" -->
            </div>
        </div>

        <!--  *ngIf="!addCourse"-->
        <button *ngIf="hasRegisteredCourses" class="col s12 m4 l3 btn left waves-effect blue" type="button"
            style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button *ngIf="hasRegisteredCourses" class="col s12 m4 offset-m2 l3 btn right waves-effect blue" type="submit"
            style="margin-bottom: 15px" title="Save">Save Courses <i class="material-icons right">send</i></button>

    </form>
</div>