import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { Options } from 'sortablejs';
import { Router } from '@angular/router';
// import { EmbedVideoService } from 'ngx-embed-video';
import { DatePipe } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { toast } from "materialize-css";

declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})

export class HomePageComponent implements OnInit {

  adminThemeColor = environment.appTheme.adminThemeColor;
  adminFontColor = environment.appTheme.adminFontColor;
  showLoading = false;
  model: any[];
  iframehtml: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  options: Options = {
    animation: 150,
  };
  videoId: any;
  pinned: any;
  unpinned: any
  pinnedNews: any[];

  constructor(private location: Location,
    private staticContentService: StaticContentService,
    // private embedService: EmbedVideoService,
    private settingsService: AppSettingsService,
    public appSettingsService: AppSettingsService,
    private router: Router) {
    localStorage.setItem('from', 'Notification');
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.index = this.model.findIndex(x => x.id === element.id);
        });
        this.staticContentService.SortContentOnServer(this.model);
      }
    };
  }

  ngOnInit() {
    this.showLoading = true;
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model = this.model.filter(e => e.published === true);

        const sortedNewsList = this.model.sort((a, b) => {
          if (a.pinned === b.pinned) {
            if (a.pinned) {
              const dateA = new Date(a.pinnedDate);
              const dateB = new Date(b.pinnedDate);
              if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                return dateB.getTime() - dateA.getTime(); // Descending order
              }
              return isNaN(dateB.getTime()) ? 1 : -1; // Move invalid dates to the end
            } else {
              // Both are unpinned, sort by publicationDate
              const pubDateA = new Date(a.datePublished);
              const pubDateB = new Date(b.datePublished);

              // Check if both publication dates are valid
              if (!isNaN(pubDateA.getTime()) && !isNaN(pubDateB.getTime())) {
                return pubDateB.getTime() - pubDateA.getTime(); // Descending order
              }
              // If one of the dates is invalid, you can decide how to handle it
              return isNaN(pubDateB.getTime()) ? 1 : -1; // Move invalid dates to the end
            }
          }
          return a.pinned ? -1 : 1;
        });

        this.model = sortedNewsList;
        // this.model.sort((a, b) => a.datePublished - b.datePublished);
        // this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

        this.showLoading = false;
      }
    });
    this.showLoading = false;
    $('.tooltipped').tooltip();
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  getHowLongAgo(postDate) {
    const datePipe = new DatePipe('en-US');
    let today = new Date();
    let difference = datePipe.transform(today.getTime() - postDate.getTime(), 'dd');
    return parseInt(difference);
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.model, event.previousIndex, event.currentIndex);
  //   // Update the display order
  //   this.model.forEach((item, index) => {
  //     item.displayOrder = index;
  //   });
  //   // Save the new order
  //   this.staticContentService.SortContentOnServer(this.model);
  // }

  onPin(i) {
    this.pinned = i;
    $(".modal").modal();
  }

  onUnpin(i) {
    this.unpinned = i;
    $(".modal").modal();
  }

  pin() {
    let pinnedNews = this.model[this.pinned];
    pinnedNews.pinned = true;
    pinnedNews.pinnedDate = new Date();
    this.staticContentService.UpdateContent(pinnedNews).then(resp => {
      toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i> <span class="white-text"> News Pinned Successfully.</span>', classes: "green rounded" });
      $("#pinModal").modal('close');
    });
  }

  unpin() {
    let pinnedNews = this.model[this.unpinned];
    pinnedNews.pinned = false;
    pinnedNews.pinnedDate = null;
    this.staticContentService.UpdateContent(pinnedNews).then(resp => {
      toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i> <span class="white-text"> News Unpinned Successfully.</span>', classes: "green rounded" });
      $("#pinModal").modal('close');
    });
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}
