<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="">Create an Application</h4>
                <p class="bold-100 sub-title-section3">This is the menu that the general public will have
                    access to on your mobile app</p>
            </div>
        </div>

        <main class="StickyContent white ">

            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row col s12" *ngIf="step == 0 && templates">
                <div class="row  card white">
                    <div class="row col s12 center-align">
                        <p>Please select the sections you want to include in your applications.</p>
                        <p>You will be able edit and customize each section.</p>
                        <P>If you want to add a new section click on the new section button located at the bottom right
                            of the
                            screen.
                        </P>
                        <a class="waves-effect waves-light btn col s12 m8 offset-m2 blue" (click)="next()"
                            style="margin-bottom: 25px;"><i class="material-icons left">note_add</i>Blank
                            Application</a>
                    </div>

                    <div class="row col s12">
                        <p><strong>Select one or more section to get started.</strong></p>
                        <ul class="collection">
                            <li class="collection-item" *ngFor="let section of templates;let i=index">
                                <div>{{section.title}}
                                    <a class="secondary-content">
                                        <label>
                                            <input type="checkbox" (click)="selectSection(i)" />
                                            <span></span>
                                        </label>
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <button class="waves-effect waves-light btn col col s12 m8 offset-m2 green" (click)="next()"
                            [disabled]="!getStarted()" style="margin-bottom: 15px;"><i
                                class="material-icons left">note_add</i>Get
                            Started</button>
                    </div>

                </div>

                <div class="row col s12" *ngIf="step < 0">
                    <div class="row">
                        <p>Select one or more section to get started.</p>
                        <div class="row">
                            <div class="row">
                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Applicant Information</strong></h6>
                                            <!-- <p>First Name, Last Name, Date of Birth, SIN, ISN, Marital Status......... of the primary
                                                        Applicant</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Contact Information</strong></h6>
                                            <!-- <p>Telephone Number, Cellular Number, Work Number, Email Address.........</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Co-Applicant/Spouse Information</strong>
                                            </h6>
                                            <!-- <p>First Name, Last Name, Date of Birth, SIN, ISN, Marital Status......... of the Co-applicat/spouse</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Mailing Address</strong></h6>
                                            <!-- <p>Address, city, Province, Postal Code.........</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Income</strong></h6>
                                            <!-- <p>Income Source, Amount, Total.........</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m4">
                                    <div class="card horizontal">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Assets</strong></h6>
                                            <!-- <p>Asset, Amount........</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s12 m3">
                                    <div class="card">
                                        <div class="row card-content">
                                            <h6 class="center-align"><strong>Family Composition/Dependents</strong></h6>
                                            <!-- <p>First Name, Last Name, Date of Birth, SIN, ISN, Marital Status, School.........dependent</p> -->
                                        </div>
                                        <div class="card-action">
                                            <label class="right">
                                                <input type="checkbox" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row col s12">
                            <a class="col s12 waves-effect waves-light btn"><i class="material-icons right">start</i>Get
                                Started</a>
                        </div>
                    </div>
                </div>

                <br />

                <div class="row">
                    <button class="col s12 btn wave-effect" (click)="backToPreviousePage()">
                        <i class="material-icons left">house</i>Back To Previous Page
                    </button>
                </div>
            </div>

            <div class="row col s12" *ngIf="step == 1">
                <div class="col l10" *ngIf="step == 1">
                    <form class="row col s12 card white" [formGroup]="appForm"
                        (ngSubmit)="submitAppForm(appForm.value)">

                        <div class="input-field col s12 l6">
                            <div class="label-input">Application Name<span class="red-text">*</span></div>
                            <input id="name" type="text" class="validate" formControlName="name" autocomplete="off"
                                style="text-transform: capitalize;">
                            <div *ngIf="(appName.dirty || appName.touched) && appName.invalid">
                                <small *ngIf="appName.errors.required" class="red-text errorMsg">Application Name is
                                    required.
                                </small>
                            </div>
                        </div>
                        <div class="input-field col s12 l6">
                            <div class="label-input">Department<span class="red-text">*</span></div>
                            <select formControlName="department" class="browser-default">
                                <option value="" disabled selected>Select Department</option>
                                <option *ngFor="let item of departmentList" [value]='item'>{{item}}</option>
                            </select>
                        </div>

                        <div class="input-field col s12 l12">
                            <div class="label-input">Description</div>
                            <textarea id="description" type="text" class="validate" formControlName="description"
                                style="height: 250px;" class="materialize-textarea" autocomplete="off"
                                style="text-transform: capitalize;"></textarea>
                        </div>

                        <div class="col s12 file-field input-field">
                            <div class="btn">
                                <span>Browse <i class="material-icons">file_upload
                                    </i></span>
                                <input type="file" (change)="uploadLogo($event.target.files)" formControlName="logo"
                                    accept="image/*" />
                            </div>
                            <div class="file-path-wrapper">
                                <input class="file-path validate" type="text" placeholder="Choose a Logo" />
                            </div>

                            <div class="row col s6" *ngIf="logoSrc">
                                <a class="waves-effect waves-light btn-flat tooltipped" data-tooltip="Clear Image"
                                    (click)="clearLogo()"><i class="material-icons red-text">clear</i></a>
                                <img [src]="logoSrc" width="100px;" class="left">
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <p>
                                <label>
                                    <input type="checkbox" [checked]="appButton" (click)="addAppButton()" />
                                    <span>Customize Button</span>
                                </label>
                            </p>
                        </div>

                        <div class="row col s12" *ngIf="appButton" formGroupName="menu">
                            <h6><strong>Customize Menu</strong></h6>

                            <div class="input-field col s12">
                                <p>Choose a Menu Color</p>
                               
                                <a class="col s11 btn grey lighten-3 black-text" style="margin-bottom: 15px;">
                                    <strong>Select Color <input formControlName="color" type="color"></strong> </a>

                                <i class="material-icons col s1 small"
                                    [ngStyle]="{'color': appForm.get('menu.color').value}">
                                    circle
                                </i>

                            </div>
                            
                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">feed</i>
                                <select formControlName="icon" class="validate" id="icon">
                                    <option value="" disabled selected>Choose your option</option>
                                    <option *ngFor="let icon of icons" [value]="icon">
                                        <span>{{icon}}<i class="material-icons left teat-text">{{icon}}</i></span>
                                    </option>
                                </select>
                                <label for="icon">Icon</label>
                            </div>

                        </div>

                        <div class="row col s12">
                            <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green"
                                [disabled]="!appForm.valid">
                                <i class="material-icons right">navigate_next</i> Next
                            </button>
                            <button class="col s12 btn wave-effect" (click)="back()">
                                <i class="material-icons left">arrow_back</i>Back To Previous Page
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row col s12 valign-wrapper" *ngIf="step==2">
                <div class="row col l1">
                    <i *ngIf="currentSection > 0" class="material-icons left hide-on-small-only"
                        style="cursor: pointer; font-weight: bolder;; font-size: 25px;"
                        (click)="previousSection()">arrow_back_ios</i>
                </div>

                <div class="col l10">
                    <form [formGroup]="appDetail" (ngSubmit)="submitAppDetails(appDetail.value)">
                        <div class="row col s12 card white">
                            <div formArrayName="sections" *ngFor="let section of sectionControls.controls;let si=index">

                                <div class="row col s12" [formGroupName]='si'
                                    [ngStyle]="{'display': (currentSection == si)? 'block' : 'none'}">
                                    <div class="fixed-action-btn"
                                        style="position:relative; float:right; top:10px; right:10px; margin-bottom: 25px;">
                                        <a class="btn-floating white">
                                            <i class="large material-icons red-text">menu</i>
                                        </a>
                                        <ul>
                                            <li><a class="btn-floating tooltipped blue" data-position="top"
                                                    data-tooltip="Save as Template" (click)="saveSection(si)"><i
                                                        class="material-icons">save</i></a></li>

                                            <li><a class="btn-floating tooltipped red" data-position="top"
                                                    [ngClass]="{'disabled': (sectionControls.controls.length == 1)}"
                                                    data-tooltip="Delete Section" (click)="deleteSection(si)"><i
                                                        class="material-icons">delete</i></a></li>

                                            <li><a class="btn-floating tooltipped purple lighten-2" data-position="top"
                                                    [ngClass]="{'disabled': (sectionControls.controls.length == 1)}"
                                                    data-tooltip="Move Section"><i
                                                        class="material-icons">move_up</i></a></li>

                                            <li><a class="btn-floating tooltipped yellow darken-1" data-position="top"
                                                    data-tooltip="Duplicate Section" (click)="duplicateSection(si)"><i
                                                        class="material-icons">content_copy</i></a>
                                            </li>
                                            <li><a class="btn-floating tooltipped green" data-position="top"
                                                    data-tooltip="New Section" (click)="onAddSection()"><i
                                                        class="material-icons">add</i></a></li>
                                        </ul>
                                    </div>
                                    <h6 class="col s12 center-align">Section {{si+1}} of
                                        {{sectionControls.controls.length}}</h6>

                                    <div class="input-field col s12">
                                        <i class="material-icons prefix blue-text">info</i>
                                        <input id="title" type="text" class="validate" formControlName="title"
                                            placeholder="untitled" autocomplete="off"
                                            style="text-transform: capitalize;">
                                        <label for="title">Title<span class="red-text">*</span></label>
                                    </div>

                                    <div class="input-field col s12">
                                        <i class="material-icons prefix blue-text">description</i>
                                        <textarea id="statement" class="materialize-textarea textarea" rows="3"
                                            style="height: 150px;" type="text" formControlName="statement"
                                            style="text-transform: capitalize;"></textarea>
                                        <label for="statement">Description</label>
                                    </div>

                                    <div class="input-field col s12">
                                        <i class="material-icons prefix blue-text">storage</i>
                                        <select formControlName="data" class="validate" id="icon">
                                            <option value="" disabled selected>Choose your option</option>
                                            <option *ngFor="let data of tables" [value]="data"> {{data}}</option>
                                        </select>
                                        <label>Get Data From</label>
                                    </div>

                                    <div class="input-field col s12">
                                        <div>Accept Multiple Values</div>
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input name="multipleEntry" class="with-gap" type="radio" formControlName="multipleEntry"
                                                    [value]="true" />
                                                <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input name="multipleEntry" class="with-gap" type="radio" formControlName="multipleEntry"
                                                    [value]="false" />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>

                                    <!------------------------------------------fields--------------------------------------------------->
                                    <div [sortablejs]="getSectionFields(si).controls" [sortablejsOptions]="options">
                                        <div class="col s12" formArrayName="fields">
                                            <!-- <h6 class="center-align col s12">Fields</h6> -->
                                            <div class="row col s12"
                                                *ngFor="let field of getSectionFields(si).controls; let fi=index">

                                                <div [formGroupName]="fi" style="margin-top: 20px;">

                                                    <div class="row col s12">

                                                        <i class="small material-icons blue-text right"
                                                            *ngIf="!showFieldDetail[fi]" (click)="onShowFieldDetail(fi)"
                                                            style="cursor: pointer;">unfold_more</i>

                                                        <i class="small material-icons blue-text right"
                                                            *ngIf="showFieldDetail[fi]" (click)="onShowFieldDetail(fi)"
                                                            style="cursor: pointer;">unfold_less</i>

                                                        <i class="small material-icons red-text right"
                                                            (click)="deleteField(si, fi)">delete</i>

                                                    </div>

                                                    <div class="input-field col s12">
                                                        <i class="material-icons prefix green-text">label</i>
                                                        <input id="label" type="text" class="validate"
                                                            formControlName="label" placeholder="Untitled Field"
                                                            autocomplete="off" style="text-transform: capitalize;">
                                                        <label for="label">Label<span class="red-text">*</span></label>
                                                    </div>

                                                    <div *ngIf="showFieldDetail[fi]">
                                                        <div class="input-field col s12 m6">
                                                            <i class="material-icons prefix blue-text">description</i>
                                                            <input id="default_value" type="text"
                                                                formControlName="default_value" class="validate"
                                                                autocomplete="off" style="text-transform: capitalize;">
                                                            <label for="default_value">default_value</label>
                                                        </div>

                                                        <div class="input-field col s12 m6">
                                                            <i class="material-icons prefix blue-text">feed</i>
                                                            <select formControlName="icon" class="validate" id="icon"
                                                                (change)="iconSelected(fi)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let icon of icons" [value]="icon">
                                                                    <span>{{icon}}<i
                                                                            class="material-icons left teat-text">{{icon}}</i></span>
                                                                </option>
                                                            </select>
                                                            <label for="icon">Icon</label>
                                                        </div>

                                                        <div class="input-field col s12">
                                                            <i class="material-icons prefix blue-text">list</i>
                                                            <select formControlName="field_type" class="validate"
                                                                id="field_type"
                                                                (change)="fieldTypeSelected($event.target.value,si,fi)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let type of fieldTypes"
                                                                    [value]="type.value">
                                                                    {{type.name}}
                                                                </option>
                                                            </select>
                                                            <label for="field_type">Field Type<span
                                                                    class="red-text">*</span></label>
                                                        </div>

                                                        <div
                                                            *ngIf="fieldType == 'Select' || fieldType == 'Radio' || fieldType == 'Checkbox'">

                                                            <p>field type selected</p>
                                                            <div class="row col s12" formArrayName="options">
                                                                <p>Please enter options for the dropdown</p>
                                                                <div class="row col s12"
                                                                    *ngFor="let option of getOptions(si, fi)?.controls; let oi=index">
                                                                    <div class="input-field col s10">
                                                                        <input [id]="oi" [formControlName]="oi"
                                                                            type="text" class="validate">
                                                                        <label [for]="oi">Value</label>
                                                                    </div>
                                                                    <i *ngIf="oi == 0"
                                                                        class="small material-icons right prefix blue-text"
                                                                        (click)="addOption(si, fi)">add_box</i>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="fieldType == 'Scale'" formGroupName="scales">
                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix green-text">linear_scale</i>
                                                                <select formControlName="start" class="validate">
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                </select>
                                                                <label for="start">Start At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix green-text">label</i>
                                                                <input id="startLabel" type="text"
                                                                    formControlName='startLabel' class="validate" />
                                                                <label for="startLabel">Label</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i
                                                                    class="material-icons prefix blue-text">linear_scale</i>
                                                                <select formControlName="end" class="validate">
                                                                    <option *ngFor="let s of scales" [value]="s">{{s}}
                                                                    </option>
                                                                </select>
                                                                <label for="end">End At</label>
                                                            </div>

                                                            <div class="input-field col s6 m3">
                                                                <i class="material-icons prefix blue-text">label</i>
                                                                <input id="endLabel" type="text"
                                                                    formControlName='endLabel' class="validate" />
                                                                <label for="endLabel">Label</label>
                                                            </div>
                                                        </div>

                                                        <div class="input-field col s12">
                                                            <i class="material-icons prefix blue-text">feed</i>
                                                            <select formControlName="data_type" class="validate"
                                                                id="data_type"
                                                                (change)="dataTypeSelected($event.target.value, f)">
                                                                <option value="" disabled selected>Choose your option
                                                                </option>
                                                                <option *ngFor="let type of dataTypes" [value]="type">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                            <label for="data_type">Data Type<span
                                                                    class="red-text">*</span></label>
                                                        </div>

                                                        <div class="row input-field col s12">
                                                            <div class="row col s12">
                                                                <label class="col s6">
                                                                    <input type="radio" [value]="true" class="with-gap"
                                                                        formControlName="required" />
                                                                    <span>Required</span>
                                                                </label>
                                                                <label class="col s6">
                                                                    <input type="radio" [value]="false" class="with-gap"
                                                                        formControlName="required" />
                                                                    <span>Optional</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="field.multipleEntry">
                                                        <button type="button" style="margin-bottom: 15px;">
                                                            <i
                                                                class="material-icons right black-text">add</i><strong>Add</strong>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12 card-action">
                                <button type="button"
                                    class="col l4 m5 s12 left btn waves-effect blue lighten-4 black-text"
                                    style="margin-bottom: 15px;" (click)="onAddField()">
                                    <i class="material-icons right black-text">add</i><strong> Add Field</strong>
                                </button>

                                <button *ngIf="currentSection == sectionControls.controls.length-1" type="button"
                                    style="margin-bottom: 15px;"
                                    class="col l4 m5 s12 right btn waves-effect green lighten-4 black-text"
                                    (click)="onAddSection()">
                                    <i class="material-icons right black-text">library_add</i><strong> New
                                        Section</strong>
                                    <!-- [disabled]="!appDetail.get('sections').controls[si].valid" -->
                                </button>

                                <button *ngIf="currentSection < sectionControls.controls.length-1" type="button"
                                    (click)="nextSection()" style="margin-bottom: 15px;"
                                    class="col l4 m5 s12 right btn waves-effect green lighten-4 black-text">
                                    <i class="material-icons right black-text">navigate_next</i><strong> Next
                                        Section</strong>
                                </button>
                            </div>

                            <div class="row col s12">
                                <div class="row center-align" *ngIf="sectionControls.controls.length > 1">
                                    <ul class="pagination">
                                        <li *ngIf="currentSection > 0" class="waves-effect" (click)="previousSection()">
                                            <a><i class="material-icons">chevron_left</i></a></li>
                                        <li *ngFor="let section of sectionControls.controls;let i=index"
                                            (click)="goto(i)" [ngClass]="{'active': (currentSection == i)}">
                                            <a>{{i+1}}</a></li>

                                        <li *ngIf="currentSection < sectionControls.controls.length-1"
                                            class="waves-effect" (click)="nextSection()"><a><i
                                                    class="material-icons">chevron_right</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green">
                                <i class="material-icons right">save</i> Save Application
                            </button>

                            <button class="col s12 btn wave-effect" (click)="back()" type="button">
                                <i class="material-icons left">arrow_back</i>Back To Previous Page
                            </button>
                        </div>
                    </form>
                </div>

                <div class="row col l1">
                    <i *ngIf="currentSection != sectionControls.controls.length-1"
                        style="cursor: pointer;font-weight: bolder;; font-size: 25px;"
                        class="material-icons right hide-on-small-only" (click)="nextSection()">arrow_forward_ios</i>
                </div>
            </div>

            <div class="row col s12 card white" *ngIf="step == 3">
                <div class="col l1"></div>
                <div class="card-content col l10">
                    <div class="row center">
                        <i style="font-size: 100px;" class="material-icons green-text">
                            check_circle
                        </i>
                        <h5 class="green-text"> <strong>Application Successfully Created</strong></h5>
                        <br />

                        <div class="row col s12" style="margin-top: 25px;">

                            <button type="button" [routerLink]="['/admin/applications/preview', model.name]"
                                class="col s12 btn waves-effect green white-text" style="margin-bottom: 15px;">
                                <i class="material-icons right white-text">pageview</i><strong>Preview
                                    Application</strong>
                            </button>

                            <button type="button" [routerLink]="['/admin/applications/edit', model.name]"
                                style="margin-bottom: 15px;" class="col s12 right btn waves-effect blue"
                                style="margin-bottom: 15px;">
                                <i class="material-icons right white-text">edit</i><strong>Edit Application</strong>
                            </button>

                            <button [ngClass]="'col s12 btn waves-effect ' + themeColor"
                                (click)="backToPreviousePage()">
                                <i class="material-icons left">home</i> Close
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col l1"></div>
            </div>

        </main>
    </div>
</div>