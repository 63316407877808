<div class="body-h container-pi">
  <!-- <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class=""> Edit Work Experience </h4>
    </div>
  </div> -->

  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
  </span>Edit Work Experience</h4>

  <main class="StickyContent white row">
    <div id="step0" *ngIf="model" class="col s12 l11">
      <form (ngSubmit)="submitForm(editEmploymentForm.value)" [formGroup]="editEmploymentForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">Job Title <strong class="red-text">*</strong> </div>
            <input formControlName="Name" id="Name" type="text" maxlength="75" autocomplete="off" required
              class="capitalize validate">
            <div *ngIf="(Name.dirty || Name.touched) && Name.invalid">
              <small *ngIf="Name.errors.required" class="red-text"> Please enter the job title
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Description<strong class="red-text">*</strong></div>
            <input formControlName="description" id="description" type="text" maxlength="100" autocomplete="off"
              required class="capitalize validate">
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> Please enter the description.
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Employer Name<strong class="red-text">*</strong></div>
            <input formControlName="employer" id="employer" type="text" maxlength="100" autocomplete="off" required
              class="capitalize validate">
            <div *ngIf="(employer.dirty || employer.touched) && employer.invalid">
              <small *ngIf="employer.errors.required" class="red-text"> Please enter the employer name.
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">City<strong class="red-text">*</strong></div>
            <input formControlName="City" id="City" type="text" maxlength="100" autocomplete="off" required
              class="capitalize validate">
            <div *ngIf="(City.dirty || City.touched) && City.invalid">
              <small *ngIf="City.errors.required" class="red-text"> Please enter the employer city.
              </small>
            </div>
          </div>

          <div class="row input-field col s12 l6">
            <div class="label-input">Please Select a Province</div>
            <select formControlName="Prov" required class="validate browser-default">
              <option value="" disabled selected>Please Select a Province </option>
              <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
            </select>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Start Date<strong class="red-text">*</strong></div>
            <input id="start_date" formControlName="start_date" type="text" type="text" class="input-box"
              angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()" [options]="myDpOptions"
              #dp1="angular-mydatepicker" (dateChanged)="onStartDateChange($event)" />
          </div>

          <div class="input-field class col s12 l6" *ngIf="showEndDate">
            <div class="label-input">End Date<strong class="red-text">*</strong></div>
            <input id="end_date" formControlName="end_date" type="text" class="input-box" angular-mydatepicker
              name="mydate" (click)="dp2.toggleCalendar()" [options]="myDpOptions" #dp2="angular-mydatepicker"
              (dateChanged)="onEndDateChange($event)" />
          </div>

          <div class="row input-field col s12 l6" *ngIf="showEndDate"><span class="black-text"><strong>
                <h6> Reason for Leaving</h6>
              </strong></span>
            <div class="md-form mb-4 pink-textarea active-pink-textarea">
              <textarea id="reason" formControlName="reason" class="md-textarea form-control" rows="3"
                maxlength="90"></textarea>
            </div>
          </div>

          <div class="row input-field col s12 l6">
            <div class="row col s12">Is it your Current Employment ?</div>
            <div class="row col s12">
              <label class="col s6">
                <input class="with-gap" formControlName="iscurrent" name="iscurrent" type="radio" checked="checked"
                  value="yes" (change)="onCurrentEmploymentYes($event)" />
                <span>Yes</span>
              </label>
              <label class="col s6">
                <input class="with-gap" formControlName="iscurrent" name="iscurrent" type="radio" value="no"
                  (change)="onCurrentEmploymentNo($event)" />
                <span>No</span>
              </label>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!editEmploymentForm.valid">
            <i class="material-icons right">keyboard_arrow_right</i> Update
          </button>
          <button data-target="modal1" class="col s12 btn modal-trigger red " style="margin-bottom: 15px;">Remove
            Employment</button>
          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Work Experience List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h5>Delete Employment</h5>
    <strong> Do You Want to Remove This Employment ? </strong>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="DeleteEmployment()" class="btn waves-effect blue white-text"
      style="margin-right: 15px;"> Yes
    </button>
    <a class="modal-close amber darken-4 white-text btn-flat white-text">No</a>
  </div>
</div>