<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2" *ngIf="!showProfile">
            <h4 class=""> <i class="material-symbols-outlined tiny bold-900 p-r-1"
                (click)="backClicked()"> arrow_back </i>Add Members </h4>
        </div>

        <main class="StickyContent white ">
            <div class="row col s12" *ngIf="!showProfile">
                <div class="col s12">
                    <form [formGroup]="addMemberForm" (ngSubmit)="addMemberIfNotExist(addMemberForm.value)">
                        <div class="row card white">

                            <div class="input-field col s12 l6">
                                <div class="label-input">First Name<span class="red-text">*</span></div>
                                <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                                    style="text-transform: capitalize;" placeholder="First Name" class="validate" />
                                <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid"
                                    class="required">
                                    <small *ngIf="firstName.errors.required" class="red-text"> First Name is required.
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Middle Name</div>
                                <input id="middleName" type="text" formControlName='middleName' maxlength="49"
                                    autocomplete="off" style="text-transform: capitalize;" placeholder="Middle Name"
                                    class="validate" />
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Last Name<span class="red-text">*</span></div>
                                <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                                    style="text-transform: capitalize;" placeholder="Last Name" class="validate" />
                                <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                    <small *ngIf="lastName.errors.required" class="red-text"> Last Name is required.
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></div>
                                <input id="dob" type="date" formControlName="dob">
                                <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                                    <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required.
                                    </small>
                                    <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date is invalid </small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Gender<span class="red-text">*</span></div>
                                <select formControlName="gender" class="validate">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let g of genders" [value]="g.value">{{g.gender}}</option>
                                </select>
                                <div *ngIf="(gender.dirty || gender.touched) && gender.invalid">
                                    <small *ngIf="gender.errors.required" class="red-text"> Gender is required. </small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">ISN</div>
                                <!-- <input id="isn" type="text" class="validate" formControlName='isn' autocomplete="off"
                                    [textMask]="{mask: isnMask, guide: false}" placeholder="ISN" /> -->
                                <input id="isn" type="text" [inputMask]="isnMask" class="validate" formControlName='isn'
                                    autocomplete="off" placeholder="ISN" />
                                <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                                    <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text"> The
                                        ISN
                                        must be 10
                                        Digits.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Email</div>
                                <input id="email" type="email" formControlName='email' class="validate"
                                    autocomplete="off" placeholder="Email" />
                                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                    <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                                </div>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Home Number</div>
                                <!-- <input id="telephone" type="text" formControlName='telephone' class="validate"
                                    autocomplete="off" [textMask]="{mask: phoneNumberMask}" placeholder="Home Number" /> -->
                                <input id="telephone" type="text" [inputMask]="phoneInputMask"
                                    formControlName='telephone' class="validate" autocomplete="off"
                                    placeholder="Home Number" />
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Mobile Number</div>
                                <!-- <input id="cellPhone" type="text" formControlName='cellPhone' class="validate"
                                    autocomplete="off" [textMask]="{mask: phoneNumberMask}"
                                    placeholder="Mobile Number" /> -->
                                <input id="cellPhone" type="text" [inputMask]="phoneInputMask"
                                    formControlName='cellPhone' class="validate" autocomplete="off"
                                    placeholder="Mobile Number" />
                            </div>

                            <div class="input-field col s12 l6" *ngIf="nationofUse === 'Batc'">
                                <div class="label-input">Health Care Number</div>
                                <input id="healthCareNumber" type="number" class="validate"
                                    formControlName='healthCareNumber' autocomplete="off"
                                    placeholder="Health Care Number" />
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Address</div>
                                <input id="address" type="text" formControlName='address' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" placeholder="Address" />
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">City</div>
                                <input id="city" type="text" formControlName='city' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" placeholder="City" />
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">Postal Code</div>
                                <!-- <input id="postalCode" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                                    formControlName="postalCode" class="validate" style='text-transform:uppercase'
                                    placeholder="Postal Code"> -->
                                <input id="postalCode" type="text" [inputMask]="postalCodeMask"
                                    formControlName="postalCode" class="validate" style='text-transform:uppercase'
                                    placeholder="Postal Code">
                            </div>

                            <div class="row input-field col s12 l6">
                                <div class="label-input">Province</div>
                                <select formControlName="province" class="validate">
                                    <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
                                </select>
                            </div>

                            <div class="input-field col s12 l6"
                                *ngIf="nationofUse === 'Batc' || nationofUse === 'Pbcn'">
                                <div class="label-input">Community</div>
                                <select formControlName="community" class="validate">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let c of nationCommunities" [value]="c">{{c}}</option>
                                </select>
                            </div>

                            <div class="input-field col s12 l6">
                                <div class="label-input">SIN</div>
                                <!-- <input id="sin" type="text" class="validate" formControlName='sin' autocomplete="off"
                                    [textMask]="{mask: SINMask}" placeholder="SIN" /> -->
                                <input id="sin" type="text" [inputMask]="SINMask" class="validate" formControlName='sin'
                                    autocomplete="off" placeholder="SIN" />
                                <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                                    <small *ngIf="sin.errors.minlength || sin.errors.maxlength" class="red-text">The SIN
                                        must be 9
                                        Digits.</small>
                                </div>
                            </div>

                            <div class="col s12">
                                <button type="submit" class="waves-effect waves-light btn-small col s12"
                                    [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}"
                                    style="margin-top: 15px; margin-bottom: 25px;" [disabled]="!addMemberForm.valid">
                                    <i class="material-icons right">person_add</i><span
                                        *ngIf="nationOfUse !== 'Batc'">Add
                                        Member</span> <span *ngIf="nationOfUse === 'Batc'">Add Client</span></button>

                                <!-- <button class="col s12 white" style="border: 1px solid;margin-bottom: 25px;"
                                    [ngStyle]="{'border-color': themeColor, 'color': themeColor}"
                                    (click)="backClicked()" [ngClass]="'btn-flat ' + buttonType"><i
                                        class="material-icons left">arrow_back_ios</i>Back To Previous
                                    Page</button> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    </div>
</div>

<div id="similarMembersModal" class="modal">
    <div class="modal-content">
        <div class="row col s12" *ngIf="hasMatch && !hasExactMatch">
            <h5>Member To be Added</h5>
            <br>
            <ul class="collapsible">
                <li>
                    <div class="row collapsible-header">
                        <div class="col s12">
                            <strong>Name:</strong> {{model?.GivenName}} {{model?.LastName}}
                        </div>
                        <div class="col s12">
                            <strong>ISN: </strong>{{model?.IndianStatusNo}}
                        </div>
                        <button type="button" class="btn waves-effect waves-light green btn-small"
                            [disabled]="!addMemberForm.valid" (click)="addMember(addMemberForm.value)">
                            <span *ngIf="nationOfUse !== 'Batc'">Add
                                Member</span> <span *ngIf="nationOfUse === 'Batc'">Add Client</span></button>
                    </div>
                    <div class="collapsible-body">
                        <div class="row collection">
                            <a class="collection-item">
                                <div>Given Name: <a class="secondary-content blue-text"><strong>
                                            {{model?.GivenName}}</strong> </a>
                                </div>
                            </a>
                            <a class="collection-item">
                                <div>Last Name: <a class="secondary-content blue-text"><strong>
                                            {{model?.LastName}}</strong> </a>
                                </div>
                            </a>
                            <a class="collection-item">
                                <div>Middle Name: <a class="secondary-content blue-text"><strong>
                                            {{model?.middle_name}}</strong>
                                    </a></div>
                            </a>
                            <a class="collection-item">
                                <div>Date Of Birth: <a class="secondary-content blue-text"><strong> {{model?.DOB| date:
                                            'dd/MMM/yyyy'}}</strong> </a></div>
                            </a>
                            <a class="collection-item">
                                <div>Gender:<a class="secondary-content blue-text"><strong> {{model?.Sex}}</strong> </a>
                                </div>
                            </a>

                            <a class="collection-item">
                                <div>Indian Status Number: <a class="secondary-content blue-text"><strong>
                                            {{model?.IndianStatusNo}}</strong>
                                    </a></div>
                            </a>

                            <a class="collection-item">
                                <div>Email: <a class="secondary-content blue-text"><strong> {{model?.Email}}</strong>
                                    </a></div>
                            </a>

                            <a class="collection-item">
                                <div>Home Number: <a class="secondary-content blue-text"><strong>
                                            {{model?.Telephone}}</strong>
                                    </a></div>
                            </a>

                            <a class="collection-item">
                                <div>Mobile Number: <a class="secondary-content blue-text"><strong>
                                            {{model?.cell_phone_num}}</strong> </a>
                                </div>
                            </a>

                            <a class="collection-item">
                                <div>Health Care Number: <a class="secondary-content blue-text"><strong>
                                            {{model?.HealthCareNo}}</strong> </a>
                                </div>
                            </a>

                            <a class="collection-item">
                                <div>Mail Address: <a class="secondary-content blue-text"><strong>
                                            {{model?.MailAddress}}</strong> </a></div>
                            </a>

                            <a class="collection-item">
                                <div>City: <a class="secondary-content blue-text"><strong> {{model?.city}}</strong> </a>
                                </div>
                            </a>

                            <a class="collection-item">
                                <div>Province: <a class="secondary-content blue-text"><strong>
                                            {{model?.province}}</strong> </a></div>
                            </a>

                            <a class="collection-item">
                                <div>Postal Code: <a class="secondary-content blue-text"><strong>
                                            {{model?.PostalCode}}</strong> </a>
                                </div>
                            </a>

                            <a class="collection-item">
                                <div>SIN: <a class="secondary-content blue-text"><strong> {{model?.SIN}}</strong> </a>
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <h5 *ngIf="!hasExactMatch">Possible Matches</h5>
        <h5 *ngIf="hasExactMatch">Exact Match</h5>
        <div class="row card-panel white darken-2">
            <table class="striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date Of Birth</th>
                        <th>ISN</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let m of matches; let i=index" (click)="showDetails(i)" style="cursor: pointer;">
                        <td class="blue-text">{{ m.GivenName }} {{ m.LastName }}</td>
                        <td class="blue-text">{{ m.DOB | date: 'dd/MMM/yyyy'}} </td>
                        <td class="blue-text">{{ m.IndianStatusNo }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="input-field col s12 center">
                <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                    <i class="material-icons right">close</i> Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showProfile && memberProfile">
    <div class="row center-align">
        <h5><strong> Member Detail </strong></h5>
    </div>
    <div class="row collection">
        <a class="collection-item">
            <div>Given Name: <a class="secondary-content blue-text"><strong> {{memberProfile?.GivenName}}</strong> </a>
            </div>
        </a>
        <a class="collection-item">
            <div>Last Name: <a class="secondary-content blue-text"><strong> {{memberProfile?.LastName}}</strong> </a>
            </div>
        </a>
        <a class="collection-item">
            <div>Middle Name: <a class="secondary-content blue-text"><strong> {{memberProfile?.middle_name}}</strong>
                </a></div>
        </a>
        <a class="collection-item">
            <div>Date Of Birth: <a class="secondary-content blue-text"><strong> {{memberProfile?.DOB| date:
                        'dd/MMM/yyyy'}}</strong> </a></div>
        </a>
        <a class="collection-item">
            <div>Gender:<a class="secondary-content blue-text"><strong> {{memberProfile?.Sex}}</strong> </a></div>
        </a>
        <a class="collection-item">
            <div>Indian Status Number: <a class="secondary-content blue-text"><strong>
                        {{memberProfile?.IndianStatusNo}}</strong>
                </a></div>
        </a>
        <a class="collection-item">
            <div>Email: <a class="secondary-content blue-text"><strong> {{memberProfile?.Email}}</strong> </a></div>
        </a>
        <a class="collection-item">
            <div>Home Number: <a class="secondary-content blue-text"><strong> {{memberProfile?.Telephone}}</strong>
                </a></div>
        </a>
        <a class="collection-item">
            <div>Mobile Number: <a class="secondary-content blue-text"><strong>
                        {{memberProfile?.cell_phone_num}}</strong> </a>
            </div>
        </a>
        <a class="collection-item">
            <div>Health Care Number: <a class="secondary-content blue-text"><strong>
                        {{memberProfile?.HealthCareNo}}</strong> </a>
            </div>
        </a>
        <a class="collection-item">
            <div>Mail Address: <a class="secondary-content blue-text"><strong> {{memberProfile?.MailAddress}}</strong>
                </a></div>
        </a>
        <a class="collection-item">
            <div>City: <a class="secondary-content blue-text"><strong> {{memberProfile?.city}}</strong> </a></div>
        </a>
        <a class="collection-item">
            <div>Province: <a class="secondary-content blue-text"><strong> {{memberProfile?.province}}</strong> </a>
            </div>
        </a>
        <a class="collection-item">
            <div>Postal Code: <a class="secondary-content blue-text"><strong> {{memberProfile?.PostalCode}}</strong>
                </a>
            </div>
        </a>
        <a class="collection-item">
            <div>SIN: <a class="secondary-content blue-text"><strong> {{memberProfile?.SIN}}</strong> </a></div>
        </a>
    </div>
    <div class="row section">
        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="hideProfile()"
            data-target="similarMembersModal" class="btn modal-trigger"><i
                class="material-icons left">arrow_back_ios</i> Back To List</a>
    </div>
</div>