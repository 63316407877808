import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { swing } from 'ng-animate';
import { Options } from 'sortablejs';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
//import { EmbedVideoService } from 'ngx-embed-video';
import { IsetsRequesttojoinService } from 'src/app/services/firebase/isets/IsetsRequesttojoin.service';
import { toast } from 'materialize-css';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(swing))])
  ],
})

export class AboutUsComponent implements OnInit {

  model: any[];
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  buttonType: any;
  options: Options = {
    animation: 50,
  };
  iframehtml: any;
  sectionName: string;
  submenus: any[];
  editName = false;
  videoId: any;
  isDraggable: boolean = true; 


  constructor(public appSettingsService: AppSettingsService,
    private router: Router,
    // private embedService: EmbedVideoService,
    private service: AboutusService,
    private isetService: IsetsRequesttojoinService,
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.displayIndex = this.model.findIndex(x => x.id === element.id) + 1;
        });
        this.service.SortContentOnServer(this.model);
      }
    };
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'AboutUs');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.service.getSectionsList().valueChanges().subscribe(sec => {
      if (sec && sec.length > 0) {
        this.model = sec;
        this.model.sort((a, b) => a.datePublished - b.datePublished);
        this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        // this.sortSections();
      }
    });
  }


  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'AboutUs');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      this.appSettingsService.updateMenuOnPublic('AboutUs', this.sectionName);
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }


  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    const strippedText = text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    if (!text || text.length <= charlimit) {
      return strippedText
    }
    return strippedText.substring(0, charlimit) + '...';
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;

    // this.iframehtml = this.embedService.embed(videoLink);
    // return this.iframehtml;
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  FixISETTable() {
    var myArray: any;
    this.isetService.ISETFixDBTables().valueChanges().subscribe(x => {
      if (x) {
        myArray = x;
      }
    });

    setTimeout(() => {
      var idArray: any[];
      idArray = []
      if (myArray) {
        myArray.forEach(element => {
          if (element.role === "Member") {
            idArray.push(element.ID)
          }
        });
      }
    }, 3500);
  }



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);
    this.model.forEach((item, index) => {
      item.displayOrder = index;
    });
    this.service.SortContentOnServer(this.model);
  }

  // drop(event: CdkDragDrop<any[]>): void {
  //   if (event.previousIndex === event.currentIndex) {
  //     return; // Prevent execution if the item was not moved.
  //   }
  
  //   if (!this.isDraggable) {
  //     console.warn('Drag-and-drop is currently disabled.');
  //     return; // Prevent execution based on custom logic (e.g., a condition or flag).
  //   }
  
  //   moveItemInArray(this.model, event.previousIndex, event.currentIndex);
  //   this.model.forEach((item, index) => {
  //     item.displayOrder = index;
  //   });
  
  //   // Ensure the method updates the order correctly and handles potential issues.
  //   this.service.SortContentOnServer(this.model);
  // }
  

 
  

}