<div class="row center-align">
    <h5><strong> Update House </strong></h5>
</div>

<div *ngIf="step1">
    <div class="row col s12" *ngIf="property">
        <div class="col l1"></div>
        <div class="col s12 l10">
            <form [formGroup]="propertyForm" (ngSubmit)="submitForm(propertyForm.value)">

                <div class="card-panel white">
                    <div class="row">

                        <div class="input-field col s10">
                            <i class="material-icons prefix green-text" *ngIf="!ownerSelected">person</i>
                            <i class="material-icons prefix green-text" *ngIf="ownerSelected">how_to_reg</i>
                            <input id="HoH" type="text" formControlName='HoH' class="validate"
                                placeholder="Select HoH" autocomplete="off" style="text-transform: capitalize;"
                                (focus)="showMembers()" />
                            <label for="HoH">Head of Household<span class="red-text">*</span></label>
                        </div>

                        <a class="waves-effect btn-flat col s2 right blue-text" style="cursor: pointer;" (click)="showMembers()" *ngIf="!ownerSelected"><i class="material-icons left">person_add_alt_1</i>Select</a>
                        <a class="waves-effect btn-flat col s2 right red-text" style="cursor: pointer;" (click)="clearHoH()" *ngIf="ownerSelected"><i class="material-icons left">person_remove_alt_1</i>Clear</a>

                        <div class="input-field col s12">
                            <i class="material-icons prefix orange-text">people</i>
                            <select formControlName="community">
                                <option value="" disabled selected>Select Province</option>
                                <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
                            </select>
                            <label for="community">Community<span class="red-text">*</span></label>
                        </div>

                        <div class="input-field col s12">
                            <div class="row col s12">
                                <label class="col m3 s6">
                                    <input name="desc_type" type="radio" formControlName="desc_type" value="HN" class="with-gap"
                                        (click)="onHouseNo()" />
                                    <span>House Number</span>
                                </label>
                                <label class="col m3 s6">
                                    <input name="desc_type" type="radio" formControlName="desc_type" value="LB" class="with-gap"
                                        (click)="onLotBlock()" />
                                    <span>LOT-BLOCK-PLAN</span>
                                </label>
                                <label class="col m3 s6">
                                    <input name="desc_type" type="radio" formControlName="desc_type" value="LD" class="with-gap"
                                        (click)="onLandDesc()" />
                                    <span>Legal Land Description</span>
                                </label>
                                <label class="col m3 s6">
                                    <input name="desc_type" type="radio" formControlName="desc_type" value="OL" class="with-gap"
                                        (click)="onOther()" />
                                    <span>Other</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="houseNo">
                            <i class="material-icons prefix blue-text">tag</i>
                            <input id="house_number" type="number" formControlName='house_number' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" />
                            <label for="house_number">House Number<span class="red-text">*</span></label>
                        </div>

                        <div class="col s12" *ngIf="lotBlock">
                            <div class="input-field col s4">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="block" type="number" formControlName='block' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="block">Block<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s8">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="lot" type="number" formControlName='lot' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" />
                                <label for="lot">Lot<span class="red-text">*</span></label>
                            </div>
                        </div>

                        <div class="col s12" *ngIf="landDesc">
                            <div class="input-field col s4">
                                <i class="material-icons prefix blue-text">tag</i>
                                <select formControlName="QRT">
                                    <option value="NE">NE</option>
                                    <option value="NW">NW</option>
                                    <option value="SE">SE</option>
                                    <option value="SW">SW</option>
                                </select>
                                <label for="block">QRT<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s8">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="section" type="text" formControlName='section' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="section">Section<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s4">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="township" type="text" formControlName='township' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="township">Township<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s4">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="range" type="text" formControlName='range' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="range">Range<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s4">
                                <i class="material-icons prefix blue-text">tag</i>
                                <input id="meridian" type="text" formControlName='meridian' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="meridian">Meridian<span class="red-text">*</span></label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="other">
                            <i class="material-icons prefix purple-text">my_location</i>
                            <input id="other_location" type="text" formControlName='other_location' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" />
                            <label for="other_location">Description</label>
                        </div>

                        <div class="input-field col s12">
                            <div>CMHC<span class="red-text">*</span></div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="CMHC" type="radio" formControlName="CMHC" [value]="true" class="with-gap"/>
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="CMHC" type="radio" formControlName="CMHC" [value]="false" class="with-gap"/>
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <span>Financed <span class="red-text">*</span></span>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="financed" type="radio" formControlName="financed" [value]="true" class="with-gap"/>
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="financed" type="radio" formControlName="financed" [value]="false" class="with-gap"/>
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix red-text">meeting_room</i>
                            <input id="rooms" type="number" formControlName='rooms' class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <label for="rooms">Number of Rooms</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">style</i>
                            <select formControlName="style">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let type of houseTypes" [value]="type">{{type}}</option>
                            </select>
                            <label for="style">Style</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">event</i>
                            <!-- <input id="year" type="text" [textMask]="{mask: yearMask}"
                                (change)="yearChanged($event.target.value)" formControlName='year' class="validate"
                                placeholder="yyyy" autocomplete="off" /> -->
                                <input id="year" type="text" [inputMask]="yearMask" 
                                (change)="yearChanged($event.target.value)" formControlName='year' class="validate"
                                placeholder="yyyy" autocomplete="off" />
                            <label for="year">Year</label>
                            <div *ngIf="yearSet" style="position: absolute;">
                                <small class="green-text"> Age of House : {{ageOfHouse}}
                                </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix purple-text">paid</i>
                            <input id="value" type="number" formControlName='value' min="0" step="0.25" value="0.00" placeholder="0.00" 
                            class="validate" autocomplete="off" (change)="setTwoNumberDecimal($event.target.value, 'costOfBuilding')"/>
                            <label for="value">Cost of Building ($)</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix red-text">aspect_ratio</i>
                            <input id="size" type="number" formControlName='size' class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <label for="size">Size of House (sq ft)</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix red-text">fireplace</i>
                            <select formControlName="fuel_type">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let type of fuelType" [value]="type">{{type}}</option>
                            </select>
                            <label for="fuel_type">Primary Heat Source</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix orange-text">fireplace</i>
                            <select formControlName="fuel_type2">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let type of fuelType" [value]="type">{{type}}</option>
                            </select>
                            <label for="fuel_type">Secondary Heat Source</label>
                        </div>

                    </div>
                </div>
                <div class="section"></div>

                <div class="row col s12">
                    <button type="button" style="margin-bottom: 15px;" (click)="getOccupants()" *ngIf="ownerSelected"
                        class="col s12 btn waves-effect blue">
                        <i class="material-icons right">person_add</i> Occupants
                    </button>

                    <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green"
                        [disabled]="!propertyForm.valid">
                        <i class="material-icons right">save</i> Update House
                    </button>

                    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()"><i
                            class="material-icons left">house</i>
                        Back To Previous Page </a>
                </div>
            </form>
        </div>
        <div class="col l1"></div>
    </div>
</div>

<div *ngIf="step2">
    <div class="row">
        <h5><strong>Occupants</strong></h5>
    </div>

    <div class="row col s12 card white" *ngIf="familyMembers">
        <div class="col s12 card-content">
            <table class="highlight centered" *ngIf="familyMembers?.length>0">
                <thead>
                    <tr>
                        <th>Memebr ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Classification</th>
                        <th>Mail Address</th>
                    </tr>
                </thead>
    
                <tbody>
                    <tr *ngFor="let fam of familyMembers; let i=index;">
                        <td>{{fam.applicantID}}</td>
                        <td>{{fam.GivenName}}</td>
                        <td>{{fam.LastName}}</td>
                        <td>{{fam.Classification}}</td>
                        <td>{{fam.MailAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row col s12">
        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToPreviousPage()"><i
            class="material-icons left">arrow_back</i>
        Back To previous Page </a>

    </div>
</div>

<div id="memberListModal" class="modal" *ngIf="members">
    <div class="modal-content">
        <h4>Members</h4>

        <form class="row" #filterForm="ngForm" style="padding: 10px;">
            <div class="input-field col s12 m6">
                <input name="firstName" type="text" [(ngModel)]="filters.firstName" autocomplete="off"
                    (input)="filterMembers()" style="text-transform: capitalize;" />
                <label for="firstName">First Name</label>
            </div>

            <div class="input-field col s12 m6">
                <input name="lastName" type="text" [(ngModel)]="filters.lastName" autocomplete="off"
                    (input)="filterMembers()" style="text-transform: capitalize;" />
                <label for="lastName">Last Name</label>
            </div>
        </form>

        <div class="row card white">
            <table class="striped centered highlight" *ngIf="members.length >0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let m of filtredMembers? filtredMembers: members | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;"
                        (click)="selectHoH(m)">
                        <td>{{m.applicantID}}</td>
                        <td>{{m.GivenName}}</td>
                        <td>{{m.LastName}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row center">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
    </div>
</div>

<div id="messageModel" class="modal">
    <div class="modal-content">
        <h6><strong> Select Owner of the House </strong></h6>
        <p>
            This memeber has a house in the system.
            Please Make sure you have selected the right member.
        </p>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
    </div>
</div>

<div Id="confirmationModel" class="modal">
    <div class="modal-content">
        <h6><strong>{{confirmationMessage}}</strong></h6>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">Yes</a>
        <a class="modal-close waves-effect waves-green btn-flat">Cancel</a>
    </div>
</div>