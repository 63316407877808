<div class="row">
  <form [formGroup]="pollForm" (ngSubmit)="submitPoll(pollForm.value)">
    <div formArrayName="answerItems" *ngFor="let item of answers; let i = index;">
      <div [formGroupName]="i">
        <div *ngIf="visibleStepIndex === i" class="row card white col s12">
          <div class="row card-content">
            <div class="row center-align">
              <h5><strong> {{model.name}} </strong></h5>
            </div>
            <div class="section"></div>
            <span class="card-title"><strong class="blue-text" style="margin-left: 10px;">
                {{item.controls.questionNumber.value}}
                : </strong> {{item.controls.question.value}} ? </span>
            <br />
            <div *ngIf="item.controls.questionType.value === 'Open Ended Answer'">
              <div class="row input-field col s12">
                <i class="material-icons prefix blue-text">question_answer</i>
                <textarea id="answer" type="text" formControlName="answer" class="materialize-textarea validate"
                  autocomplete="off" maxlength="150">
                            </textarea>
                <div *ngIf="(item.controls.answer.dirty ||
                            item.controls.answer.touched) &&
                            item.controls.answer.invalid">
                  <small *ngIf="item.controls.answer.errors.required" class="red-text"> The Answer is required. </small>
                </div>
              </div>
            </div>

            <div *ngIf="item.controls.questionType.value === 'Multiple Choice'">
              <div formArrayName="answers"
                *ngFor="let ans of pollForm.get('answerItems').controls[i].get('answers').controls; let j = index;">
                <div class="col s12 m6" [formGroupName]="j">
                  <label>
                    <input name="answer" value="{{ans.controls.answer.value}}"
                      (change)="answerSelected(i,ans.controls.answer.value)" type="radio" class="with-gap"/>
                    <span><strong>{{ans.controls.answer.value}}</strong></span>
                  </label>
                </div>
              </div>
            </div>

            <div *ngIf="item.controls.questionType.value === 'Scale'">
              <div class="row col s12">
                <p [ngClass]="getColSize(i)">{{getLables(i).start}}</p>
                  <div [ngClass]="getColSize(i)" *ngFor="let value of createRange(i)">

                    <label>
                      <input name="answer" (click)="setValue(i, value)" type="radio" class="with-gap" />
                      <span >{{value}}</span>
                    </label>
                  </div>
                <p [ngClass]="getColSize(i)">{{getLables(i).end}}</p>
              </div>
            </div>
          </div>

          <div class="card-action">
            <div class="row">
              <div class="col s12 m6" *ngIf="i === 0">
                <button type="button" style="margin-bottom: 15px;" class="col s12 btn red" (click)="backToList()">
                  <i class="material-icons left">cancel</i> Cancel
                </button>
              </div>
              <div class="col s12 m6" *ngIf="i !== 0">
                <button type="button" style="margin-bottom: 15px;" class="col s12 btn" (click)="previous(i)">
                  <i class="material-icons left">arrow_back_ios</i> Previous
                </button>
              </div>
              <div class="col s12 m6" *ngIf="i < totalQuestions">
                <button type="button" style="margin-bottom: 15px;" [disabled]="!item.controls.answer.valid"
                  class="col s12 btn blue" (click)="continue(i)">
                  Continue <i class="material-icons right">arrow_forward_ios</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="visibleStepIndex == totalQuestions" class="row card white col s12">
      <div class="row card-content">
        <div class="row input-field col s12">
          <div class="row col s12">Do You Want to Leave A comment in this poll ? <span class="red-text">*</span>
          </div>
          
          <div class="row col s12">
            <label class="col s6">
              <input class="with-gap" type="radio" formControlName="leaveComment" value="yes"
                (change)="onCommentYes($event)" />
              <span>Yes</span>
            </label>
            <label class="col s6">
              <input class="with-gap" type="radio" formControlName="leaveComment" value="no"
                (change)="onCommentNo($event)" />
              <span>No</span>
            </label>
          </div>

          <div class="input-field col s12" *ngIf="showComment">
            <i class="material-icons prefix purple-text">poll</i>
            <textarea id="comment" rows="3" style="height: 100px;" formControlName="comment"
              class="materialize-textarea"></textarea>
            <label for="comment">Comment<span class="red-text">*</span></label>
          </div>
        </div>
      </div>

      <div class="card-action">
        <div class="row">
          <div class="col s12 m6">
            <button type="button" style="margin-bottom: 15px;" class="col s12 btn" (click)="prev()">
              <i class="material-icons left">arrow_back_ios</i> Previous
            </button>
          </div>
          <div class="col s12 m6">
            <button type="submit" style="margin-bottom: 15px;" class="col s12 btn green" [disabled]="!pollForm.valid">
              Submit <i class="material-icons right">send</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>