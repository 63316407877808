<div class="row">
  <div class="col s12 ">
    <div class="body-h">

      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12">
          <h4 *ngIf="!editName">
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Nation Information</span>
            <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
          </h4>

          <div class="input-field" *ngIf="editName">
            <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
            <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
              (click)="saveName()">Save</button>
          </div>

          <p class="col s12 bold-300 sub-title-section3">Here you can add and update the nation information. You can
            also
            include address and contact information along with important social medias .</p>
        </div>
      </div>

      <main class="StickyContent" *ngIf="sqlSettingsModel && socialMediaModel">
        <form [formGroup]="nationInfoForm" (ngSubmit)="saveSettings(nationInfoForm.value)" class="col l11 s12 m12">
          <ul class="collapsible">
            <li class="row">
              <div class="col l12 s12 m12 ">
                <div class="collapsible-header">
                  <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                    info
                  </span> Nation Details
                </div>


                <p>This comprises the Nation's name and nation logo tailored to your preferences</p>
              </div>

              <div class="collapsible-body" style="height: 35vh;">
                <div id="nationDetails" class="col s12" formGroupName="nationDetails">

                  <div class="input-field col s12 l8 ">
                    <div class="label-input">Nation Name</div>
                    <input id="name" type="text" formControlName="nationName" class="validate" autocomplete="off"
                      placeholder="Nation Name" />
                  </div>

                  <div class="row col s12">
                    <p class="row sub-title-section bold-700 p-b-1">
                      Would you like to Upload a logo for your Nation? <span class="red-text">*</span>
                    </p>
                    <!-- ========== yes or no ============= -->
                    <div class="row">
                      <div class="col s6">
                        <label>
                          <input type="radio" value="Yes" name="group1" [checked]="addLogo" (click)="yesClicked()" class="with-gap" />
                          <span><strong class="green-text">Yes</strong></span>
                        </label>
                      </div>
                      <div class="col s6">
                        <label>
                          <input type="radio" value="No" name="group1" [checked]="!addLogo" (click)="noClicked()" class="with-gap" />
                          <span><strong class="blue-text">No</strong></span>
                        </label>
                      </div>
                    </div>
                    <!-- ========== if image ============= -->
                    <div class="row file-upload" *ngIf="addLogo">
                      <div>
                        <p class="sub-title-section bold-700 p-b-1">Please Select A Logo From Your Library</p>

                        <div class="card col s12 m6 l4" (change)="handleFileInput($event.target.files)">
                          <div class="center-align">
                            <input type="file" formControlName="logo" accept="image/*"
                              (change)="handleFileInput($event.target.files)" />
                            <i class="fa fa-cloud-upload p-t-2 icon-upload"></i>
                            <h4 class="label-input p-b-2  bold-200">
                              Drop & Drop file here or <u>Click to Upload</u></h4>
                          </div>
                        </div>

                        <p class="red-text" *ngIf="imgErrorMsg">
                          <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                        </p>

                        <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="imgLink || uploadedImage">
                          <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
                            <i class="material-symbols-outlined">
                              delete
                            </i>Delete</a>
                          <img *ngIf="imgLink" class="materialboxed responsive-img" [src]="imgLink" width="200px"
                            height="auto" />
                          <img *ngIf="uploadedImage" class="materialboxed responsive-img" [src]="uploadedImage"
                            width="200px" height="auto" />
                        </div>
                      </div>
                    </div>

                    <!-- ========== View on the header  ============= -->
                  </div>
                </div>
              </div>
            </li>

            <li class="row">
              <div class="col l12 s12 m12">
                <div class="collapsible-header">
                  <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                    contact_mail
                  </span> Address
                </div>

                <p>This includes the Nation's Address, Town, Postal Codes, and Province.</p>
              </div>

              <div class="collapsible-body" style="height: 35vh;">
                <div id="address" class="col s12" formGroupName="address">

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Address</div>
                    <input id="address" type="text" formControlName="address" class="validate" autocomplete="off"
                      placeholder="Address" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Town</div>
                    <input id="town" type="text" formControlName="town" class="validate" autocomplete="off"
                      placeholder="Town" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Postal Code</div>
                    <!-- <input id="postalCode" type="text" formControlName="postalCode" class="validate" autocomplete="off"
                      [textMask]="{mask: postalCodeMask, guide: true}" placeholder="Postal Code" /> -->
                    <input id="postalCode" type="text" [inputMask]="postalCodeMask" formControlName="postalCode"
                      class="validate" autocomplete="off" placeholder="Postal Code" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Province</div>
                    <select id="province" formControlName="province" class="validate">
                      <option value="" disabled selected>Choose your option</option>
                      <option *ngFor="let prov of provinces" [value]="prov">{{prov}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>

            <li class="row">
              <div class="col l12 s12 m12">
                <div class="collapsible-header">
                  <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                    perm_phone_msg
                  </span> Contact Information

                </div>
                <p>This includes the Nation's Email, Treaty, Phone, and Fax.</p>
              </div>

              <div class="collapsible-body" style="height: 35vh;">
                <div id="contactInfo" class="col s12" formGroupName="contactInfo">
                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Email</div>
                    <input id="email" type="email" formControlName="email" class="validate" autocomplete="off"
                      placeholder="Email" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Treaty #</div>
                    <!-- <input id="treaty" [textMask]="{mask: treatyNoMask, guide: true}" type="text"
                      formControlName="treatyNo" class="validate" autocomplete="off" placeholder="Treaty" /> -->
                    <input id="treaty" type="text" formControlName="treatyNo" class="validate" autocomplete="off"
                      placeholder="Treaty" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Phone #</div>
                    <!-- <input id="phone" type="text" formControlName="telephone" class="validate" autocomplete="off"
                      [textMask]="{mask: phoneNumberMask, guide: true}" placeholder="Phone" /> -->
                    <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName="telephone"
                      class="validate" autocomplete="off" placeholder="Phone" />
                  </div>

                  <div class="input-field col l6 m6 s12">
                    <div class="label-input">Fax #</div>
                    <input id="fax" type="text" [inputMask]="phoneInputMask" formControlName="fax" class="validate"
                      autocomplete="off" placeholder="Fax" />
                    <!-- <input id="fax" [textMask]="{mask: phoneNumberMask, guide: true}" type="text" formControlName="fax"
                      class="validate" autocomplete="off" placeholder="Fax" /> -->
                  </div>
                </div>
              </div>
            </li>

            <li class="row social-m">
              <div class="col l12 s12 m12">
                <div class="collapsible-header">
                  <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                    public
                  </span> Social Media
                </div>

                <p>This includes the Social Media Accounts Link for Facebook , X , Instagram , Youtube , LinkedIn and
                  Tiktok.</p>
              </div>

              <div class="collapsible-body" style="height: 35vh;">
                <div id="socialMedia" class="col s12" formGroupName="socialMedia">
                  <!-- <div class="col l12">
                    <h6 class="sub-title-section2">Please Add or update your social media links.</h6>
                  </div>  <br /> -->
                  <div class="input-field class col l9 s12">
                    <i class="fa fa-facebook prefix blue-text"></i>
                    <input formControlName="facebook" id="facebook" class="validate p-l-9" type="text" maxlength="250"
                      placeholder="Facebook URL" autocomplete="off">
                    <div *ngIf="(facebook.dirty || facebook.touched) && facebook.invalid">
                      <small *ngIf="facebook.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                  <div class="input-field class col l9 s12">
                    <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512" class="prefix p-l-2">
                      <path _ngcontent-yei-c148="" fill="currentcolor"
                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
                      </path>
                    </svg>
                    <input formControlName="twitter" id="twitter" class="validate" type="text" maxlength="250"
                      placeholder="Twitter URL" autocomplete="off">
                    <div *ngIf="(twitter.dirty || twitter.touched) && twitter.invalid">
                      <small *ngIf="twitter.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                  <div class="input-field class col l9 s12">
                    <i class="fa fa-instagram prefix " style="color: #3f729b;"></i>
                    <input formControlName="instagram" id="instagram" class="validate " type="text" maxlength="250"
                      placeholder="Instagram URL" autocomplete="off">
                    <div *ngIf="(instagram.dirty || instagram.touched) && instagram.invalid">
                      <small *ngIf="instagram.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                  <div class="input-field class col l9 s12">
                    <i class="fa fa-youtube prefix red-text"></i>
                    <input formControlName="youtube" id="youtube" class="validate" type="text" maxlength="250"
                      placeholder="Youtube URL" autocomplete="off">
                    <div *ngIf="(youtube.dirty || youtube.touched) && youtube.invalid">
                      <small *ngIf="youtube.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                  <div class="input-field class col l9 s12">
                    <i class="fa fa-linkedin prefix" style="color: #3f729b;"></i>
                    <input formControlName="linkedin" id="linkedin" class="validate" type="text" maxlength="250"
                      placeholder="LinkedIn URL" autocomplete="off">
                    <div *ngIf="(linkedin.dirty || linkedin.touched) && linkedin.invalid">
                      <small *ngIf="linkedin.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                  <div class="input-field class col l9 s12">
                    <svg _ngcontent-yei-c68="" width="28" height="28" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512" class="prefix">
                      <path _ngcontent-yei-c68="" fill="currentcolor"
                        d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z">
                      </path>
                    </svg>
                    <!-- <i class="fab fa-tiktok prefix" style="color: #000000;"></i> -->
                    <input formControlName="tiktok" id="tiktok" class="validate" type="text" maxlength="250"
                      placeholder="Tiktok URL" autocomplete="off">
                    <div *ngIf="(tiktok.dirty || tiktok.touched) && tiktok.invalid">
                      <small *ngIf="tiktok.errors.pattern" class="red-text"> Please enter a valid Url.
                      </small>
                    </div>
                  </div>

                </div>
              </div>
            </li>
          </ul>

          <div class="col s10 l3 m3 offset-l9 push-s1 ">
            <button type="submit" class="col s12 btn left save-button" [ngClass]="'btn ' + buttonType"
              style="margin-top: -100px;">Save</button>
          </div>
        </form>
      </main>
    </div>
  </div>
</div>