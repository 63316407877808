import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GovernanceModel } from 'src/app/models/GovernanceModel';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';

declare var $: any;

@Component({
  selector: 'app-edit-governance',
  templateUrl: './edit-governance.component.html',
  styleUrls: ['./edit-governance.component.scss']
})

export class EditGovernanceComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  governance: GovernanceModel;
  addGovernanceForm: FormGroup;
  councillors: any;
  chiefs: any;
  governance_id: any;
  edit_governance: any;
  chiefListData: any = [];
  councillorListData: any = [];
  sectionName: string;
  councillorTitle: any;
  chiefTitle: any;
  submenus: any[];
  editName = false;
  editChief = false;
  editCouncillor = false;
  index: any;
 
  constructor(
    private location: Location,
    private governanceService: GovernanceService,
    private router: Router,
    public appSettingsService: AppSettingsService,
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    // this.chiefTitle = this.appSettingsService.getGovTitle().chiefTitle ? this.appSettingsService.getGovTitle().chiefTitle : 'Chief';
    // this.councillorTitle = this.appSettingsService.getGovTitle().councillorTitle ? this.appSettingsService.getGovTitle().councillorTitle : 'Councillors';
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        this.index = this.submenus.findIndex(m => m.name == 'Governance');
        let menu = this.submenus.find(m => m.name == 'Governance');
        if (menu) {
          this.sectionName = menu.displayName;
          this.chiefTitle = menu.chiefTitle ? menu.chiefTitle : 'Chief';
          this.councillorTitle = menu.councillorTitle ? menu.councillorTitle : 'Councillors';
        }
      }
    });

    this.governance_id = window.history.state.governance_id;
    if (this.governance_id) {
      this.getGovernanceFormData(this.governance_id);
    } else {
      //this.router.navigate(['/admin/governance']);
    }
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    this.submenus[this.index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      this.appSettingsService.updateMenuOnPublic('Governance', this.sectionName);
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  editTitle(title) {
    if (title == 'chief') {
      this.editChief = true;
    }
    else if (title == 'councillor') {
      this.editCouncillor = true;
    }
  }

  saveTitle(title) {
    if (title == 'chief') this.submenus[this.index].chiefTitle = this.chiefTitle;
    else this.submenus[this.index].councillorTitle = this.councillorTitle;
    
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      if(title == 'chief') this.appSettingsService.updateMenuOnPublic('Governance', this.chiefTitle, 'chief');
      else this.appSettingsService.updateMenuOnPublic('Governance', this.councillorTitle, 'councillor');
      this.editChief = false;
      this.editCouncillor = false;
      
      // this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  getGovernanceFormData(governance_id) {
    this.governanceService.getGovernanceList().valueChanges().subscribe(x => {
      if (x) {
        if(x.length > 0) {
          this.edit_governance = x[0];
          this.chiefs = this.edit_governance.chiefs;
          this.councillors = this.edit_governance.councillors;
        }
      }
    });
    $('.modal').modal();
  }

  /* Add Chief Form */
  gotoAddChief() {
    this.router.navigate(['/admin/dashboard-sub-menu/add-chief'], { state: {governance_id: this.governance_id}});
  }
  /* Add Councillor Form */
  gotoAddCouncillor() {
    this.router.navigate(['/admin/dashboard-sub-menu/add-councillor'], { state: {governance_id: this.governance_id}});
  }

  DeleteGovernance() {
    /*this.governanceService.DeleteGovernance(this.edit_governance).then(x => {
      toast({ html: 'Governance Successfully Deleted!', classes: 'green' });
      this.router.navigate(['/admin/governance']);
    });*/
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.location.back();
  }

  editCouncillorData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/edit-councillor'], { state: { employee_id: id, governance_id: this.governance_id } });
  }

  editChiefData(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/edit-chief'], { state: { governance_id: this.governance_id } });
  }
}
