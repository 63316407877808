<div class="body-h container-pi">
  <!-- <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Add New Education </h4>
    </div>
  </div> -->
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
    </span>Add New Education
  </h4>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(addEducationForm.value)" [formGroup]="addEducationForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">Please Select a Degree/Certificate Title <strong class="red-text">*</strong></div>
            <select class="form-control" formControlName="details" id="details" autocomplete="off" require
              class="validate browser-default">
              <option *ngFor="let c of EduLevelList" [value]="c">{{c}}</option>
            </select>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Institution Name <strong class="red-text">*</strong></div>
            <input formControlName="Institution" id="Institution" type="text" maxlength="75" autocomplete="off" required
              class="capitalize validate">
            <div *ngIf="(Institution.dirty || Institution.touched) && Institution.invalid">
              <small *ngIf="Institution.errors.required" class="red-text">The Institution Name Is Required!
              </small>
            </div>
          </div>

          <!-- 
              <div class="input-field class col s12">
                <i class="material-icons prefix blue-text">apartment</i>
                <input formControlName="details" id="details" type="text" maxlength="100" autocomplete="off" required
                class="capitalize validate">
                <label for="details"> Achievement : <strong class="red-text">*</strong> </label>
                <div *ngIf="(details.dirty || details.touched) && details.invalid">
                  <small *ngIf="details.errors.required" class="red-text"> The Achievement Is Required!
                  </small>
                </div>
              </div>
              -->

          <div class="input-field class col s12 l6">
            <div class="label-input">Description <strong class="red-text">*</strong></div>
            <input formControlName="description" id="description" type="text" maxlength="100" autocomplete="off"
              required class="capitalize validate">
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> Please enter the description.
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Completion Date<strong class="red-text">*</strong> </div>
            <input id="completion_date" formControlName="completion_date" type="text" class="input-box"
              angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" [options]="myDpOptions"
              #dp="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
          </div>

          <!-- <div class="input-field class col s12">
                <i class="material-icons prefix blue-text">edit</i>
                <input id="completion_date" formControlName="completion_date" type="text" class="datepicker"  (change)="dateChange()">
                <label for="completion_date"> date : <strong class="red-text">*</strong> </label>

              </div> -->
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addEducationForm.valid">
            <i class="material-icons right">add_circle</i> Save Educational Attainment
          </button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Education List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>