<div class="col s12 m12 l11 ">
  <div class="body-h">

    <div class="header-setting">
      <div class="col l12 m12 s12">
        <h4 class="icon-text">Add / Edit&nbsp;
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Departments</span>
        </h4>
      </div>
    </div>

    <main class="StickyContent white ">
      <form [formGroup]="addUpdateDepartmentForm" (ngSubmit)="PostDepartment(addUpdateDepartmentForm)">
        <div class="row">
          <!--------------- Department Information ------------------->
          <div class="row col l12 s12 m12 card-panel white">
            <div class="col l3 hide-on-med-and-down">
              <h4 style="font-size: 16px; font-weight: 600;">Department Information</h4>
            </div>
            <div class="col l9 m12 s12">
              <div class="input-field col s12">
                <div class="label-input">Department Name<span class="red-text">*</span></div>
                <app-editor id="name" [title]=true [control]="name" [showChatGpt]="false"></app-editor>
                <div *ngIf="(name.dirty || name.touched) && name.invalid">
                  <small *ngIf="name.errors.required" class="red-text"> This Department Name is required.</small>
                </div>
              </div>

              <ul id='options1' class='dropdown-content'>
                <li><a>Please Select a Color</a></li>
                <li *ngFor="let c of colorPicker" (click)="ChangeColor(c.value)"><a><i
                      class="material-icons {{c.value}}">fiber_manual_record</i>{{c.name}}</a></li>
              </ul>

              <div class="input-field col s12 l6">
                <div class="label-input">Short Name</div>
                <input id="shortName" type="text" formControlName='shortName' class="validate" autocomplete="off"
                  placeholder="Short Name" />
              </div>

              <div class="input-field col s12 l6">
                <div class="label-input">Email<span class="red-text">*</span></div>
                <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off"
                  placeholder="Email" />
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                  <small *ngIf="email.errors.email" class="red-text">Invalid email format. Please enter a valid email
                    address.(example&#64;example.com). </small>
                </div>
              </div>

              <div class="input-field col l7 s12">
                <div class="label-input">Phone Number</div>
                <!-- <input id="phone" type="text" formControlName='phone' class="validate" autocomplete="off"
                  [textMask]="{mask: phoneNumberMask}" placeholder="Phone Number" /> -->
                  <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName='phone' class="validate" autocomplete="off"
                   placeholder="Phone Number" />
              </div>

              <div class="input-field col l5 s12">
                <div class="label-input">Fax Number</div>
                <!-- <input id="fax" type="text" formControlName='fax' class="validate" autocomplete="off"
                  [textMask]="{mask: phoneNumberMask}" placeholder="Fax Number" /> -->
                  <input id="fax" type="text" [inputMask]="phoneInputMask" formControlName='fax' class="validate" autocomplete="off"
                   placeholder="Fax Number" />
              </div>

              <div class="input-field col l6 s12">
                <div class="label-input">Address</div>
                <input id="address" type="text" formControlName='address' class="validate" autocomplete="off"
                  maxlength="150" style="text-transform: capitalize;" placeholder="Address" />
              </div>

              <div class="input-field col l6 s12">
                <div class="label-input">Postal Code</div>
                <!-- <input id="postal_code" type="text" formControlName='postal_code' class="validate"
                  [textMask]="{mask: postalCodeMask}" placeholder="Postal Code"
                  onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
                  autocomplete="off" /> -->
                  <input id="postal_code" type="text" [inputMask]="postalCodeMask" formControlName='postal_code' class="validate"
                  placeholder="Postal Code"
                  onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
                  autocomplete="off" />
                <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                  <small *ngIf="postal_code.errors.pattern" class="red-text"> Invalid postal code format. Please ensure
                    the postal code matches the format "A1A 1A1."</small>
                </div>
              </div>

              <div class="input-field col s12">
                <div class="label-input">Mandate</div>
                <app-editor id="mandate" [title]=false [control]="mandate" [showChatGpt]="false"></app-editor>
              </div>
            </div>
          </div>

          <!--------------- Socail Media ------------------->
          <div class="row  col l12 s12 m12  card-panel white">
            <div class="col l3 hide-on-med-and-down">
              <h4 style="font-size: 16px; font-weight: 600;">Social Media Information</h4>
            </div>
            <div class="col l9 m12 s12">
              <!-------  facebook  --------->
              <div class="input-field col l6 s12">
                <div class="label-input">Department's Facebook account</div>
                <input id="deptFacebook" type="text" formControlName='deptFacebookAcc' class="validate"
                  placeholder="facebook url">
                <div *ngIf="(getdeptFacebookAcc?.dirty || getdeptFacebookAcc?.touched) && getdeptFacebookAcc?.invalid">
                  <small *ngIf="getdeptFacebookAcc.errors.pattern" class="red-text"> nvalid Facebook URL format. Please
                    provide a valid Facebook URL (www.facebook.com/yourpage).
                  </small>
                </div>
              </div>

              <!-------  instagram  ------->
              <div class="input-field col l6 s12">
                <div class="label-input">Department's Instagram account</div>
                <input id="deptInsta" type="text" formControlName='deptInstagramAcc' class="validate"
                  placeholder="instagram url">
                <div
                  *ngIf="(getdeptInstagramAcc?.dirty || getdeptInstagramAcc?.touched) && getdeptInstagramAcc?.invalid">
                  <small *ngIf="getdeptInstagramAcc.errors.pattern" class="red-text"> Invalid Instagram username format.
                    Please enter a valid Instagram username (&#64;example_username).
                  </small>
                </div>
              </div>

              <!--------  twitter  --------->
              <div class="input-field col l6 s12">
                <div class="label-input">Department's Twitter account</div>
                <input id="deptTwitter" type="text" formControlName='deptTwitterAcc' class="validate"
                  placeholder="twitter url">
                <div *ngIf="(getdeptTwitterAcc?.dirty || getdeptTwitterAcc?.touched) && getdeptTwitterAcc?.invalid">
                  <small *ngIf="getdeptTwitterAcc.errors.pattern" class="red-text"> Invalid Twitter handle format.
                    Please enter a valid Twitter handle (&#64;example_handle). </small>
                </div>
              </div>

              <!----------  linked in  ----------->
              <div class="input-field col l6 s12">
                <div class="label-input">Department's Linkedin account</div>
                <input id="deptLinkedin" type="text" formControlName='deptLinkedinAcc' class="validate"
                  placeholder="linkedin url">
                <div *ngIf="(getdeptLinkedinAcc?.dirty || getdeptLinkedinAcc?.touched) && getdeptLinkedinAcc?.invalid">
                  <small *ngIf="getdeptLinkedinAcc.errors.pattern" class="red-text"> Invalid LinkedIn profile URL
                    format. Please provide a valid LinkedIn profile URL (www.linkedin.com/in/yourprofile). </small>
                </div>
              </div>

              <!------------  youtube  ------------->
              <div class="input-field col l6 s12">
                <div class="label-input">Department's Youtube account</div>
                <input id="deptYoutube" type="text" formControlName='deptYoutubeAcc' class="validate"
                  placeholder="youtube url">
                <div *ngIf="(getdeptYoutubeAcc?.dirty || getdeptYoutubeAcc?.touched) && getdeptYoutubeAcc?.invalid">
                  <small *ngIf="getdeptYoutubeAcc.errors.pattern" class="red-text"> Invalid YouTube channel URL format.
                    Please enter a valid YouTube channel URL (e.g., www.youtube.com/user/yourchannel). </small>
                </div>
              </div>
            </div>
          </div>

          <!--------------- Department Application ------------------->
          <div class="row  col l12 s12 m12  card-panel white">
            <div class="col l3 hide-on-med-and-down">
              <h4 style="font-size: 16px; font-weight: 600;">Application</h4>
            </div>

            <div class="col l9 m12 s12">
              <div class="row col s12">
                <p class="label-input">Add Applications</p>
                <div *ngFor="let app of applicationsList" class="chip">
                  {{app}}<i class="material-icons right" (click)="removeApplication(app)">close</i>
                </div>
                <input type="text" formControlName="applications">
                <button type="button" class="btn add-button right col s8 l4 b-n-5" [ngStyle]="{'color': themeColor }" [disabled]="!applications.value"
                  (click)="addApplications()"><i class="material-icons-outlined">add</i>Add Application</button>
              </div>
            </div>
          </div>

          <!--------------- Logo ------------------->
          <div class="row  col l12 s12 m12  card-panel white" *ngIf="showLogoInput && !isMobile">
            <div class="col l3 hide-on-med-and-down">
              <h4 style="font-size: 16px; font-weight: 600;">Logo </h4>
            </div>
            <div class="col l9 m12 s12">
              <p><strong>Please Select A Logo From Your Library </strong></p>
              <div class="file-field input-field">
                <div class="btn">
                  <span>Browse</span>
                  <input type="file" formControlName="logo" accept="image/*"
                    (change)="handleImageInput($event.target.files)" />
                </div>
                <div class="file-path-wrapper">
                  <input formControlName="logo" class="file-path" type="text"
                    placeholder="Select Logo From Device Library" />
                </div>
              </div>
              <p class="red-text" *ngIf="imgErrorMsg">
                <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
              </p>

              <div class="row center" *ngIf="base64Image || edit_model?.logo">
                <div class="row col s12">
                  <img *ngIf="base64Image" class="responsive-img" [src]="base64Image" height="40%" width="40%" />
                  <img *ngIf="!base64Image && edit_model.logo" class="responsive-img" [src]="edit_model.logo"
                    height="40%" width="40%" />
                </div>
                <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
                    class="material-icons">delete</i></a>
              </div>
            </div>

            <div class="row card-panel white" *ngIf="showLogoInput && isMobile">
              <div class="col l3 hide-on-med-and-down">
                <h4 style="font-size: 16px; font-weight: 600; ">Logo </h4>
              </div>

              <div class="col l9 m12 s12">
                <div class="row">
                  <div class="col-md-8 col-md-offset-2">
                    <div class="form-group">
                      <label for="fileField" class="attachment">
                        <div class="row btn-file">
                          <div class="btn-file__preview"></div>
                          <div class="btn-file__actions">
                            <div class="btn-file__actions__item col-xs-12 text-center">
                              <div class="btn-file__actions__item--shadow">
                                <i class="fa fa-plus fa-lg fa-fw" aria-hidden="true"></i>
                                <div class="visible-xs-block"></div>
                                Select file
                              </div>
                            </div>
                          </div>
                        </div>
                        <input name="file" type="file" id="fileField">
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row center" *ngIf="base64Image">
                  <div class="row col s12">
                    <img class="responsive-img" [src]="base64Image" height="40%" width="40%"  alt="department Image" />
                  </div>
                  <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
                      class="material-icons">delete</i></a>
                </div>

                <div class="row card-panel white" *ngIf="showImageFromServer">
                  <div class="row center" *ngIf="model.logo">
                    <h6 class="center"><strong>Logo</strong></h6>
                    <div class="row col s12">
                      <img class="responsive-img" [src]="model.logo" height="40%" width="40%"  alt="department image show" />
                    </div>
                    <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeSavedFile()"><i
                        class="material-icons">delete</i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--------------- Buttons ------------------->
          <div class="row">
            <a class="btn-flat col l3 s9 push-s1 white dep-btn " [ngClass]="'btn ' + buttonType"
              [ngStyle]="{'border-color': themeColor}" [routerLink]="['/admin/dashboard-sub-menu/department']"
              style="margin: 15px;  margin-right:90px;"> Back To
              Departments List</a>
            <button type="submit" [disabled]="!addUpdateDepartmentForm.valid"
              class="col  l3 s9 push-s1  btn waves-effect dep-btn" style="margin: 15px; " *ngIf="fromWhere === 'add'"
              [ngStyle]="{'background-color': themeColor}" [ngClass]="'btn ' + buttonType">
              Add Department
            </button>
            <button type="submit" [disabled]="!addUpdateDepartmentForm.valid"
              class="col  l3 s9 push-s1  btn waves-effect dep-btn" style="margin: 15px; " *ngIf="fromWhere === 'edit'"
              [ngStyle]="{'background-color': themeColor}" [ngClass]="'btn ' + buttonType">
              Update Department
            </button>
            <button type="button" (click)="openDeleteModal()"
              class="col  l3 s9  push-s1 btn waves-effect delete-button dep-btn" style="margin: 15px; "
              *ngIf="fromWhere === 'edit'" [ngClass]="'btn ' + buttonType">Delete
              Department</button>
          </div>
        </div>
      </form>
    </main>
  </div>
</div>

<div id="deleteDepartmentConfirmation" class="modal b-n-1">
  <div class="modal-content">
    <h4>Delete Department</h4>
    <p>Are you sure you want to Delete this Department ?</p>
  </div>

  <div class="modal-footer">
    <button class="btn col s4 deletes-button right"
      (click)="DeleteDepartment(edit_model);">Delete</button>
    <button class="modal-close btn col s4 close-button">CANCEL</button>
  </div>
</div>