<div class="row">
  <div class="body-h">
    <div class="b-m-2">
      <div class="container-pi1">
        <h4 class="title-section1 title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
            (click)=" backClicked()">
            arrow_circle_left
          </span>Poll / Survey List</h4>
      </div>

      <main class="StickyContent white">
        <div class="col s12 m12 l10">
          <!-- Participation Message -->
          <div *ngIf="partcipated && !model">
            <div class=" poll-or-survey right" [ngStyle]="{'color': themeColor}">
              <h6>Poll / Survey</h6>
            </div>
            <div>
              <div class=" card col s12 l12">
                <div class="card-content">
                  <div class="row center">
                    <i class="material-icons red-text" aria-hidden="true" style="font-size: 100px;">info</i>
                    <p class="red-text"><strong>You have already participated in this survey.</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Survey View -->
          <div *ngIf="model">
            <div class=" poll-or-survey right" [ngStyle]="{'color': themeColor}">
              <h6 *ngIf="model.type === 'Poll'">Poll</h6>
              <h6 *ngIf="model.type === 'Survey'">Survey</h6>
            </div>

            <!-- Step 1: Survey View Component -->
            <div *ngIf="step1">
              <app-polls-survey-view [model]="model" [clientID]="clientID"
                (participationSaved)="parentEventHandlerFunction($event)">
              </app-polls-survey-view>
            </div>

            <!-- Step 2: Thank You Message -->
            <div *ngIf="step2">
              <div class="card  col s12 l12">
                <div class="card-content">
                  <div class="row center">
                    <i class="material-icons green-text" aria-hidden="true" style="font-size: 100px;">check_circle</i>
                    <p class="green-text"><strong>Thank you for participating in this survey!</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>