<div class="col s12">
    <div class="body-h">
        <div class="header-setting">
            <h4>Registration Requests</h4>
        </div>

        <div class="container-member-verification" *ngIf="requests">

            <div *ngIf="!showDetail">
                <div class="row position-add-button">
                    <div class="col s4">
                        <button class="btn add-button center-align" (click)="showPendingClicked()">Pending</button>
                    </div>
                    <div class="col s4">
                        <button class="btn add-button center" (click)="showDeclinedClicked()">Declined</button>
                    </div>
                    <div class="col s4">
                        <button class="btn add-button center" (click)="showApprovedClicked()">Approved</button>
                    </div>
                </div>

                <div class="">
                    <!-- ================= pending -->
                    <div class="pending" *ngIf="showPending">
                        <table class="highlight responsive-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th> </th>
                                </tr>
                            </thead>

                            <tbody *ngIf="!pending || pending.length == 0">
                                <div class="row card-yes black-text center p-t-5">
                                    <img src="../../assets/img/no-pending-request.png" height="300" width="300">
                                    <h6 class="bold-700 red-text">No Pending Requests!!</h6>
                                </div>
                            </tbody>

                            <tbody *ngIf="pending && pending.length>0">
                                <tr *ngFor="let req of pending; let i=index;" style="cursor: pointer;">
                                    <td (click)="showDetails(req.id)">{{req.firstName}} {{req.lastName}}</td>
                                    <td (click)="showDetails(req.id)">{{req.email}}</td>
                                    <td (click)="showDetails(req.id)">{{req.reqDate.toDate() | date: 'dd/MMM/yyyy'}}
                                    </td>
                                    <td *ngIf="req.status =='Pending'">
                                        <span>
                                            <button class="btn btn-small green"
                                                (click)="approve(req.id)">Approve</button>
                                        </span>
                                        <span>
                                            <button class="btn btn-small red" (click)="decline(req.id)">Decline</button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- ================= declined -->
                    <div class="declined" *ngIf="showDeclined">
                        <table class="highlight striped responsive-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody *ngIf="!declined || declined.length == 0">
                                <div class="row card-yes black-text center p-t-5" >
                                    <img src="../../assets/img/no request.png" height="300" width="300">
                                    <h6 class="bold-700 red-text">No Declined Requests!!</h6>
                                </div>
                            </tbody>

                            <tbody *ngIf="declined && declined.length>0">
                                <tr *ngFor="let req of declined; let i=index;" style="cursor: pointer;">
                                    <td (click)="showDetails(req.id)">{{req.firstName}} {{req.lastName}}</td>
                                    <td (click)="showDetails(req.id)">{{req.email}}</td>
                                    <td>{{req.reqDate.toDate() | date: 'dd/MMM/yyyy'}}</td>
                                    <td>
                                        <button class="btn btn-small blue" (click)="contactMember(req.id)">Contact
                                            Client</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- ========accepted -->
                    <div class="accepted" *ngIf="showApproved">
                        <table class="highlight responsive-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Phone</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody *ngIf="!approved || approved.length == 0">
                                <div class="row card-yes black-text center p-t-5">
                                    <img src="../../assets/img/empty-folder.png"  height="300" width="300">
                                    <h6 class="bold-700 red-text">No Approved Requests!!</h6>
                                </div>
                            </tbody>

                            <tbody *ngIf="approved && approved.length>0">
                                <tr *ngFor="let req of approved; let i=index;" style="cursor: pointer;">
                                    <td (click)="showDetails(req.id)">{{req.firstName}} {{req.lastName}}</td>
                                    <td (click)="showDetails(req.id)">{{req.email}}</td>
                                    <td>{{req.reqDate.toDate() | date: 'dd/MMM/yyyy'}}</td>
                                    <td>

                                        <button class="btn btn-small blue" (click)="contactMember(req.id)">Contact
                                            Client</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="showDetail && request">
                <div class="col s12 card-panel">
                    <i class="material-icons left p-l-1 p-t-1 col s12" (Click)="backClicked()">arrow_back</i>
                    <div class="center-align">
                        <p class="title-section3">Request Status: <strong class="bold-600">{{request.status}}</strong>
                        </p>
                        <p class="sub-title-section1">Request Date: <strong class="bold-600">{{request?.reqDate.toDate()
                                | date:
                                'dd/MMM/yyyy'}}</strong></p>

                    </div>
                </div>
                <div class="col s12 white">
                    <ul class="collection with-header">
                        <li class="collection-header">
                            <h6 class="title-section3 bold-600">Request Detail</h6>
                        </li>
                        <li class="collection-item">
                            <div>First Name
                                <a class="secondary-content">{{request.firstName}}</a>
                            </div>
                        </li>
                        <li class="collection-item">
                            <div>Last Name<a class="secondary-content">{{request.lastName}}</a></div>
                        </li>
                        <li class="collection-item">
                            <div>Date of Birth<a class="secondary-content">{{request.dob}}</a></div>
                        </li>
                        <li class="collection-item">
                            <div>Email<a class="secondary-content">{{request.email}}</a>
                            </div>
                        </li>
                        <li class="collection-item">
                            <div>Phone Number<a class="secondary-content">{{request.mobile}}</a></div>
                        </li>
                        <li class="collection-item">
                            <div>Indian Status Number<a class="secondary-content">{{request.isn}}</a></div>
                        </li>
                    </ul>
                </div>

                <div class="col s12">
                    <ul class="collapsible">
                        <li>
                            <div class="collapsible-header"><i class="material-icons">account_box</i>ID</div>
                            <div class="collapsible-body">
                                <p *ngIf="!request.imgLink" class="red-text">No Id Provided
                                </p>
                                <img class="responsive-img" *ngIf="request.imgLink" [src]="request.imgLink"
                                    alt="Siginature image" />
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">draw</i>Signature</div>
                            <div class="collapsible-body">
                                <p *ngIf="!request.signature" class="red-text">No Signature Provided
                                </p>
                                <div *ngIf="request.signature">
                                    <img class="responsive-img" [src]="request.signature" alt="Signature"
                                        alt="Request Signature image" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row right">
                    <button class="btn col s12 l4 red white-text" (click)="decline();"
                        *ngIf="request.status !== 'Declined'"
                        [ngStyle]="{'border-color': themeColor, 'color': themeColor}"
                        style="margin-bottom: 5px; border: 2px solid; margin: 10px;">Decline Request
                    </button>

                    <button class="btn col s12 l4 green white-text" style="margin-bottom: 5px; margin: 10px;"
                        *ngIf="request.status !== 'Approved'" (click)="approve();">Approve Request
                        Changes</button>

                    <button class="btn col s12 l4 white-text" style="margin-bottom: 5px; margin: 10px;"
                        [ngStyle]="{'background-color': themeColor}" (click)="contactMember();">Contact Member
                    </button>
                </div>
            </div>

            <div *ngIf="contact">
                <app-contact-members [message]=message [contactDetail]="contactDetail"
                    (success)="contactHandler($event)">
                </app-contact-members>
            </div>

        </div>
    </div>
</div>