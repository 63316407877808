<div class="row ">
  <!-- ========= header -->
  <div class="row header-auth">
    <div class="col l6 s12">
      <a class="sub-title-section" [routerLink]="['/home']">
        <span class="material-symbols-outlined">
          arrow_back
        </span>
        Back to Homepage
      </a>
    </div>
    <div class="col l6 s12">
    </div>
  </div>
  <!-- ========= main -->
  <div class="login-container">
    <div class="card login-card b-s-1" [ngStyle]="{'background-color': authformBgColor }">

      <!-- ================================form -->
      <div class="col s12 m12 l12 ">
        <div id="test1" class="col s12 no-margin">
          <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
            <div class="row col s12 m12 l12 center no-margin p-b-1" *ngIf="logo">
              <img class="responsive-img" height="35%" width="35%" [src]="logo" alt="Login Image">
            </div>
            <!-- <div class="row col s12 m12 l12 center no-margin p-b-1" *ngIf="!logo">
              <img *ngIf="nationOfUse === 'Demo'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/Demo/Logo-512x512.png" alt="logo">
              <img *ngIf="nationOfUse === 'Pbcn'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/PBCN/pbcn256x256.png" alt="logo">
              <img *ngIf="nationOfUse === 'Batc'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/BATC/batc256x256.png" alt="logo">
              <img *ngIf="nationOfUse === 'DeneTha'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/Denetha/DeneTha[1916].png" alt="logo">
              <img *ngIf="nationOfUse === 'Montana'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/Montana/PlayStore-Icon[3030].png">
              <img *ngIf="nationOfUse === 'LouisBull'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/LuisBull/luisBull.jpg" alt="logo">
              <img *ngIf="nationOfUse === 'Lrrcn'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/LRRCN/lrrcn384x384.jpg" alt="logo">
              <img *ngIf="nationOfUse === 'Oneida'" class="responsive-img" height="30%" width="30%"
                src="../../../../assets/icons/Oneida/Logo-512x512.png" alt="logo">
            </div> -->
            <div class="row col s12 m12 l12 center no-margin p-b-1" *ngIf="!logo">
              <img *ngIf="nationOfUse === 'Demo'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/Demo/Logo-512x512.png" alt="Demo logo">
              <img *ngIf="nationOfUse === 'Pbcn'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/PBCN/pbcn256x256.png" alt="PBCN logo">
              <img *ngIf="nationOfUse === 'Batc'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/BATC/batc256x256.png" alt="BATC logo">
              <img *ngIf="nationOfUse === 'DeneTha'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/Denetha/DeneTha[1916].png" alt="DeneTha logo">
              <img *ngIf="nationOfUse === 'Montana'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/Montana/PlayStore-Icon[3030].png" alt="Montana logo">
              <img *ngIf="nationOfUse === 'LouisBull'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/LuisBull/luisBull.jpg" alt="LouisBull logo">
              <img *ngIf="nationOfUse === 'Lrrcn'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/LRRCN/lrrcn384x384.jpg" alt="LRRCN logo">
              <img *ngIf="nationOfUse === 'Oneida'" class="responsive-img logo" width="512" height="512"
                src="../../../../assets/icons/Oneida/Logo-512x512.png" alt="Oneida logo">
            </div>


            <div class="row">
              <h2 class="login-title center bold-600 col s12 m12 l12 title-section2"
                [ngStyle]="{'color': authformSColor}">
                SIGN IN</h2>
              <h2 class="sub-login-title center col s12 m12 l12 p-t-2" [ngStyle]="{'color': authformSColor}"
                style="font-family: 'sans-serif'; font-size: 14px;">
                Login with email
              </h2>

              <div class="input-field col s12 l12 ">
                <div class="label-input">Email<span class="red-text"> * </span>
                </div>
                <input id="email" type="email" formControlName='email' required
                  class="validate white black-text bold-300" autocomplete="off" placeholder="Email" />
                <div *ngIf="(email.dirty || email.touched) && email.invalid" class="error-text">
                  <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                  <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                </div>
              </div>

              <div class="input-field col s12 l12">
                <div class="label-input">Password<span class="red-text"> * </span>
                </div>
                <input *ngIf="!showPassword" id="password" type="password" formControlName='password' required
                  autocomplete="off" class="validate white black-text bold-300" minlength="4" maxlength="25"
                  placeholder="Password" />
                <input *ngIf="showPassword" id="password" type="text" formControlName='password' required
                  autocomplete="off" class="validate black-text bold-300" minlength="4" maxlength="25"
                  placeholder="Password" />
                <span *ngIf="!showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -12%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility_off
                </span>
                <span *ngIf="showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -12%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility
                </span>
                <div *ngIf="(password.dirty || password.touched) && password.invalid" class="error-text">
                  <small *ngIf="password.errors.required" class="red-text"> The Password Is Required.</small>
                  <small *ngIf="password.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                    Characters.</small>
                  <small *ngIf="password.errors.maxlength" class="red-text"> Password Must Not Exceed 25
                    Characters.</small>
                </div>
              </div>

              <label class="col s12 medium p-t-2 p-b-2">
                <a class='sub-title-section2 bold-300' [routerLink]="['/auth/change-password']"
                  [ngStyle]="{'color': themeColor} ">Forgot Password?</a>
              </label>
            </div>

            <div class="input-field center" style="bottom: 30px; padding: 0a;">
              <button type="submit" class="btn-large col s12 l12 m12 rectangle-button white-text"
                [ngStyle]="{'background-color': themeColor} ">Login
              </button>
            </div>

            <h5 class="col s12 l11 m10  push-m2  center-align no-margin sub-title-section2"
              [ngStyle]="{'color': authformSColor}"> Don't have an account?
              <a [ngStyle]="{'color': themeColor}" [routerLink]="['/registration-request']"
                style="text-decoration: underline;">Register Here</a>
            </h5>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>