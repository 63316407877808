<div class="row container-pi">
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
    </span>Family Member
  </h4>

  <div class="row" *ngIf="member">
    <div class="col s12">
      <app-personal-info [model]="member" [dependant]="dep"></app-personal-info>
    </div>
  </div>
  </div>