import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  nationOfUse = environment.firstNation.name;
  hasISET = environment.firstNation.ISET;

  private appName = new BehaviorSubject<string>(null);
  private appLogo = new BehaviorSubject<string>(null);

  private publicMenus: any = {
    uid: '',
    cardView: true,
    sections: [
      {
        index: 0,
        isEnabled: true,
        name: "Slider",
        icon: 'linear_scale',
        displayName: "Main Slider",
        interval: 3000,
        options: []
      },
      {
        index: 1,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "Login",
        icon: 'login',
        logoUrl: '',
        displayName: "Login/Register",
        displayNameColor: '#000000',
        addToHeader: true,
        link: '/auth/login',
        button: {
          bgColor: '#000000',
          borderColor: '#ffffff',
          title: 'Login',
          titleColor: '#ffffff',
        }
      },
      {
        index: 2,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "AboutUs",
        icon: 'contact_emergency',
        logoUrl: '',
        displayName: "About Us",
        displayNameColor: '#000000',
        displayLimit: 3,
        addToHeader: false,
        link: '/about-us'
      },
      {
        index: 3,
        isEnabled: true,
        cardView: false,
        bgColor: '#FFFFFF',
        name: "News",
        icon: 'feed',
        logoUrl: '',
        displayName: "News",
        displayNameColor: '#000000',
        displayLimit: 3,
        addToHeader: false,
        link: '/news'
      },
      {
        index: 4,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "Events",
        icon: 'calendar_today',
        logoUrl: '',
        displayName: "Events",
        displayNameColor: '#000000',
        addToHeader: false,
        link: '/events-calendar'
      },
      {
        index: 5,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "AppStore",
        icon: 'phone_iphone',
        logoUrl: '',
        displayName: "App Store",
        displayNameColor: '#000000',
        googlePlayUrl: '',
        appleStoreUrl: '',
        link: '',
      },
      {
        index: 6,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "SocialMedia",
        icon: 'public',
        logoUrl: '',
        displayName: "Social Media",
        displayNameColor: '#000000',
        facebookUrl: '',
        twitterUrl: '',
        youtubeUrl: '',
        linkedInUrl: '',
        addToHeader: false,
        link: ''
      },
      {
        index: 7,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "Departments",
        icon: 'account_tree',
        logoUrl: '',
        displayName: "Departments",
        displayNameColor: '#000000',
        displayLimit: 4,
        addToHeader: false,
        link: '/departments'
      },
      {
        index: 8,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "Governance",
        icon: 'account_balance',
        logoUrl: '',
        displayName: "Governance",
        displayNameColor: '#000000',
        showChief: false,
        councillorsLimit: 4,
        councillorColor: '#FFFFFF',
        chiefColor: "#151414",
        addToHeader: false,
        link: '/governance'
      },
      {
        index: 9,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "ContactUs",
        icon: 'contact_page',
        logoUrl: '',
        displayName: "Contact Us",
        displayNameColor: '#000000',
        addToHeader: false,
        link: '/contact-nation',
      },
      {
        index: 10,
        isEnabled: true,
        bgColor: '#FFFFFF',
        name: "CheckIfRegistered",
        icon: 'how_to_reg',
        logoUrl: '',
        displayName: "Check If Registered",
        displayNameColor: '#000000',
        addToHeader: false,
        link: '/isRegistered',
      },
      {
        index: 11,
        isEnabled: true,
        bgColor: '#FFFFFF',
        textColor: '#000000',
        name: "Footer",
        icon: 'call_to_action',
        logoUrl: '',
        displayName: 'App Footer',
        nationName: environment.firstNation.displayName,
        address: '',
        postalCode: '',
        city: '',
        province: environment.firstNation.province,
        phone: '',
        fax: '',
        tollFree: '',
        contactEmail: '',
      },
    ],

    // {
    //   index: 9,
    //   isEnabled: true,
    //   bgColor: '#ffffff',
    //   name: "ISETReg",
    //   icon: 'how_to_reg',
    //   logoUrl: '',
    //   displayName: "ISET Registration",
    //   displayNameColor: '#000000',
    //   addToHeader: false,
    //   link: '/iset-registration-form',
    // button: {
    //   bgColor: '#ffffff',
    //   borderColor: '#000000',
    //   title: 'Apply For Funding',
    //   titleColor: '#000000',
    // },
    // },
  }

  private appMenu = [
    { index: 0, staticName: 'Home', item: 'Home', link: '', icon: 'home', isEnabled: true, favorite: false },
    { index: 1, staticName: 'News', item: 'News', link: '/news', icon: 'circle_notifications', isEnabled: true, favorite: false },
    { index: 2, staticName: 'Events', item: 'Events', link: '/events-calendar', icon: 'event_available', isEnabled: true, favorite: false },
    { index: 3, staticName: 'Login', item: 'Login', link: '/auth/login', icon: 'login', isEnabled: true, favorite: false },
    { index: 4, staticName: 'About', item: 'About Us', link: '/about-us', icon: 'feed', isEnabled: true, favorite: false },
    { index: 5, staticName: 'Departments', item: 'Departments', link: '/departments', icon: 'scatter_plot', isEnabled: true, favorite: false },
    { index: 6, staticName: 'Governance', item: 'Governance', link: '/governance', icon: 'account_balance', isEnabled: true, favorite: false },
    { index: 7, staticName: 'Follow', item: 'Follow Us', link: '', icon: 'thumb_up_off_alt', isEnabled: true, favorite: false },
    { index: 8, staticName: 'Privacy', item: 'Privacy', link: '/privacy-policy', icon: 'security', isEnabled: true, favorite: false },
    { index: 9, staticName: 'Contact', item: 'Contact', link: '/contact-nation', icon: 'email', isEnabled: true, favorite: false },
    { index: 10, staticName: 'Register', item: 'Register', link: '/registration-request', icon: 'person_add', isEnabled: true, favorite: false },
    { index: 11, staticName: 'Employers', item: 'Employers', link: '', icon: 'account_circle', isEnabled: true, favorite: false },
    { index: 12, item: 'Apply For Funding', link: '/iset-registration-form', icon: 'request_quote', isEnabled: true, favorite: true },
  ];

  private adminMenus = [
    {
      name: 'MyNation',
      displayName: 'My Nation',
      icon: 'dashboard',
      isEnabled: true,
      index: 0,
      submenus: [
        {
          name: 'NationInfo',
          displayName: "Nation Info",
          icon: 'fact_check',
          isEnabled: true,
          index: 0,
          description: "Here you can add and update the nation information. You can also include address and contact information along with important social medias .",
        },
        {
          name: 'AboutUs',
          displayName: "About Us",
          icon: 'contact_emergency',
          isEnabled: true,
          index: 1,
          description: "This is where you can make edits to the company's 'About Us' information that will be viewable by both the public and members exclusively."
        },
        {
          name: 'Departments',
          displayName: "Departments",
          icon: 'scatter_plot',
          isEnabled: true,
          index: 2,
          description: "This is where you can manage the departments in your nation",
        },
        {
          name: 'Governance',
          displayName: "Governance",
          icon: 'gavel',
          isEnabled: true,
          index: 3,
          description: '',
        },
        {
          name: 'Board',
          displayName: 'Board',
          icon: 'groups',
          isEnabled: true,
          index: 4,
          description: '',
        }
        // {
        //   name: 'AreaOfInterest',
        //   displayName: 'Area Of Interest',
        //   icon: 'view_comfy',
        //   isEnabled: true,
        //   index: 4,
        //   description: '',
        // },
      ]
    },
    {
      name: 'Manage',
      displayName: "Manage",
      icon: 'supervised_user_circle',
      isEnabled: true,
      index: 1,
      submenus: [
        {
          name: 'Admins',
          displayName: "Admins",
          icon: 'manage_accounts',
          isEnabled: true,
          index: 0,
          description: "Streamline the management of your admin team and ensure smooth operations."
        },
        {
          name: 'Membership',
          displayName: "Membership",
          icon: 'people',
          isEnabled: true,
          index: 1,
          description: ""
        },
        {
          name: 'Applications',
          displayName: "Applications",
          icon: 'list_alt',
          isEnabled: true,
          index: 2,
          description: ""
        },
        // {
        //   name: 'DemoRequest',
        //   displayName: "Demo Request",
        //   icon: 'preview',
        //   isEnabled: false,
        //   index: 3,
        //   description: ""
        // },
      ]
    },
    {
      name: 'NewsAndEvents',
      displayName: 'News / Events',
      icon: 'newspaper',
      isEnabled: true,
      index: 2,
      submenus: [
        {
          name: 'ManageNews',
          displayName: 'Manage News',
          icon: 'public',
          isEnabled: true,
          index: 0,
          description: ""
        },
        {
          name: 'ManageEvents',
          displayName: 'Manage Events',
          icon: 'public',
          isEnabled: true,
          index: 1,
          description: ""
        },
        {
          name: 'ArchivedNews',
          displayName: 'Archived News',
          icon: 'archive',
          isEnabled: true,
          index: 2,
          description: ""
        },
        {
          name: 'ArchivedEvents',
          displayName: 'Archived Events',
          icon: 'archive',
          isEnabled: true,
          index: 3,
          description: ""
        }
      ]
    },
    {
      name: 'PollsAndSurveys',
      displayName: 'Polls / Surveys',
      icon: 'ballot',
      isEnabled: true,
      index: 3,
      description: "Design and manage interactive polls and surveys with ease to gather valuable insights and feedbacks.",
      submenus: []
    },
    {
      name: 'Communication',
      displayName: 'Communication',
      icon: 'chat',
      isEnabled: true,
      index: 4,
      description: "You can connect with other nation members here.",
      submenus: []
    },
    {
      name: 'Stats',
      displayName: "Stats",
      icon: 'pie_chart',
      isEnabled: true,
      index: 5,
      submenus: [
        {
          name: 'AppStatistics',
          displayName: 'App Statistics',
          icon: 'bar_chart',
          isEnabled: true,
          index: 0,
          description: ""
        },
        {
          name: 'DepartmentStats',
          displayName: 'Department Stats',
          icon: 'assessment',
          isEnabled: true,
          index: 1,
          description: ""
        },
        {
          name: 'Demographics',
          displayName: 'Demographics',
          icon: 'show_chart',
          isEnabled: true,
          index: 2,
          description: ""
        },
        {
          name: 'Charts',
          displayName: 'Charts',
          icon: 'bar_chart',
          isEnabled: true,
          index: 3,
          description: "",
          underConstruction: true
        },
        {
          name: 'Analytics',
          displayName: 'Analytics',
          icon: 'bubble_chart',
          isEnabled: true,
          index: 4,
          description: "",
          underConstruction: true
        },
        {
          name: 'Reports',
          displayName: 'Reports',
          icon: 'description',
          isEnabled: true,
          index: 5,
          description: "",
          underConstruction: true
        },
      ]
    },
    {
      name: 'Housing',
      displayName: 'Housing',
      icon: 'house',
      isEnabled: true,
      index: 6,
      submenus: [
        {
          name: 'Houses',
          displayName: 'Houses',
          icon: 'real_estate_agent',
          isEnabled: true,
          index: 0,
          description: ""
        },
        {
          name: 'NewHouse',
          displayName: 'New House',
          icon: 'house',
          isEnabled: true,
          index: 1,
          description: ""
        },
        {
          name: 'Repairs',
          displayName: 'Repair Requests',
          icon: 'home_repair_service',
          isEnabled: true,
          index: 2,
          description: ""
        },
        {
          name: 'Renovations',
          displayName: 'Renovation Requests',
          icon: 'construction',
          isEnabled: true,
          index: 3,
          description: ""
        }
      ]
    },
    {
      name: 'Settings',
      displayName: 'Settings',
      icon: 'settings',
      isEnabled: true,
      index: 7,
      submenus: [
        {
          name: 'MenuSetting',
          displayName: 'Menu Setting',
          icon: 'phonelink_setup',
          isEnabled: true,
          index: 0,
          description: "It is where you configure your app's form, views , color and other items to adjust your app to your business needs."
        },
        {
          name: 'LandingPage',
          displayName: 'Landing Page',
          icon: 'web',
          isEnabled: true,
          index: 1,
          description: "This is what the general public will see and have access to when they connect to your site or mobile app."
        },
        // {
        //   name: 'AppMenu',
        //   displayName: 'App Menu',
        //   icon: 'list',
        //   isEnabled: true,
        //   index: 2,
        //   description: "This is the menu that the general public will have access to on your mobile app."
        // },
        // {
        //   name: 'LoginAndSignup',
        //   displayName: 'Login/Signup Pages',
        //   icon: 'lock_open',
        //   isEnabled: true,
        //   index: 3,
        //   description: "This is where you customize your app's login and registration pages to suit your business requirements."
        // },
        {
          name: 'AdminMenu',
          displayName: 'Admin Menu',
          icon: 'menu',
          isEnabled: true,
          index: 2,
          description: "This is the menu items that will be displayed when Admins and employees log in."
        },
        {
          name: 'MemberMenu',
          displayName: 'Member Menu',
          icon: 'menu_book',
          isEnabled: true,
          index: 3,
          description: "This is the menu items that will be displayed when Members logs in."
        },
        {
          name: 'SectionSetting',
          displayName: 'Custom Section',
          icon: 'settings_overscan',
          isEnabled: true,
          index: 4,
          description: "This is the menu where you can add and customize sections."
        },
        {
          name: 'SocialMedia',
          displayName: 'Social Media',
          icon: 'public',
          isEnabled: true,
          index: 5,
          description: "This is the menu where you can create posts in social media."
        },

        {
          name: 'AllMenus',
          displayName: 'All Menus',
          icon: 'settings_overscan',
          isEnabled: true,
          index: 6,
          description: "This is the menu where you can add and customize sections."
        },
      ]
    },
    {
      name: 'Logout',
      displayName: 'Logout',
      icon: 'logout',
      isEnabled: true,
      index: 8,
      submenus: []
    },
  ];

  private memberMenus = [
    {
      name: 'MyAccount',
      displayName: 'My Account',
      icon: 'account_circle',
      isEnabled: true,
      index: 0,
      submenus: [
        {
          name: 'MyPersonalInformation',
          displayName: "My Personal Information",
          icon: 'account_box',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'FamilyComposition',
          displayName: "Family Composition",
          icon: 'people',
          isEnabled: true,
          index: 1,
          underConstruction: false
        },
        {
          name: 'ISApplication',
          displayName: "IS Application",
          icon: 'import_contacts',
          isEnabled: true,
          index: 2,
        },
        {
          name: 'SKISApplication',
          displayName: "SK IS Application",
          icon: 'import_contacts',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'MyCasePlan',
          displayName: 'My Case Plan',
          icon: 'add_business',
          isEnabled: true,
          index: 4,
        },
        {
          name: 'UploadDocuments',
          displayName: 'Upload Documents',
          icon: 'cloud_upload',
          isEnabled: true,
          index: 5,
        },
        {
          name: 'MySettings',
          displayName: 'My Settings',
          icon: 'settings',
          isEnabled: true,
          index: 6,
        },
        {
          name: 'MyAreasOfInterest',
          displayName: 'My Areas Of Interest',
          icon: 'share',
          isEnabled: true,
          index: 7,
        },
        {
          name: 'IASurvey',
          displayName: 'IA Survey',
          icon: 'list_alt',
          isEnabled: true,
          index: 8,
        }
      ]
    },
    {
      name: 'MyChat',
      displayName: 'My Chat / Circle',
      icon: 'mail',
      isEnabled: true,
      index: 1,
      submenus: []
    },
    {
      name: 'NewsAndEvents',
      displayName: 'News / Events',
      icon: 'notifications_none',
      isEnabled: true,
      index: 2,
      submenus: [
        {
          name: 'News',
          displayName: "News",
          icon: 'circle_notifications',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Events',
          displayName: "Events",
          icon: 'event_available',
          isEnabled: true,
          index: 1,
        },
      ]
    },
    // {
    //   name: 'News',
    //   displayName: 'News',
    //   icon: 'notifications_none',
    //   isEnabled: true,
    //   index: 2,
    //   submenus: []
    // },
    // {
    //   name: 'Events',
    //   displayName: 'Events',
    //   icon: 'event_available',
    //   isEnabled: true,
    //   index: 3,
    //   submenus: []
    // },
    {
      name: 'MyServices',
      displayName: 'My Services',
      icon: 'home_repair_service',
      isEnabled: true,
      index: 4,
      submenus: [
        {
          name: 'SocialDevelopment',
          displayName: 'Social Development',
          icon: 'assessment',
          isEnabled: true,
          index: 0,
          submenus: [
            {
              name: 'RequiredSignature',
              displayName: 'Required Signature',
              icon: 'pending_actions',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'MyBenefits',
              displayName: 'My Benefits',
              icon: 'update',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'RequestBenefits',
              displayName: 'Request Benefits',
              icon: 'add_circle',
              isEnabled: true,
              index: 2,
            },
            // {
            //   name: 'IncomeStatement',
            //   displayName: 'Submit My Income Statement',
            //   icon: 'monetization_on',
            //   isEnabled: true,
            //   index: 3,
            // },
            // {
            //   name: 'ActionPlan',
            //   displayName: 'My Action Plan',
            //   icon: 'supervised_user_circle',
            //   isEnabled: true,
            //   index: 4,
            // },
            // {
            //   name: 'PastBenefits',
            //   displayName: 'My Past Benefits',
            //   icon: 'history',
            //   isEnabled: true,
            //   index: 5,
            // },
          ]
        },
        {
          name: 'Housing',
          displayName: 'Housing',
          icon: 'assessment',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'ISET',
          displayName: 'I.S.E.T',
          icon: 'assessment',
          isEnabled: true,
          index: 2,
          submenus: [
            {
              name: 'NewApplication',
              displayName: 'New Application',
              icon: 'account_box',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'UpdateApplication',
              displayName: 'Update Application',
              icon: 'account_box',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'Interventions',
              displayName: 'My Interventions',
              icon: 'update',
              isEnabled: true,
              index: 2,
            },
            {
              name: 'ServicePlan',
              displayName: 'Service Plan',
              icon: 'account_box',
              isEnabled: true,
              index: 3,
            },
            {
              name: 'UploadDocuments',
              displayName: 'Upload Documents',
              icon: 'pending_actions',
              isEnabled: true,
              index: 4,
            },
          ]
        },
        {
          name: 'PostSecondary',
          displayName: "Post Secondary Education",
          icon: 'assessment',
          isEnabled: true,
          index: 3,
          submenus: [
            {
              name: 'MyApplication',
              displayName: 'My Application',
              icon: 'pending_actions',
              isEnabled: true,
              index: 0,
            },
          ]
        },
      ]
    },

    {
      name: 'MyNation',
      displayName: 'My Nation',
      icon: 'dashboard',
      isEnabled: true,
      index: 5,
      submenus: [
        {
          name: 'Departments',
          displayName: 'Departments / Offices',
          icon: 'account_tree',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Governance',
          displayName: 'Governance',
          icon: 'gavel',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'Boards',
          displayName: 'Boards',
          icon: 'groups',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'NationContact',
          displayName: "Nation Contact",
          icon: 'contact_mail',
          isEnabled: true,
          index: 4,
        },
      ]
    },

    {
      name: 'PollsAndElection',
      displayName: 'Polls / Election',
      icon: 'poll',
      isEnabled: true,
      index: 6,
      submenus: [
        {
          name: 'Nomination',
          displayName: "Nomination",
          icon: 'people',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Election',
          displayName: "Election",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'PollsAndSurveys',
          displayName: "Polls and Surveys",
          icon: 'poll',
          isEnabled: true,
          index: 2,
        },
        {
          name: 'MyCampaign',
          displayName: "My Campaign",
          icon: 'campaign',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'MyNominations',
          displayName: "My Nominations",
          icon: 'campaign',
          isEnabled: true,
          index: 4,
        },
        {
          name: 'MyElections',
          displayName: "My Elections",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 5,
        }
      ]
    },

    {
      name: 'JobRelated',
      displayName: 'Job Related',
      icon: 'work',
      isEnabled: true,
      index: 7,
      submenus: [
        {
          name: 'ResumeBuilder',
          displayName: 'Resume Builder',
          icon: 'folder_shared',
          isEnabled: true,
          index: 0,
          submenus: [
            {
              name: 'Objective',
              displayName: 'Objective',
              icon: 'home_work',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'Highlights',
              displayName: 'Highlights',
              icon: 'person_add',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'WorkExperienceAndTraining',
              displayName: 'Work Experience / Training',
              icon: 'apartment',
              isEnabled: true,
              index: 2,
            },
            {
              name: 'Skills',
              displayName: 'Skills',
              icon: 'home_work',
              isEnabled: true,
              index: 3,
            },
            {
              name: 'Education',
              displayName: 'Education',
              icon: 'import_contacts',
              isEnabled: true,
              index: 4,
            },
            {
              name: 'CoursesAndCertificates',
              displayName: 'Courses / Certificates',
              icon: 'note',
              isEnabled: true,
              index: 5,
            },
            {
              name: 'Activities',
              displayName: 'Activities',
              icon: 'home_work',
              isEnabled: true,
              index: 6,
            },
            {
              name: 'References',
              displayName: 'References',
              icon: 'group_add',
              isEnabled: true,
              index: 7,
            }
          ]
        },

        {
          name: 'JobBoard',
          displayName: "Job Board",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 1,
        },
      ]
    },

    {
      name: 'MyHouse',
      displayName: "My House",
      icon: 'house',
      isEnabled: true,
      index: 8,
      submenus: [
        {
          name: 'HouseDetail',
          displayName: 'My House Detail',
          icon: 'house',
          isEnabled: true,
          index: 0,
          description: ""
        },
        {
          name: 'HouseApp',
          displayName: 'Application for Housing',
          icon: 'house',
          isEnabled: true,
          index: 1,
          description: ""
        },
        {
          name: 'Repairs',
          displayName: 'Repairs',
          icon: 'home_repair_service',
          isEnabled: true,
          index: 2,
          description: ""
        },
        {
          name: 'Renovations',
          displayName: 'Renovations',
          icon: 'construction',
          isEnabled: true,
          index: 3,
          description: ""
        },
      ]
    },

    {
      name: 'SocialDevelopment',
      displayName: 'Social Development',
      icon: 'assessment',
      isEnabled: true,
      index: 9,
      submenus: []
    },

    {
      name: 'Logout',
      displayName: 'Logout',
      icon: 'logout',
      isEnabled: true,
      index: 10,
      submenus: []
    },
  ];

  private colors = [
    { name: 'Black', value: 'black-text $' },
    { name: 'Light Blue 1', value: 'blue-text text-lighten-2 $' },
    { name: 'Light Blue 2', value: 'blue-text text-lighten-1 $' },
    { name: 'Blue', value: 'blue-text $' },
    { name: 'Dark Blue 1', value: 'blue-text text-darken-1 $' },
    { name: 'Dark Blue 2', value: 'blue-text text-darken-2 $' },
    { name: 'Dark Blue 3', value: 'blue-text text-darken-3 $' },
    { name: 'Light Teal 1', value: 'teal-text text-lighten-2 $' },
    { name: 'Light Teal 2', value: 'teal-text text-lighten-1 $' },
    { name: 'Teal', value: 'teal-text $' },
    { name: 'Dark Teal 1', value: 'teal-text text-darken-1 $' },
    { name: 'Dark Teal 2', value: 'teal-text text-darken-2 $' },
    { name: 'Dark Teal 3', value: 'teal-text text-darken-3 $' },
    { name: 'Light Purple 1', value: 'purple-text text-lighten-2 $' },
    { name: 'Light Purple 2', value: 'purple-text text-lighten-1 $' },
    { name: 'Purple', value: 'purple-text $' },
    { name: 'Dark Purple 1', value: 'purple-text text-darken-1 $' },
    { name: 'Dark Purple 2', value: 'purple-text text-darken-2 $' },
    { name: 'Dark Purple 3', value: 'purple-text text-darken-3 $' },
    { name: 'Light Green 1', value: 'green-text text-lighten-2 $' },
    { name: 'Light Green 2', value: 'green-text text-lighten-1 $' },
    { name: 'Green', value: 'green-text $' },
    { name: 'Dark Green 1', value: 'green-text text-darken-1 $' },
    { name: 'Dark Green 2', value: 'green-text text-darken-2 $' },
    { name: 'Dark Green 3', value: 'green-text text-darken-3 $' },
    { name: 'Light Orange 1', value: 'orange-text text-lighten-2 $' },
    { name: 'Light Orange 2', value: 'orange-text text-lighten-1 $' },
    { name: 'Orange', value: 'orange-text $' },
    { name: 'Dark Orange 1', value: 'orange-text text-darken-1 $' },
    { name: 'Dark Orange 2', value: 'orange-text text-darken-2 $' },
    { name: 'Dark Orange 3', value: 'orange-text text-darken-3 $' },
    { name: 'Light Red 1', value: 'red-text text-lighten-2 $' },
    { name: 'Light Red 2', value: 'red-text text-lighten-1 $' },
    { name: 'Red', value: 'red-text $' },
    { name: 'Dark Red 1', value: 'red-text text-darken-1 $' },
    { name: 'Dark Red 2', value: 'red-text text-darken-2 $' },
    { name: 'Dark Red 3', value: 'red-text text-darken-3 $' },
    { name: 'Light Brown 1', value: 'brown-text text-lighten-2 $' },
    { name: 'Light Brown 2', value: 'brown-text text-lighten-1 $' },
    { name: 'Brown', value: 'brown-text $' },
    { name: 'Dark Brown 1', value: 'brown-text text-darken-1 $' },
    { name: 'Dark Brown 2', value: 'brown-text text-darken-2 $' },
    { name: 'Dark Brown 3', value: 'brown-text text-darken-3 $' },
    { name: 'Light Cyan 1', value: 'cyan-text text-lighten-2 $' },
    { name: 'Light Cyan 2', value: 'cyan-text text-lighten-1 $' },
    { name: 'Cyan', value: 'cyan-text $' },
    { name: 'Dark Cyan 1', value: 'cyan-text text-darken-1 $' },
    { name: 'Dark Cyan 2', value: 'cyan-text text-darken-2 $' },
    { name: 'Dark Cyan 3', value: 'cyan-text text-darken-3 $' },
    { name: 'Light Pink 1', value: 'pink-text text-lighten-2 $' },
    { name: 'Light Pink 2', value: 'pink-text text-lighten-1 $' },
    { name: 'Pink', value: 'pink-text $' },
    { name: 'Dark Pink 1', value: 'pink-text text-darken-1 $' },
    { name: 'Dark Pink 2', value: 'pink-text text-darken-2 $' },
    { name: 'Dark Pink 3', value: 'pink-text text-darken-3 $' },
    { name: 'Light Indigo 1', value: 'indigo-text text-lighten-2 $' },
    { name: 'Light Indigo 2', value: 'indigo-text text-lighten-1 $' },
    { name: 'Indigo', value: 'indigo-text $' },
    { name: 'Dark Indigo 1', value: 'indigo-text text-darken-1 $' },
    { name: 'Dark Indigo 2', value: 'indigo-text text-darken-2 $' },
    { name: 'Dark Indigo 3', value: 'indigo-text text-darken-3 $' },
  ];

  private countries = ["Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla",
    "Antarctica", "Antigua and Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria",
    "Azerbaijan", "Bahamas (the)", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize",
    "Benin", "Bermuda", "Bhutan", "Bolivia (Plurinational State of)", "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina", "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory (the)",
    "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Cayman Islands (the)", "Central African Republic (the)", "Chad", "Chile", "China",
    "Christmas Island", "Cocos (Keeling) Islands (the)", "Colombia", "Comoros (the)", "Congo (the Democratic Republic of the)",
    "Congo (the)", "Cook Islands (the)", "Costa Rica", "Croatia", "Cuba", "Curaçao", "Cyprus", "Czechia",
    "Côte d'Ivoire", "Denmark", "Djibouti", "Dominica", "Dominican Republic (the)", "Ecuador", "Egypt",
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Territories (the)",
    "Gabon", "Gambia (the)", "Georgia", "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada",
    "Guadeloupe", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Heard Island and McDonald Islands", "Holy See (the)", "Honduras", "Hong Kong", "Hungary", "Iceland",
    "India", "Indonesia", "Iran (Islamic Republic of)", "Iraq", "Ireland", "Isle of Man", "Israel",
    "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic (the)", "Latvia",
    "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macao",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands (the)", "Martinique",
    "Mauritania", "Mauritius", "Mayotte", "Mexico", "Micronesia (Federated States of)", "Moldova (the Republic of)",
    "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
    "Nepal", "Netherlands (the)", "New Caledonia", "New Zealand", "Nicaragua", "Niger (the)", "Nigeria", "Niue",
    "Norfolk Island", "Northern Mariana Islands (the)", "Norway", "Oman", "Pakistan", "Palau", "Palestine, State of", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines (the)", "Pitcairn", "Poland", "Portugal", "Puerto Rico",
    "Qatar", "Republic of North Macedonia", "Romania", "Russian Federation (the)", "Rwanda", "Réunion",
    "Saint Barthélemy", "Saint Helena, Ascension and Tristan da Cunha", "Saint Kitts and Nevis", "Saint Lucia",
    "Saint Martin (French part)", "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa",
    "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
    "Singapore", "Sint Maarten (Dutch part)", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Georgia and the South Sandwich Islands", "South Sudan", "Spain", "Sri Lanka",
    "Sudan (the)", "Suriname", "Svalbard and Jan Mayen", "Sweden", "Switzerland", "Syrian Arab Republic",
    "Taiwan", "Tajikistan", "Tanzania, United Republic of", "Thailand", "Timor-Leste", "Togo", "Tokelau",
    "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands (the)",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates (the)", "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela (Bolivarian Republic of)",
    "Vietnam", "Virgin Islands (British)", "Virgin Islands (U.S.)", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe",
    "Åland Islands"
  ];

  private provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];

  private provincesWithCode = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }];

  private cities = ["Ontario", 'Quebec', "Nova Scotia", "Yukon", "Newfoundland and Labrador", "Manitoba", "Sakatchewan", "New Brunswick",
    "Nunavut", "Alberta", "British Columbia", "Prince Edward Island", "Northwest Territories"
  ];

  private states = ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
    "Delaware", "District Of Columbia", "Federated States Of Micronesia", "Florida", "Georgia", "Guam",
    "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands",
    "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio",
    "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];

  private Icons: string[] = [
    "accessibility", "account_balance", "alarm", "android", "assignment", "backup",
    "book", "camera", "cloud", "dashboard", "email", "favorite",
    "grade", "home", "info", "language", "mail", "notifications",
    "people", "settings", "3d_rotation", "ac_unit", "access_alarm", "access_alarms",
    "access_time", "accessible", "account_balance_wallet", "account_box", "account_circle", "adb",
    "add", "add_a_photo", "add_alarm", "add_alert", "add_box", "add_circle",
    "add_circle_outline", "add_location", "add_shopping_cart", "add_to_photos", "add_to_queue", "adjust",
    "airline_seat_flat", "airline_seat_flat_angled", "airline_seat_individual_suite", "airline_seat_legroom_extra", "airline_seat_legroom_normal", "airline_seat_legroom_reduced",
    "airline_seat_recline_extra", "airline_seat_recline_normal", "airplanemode_active", "airplanemode_inactive", "airplay", "airport_shuttle",
    "alarm_add", "alarm_off", "alarm_on", "album", "all_inclusive", "all_out",
    "announcement", "apps", "archive", "arrow_back", "arrow_downward", "arrow_drop_down",
    "arrow_drop_down_circle", "arrow_drop_up", "arrow_forward", "arrow_upward", "art_track", "aspect_ratio",
    "assessment", "assignment_ind", "assignment_late", "assignment_return", "assignment_returned", "assignment_turned_in",
    "assistant", "assistant_photo", "attach_file", "attach_money", "attachment", "audiotrack",
    "autorenew", "av_timer", "backspace", "battery_alert", "battery_charging_full", "battery_full",
    "battery_std", "battery_unknown", "beach_access", "beenhere", "block", "bluetooth", "bluetooth_audio",
    "bluetooth_connected", "bluetooth_disabled", "bluetooth_searching", "blur_circular", "blur_linear", "blur_off",
    "blur_on", "bookmark", "bookmark_border", "border_all", "border_bottom", "border_clear", "border_color",
    "border_horizontal", "border_inner", "border_left", "border_outer", "border_right", "border_style",
    "border_top", "border_vertical", "branding_watermark", "brightness_1", "brightness_2", "brightness_3", "brightness_4",
    "brightness_5", "brightness_6", "brightness_7", "brightness_auto", "brightness_high", "brightness_low",
    "brightness_medium", "broken_image", "brush", "bubble_chart", "bug_report", "build", "burst_mode",
    "business", "business_center", "cached", "cake", "call", "call_end", "call_made", "call_merge", "call_missed",
    "call_missed_outgoing", "call_received", "call_split", "call_to_action", "camera_alt", "camera_enhance", "camera_front",
    "camera_rear", "camera_roll", "cancel", "card_giftcard", "card_membership", "card_travel", "casino",
    "cast", "cast_connected", "center_focus_strong", "center_focus_weak", "change_history", "chat", "chat_bubble",
    "chat_bubble_outline", "check", "check_box", "check_box_outline_blank", "check_circle", "check_circle_outline",
    "chevron_left", "chevron_right", "child_care", "child_friendly", "chrome_reader_mode", "class", "clear",
    "clear_all", "close", "closed_caption", "cloud_circle", "cloud_done", "cloud_download", "cloud_off", "cloud_queue",
    "cloud_upload", "code", "collections", "color_lens", "colorize", "comment", "compare", "compare_arrows",
    "computer", "confirmation_number", "contact_mail", "contact_phone", "contacts", "content_copy", "content_cut",
    "content_paste", "control_point", "control_point_duplicate", "create", "create_new_folder", "credit_card", "crop",
    "crop_16_9", "crop_3_2", "crop_5_4", "crop_7_5", "crop_din", "crop_free", "crop_landscape", "crop_original",
    "crop_portrait", "crop_rotate", "crop_square", "data_usage", "date_range", "dehaze", "delete", "delete_forever",
    "delete_outline", "delete_sweep", "departure_board", "description", "desktop_mac", "desktop_windows", "details",
    "developer_board", "developer_mode", "device_hub", "device_unknown", "devices", "devices_other", "dialer_sip",
    "dialpad", "directions", "directions_bike", "directions_boat", "directions_bus", "directions_car", "directions_railway",
    "directions_run", "directions_subway", "directions_transit", "directions_walk", "disc_full", "dns", "do_not_disturb",
    "do_not_disturb_alt", "do_not_disturb_off", "do_not_disturb_on", "dock", "domain", "done", "done_all", "done_outline",
    "donut_large", "donut_small", "drafts", "drag_handle", "drive_eta", "dvr", "edit", "edit_location", "eject",
    "enhanced_encryption", "equalizer", "error", "error_outline", "euro_symbol", "ev_station", "event", "event_available",
    "event_busy", "event_note", "event_seat", "exit_to_app", "expand_less", "expand_more", "explicit", "explore", "exposure",
    "exposure_neg_1", "exposure_neg_2", "exposure_plus_1", "exposure_plus_2", "exposure_zero", "extension", "face",
    "fast_forward", "fast_rewind", "favorite_border", "featured_play_list", "featured_video", "feedback", "fiber_dvr",
    "fiber_manual_record", "fiber_new", "fiber_pin", "fiber_smart_record", "file_download", "file_upload", "filter",
    "filter_1", "filter_2", "filter_3", "filter_4", "filter_5", "filter_6", "filter_7", "filter_8", "filter_9",
    "filter_9_plus", "filter_b_and_w", "filter_center_focus", "filter_drama", "filter_frames", "filter_hdr", "filter_list",
    "filter_none", "filter_tilt_shift", "filter_vintage", "find_in_page", "find_replace", "fingerprint", "first_page",
    "fitness_center", "flag", "flare", "flash_auto", "flash_off", "flash_on", "flight", "flight_land", "flight_takeoff",
    "flip", "flip_to_back", "flip_to_front", "folder", "folder_open", "folder_shared", "folder_special", "font_download",
    "format_align_center", "format_align_justify", "format_align_left", "format_align_right", "format_bold", "format_clear",
    "format_color_fill", "format_color_reset", "format_color_text", "format_indent_decrease", "format_indent_increase",
    "format_italic", "format_line_spacing", "format_list_bulleted", "format_list_numbered", "format_paint", "format_quote",
    "format_shapes", "format_size", "format_strikethrough", "format_textdirection_l_to_r", "format_textdirection_r_to_l",
    "format_underlined", "forum", "forward", "forward_10", "forward_30", "forward_5", "free_breakfast", "fullscreen",
    "fullscreen_exit", "functions", "g_translate", "gamepad", "games", "gavel", "gesture", "get_app", "gif",
    "golf_course", "gps_fixed", "gps_not_fixed", "gps_off", "gradient", "grain", "graphic_eq", "grid_off", "grid_on",
    "group", "group_add", "group_work", "hd", "hdr_off", "hdr_on", "hdr_strong", "hdr_weak", "headset", "headset_mic",
    "healing", "hearing", "height", "help", "help_outline", "high_quality", "highlight", "highlight_off", "history",
    "hot_tub", "hotel", "hourglass_empty", "hourglass_full", "http", "https", "image", "image_aspect_ratio", "import_contacts",
    "import_export", "important_devices", "inbox", "indeterminate_check_box", "input", "insert_chart", "insert_comment",
    "insert_drive_file", "insert_emoticon", "insert_invitation", "insert_link", "insert_photo", "invert_colors",
    "invert_colors_off", "iso", "keyboard", "keyboard_arrow_down", "keyboard_arrow_left", "keyboard_arrow_right",
    "keyboard_arrow_up", "keyboard_backspace", "keyboard_capslock", "keyboard_hide", "keyboard_return", "keyboard_tab",
    "keyboard_voice", "kitchen", "label", "label_outline", "landscape", "laptop", "laptop_chromebook", "laptop_mac",
    "laptop_windows", "last_page", "launch", "layers", "layers_clear", "leak_add", "leak_remove", "lens", "library_add",
    "library_books", "library_music", "lightbulb_outline", "line_style", "line_weight", "linear_scale", "link",
    "linked_camera", "list", "live_help", "live_tv", "local_activity", "local_airport", "local_atm", "local_bar",
    "local_cafe", "local_car_wash", "local_convenience_store", "local_dining", "local_drink", "local_florist",
    "local_gas_station", "local_grocery_store", "local_hospital", "local_hotel", "local_laundry_service", "local_library",
    "local_mall", "local_movies", "local_offer", "local_parking", "local_pharmacy", "local_phone", "local_pizza",
    "local_play", "local_post_office", "search", "account_circle", "chat", "lock", "help", "arrow_back", "arrow_forward",
    "menu", "delete", "edit", "done", "clear", "refresh", "star", "star_border", "flag", "share", "more_vert",
    "attach_file", "file_download", "file_upload", "picture_in_picture", "videocam", "photo", "library_books", "map",
    "location_on", "event", "group", "work", "shopping_cart", "payment", "receipt", "local_offer", "restaurant", "flight",
    "hotel", "directions", "local_phone", "web", "public", "today", "event_available", "event_busy", "timer", "location_city",
    "place", "local_gas_station", "local_hospital", "local_movies", "local_grocery_store", "local_pizza", "local_cafe",
    "local_bar", "local_pharmacy", "local_library", "local_mall", "local_airport", "local_atm", "local_car_wash",
    "local_convenience_store", "local_dining", "local_drink", "local_florist", "local_play", "local_post_office",
    "local_laundry_service", "local_offer", "flight_land", "flight_takeoff", "directions_car", "directions_transit",
    "directions_bike", "directions_walk", "directions_run", "train", "tram", "subway", "directions_railway", "directions_boat",
    "directions_bus", "restaurant_menu", "store", "store_mall_directory", "local_printshop", "local_shipping", "storefront",
    "business", "school", "wifi", "bluetooth", "data_usage", "battery_full", "signal_cellular_alt", "signal_wifi_4_bar",
    "airplanemode_active", "location_disabled", "gps_fixed", "settings_brightness", "brightness_medium", "screen_rotation",
    "screen_lock_portrait", "developer_mode", "security", "vpn_lock", "lock_outline", "credit_card", "payment", "money",
    "attach_money", "monetization_on", "trending_up", "trending_down", "thumbs_up_down", "thumb_up", "thumb_down", "stars",
    "star_border", "star_half", "whatshot", "favorite_border", "share", "sms", "phone", "call", "call_end", "contact_phone",
    "contact_mail", "location_on", "place", "map", "directions", "directions_run", "explore", "near_me",
  ];

  constructor() {
    // if (this.nationOfUse === 'Demo') {
    //   this.publicMenus.sections.push(
    //     { 
    //       index: 10,
    //       isEnabled: true,
    //       bgColor: '#ffffff',
    //       name: 'Demo',
    //       icon: 'account_circle',
    //       logoUrl: '',
    //       displayName: 'Demo Account',
    //       displayNameColor: '#000000',
    //       addToHeader: true,
    //       link: '',
    //     }
    //   );
    //   this.publicMenus.sections.find(m => m.name == 'Footer').index++;
    // }
    if (this.hasISET) {
      this.publicMenus.sections.push(
        {
          index: 11,
          isEnabled: true,
          name: 'ISETReg',
          bgColor: '#ffffff',
          icon: 'how_to_reg',
          logoUrl: '',
          displayName: 'ISET Registration',
          displayNameColor: '#000000',
          button: {
            bgColor: '#ffffff',
            borderColor: '#000000',
            title: 'Apply For Funding',
            titleColor: '#000000',
          },
          addToHeader: true,
          link: '/iset-registration-form',
        }
      );
      this.publicMenus.sections.find(m => m.name == 'Footer').index++;
    }
  }

  //Nation Info
  updateAppName(name: string) {
    this.appName.next(name);
  }

  getAppName() {
    return this.appName.asObservable();
  }

  updateAppLogo(logo: string) {
    this.appLogo.next(logo);
  }

  getAppLogo() {
    return this.appLogo.asObservable();
  }

  //Default Menus
  getPublicMenus() {
    return this.publicMenus;
  }

  getAppMenu() {
    // if (this.nationOfUse === 'Demo') {
    //   this.appMenu.push(
    //     { index: 13, staticName: 'Demo', item: 'Demo Account', link: '', icon: 'account_circle', isEnabled: true, favorite: false }
    //   )
    // }
    return this.appMenu;
  }

  getAdminMenus() {
    const myNationMenu = this.adminMenus.find(menu => menu.name == "MyNation");
    if (myNationMenu) {
      myNationMenu.displayName = this.nationOfUse;
    }
    return this.adminMenus;
  }

  getMemberMenus() {
    return this.memberMenus;
  }

  //colors
  getColors() {
    return this.colors;
  }

  //Address
  getCountries() {
    return this.countries;
  }

  getProvince() {
    return this.provinces;
  }

  getProvinceWithCode() {
    return this.provincesWithCode;
  }

  getCities() {
    return this.cities;
  }

  getStates() {
    return this.states;
  }

  getIcons() {
    return this.Icons;
  }

}
