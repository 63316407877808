import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { MemberService } from 'src/app/services/api/member.service';
import { CommunityService } from 'src/app/services/api/community.service';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { toast } from 'materialize-css';
import { ChartOptions, ChartType, ChartTypeRegistry, TooltipItem } from 'chart.js';
// import { Label, SingleDataSet } from 'ng2-charts';
import * as  pluginDataLabels from 'chartjs-plugin-datalabels';
import { SMSModel } from 'src/app/models/SMSModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { MediaService } from 'src/app/services/api/media.service';
//import { SearchMembersModel } from 'src/app/models/searchMemberModel';
import { FamilyCompositionService } from 'src/app/services/api/family-composition.service';
import { Router } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { MemberSearchModel } from 'src/app/models/BandMemberFilterModel';
import { createMask } from '@ngneat/input-mask';
import { messaging } from 'firebase';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-search-members',
  templateUrl: './search-members.component.html',
  styleUrls: ['./search-members.component.css']
})

export class SearchMembersComponent implements OnInit {

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  //public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  // isnMask = createMask({
  //   mask: '9999999999',
  //   placeholderChar: '',
  //   maskChar: null,
  //   maxLength: 10,
  //   minLength: 3
  // });

  // isnMask = createMask({
  //   mask: '9999999999?',
  //   placeholderChar: '',
  //   maskChar: null,
  // });

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  treatyNo: any;
  loggedUser: any;
  communities: any[];
  genders = ['Male', 'Female'];

  filterForm: FormGroup;
  dobSelected = '';
  dobSet = false;
  nationalResidency = false;
  urbanResidency = false;
  member = false;
  notMember = false;
  showCommunity = false;

  p: number = 1;
  members: any[];
  count = 0;
  overAllPopulation = 0;
  percentage: any;

  emailForm: FormGroup;
  SMSForm: FormGroup;
  contactInfo: FormGroup;
  emailList = [];
  phoneNoList = [];

  selectedMember: any;
  selectAllChecked = false;
  selectAllNosChecked = false;

  hasEmail = false;
  hasMobile = false;
  notRegistered = false;
  membersData: any[];

  showCharts = false;
  // chartDatas: { name: string, labels: Label[], data: any[] }[] = []
  chartDatas: { name: string, labels: string[], data: any[] }[] = [];
  pieChartOptions: ChartOptions;
  // pieChartLabels: Label[];
  // pieChartData: SingleDataSet;
  pieChartLabels: string[];
  pieChartData: number[];
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  pieChartColors = [];
  chart = 0;

  send = '';
  adminInfo: any = {
    FirstName: '',
    LastName: '',
    Email_Address: '',
    Cell: '',
    phone: '',
    ph_extension: '',
    Title: '',
    show_cell: false,
    show_email: false,
    show_office: false,
    show_title: false,
  };

  //adminInfo: any = {};
  adminNotFound = false;
  searchQuery = '';
  binDoc: File;
  binDocList: any = [];
  searchModel: any;
  showProfile = false;
  memberDetail: any;
  attachments: any[] = [];
  failedToUpload: any[] = [];
  uploading = false;
  error = false;
  errorMsg = '';
  uplodedFile: any[] = [];
  filesToBeUploaded: any[] = [];
  showFileList = false;
  sendEmail = false;
  sendSMS = false;
  selectedMembers: any[] = [];
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  contact = false;
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  filters = {
    hasEmail: false,
    hasMobile: false,
    notRegistered: false,
    registered: false,
    invited: false,
  }
  toBeInvited: any[] = [];
  verifySIN = false;
  verifyISN = false;
  secretQuestion = false;
  question = '';
  answer = '';
  viaEmail = false;
  viaSms = false;
  disablefilters = false;
  reason = '';
  resetPasswordForm: FormGroup;
  client_email = '';
  firebaseMember: any;
  multiple = false;
  sendMessage = false;
  SMSModal= false;

  constructor(private fb: FormBuilder,
    private location: Location,
    private memberService: MemberService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private communityService: CommunityService,
    private emailService: EmailService,
    private smsService: SMSService,
    private authService: AuthService,
    private mediaService: MediaService,
    private familyCompositionService: FamilyCompositionService,
    private manageAdminService: ManageAdminService,
    private fireMembersService: FireMembersService,
    private router: Router,
    private settingsService: AppSettingsService) {
    this.loggedUser = this.authService.getGlobalUser();
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.communityService.getCommunity().subscribe(com => {
      if (com && com.length > 0) {
        this.communities = com;
        this.showCommunity = true;
        setTimeout(() => {
          $('select').formSelect();
        }, 25);
      }
    });

    this.filterForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      dob: ['', [this.isValidDate]],
      isn: ['', [Validators.minLength(3), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      community: [],
      startAge: [, [Validators.min(1)]],
      endAge: [, [Validators.min(1), this.validAgeRange]],
      referenceDate: [this.formatDate(new Date())]
    });

    let today = this.formatDate(new Date());
    this.filterForm.get('referenceDate').patchValue(today);

    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    setTimeout(() => {
      $('.modal').modal();
      M.updateTextFields();
      const maxtDate = new Date();
      maxtDate.setFullYear(maxtDate.getFullYear() + 1);
      $('#referenceDate').datepicker(
        {
          format: 'dd/mmm/yyyy',
          minDate: new Date(),
          maxDate: maxtDate,
          defaultDate: new Date(),
          yearRange: [null, null],
          onSelect: (date => {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const selectedDate = new Date(date);
            const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
          })
        }
      );
    }, 25);

    //**********************************send email and sms ****************************************************/
    this.emailForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      files: [''],
    });

    this.SMSForm = this.fb.group({
      message: ['', [Validators.required]],
    });

    this.contactInfo = this.fb.group({
      Cell: [''],
      phone: [''],
      ph_extension: [''],
      Email_Address: ['', [Validators.email]],
      Title: [''],
      show_cell: [false],
      show_office: [false],
      show_title: [false],
      show_email: [false],
    });

    this.show_email.valueChanges.subscribe(value => {
      if (value || this.send == 'email') this.Email_Address.setValidators([Validators.required, Validators.email]);
      else {
        this.Email_Address.setValidators([Validators.email]);
      }
      this.Email_Address.updateValueAndValidity();
    });
    this.show_cell.valueChanges.subscribe(value => {
      if (value || this.send == 'text') {
        this.Cell.setValidators([Validators.required]);
      }
      else {
        this.Cell.clearValidators();
      }
      this.Cell.updateValueAndValidity();
      this.ph_extension.updateValueAndValidity();
    });
    this.show_office.valueChanges.subscribe(value => {
      if (value) {
        this.phone.setValidators([Validators.required]);
        this.ph_extension.setValidators([Validators.required]);
      }
      else {
        this.phone.clearValidators();
        this.ph_extension.clearValidators();
      }
      this.phone.updateValueAndValidity();
      this.ph_extension.updateValueAndValidity();
    });
    this.show_title.valueChanges.subscribe(value => {
      if (value) this.Title.setValidators([Validators.required]);
      else this.Title.clearValidators();
      this.Title.updateValueAndValidity();
    });

    setTimeout(() => {
      $('.modal').modal();
      $('.chips').chips();
      $('.chips-initial').chips({
        data: [{
          tag: 'email@gmail.com'
        }, {
          tag: 'example@gmail.com'
        }]
      });
      $('select').formSelect();
      $('.email-chips').chips({
        onChipDelete: (e, data) => {
          var email = data.innerText.replace('close', '').trim();
          if (this.emailList.length > 0) {
            var index = this.emailList.indexOf(email);
            this.emailList.splice(index, 1);
          }
        }
      });
      $('.no-chips').chips({
        onChipDelete: (e, data) => {
          var number = data.innerText.replace('close', '').trim();
          if (this.phoneNoList.length > 0) {
            var index = this.phoneNoList.indexOf(number);
            this.phoneNoList.splice(index, 1);
          }
        }
      });
    }, 25);

    this.pieChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'nearest',
          callbacks: {
            label: function (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) {
              const data = tooltipItem.chart.data;
              const count = data.datasets[0].data[tooltipItem.dataIndex] as number;
              const total = (data.datasets[0].data as number[]).reduce((acc, value) => acc + value, 0);
              const percentage = Math.round((count / total) * 100);
              return tooltipItem.dataIndex === 1 ? `${percentage} %` : `Total = ${total}`;
            }
          }
        },
        legend: {
          labels: {
            font: {
              size: 14
            }
          }
        },
        // datalabels: {
        //   color: ['black', 'white'],
        //   align: 'top',
        //   font: {
        //     size: 12,
        //     weight: 'bold'
        //   },
        //   formatter: (value: number, ctx: any) => {
        //     const data = ctx.chart.data;
        //     const count = data.datasets[0].data[ctx.dataIndex] as number;
        //     const total = (data.datasets[0].data as number[]).reduce((acc, value) => acc + value, 0);
        //     const percentage = ((count / total) * 100).toFixed(2);
        //     return ctx.dataIndex === 1 ? `${count} ( ${percentage} % )` : `${total}`;
        //   }
        // }
      }
    };


    // this.pieChartOptions = {
    //   responsive: true,
    //   maintainAspectRatio: true,
    //   tooltips: {
    //     enabled: true,
    //     mode: 'single',
    //     callbacks: {
    //       label: function (tooltipItems, data) {
    //         const count: any = data.datasets[0].data[1];
    //         const total: any = data.datasets[0].data[0] + count;
    //         const percentage: any = Math.round((count / (total)) * 100);
    //         if (tooltipItems.index == 1) return `${percentage} %`;
    //         else return `Total = ${(total)}`;
    //       }
    //     }
    //   },
    //   plugins: {
    //     legend: {
    //       labels: {
    //         font: {
    //           size: 14
    //         }
    //       }
    //     },

    //     datalabels: {
    //       color: ['black', 'white'],
    //       align: 'top',
    //       font: {
    //         size: 12,
    //         weight: 'bold',
    //       },
    //       formatter: (value, ctx) => {
    //         const count: any = ctx.chart.data.datasets[0].data[1];
    //         const total: any = ctx.chart.data.datasets[0].data[0] + count;
    //         const percentage: any = ((count / (total)) * 100).toFixed(2);
    //         if (ctx.dataIndex == 1) return `${count} ( ${percentage} % )`;
    //         else return `${(total)}`;
    //       }
    //     }
    //   }
    // };
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
    this.pieChartColors = [{
      backgroundColor: ['rgb(0,191,255)', 'rgb(255,105,180)'],
    }];
    this.pieChartPlugins = [pluginDataLabels];
  }

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  isValidDate(control: FormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  validAgeRange(c: AbstractControl): any {
    if (!c.parent || !c) { return; }
    const minAge = c.parent.get('startAge');
    const maxAge = c.parent.get('endAge');

    if (minAge.value && maxAge.value) {
      if (minAge.value >= maxAge.value) {
        return { invalidAgeRange: true };
      }
    }
    return;
  }

  onReferenceDateChange(value) {
    if (this.filterForm) {
      this.referenceDate.patchValue(value);
    }
  }

  // getBirthdayRangeFromAge(minAge, maxAge, date) {
  //   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  //   let dateArray = date.split('/');
  //   let year = dateArray[2];
  //   let month = months.indexOf(dateArray[1]) + 1
  //   let day = dateArray[0];
  //   const startDate = new Date(year, month, day);

  //   var earliestBirthYear = year - minAge;
  //   var latestBirthYear = year - maxAge + 1;

  //   var birthMonth = month;
  //   var birthDay = day;
  //   var earliestBirthdate = new Date(
  //     earliestBirthYear,
  //     birthMonth,
  //     birthDay
  //   );

  //   var latestBirthDate = new Date(
  //     latestBirthYear,
  //     birthMonth,
  //     birthDay + 1
  //   );

  //   let birthdayRange = {
  //     earliestBirthday: earliestBirthdate.toLocaleDateString(),
  //     latestBirthday: latestBirthDate.toLocaleDateString(),
  //   }

  //   return birthdayRange;
  // }

  // getBirthdayRange(age, date) {
  //   const referenceDate = new Date(date);
  //   const year = referenceDate.getFullYear();
  //   const month = referenceDate.getMonth();
  //   const day = referenceDate.getDate();

  //   // Calculate the birth year based on the age and reference date
  //   const earliestBirthYear = year - age;
  //   const latestBirthYear = earliestBirthYear + 1;

  //   // Calculate the start date of the birthday range
  //   const earliestBirthday = new Date(earliestBirthYear, month, day);
  //   const latestBirthday = new Date(latestBirthYear, month, day);

  //   return { earliestBirthday, latestBirthday };
  // }

  get firstName() { return this.filterForm.get('firstName'); }
  get lastName() { return this.filterForm.get('lastName'); }
  get dob() { return this.filterForm.get('dob'); }
  get isn() { return this.filterForm.get('isn'); }

  get startAge() { return this.filterForm.get('startAge'); }
  get endAge() { return this.filterForm.get('endAge'); }
  get referenceDate() { return this.filterForm.get('referenceDate'); }

  get email() { return this.resetPasswordForm.get('email'); }

  get subject() { return this.emailForm.get('subject'); }
  get body() { return this.emailForm.get('body'); }
  get files() { return this.emailForm.get('files'); }
  get textMsg() { return this.SMSForm.get('message'); }

  get Email_Address() { return this.contactInfo.get('Email_Address'); }
  get Cell() { return this.contactInfo.get('Cell'); }
  get phone() { return this.contactInfo.get('phone'); }
  get ph_extension() { return this.contactInfo.get('ph_extension'); }
  get Title() { return this.contactInfo.get('Title'); }
  get show_email() { return this.contactInfo.get('show_email'); }
  get show_cell() { return this.contactInfo.get('show_cell'); }
  get show_office() { return this.contactInfo.get('show_office'); }
  get show_title() { return this.contactInfo.get('show_title'); }

  searchMember(filters: any) {
    this.resetControls();
    const model = new MemberSearchModel();
    if (filters.firstName && filters.firstName !== '') { model.firstName = filters.firstName; }
    if (filters.lastName && filters.lastName !== '') { model.lastName = filters.lastName; }
    if (filters.startAge && filters.startAge !== '') { model.startAge = filters.startAge; }
    if (filters.endAge && filters.endAge !== '') { model.endAge = filters.endAge; }
    if (filters.isn && filters.isn !== '') { model.isn = filters.isn; }
    if (filters.community && filters.community.length > 0) { model.community = filters.community.join(','); }

    this.memberService.SearchMembers2(model).subscribe(resp => {
      if (resp && resp.length > 0) {
        this.members = resp;
        this.membersData = this.members;
        this.membersData.forEach(m => m.invited = false);
        this.count = this.members.length;
        this.selectAllChecked = false;
        // this.memberService.getBandMembersCount().subscribe(data => {
        //   if (data) {
        //     this.overAllPopulation = data.count;
        //     this.showCharts = true;
        //     this.searchQuery = 'Search Result';
        //     this.getChartData();
        //   }
        //   setTimeout(() => {
        //     $('.tooltipped').tooltip();
        //     $('.dropdown-trigger').dropdown({
        //       constrainWidth: false,
        //       hover: true
        //     });
        //     $('.collapsible').collapsible();
        //   }, 25);
        // });
      }
      else {
        this.members = [];
        this.count = 0;
      }
    });
  }

  filterBy(filter) {
    if (filter == 'email') this.filters.hasEmail = !this.filters.hasEmail;
    if (filter == 'mobile') this.filters.hasMobile = !this.filters.hasMobile;
    if (filter == 'notRegistered') this.filters.notRegistered = !this.filters.notRegistered;
    if (filter == 'registered') this.filters.registered = !this.filters.registered;
    if (filter == 'invited') this.filters.invited = !this.filters.invited;

    if (this.filters.notRegistered || this.filters.registered || this.filters.invited) {
      this.disablefilters = true;
    } else {
      this.disablefilters = false;
    }
    this.filter();
    this.getEmailList();
  }

  filter() {
    if (Object.values(this.filters).every(value => !value)) {
      this.membersData = this.members;
      this.count = this.members.length;
    } else {
      let filtered = this.members.filter(m => {
        for (let key in this.filters) {
          if (key === 'hasEmail' && this.filters[key]) if (!m.Email) return false;
          if (key === 'hasMobile' && this.filters[key]) if (!m.cell_phone_num) return false;
          if (key === 'notRegistered' && this.filters[key]) if (m.isRegistred) return false;
          if (key === 'registered' && this.filters[key]) if (m.isRegistred !== 'Yes') return false;
          if (key === 'invited' && this.filters[key]) if (m.isRegistred !== 'Invited') return false;
        }
        return true;
      });
      this.membersData = filtered;
      this.count = this.membersData.length;
      if (this.selectAllChecked) this.selectedMembers = this.membersData;
      let filterLabel = 'members ';
      if (this.filters.hasEmail) filterLabel += 'with Email ';
      if (this.filters.hasMobile) filterLabel += 'with phone number ';
      if (this.filters.notRegistered) filterLabel += 'not registered ';
      if (this.filters.registered) filterLabel += 'registered ';
      if (this.filters.invited) filterLabel += 'invited ';
      const percentage = ((this.count / this.members.length) * 100).toFixed(2);
      const labels = [`${this.searchQuery} = ${this.members.length}`, `${filterLabel}=${this.count} (${percentage} %)`];
      const data = [this.members.length - this.count, this.count];
      this.chartDatas.push({ name: `${this.searchQuery} Vrs  ${filterLabel}`, labels: labels, data: data });
      this.chart += 1;
      setTimeout(() => {
        $('.tooltipped').tooltip();
        $('.dropdown-trigger').dropdown({
          constrainWidth: false,
          hover: true
        });
        $('.collapsible').collapsible();
      }, 25);
    }
  }

  // nextChart() {
  //   this.chart += 1;
  // }

  // previousChart() {
  //   this.chart -= 1;
  // }

  resetControls() {
    this.searchQuery = '';
    this.hasEmail = false;
    this.hasMobile = false;
    this.notRegistered = false;
    this.selectAllChecked = false;
    this.chartDatas = [];
    this.chart = 0;
    this.selectAllChecked = false;
  }

  selectAll() {
    this.selectAllChecked = !this.selectAllChecked;
    if (this.selectAllChecked) {
      this.membersData.forEach(member => {
        this.selectedMembers.push(member);
      });
    } else {
      this.selectedMembers = [];
      this.selectAllChecked = false;
    }
    this.getEmailList();
    this.getPhoneNoList();
  }

  selectMember(member) {
    let exists = this.selectedMembers.includes(member);
    if (exists) {
      const index = this.selectedMembers.indexOf(member);
      this.selectedMembers.splice(index, 1);
      if (this.selectedMembers.length == 0) this.selectAllChecked = false;
    } else {
      this.selectedMembers.push(member);
    }
    this.getEmailList();
    this.getPhoneNoList();
  }

  onShowProfile(id) {
    this.memberService.getMember(id).subscribe(data => {
      this.fireMembersService.getMemberByAppliciantId(id).valueChanges().subscribe(x => {
        if (x) this.firebaseMember = x[0];
        this.showProfile = true;
        this.memberDetail = data[0];
      });
    });
  }

  //Email andd SMS
  onSendEmail(index) {
    const member = this.membersData[index];
    if (member) {
      if (!member.Email) toast({ html: '<span class="material-icons">info</span>&nbsp;No Email Address Provided.', classes: 'yellow darken-4' });
      else {
        this.contactDetail.firstName = member.GivenName;
        this.contactDetail.lastName = member.LastName;
        this.contactDetail.email = member.Email;
        this.contactDetail.phoneNo = member.cell_phone_num;
        this.contact = true;
      }
    }
  }

  onSendEmails() {
    if (!this.selectedMembers || this.selectedMembers.length == 0) toast({ html: '<span class="material-icons">info</span>&nbsp;No Members Selected.', classes: 'red' });
    else {
      this.contactDetail.email = true;
      this.contactDetail.emailList = this.emailList;
      this.contactDetail.multiple = true;
      this.contact = true;
    }
  }

  onSendSMS(index) {
    const member = this.membersData[index];
    if (member) {
      if (!member.cell_phone_num) toast({ html: '<span class="material-icons">info</span>&nbsp;No Phone Number Provided.', classes: 'yellow darken-4' });
      else {
        this.contactDetail.firstName = member.GivenName;
        this.contactDetail.lastName = member.LastName;
        this.contactDetail.email = member.Email;
        this.contactDetail.phoneNo = member.cell_phone_num;
        this.phoneNoList.push(member.cell_phone_num);
        // $('.chips').chips();
        // $('.no-chips').chips({
        //   data: this.phoneNoList
        // });
        // $('.chips').chips();
        // $('.no-chips').chips({
        //   data: this.phoneNoList
        // });
        //this.contact = true;
        // $('.modal').modal();
        // $('#SMSModal').modal('open');
        this.SMSModal = true;
      }
    }
  }

  msgSentHandler(event){
    if (event) this.sendEmail = false;
  }

  onSendMessages() {
    if (!this.selectedMembers || this.selectedMembers.length == 0) toast({ html: '<span class="material-icons">info</span>&nbsp;No Members Selected.', classes: 'red' });
    else {
      if (this.selectedMembers && this.selectedMembers.length == 1) {
        const member = this.membersData[0];
        this.contactDetail.firstName = member.GivenName;
        this.contactDetail.lastName = member.LastName;
        this.contactDetail.email = member.Email;
        this.contactDetail.phoneNo = member.cell_phone_num;
      }
      else {
        this.contactDetail = null;
        $('.modal').modal();
        $('#SMSModal').modal('open');
      }
    }
  }

  SendText(SMS) {
    let msg = new SMSModel();
    msg.phoneNumberList = this.phoneNoList;
    msg.body = SMS.message;
    if (this.contactDetail?.firstName && this.contactDetail?.lastName) msg.name = this.contactDetail.firstName + " " + this.contactDetail.lastName;
    if (this.contactDetail?.Email_Address) msg.Email_Address = this.contactDetail.Email;

    this.smsService.sendSMS(msg).subscribe(x => {
      toast({ html: 'Text Message Successfully Sent.', classes: 'green' });
      $('#SMSModal').modal('close');
    });
  }

  clientActivity() {
    this.router.navigate(['admin/members/member-activity', this.firebaseMember.uid]);
  }

  onSendMessage() {
    this.sendMessage = !this.sendMessage;
  }

  enableAccount() {
    this.firebaseMember.status = 'Enabled';
    this.firebaseMember.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseMember);
    this.reason = null;
    toast({ html: `${this.firebaseMember.Email} Enabled`, classes: 'green rounded' });
    if (this.sendMessage) {
      this.contactDetail.firstName = this.firebaseMember.GivenName;
      this.contactDetail.lastName = this.firebaseMember.LastName;
      this.contactDetail.email = this.firebaseMember.Email;
      this.contactDetail.phoneNo = this.firebaseMember.cell_phone_num;
      this.message.subject = 'Account Enabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  disableAccount() {
    this.firebaseMember.status = 'Disabled';
    this.firebaseMember.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseMember);
    this.reason = null;
    toast({ html: `${this.firebaseMember.Email} Disabled`, classes: 'red rounded' });
    if (this.sendMessage) {
      this.contactDetail.firstName = this.firebaseMember.GivenName;
      this.contactDetail.lastName = this.firebaseMember.LastName;
      this.contactDetail.email = this.firebaseMember.Email;
      this.contactDetail.phoneNo = this.firebaseMember.cell_phone_num;
      this.message.subject = 'Account Disabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  resetPassword() {
    $('.modal').modal('close');
    this.authService.resetPassword(this.email.value).then(() =>
      toast({ html: '<p> Reset Password Link Successfully Sent. </p>', classes: 'green' })
    ).catch((error) => toast({ html: error.message, classes: 'red' }));
  }

  getEmailList() {
    if (this.selectedMembers && this.selectedMembers.length > 0) {
      this.emailList = this.selectedMembers.filter(m => m.Email).map(m => m.Email);
    } else {
      this.emailList = [];
    }
    return;
  }

  getPhoneNoList() {
    if (this.selectedMembers && this.selectedMembers.length > 0) {
      this.phoneNoList = this.selectedMembers.filter(m => m.cell_phone_num).map(m => m.cell_phone_num);
    } else {
      this.phoneNoList = [];
    }
  }

  contactClient() {
    if (this.member) {
      this.contactDetail.firstName = this.memberDetail.GivenName;
      this.contactDetail.lastName = this.memberDetail.LastName;
      this.contactDetail.email = this.memberDetail.Email;
      this.contactDetail.phoneNo = this.memberDetail.cell_phone_num;
    }
    this.contact = true;
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) {
      toast({ html: 'Email sent Successfully.', classes: 'green' });
      if (valueEmitted.title) {
        const member = this.membersData.filter(m => m.applicantID == Number(valueEmitted.title));
        if (member && member.length > 0) {
          const index = this.membersData.indexOf(member[0]);
          this.membersData[index].invited = true;
          this.membersData[index].isRegistred = 'invited';
        }
      }
    }
    this.contact = false;
    setTimeout(() => {
      $('.tooltipped').tooltip();
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        hover: true
      });
      $('.collapsible').collapsible();
    }, 25);
  }

  onInvite(member) {
    if (member) {
      if (member.isRegistred) toast({ html: '<span class="material-icons">lightbulb</span>&nbsp; Member is already registred.', classes: 'yellow darken-4' });
      else if (!member.cell_phone_num && !member.Email) toast({ html: '<span class="material-icons">info</span>&nbsp;No Email or Phone Number Provided.', classes: 'yellow darken-4' });
      else {
        this.selectedMember = member;
        this.multiple = false;
        $('.modal').modal();
        $('#inviteConfirmationModal').modal('open');
      }
    }
  }

  onInviteMembers() {
    if (this.selectedMembers.length == 1) {
      this.onInvite(this.selectedMembers[0]);
    } else {
      this.multiple = true;
      this.selectedMember = null;
      $('.modal').modal();
      $('#invitationModal').modal('open');
    }

  }

  addToInvite(m) {
    if (this.toBeInvited.includes(m)) {
      let index = this.toBeInvited.indexOf(m);
      this.toBeInvited.splice(index);
    } else this.toBeInvited.push(m);
  }

  inviteAll() {
    this.selectedMembers.forEach(m => {
      if (!m.isRegistred) this.toBeInvited.push(m);
    });
    $('#inviteConfirmationModal').modal('open');
  }

  sendInvitation() {
    const url = window.location.hostname !== 'localhost' ? environment.firstNation.website : 'http://localhost:4200/#';
    if (!this.multiple && this.selectedMember) {
      const invitationModel = {
        ApplicantID: this.selectedMember.applicantID,
        verifySIN: this.verifySIN,
        verifyISN: this.verifyISN,
        secretQuestion: this.question,
        secretAnswer: this.answer,
        email: this.selectedMember.Email,
      };
      this.memberService.InviteMember(invitationModel).subscribe(res => {
        if (res) {
          $('#confirmationModal').modal('close');
          this.contactDetail.firstName = this.selectedMember.GivenName;
          this.contactDetail.lastName = this.selectedMember.LastName;
          this.contactDetail.email = this.selectedMember.Email;
          this.contactDetail.phoneNo = this.selectedMember.cell_phone_num;
          this.message.subject = 'Member Invitation';
          this.message.body = 'You have been invited to join the my nation app.\n\nPlease click the following link to complete your registration.\nRegistration Link : ' + url + '/auth/register/' + this.selectedMember.applicantID;
          this.message.title = `${this.selectedMember.applicantID}`;
          this.contact = true;
        }
      });
    } else if (this.multiple && this.toBeInvited && this.toBeInvited.length > 0) {
      let msg = new EmailModel();
      msg.emailList = [];
      this.toBeInvited.forEach(m => {

        msg.emailList = [m.Email];
        msg.subject = 'Member Invitation';
        msg.body = 'You have been invited to join the my nation app.\n\nPlease click the following link to complete your registration.\nRegistration Link : ' + url + '/auth/register/' + m.applicantID;
        const invitationModel = {
          ApplicantID: m.applicantID,
          verifySIN: this.verifySIN,
          verifyISN: this.verifyISN,
          secretQuestion: this.question,
          secretAnswer: this.answer,
          email: m.Email,
        };
        this.emailService.sendEmail(msg).subscribe(x => {
          this.memberService.InviteMember(invitationModel).subscribe(x => {
            toast({ html: `<span class="material-icons">check_circle</span>&nbsp; Invitation Successfully sent to ${m.GivenName}.`, classes: 'green' });
            $('.modal').modal('close');
          });
        });
      });
    }
  }

  sinChecked() {
    this.verifySIN = !this.verifySIN;
  }

  isnChecked() {
    this.verifyISN = !this.verifyISN;
  }

  secretQuestionChecked() {
    this.secretQuestion = !this.secretQuestion;
    if (!this.secretQuestion) { this.question = ''; this.answer = ''; }
  }

  viaEmailChecked() {
    this.viaEmail = !this.viaEmail;
  }

  viaSmsChecked() {
    this.viaSms = !this.viaSms;
  }

  clearSelection() {
    this.toBeInvited = [];
  }

  backClicked() {
    this.location.back();
  }

  selectAllNos() {
    this.selectAllNosChecked = !this.selectAllNosChecked;
    this.membersData.forEach(member => {
      if (member.mobileNumber) {
        if (this.selectAllNosChecked) {
          if (!this.phoneNoList.includes(member.mobileNumber)) {
            this.phoneNoList.push(member.mobileNumber);
            $('.no-chips').chips('addChip', { tag: member.mobileNumber });
          }
        } else {
          var index = this.phoneNoList.indexOf(member.mobileNumber);
          if (index >= 0) $('.no-chips').chips('deleteChip', index);
          if (this.phoneNoList.length == 0) this.selectAllNosChecked = false;
        }
      }
    });
  }

  emailSelected(email) {
    if (this.emailList.includes(email)) {
      const index = this.emailList.indexOf(email);
      $('.email-chips').chips('deleteChip', index);
      if (this.emailList.length == 0) this.selectAllChecked = false;
    } else {
      this.emailList.push(email);
      $('.email-chips').chips('addChip', { tag: email });
    }
  }

  phoneNoSelected(number) {
    if (this.phoneNoList.includes(number)) {
      const index = this.phoneNoList.indexOf(number);
      $('.no-chips').chips('deleteChip', index);
      if (this.phoneNoList.length == 0) this.selectAllNosChecked = false;
    } else {
      this.phoneNoList.push(number);
      $('.no-chips').chips('addChip', { tag: number });
    }
  }

  getAdminSetting(content) {
    if (content == 'email') {
      this.send = 'email';
    }
    if (content == 'text') {
      this.send = 'text';
    }
    this.loggedUser = this.authService.getGlobalUser();
    if (this.loggedUser && this.loggedUser.id) {
      this.manageAdminService.getAdmin(this.loggedUser.id).subscribe(user => {
        if (user == "No Result Found" || user.length == 0) {
          this.adminNotFound = true;
          if (this.send == 'email') {
            $('#emailModal').modal('open');
          }
          if (this.send == 'text') {
            $('#SMSModal').modal('open');
          }
        }
        else if (user && user.length > 0) {
          this.adminInfo = user[0];
          this.contactInfo.patchValue(this.adminInfo);
          if (this.send == 'email') {
            if (!this.adminInfo.Email_Address) $('#settingModal').modal('open');
            else $('#emailModal').modal('open');
          }
          if (this.send == 'text') {
            if (!this.adminInfo.Cell) $('#settingModal').modal('open');
            else $('#SMSModal').modal('open');
          }
          M.updateTextFields();
        }
      });
    }
  }

  Send(email: EmailModel) {
    let msg = new EmailModel();
    msg.emailList = this.emailList;
    msg.subject = email.subject;
    msg.body = email.body;
    $('.modal').modal('close');
    this.emailService.sendEmail(msg).subscribe(x => {
      toast({ html: 'Email Successfully Sent.', classes: 'green' });
    });
  }

  cancel() {
    $('.modal').modal('close');
  }

  showDetail(member) {
    this.selectedMember = member;
  }

  getChartData() {
    if (this.overAllPopulation && this.count) {
      this.percentage = ((this.count / this.overAllPopulation) * 100).toFixed(2);
      const labels = [`Over all Population=${this.overAllPopulation}`, `${this.searchQuery} = ${this.count} (${this.percentage} % of Total)`];
      const data = [this.overAllPopulation - this.count, this.count];
      this.chartDatas.push({ name: `Overall Population Vrs ${this.searchQuery} `, labels: labels, data: data });
    }
  }

  getInfo() {
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  updateInfo(values) {
    if (!this.adminNotFound) {
      values.uid = this.loggedUser.id;
      if (values.Cell) values.Cell = values.Cell.replace(/\D+/g, '');
      if (values.phone) values.phone = values.phone.replace(/\D+/g, '');
      this.manageAdminService.UpdateAdminContact(values).subscribe(x => {
        if (x) {
          this.adminInfo.Email_Address = values.Email_Address;
          this.adminInfo.Cell = values.Cell;
          this.adminInfo.phone = values.phone;
          this.adminInfo.ph_extension = values.ph_extension;
          this.adminInfo.Title = values.Title;
          this.adminInfo.show_cell = values.show_cell;
          this.adminInfo.show_email = values.show_email;
          this.adminInfo.show_office = values.show_office;
          this.adminInfo.show_title = values.show_title;
          toast({ html: 'Contact Info Successfully Updated!', classes: 'green' });
        }
      });
      $('#settingModal').modal('close');
      if (this.send == 'email') $('#emailModal').modal('open');
      if (this.send == 'text') $('#SMSModal').modal('open');
    }
  }

  backToList() {
    this.showProfile = false;
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('.chips').chips();
      $('select').formSelect();
      $('.tooltipped').tooltip();
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        hover: true
      });
      $('.collapsible').collapsible();
      $('.email-chips').chips({
        onChipDelete: (e, data) => {
          var email = data.innerText.replace('close', '').trim();
          if (this.emailList.length > 0) {
            var index = this.emailList.indexOf(email);
            this.emailList.splice(index, 1);
          }
        }
      });
      $('.no-chips').chips({
        onChipDelete: (e, data) => {
          var number = data.innerText.replace('close', '').trim();
          if (this.phoneNoList.length > 0) {
            var index = this.phoneNoList.indexOf(number);
            this.phoneNoList.splice(index, 1);
          }
        }
      });
    }, 25);
  }

  handleFileInput(files: FileList) {
    this.binDoc = undefined;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        this.binDocList.push(files[i]);
      }
    }
  }

  uploadFile() {
    this.showFileList = true;
    this.emailForm.get('files').reset();
  }

  removeFile(index) {
    this.binDocList.splice(index, 1);
    if (this.binDocList.length == 0) {
      this.showFileList = false;
    }
  }

}







