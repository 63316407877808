import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
//import { EmbedVideoService } from 'ngx-embed-video';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Comment, CommentReply, StaticContentModel } from '../../../models/StaticContentModel'
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { User } from 'firebase/app';

declare var $: any;

@Component({
  selector: 'app-content-viewer',
  templateUrl: './content-viewer.component.html',
  styleUrls: ['./content-viewer.component.css']
})

export class ContentViewerComponent implements OnInit {
  @Input() model: any;
  @Input() newsList: any[];
  @ViewChild('commentsSection') commentsSectionRef!: ElementRef;
  @Input() userId: string;
  @Input() contentId: string;
  @Input() replyId: string;
  @Input() comment: Comment;
  @Input() currentUser: User;

  iframehtml: any;
  pdfURL: SafeUrl;
  buttonType: any;
  fontColor = environment.appTheme.fontColor;
  isLogged = false;
  commentOnNews: UntypedFormGroup;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  otherNews: any[];
  likeCount = 0;
  isLiked: boolean = false;

  newComment: any = '';
  hasComment: any;

  comments: Comment[] = [];
  replies: CommentReply[] = [];

  showAllComments: boolean = false;
  visibleCommentCount: number = 4;
  latestLikerName: string = '';
  otherLikersCount: number = 0;

  private userData: any;

  private commentsSubscription: Subscription;
  commentToDelete: Comment | null = null;
  replyToDelete: CommentReply | null = null;

  newReplyText: string = '';
  showReplyInput: { [commentId: string]: boolean } = {};
  selectedCommentId: string | null = null;
  newCommentText: string = '';
  replyText: string = '';

  likedC: boolean = false;

  likedByUserIds: string[] = []; 
  useCustomTooltipStyle: boolean = true;
  videoId: any;


  constructor(public appSettingsService: AppSettingsService, private fb: UntypedFormBuilder, private authservice: AuthService, 
    // private embedService: EmbedVideoService,
    private location: Location, private sanitizer: DomSanitizer, private staticContentService: StaticContentService, private cdr: ChangeDetectorRef, private authService: AuthService, private route: ActivatedRoute) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
    this.useCustomTooltipStyle = true;
  }

  ngOnInit() {
    this.commentOnNews = this.fb.group({
      comment: ['', [Validators.required]],
      newReply: ['', Validators.required]
    });

    this.commentOnNews = this.fb.group({
      newReply: ['', Validators.required]
    });

    if (this.model) {
      // this.otherNews = this.newsList.filter(data => data.id != this.model.id);
      this.otherNews = this.newsList.filter(data => 
        data.id !== this.model.id &&
        data.published === true &&
        data.visibility === 'Public' &&
        !data.isArchived
      );
  

      if (this.model.type === 'Video') {
        this.videoId = this.embedVideo(this.model.videoLink);
      }

      this.isLogged = !!this.authservice.globalUser;

      if (this.model.comments) {
        this.comments = this.model.comments;
      }

      if (this.model) {
        this.staticContentService.getCommentsByContentId(this.model.id)
          .subscribe((comments: Comment[]) => {
            this.comments = comments;
          });
      }

      const likedContentIds = JSON.parse(localStorage.getItem('likedContentIds') || '[]');
      this.isLiked = likedContentIds.includes(this.model.id);

      this.likeCount = this.model.likeCount || 0;
      this.isLiked = this.authservice.globalUser?.uid ? this.model.likedBy.includes(this.authservice.globalUser.uid) : false;

      setTimeout(() => {
        $('.materialboxed').materialbox();
        $('.modal').modal();
        $('.dropdown-trigger').dropdown();
        $('.tooltipped').tooltip();
      }, 100);
    }

    this.likeCount = this.model.likeCount || 0;
    this.isLiked = this.isCurrentUserLiked();
    this.fetchAndUpdateLikeCount(this.model.id);

    for (const userId of this.likedByUserIds) {
      this.staticContentService.getUserData(userId);
    }

  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  ngOnDestroy() {
    if (this.commentsSubscription) {
      this.commentsSubscription.unsubscribe();
    }
  }

  get tooltipContent(): string {
    const likedByItems = this.model.nameLikedBy || [];
  
    if (likedByItems.length === 0) {
      return '';
    }
  
    // Define a CSS class based on the flag
    const tooltipClass = this.useCustomTooltipStyle ? 'custom-tooltip' : '';
  
    const listHtml = `
      <ul class="white-text left-align description-section bold-100 ${tooltipClass}">
        ${likedByItems.map(item => `<li>${item}</li><br>`).join('')}
      </ul>`;
  
    return listHtml;
  }
  
  calculateTimeElapsed(timeStamp: any): string {
    const currentTime = new Date().getTime();
    const commentTime = timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000;
    const timeDifference = currentTime - commentTime;

    if (timeDifference < 60000) {
      const seconds = Math.floor(timeDifference / 1000);
      return `${seconds} sec`;
    } else if (timeDifference < 3600000) {
      const minutes = Math.floor(timeDifference / 60000);
      return `${minutes} min`;
    } else if (timeDifference < 86400000) {
      const hours = Math.floor(timeDifference / 3600000);
      return `${hours} hr`;
    } else if (timeDifference < 604800000) {
      const days = Math.floor(timeDifference / 86400000);
      return `${days} D`;
    } else if (timeDifference < 2419200000) {
      const weeks = Math.floor(timeDifference / 604800000);
      return `${weeks} W`;
    } else {
      const months = Math.floor(timeDifference / 2419200000);
      return `${months} M`;
    }
  }

  open(id) {
    this.commentOnNews.reset();
    this.comments = [];
    this.model = this.newsList.find(data => data.id === id);
    this.otherNews = this.newsList.filter(data => data.id !== this.model.id && data.published === true && data.visibility === 'Public' &&!data.isArchived);
    if (this.model.comments) {
      this.comments = this.model.comments;
    }
    this.fetchAndUpdateLikeCount(this.model.id);
  }

 
  fetchAndUpdateLikeCount(contentId: string) {
    this.staticContentService.getLikeCount(contentId)
      .then(likeCount => {
        this.likeCount = likeCount;
        this.isLiked = this.isCurrentUserLiked();
      })
      .catch(error => {
        console.error('Error fetching like count:', error);
      });
  }

  addLike(contentId: string) {
    if (!this.isLiked) {
      console.log('Adding like for content ID:', contentId);

      this.model.likedBy.push(this.authservice.globalUser.uid);
      this.model.nameLikedBy.push(this.authservice.globalUser.uid);

      this.staticContentService.updateLike(contentId, this.model.likedBy, this.model.nameLikedBy)
        .then(() => {
          console.log('Like updated successfully.');
          this.fetchAndUpdateLikeCount(contentId);
        })
        .catch(error => {
          console.error('Error updating like:', error);
        });
    } else {
      console.log('You have already liked this content.');
    }
  }
  updateLatestLikerName(): void {
    if (this.model?.likedBy && this.model.likedBy.length > 0) {
      const latestLikerId = this.model.likedBy[this.model.likedBy.length - 1];

      this.authService.getUserDataById(latestLikerId).subscribe(
        (latestLiker) => {
          if (latestLiker) {
            this.latestLikerName = `${latestLiker.GivenName} ${latestLiker.LastName}`;
            this.otherLikersCount = this.model.likedBy.length - 1; // Exclude the latest liker
          }
        },
        (error) => {
          console.error('Error fetching latest liker data:', error);
        }
      );
    } else {

    }
  }

  isCurrentUserLiked(): boolean {
    return this.model.likedBy.includes(this.authservice.globalUser?.uid || '');
  }

  like(contentId: string) {
    if (this.authservice.globalUser?.uid && this.model) {
      if (!this.model.likedBy) {
        this.model.likedBy = [];
        this.model.nameLikedBy = [];
      }
  
      if (this.isLiked) {
        // Unlike the content
        const userIndex = this.model.likedBy.indexOf(this.authservice.globalUser.uid);
        if (userIndex !== -1) {
          this.model.likedBy.splice(userIndex, 1);
          this.model.nameLikedBy.splice(userIndex, 1);
        }
      } else {
        // Like the content
        this.model.likedBy.push(this.authservice.globalUser.uid);
        this.model.nameLikedBy.push(`${this.authservice.globalUser.GivenName} ${this.authservice.globalUser.LastName}`);
      }
  
      this.updateLatestLikerName();
  
      this.staticContentService.updateLike(contentId, this.model.likedBy, this.model.nameLikedBy)
        .then(() => {
          console.log('Like updated successfully.');
          this.fetchAndUpdateLikeCount(contentId);
        })
        .catch(error => {
          console.error('Error updating like:', error);
        });
    } else {
      console.error('User not authenticated or content not available.');
    }
  }
  
  findUserById(id: string): any {
    return this.authService.getUserDataById(id);
  }

  truncateHTML(text: string | undefined, limit: string): string {
    if (!text || typeof limit !== 'string' || !/^\d+$/.test(limit)) {
      return '';
    }

    const charlimit = +limit;

    if (text.length <= charlimit) {
      return text;
    }

    const truncatedText = text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit);

    // Ensure that the truncatedText does not cut off within an HTML tag
    const lastTagIndex = truncatedText.lastIndexOf('<');
    const lastClosingTagIndex = truncatedText.lastIndexOf('>');

    if (lastTagIndex > lastClosingTagIndex) {
      // If the last tag is not closed, find the last closing tag before the limit
      const closingTag = truncatedText.substring(lastTagIndex).match(/<\/[^>]+>/);

      if (closingTag) {
        return truncatedText.substring(0, lastTagIndex) + closingTag[0];
      }
    }

    return truncatedText + '...';
  }

  scrollToComments() {
    if (this.commentsSectionRef) {
      this.commentsSectionRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  toggleComments(showAll: boolean) {
    this.showAllComments = showAll;
    this.visibleCommentCount = showAll ? this.comments.length : 4;
  }

  addComment() {
    if (this.newCommentText.trim() !== '') {
      const newComment: Comment = {
        userName: `${this.authservice.globalUser.GivenName} ${this.authservice.globalUser.LastName}`,
        userId: this.authservice.globalUser.uid,
        comment: this.newCommentText,
        timeStamp: new Date(),
        id: this.staticContentService.generateUniqueId(),
        showReplies: false,
        replies: [],
        newReplyText: '',
        likesC: 0,
        likedC: false,
        likedByC: []
      };
      this.comments.push(newComment);

      this.newCommentText = '';

      this.staticContentService.updateContentComments(this.model, newComment)
        .then(() => {
          console.log('Comment added successfully.');
        })
        .catch(error => {
          console.error('Error adding comment:', error);
        });
    }
  }

  addReply(comment: Comment) {
    if (this.newReplyText.trim() !== '') {
      const newReply: CommentReply = {
        userName: `${this.authservice.globalUser.GivenName} ${this.authservice.globalUser.LastName}`,
        userId: this.authservice.globalUser.uid,
        timeStamp: new Date(),
        id: this.staticContentService.generateUniqueId(),
        reply: this.newReplyText,
        parentId: comment.id,
        likesR: 0,
        likedR: false,
        likedByR: []
      };

      if (!comment.replies) {
        comment.replies = [];
      }
      comment.replies.push(newReply);

      this.staticContentService.updateComment(this.model.id, comment)
        .then(() => {
          console.log('Reply added successfully.');
          this.newReplyText = '';
        })
        .catch((error) => {
          console.error('Error adding reply:', error);
        });
    }
  }

  removeReply(comment: Comment, reply: CommentReply) {
    if (this.authservice.globalUser?.uid === reply.userId) {
      const commentIndex = this.comments.indexOf(comment);
      if (commentIndex !== -1) {
        const replyIndex = this.comments[commentIndex].replies.indexOf(reply);
        if (replyIndex !== -1) {
          this.comments[commentIndex].replies.splice(replyIndex, 1);

          this.staticContentService.updateContentComments(this.model, this.comments[commentIndex])
            .then(() => {
              console.log('Reply removed successfully.');
            })
            .catch(error => {
              console.error('Error removing reply:', error);
            });
        }
      }
    } else {
      console.error('Only the author can remove this reply.');
    }
  }

  setCommentToDelete(comment: Comment) {
    this.commentToDelete = comment;
  }

  backClicked() {
    this.location.back();
  }

  toggleReplies(comment: Comment) {
    comment.showReplies = !comment.showReplies;
    this.showReplyInput[comment.id] = false;
  }

  showReplyInputForComment(commentId: string) {
    this.showReplyInput[commentId] = !this.showReplyInput[commentId];
  }

  findCommentByIdForContent(contentId: string, commentId: string) {
    this.staticContentService.findCommentById(contentId, commentId)
      .then((comment: Comment | undefined) => {
        if (comment) {
          console.log('Found comment by ID:', comment);
        } else {
          console.log('Comment not found by ID.');
        }
      })
      .catch((error) => {
        console.error('Error finding comment by ID:', error);
      });
  }


  likeComment(comment: Comment) {
    const userId = this.authservice.globalUser.uid;
    const contentId = this.model.id;
    const hasLiked = comment.likedByC.includes(userId);

    if (!hasLiked) {
      comment.likedByC.push(userId);
      comment.likesC = (comment.likesC || 0) + 1;
    } else {
      comment.likedByC = comment.likedByC.filter(id => id !== userId);
      comment.likesC = Math.max((comment.likesC || 0) - 1, 0);
    }
    this.staticContentService.updateCommentLikes(contentId, comment.id, comment.likesC, comment.likedByC);
    comment.likedC = !hasLiked;
    console.log(comment);
  }


  likeReply(comment: Comment, reply: CommentReply) {
    if (!reply) {
      console.error('Invalid reply:', reply);
      return;
    }
    const userId = this.authservice.globalUser?.uid;
    const contentId = this.model?.id;
    if (!userId || !contentId) {
      console.error('User ID or content ID is missing.');
      return;
    }
    const hasLiked = reply.likedByR?.includes(userId) || false;
    if (!hasLiked) {
      reply.likedByR = reply.likedByR || [];
      reply.likedByR.push(userId);
      reply.likesR = (reply.likesR || 0) + 1;
    } else {
      reply.likedByR = reply.likedByR?.filter((id) => id !== userId) || [];
      reply.likesR = Math.max((reply.likesR || 0) - 1, 0);
    }

    if (contentId && comment && reply.id) {
      this.staticContentService.updateCommentReply(contentId, comment.id, reply.id, reply, userId);
    }
    reply.likedR = !hasLiked;
    console.log(reply);
  }

  deleteCommentFromModal() {
    if (this.commentToDelete) {
      this.staticContentService.deleteComment(this.model, this.commentToDelete.userId)
        .then(() => {
          this.comments = this.comments.filter(c => c.userId !== this.commentToDelete?.userId);
          this.commentToDelete = null;
          $('#deleteCommentModal').modal('close'); // Use #deleteCommentModal
        })
        .catch(error => {
          console.error('Error deleting comment:', error);
        });
    }
  }

  deleteReplyFromModal() {
    if (this.replyToDelete) {
      const replyIdToDelete = this.replyToDelete.id; // Use the appropriate property to identify the reply

      this.staticContentService.deleteReply(this.comment, this.replyToDelete)
        .then(() => {
          this.replies = this.replies.filter(reply => reply.id !== replyIdToDelete);
          this.replyToDelete = null;
          $('#deleteReplyModal').modal('close');
        })
        .catch(error => {
          console.error('Error deleting reply:', error);
        });
    }
  }

  getLikedByNames(): string {
    const likedByNames = this.model?.likedBy?.map(uid => {
      console.log('Liked By Names:', likedByNames);
      console.log('LikedBy:', this.model.likedBy);
      const user = this.findUserById(uid);
      console.log('user', user)

      return user ? `${user.FirstName} ${user.LastName}` : '';
    });

    if (!likedByNames || likedByNames.length === 0) {
      return 'No likes yet';
    }
    
    // Customize the format as per your requirements.
    return likedByNames.join(', ');
  }  
}