import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { Options } from 'sortablejs';
import { Router } from '@angular/router';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
//import { EmbedVideoService } from 'ngx-embed-video';
import { environment } from 'src/environments/environment';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { HelperService } from 'src/app/services/helper/helper';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { DataService } from 'src/app/services/data.service';
import { toast } from 'materialize-css';
import { cloneDeep } from "lodash";
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-landing-page-settings',
  templateUrl: './landing-page-settings.component.html',
  styleUrls: ['./landing-page-settings.component.css']
})

export class LandingPageSettingsComponent implements OnInit {

  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];

  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });
  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  chiefTitle: any;
  councillorTitle: any;
  options: Options = {
    animation: 150,
  };
  nationOfUse = environment.firstNation.name;
  hasISET = environment.firstNation.ISET;
  sectionName: string;

  isNewSetting = true;
  sectionsSetting = true;
  preview = false;
  pageSections: any;

  // *********** Slider Setting ********************
  sliderSettings = false;
  sliderRef: any;
  slideRef: any;
  emptySlideOption = false;
  editSlider = false;
  selectedSlider: any;
  saveNo = 0;
  cardView: null;
  viewChanged = false;
  appSettings: UntypedFormGroup;

  desktopGridSize = 'l4';
  mobileGridSize = 's2';
  cardOption = 'Rectangle';
  cardIconOption = 'Rectangle';
  cardSizeOption = 'Small';
  titlePositionOption = 'Bottom';
  imageOption = "no";


  imageCoverOption = "InhertContent";
  isCollapsed: boolean = true;
  isCollapsedd: boolean = true;
  previewSection: any;
  previewBefore = false;
  imageOptionOn = false;
  concillorsRef: any = {
    displayName: '' // Initial value for displayName
  };
  // ============== modal ===============
  cropModalOpen = false;
  imageChangedEvent: any;
  showImageCropperModal = false;
  croppedImage: any = '';
  imageChangeEvt: any = "";
  cropImgPreview: any;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  zoomScale = 10;
  createModalOpen = true;
  mediaList = [];
  base64Image: any;
  binDoc: File;
  imgErrorMsg: any;
  iseasyChange = false;
  buttonText: string = 'Easy Fix';
  isRearrangeClicked: boolean = false;
  carouselForm: UntypedFormGroup;
  sliderForm: UntypedFormGroup;

  currentEditingSection: any;
  filteredIcons: string[] = [];
  searchQuery: string = "";
  selectedIcon: string;
  selectedSection: any;
  allIcons: string[] = [
    "accessibility", "account_balance", "alarm", "android", "assignment", "backup",
    "book", "camera", "cloud", "dashboard", "email", "favorite",
    "grade", "home", "info", "language", "mail", "notifications",
    "people", "settings", "3d_rotation", "ac_unit", "access_alarm", "access_alarms",
    "access_time", "accessible", "account_balance_wallet", "account_box", "account_circle", "adb",
    "add", "add_a_photo", "add_alarm", "add_alert", "add_box", "add_circle",
    "add_circle_outline", "add_location", "add_shopping_cart", "add_to_photos", "add_to_queue", "adjust",
    "airline_seat_flat", "airline_seat_flat_angled", "airline_seat_individual_suite", "airline_seat_legroom_extra", "airline_seat_legroom_normal", "airline_seat_legroom_reduced",
    "airline_seat_recline_extra", "airline_seat_recline_normal", "airplanemode_active", "airplanemode_inactive", "airplay", "airport_shuttle",
    "alarm_add", "alarm_off", "alarm_on", "album", "all_inclusive", "all_out",
    "announcement", "apps", "archive", "arrow_back", "arrow_downward", "arrow_drop_down",
    "arrow_drop_down_circle", "arrow_drop_up", "arrow_forward", "arrow_upward", "art_track", "aspect_ratio",
    "assessment", "assignment_ind", "assignment_late", "assignment_return", "assignment_returned", "assignment_turned_in",
    "assistant", "assistant_photo", "attach_file", "attach_money", "attachment", "audiotrack",
    "autorenew", "av_timer", "backspace", "battery_alert", "battery_charging_full", "battery_full",
    "battery_std", "battery_unknown", "beach_access", "beenhere", "block", "bluetooth", "bluetooth_audio",
    "bluetooth_connected", "bluetooth_disabled", "bluetooth_searching", "blur_circular", "blur_linear", "blur_off",
    "blur_on", "bookmark", "bookmark_border", "border_all", "border_bottom", "border_clear", "border_color",
    "border_horizontal", "border_inner", "border_left", "border_outer", "border_right", "border_style",
    "border_top", "border_vertical", "branding_watermark", "brightness_1", "brightness_2", "brightness_3", "brightness_4",
    "brightness_5", "brightness_6", "brightness_7", "brightness_auto", "brightness_high", "brightness_low",
    "brightness_medium", "broken_image", "brush", "bubble_chart", "bug_report", "build", "burst_mode",
    "business", "business_center", "cached", "cake", "call", "call_end", "call_made", "call_merge", "call_missed",
    "call_missed_outgoing", "call_received", "call_split", "call_to_action", "camera_alt", "camera_enhance", "camera_front",
    "camera_rear", "camera_roll", "cancel", "card_giftcard", "card_membership", "card_travel", "casino",
    "cast", "cast_connected", "center_focus_strong", "center_focus_weak", "change_history", "chat", "chat_bubble",
    "chat_bubble_outline", "check", "check_box", "check_box_outline_blank", "check_circle", "check_circle_outline",
    "chevron_left", "chevron_right", "child_care", "child_friendly", "chrome_reader_mode", "class", "clear",
    "clear_all", "close", "closed_caption", "cloud_circle", "cloud_done", "cloud_download", "cloud_off", "cloud_queue",
    "cloud_upload", "code", "collections", "color_lens", "colorize", "comment", "compare", "compare_arrows",
    "computer", "confirmation_number", "contact_mail", "contact_phone", "contacts", "content_copy", "content_cut",
    "content_paste", "control_point", "control_point_duplicate", "create", "create_new_folder", "credit_card", "crop",
    "crop_16_9", "crop_3_2", "crop_5_4", "crop_7_5", "crop_din", "crop_free", "crop_landscape", "crop_original",
    "crop_portrait", "crop_rotate", "crop_square", "data_usage", "date_range", "dehaze", "delete", "delete_forever",
    "delete_outline", "delete_sweep", "departure_board", "description", "desktop_mac", "desktop_windows", "details",
    "developer_board", "developer_mode", "device_hub", "device_unknown", "devices", "devices_other", "dialer_sip",
    "dialpad", "directions", "directions_bike", "directions_boat", "directions_bus", "directions_car", "directions_railway",
    "directions_run", "directions_subway", "directions_transit", "directions_walk", "disc_full", "dns", "do_not_disturb",
    "do_not_disturb_alt", "do_not_disturb_off", "do_not_disturb_on", "dock", "domain", "done", "done_all", "done_outline",
    "donut_large", "donut_small", "drafts", "drag_handle", "drive_eta", "dvr", "edit", "edit_location", "eject",
    "enhanced_encryption", "equalizer", "error", "error_outline", "euro_symbol", "ev_station", "event", "event_available",
    "event_busy", "event_note", "event_seat", "exit_to_app", "expand_less", "expand_more", "explicit", "explore", "exposure",
    "exposure_neg_1", "exposure_neg_2", "exposure_plus_1", "exposure_plus_2", "exposure_zero", "extension", "face",
    "fast_forward", "fast_rewind", "favorite_border", "featured_play_list", "featured_video", "feedback", "fiber_dvr",
    "fiber_manual_record", "fiber_new", "fiber_pin", "fiber_smart_record", "file_download", "file_upload", "filter",
    "filter_1", "filter_2", "filter_3", "filter_4", "filter_5", "filter_6", "filter_7", "filter_8", "filter_9",
    "filter_9_plus", "filter_b_and_w", "filter_center_focus", "filter_drama", "filter_frames", "filter_hdr", "filter_list",
    "filter_none", "filter_tilt_shift", "filter_vintage", "find_in_page", "find_replace", "fingerprint", "first_page",
    "fitness_center", "flag", "flare", "flash_auto", "flash_off", "flash_on", "flight", "flight_land", "flight_takeoff",
    "flip", "flip_to_back", "flip_to_front", "folder", "folder_open", "folder_shared", "folder_special", "font_download",
    "format_align_center", "format_align_justify", "format_align_left", "format_align_right", "format_bold", "format_clear",
    "format_color_fill", "format_color_reset", "format_color_text", "format_indent_decrease", "format_indent_increase",
    "format_italic", "format_line_spacing", "format_list_bulleted", "format_list_numbered", "format_paint", "format_quote",
    "format_shapes", "format_size", "format_strikethrough", "format_textdirection_l_to_r", "format_textdirection_r_to_l",
    "format_underlined", "forum", "forward", "forward_10", "forward_30", "forward_5", "free_breakfast", "fullscreen",
    "fullscreen_exit", "functions", "g_translate", "gamepad", "games", "gavel", "gesture", "get_app", "gif",
    "golf_course", "gps_fixed", "gps_not_fixed", "gps_off", "gradient", "grain", "graphic_eq", "grid_off", "grid_on",
    "group", "group_add", "group_work", "hd", "hdr_off", "hdr_on", "hdr_strong", "hdr_weak", "headset", "headset_mic",
    "healing", "hearing", "height", "help", "help_outline", "high_quality", "highlight", "highlight_off", "history",
    "hot_tub", "hotel", "hourglass_empty", "hourglass_full", "http", "https", "image", "image_aspect_ratio", "import_contacts",
    "import_export", "important_devices", "inbox", "indeterminate_check_box", "input", "insert_chart", "insert_comment",
    "insert_drive_file", "insert_emoticon", "insert_invitation", "insert_link", "insert_photo", "invert_colors",
    "invert_colors_off", "iso", "keyboard", "keyboard_arrow_down", "keyboard_arrow_left", "keyboard_arrow_right",
    "keyboard_arrow_up", "keyboard_backspace", "keyboard_capslock", "keyboard_hide", "keyboard_return", "keyboard_tab",
    "keyboard_voice", "kitchen", "label", "label_outline", "landscape", "laptop", "laptop_chromebook", "laptop_mac",
    "laptop_windows", "last_page", "launch", "layers", "layers_clear", "leak_add", "leak_remove", "lens", "library_add",
    "library_books", "library_music", "lightbulb_outline", "line_style", "line_weight", "linear_scale", "link",
    "linked_camera", "list", "live_help", "live_tv", "local_activity", "local_airport", "local_atm", "local_bar",
    "local_cafe", "local_car_wash", "local_convenience_store", "local_dining", "local_drink", "local_florist",
    "local_gas_station", "local_grocery_store", "local_hospital", "local_hotel", "local_laundry_service", "local_library",
    "local_mall", "local_movies", "local_offer", "local_parking", "local_pharmacy", "local_phone", "local_pizza",
    "local_play", "local_post_office", "search", "account_circle", "chat", "lock", "help", "arrow_back", "arrow_forward",
    "menu", "delete", "edit", "done", "clear", "refresh", "star", "star_border", "flag", "share", "more_vert",
    "attach_file", "file_download", "file_upload", "picture_in_picture", "videocam", "photo", "library_books", "map",
    "location_on", "event", "group", "work", "shopping_cart", "payment", "receipt", "local_offer", "restaurant", "flight",
    "hotel", "directions", "local_phone", "web", "public", "today", "event_available", "event_busy", "timer", "location_city",
    "place", "local_gas_station", "local_hospital", "local_movies", "local_grocery_store", "local_pizza", "local_cafe",
    "local_bar", "local_pharmacy", "local_library", "local_mall", "local_airport", "local_atm", "local_car_wash",
    "local_convenience_store", "local_dining", "local_drink", "local_florist", "local_play", "local_post_office",
    "local_laundry_service", "local_offer", "flight_land", "flight_takeoff", "directions_car", "directions_transit",
    "directions_bike", "directions_walk", "directions_run", "train", "tram", "subway", "directions_railway", "directions_boat",
    "directions_bus", "restaurant_menu", "store", "store_mall_directory", "local_printshop", "local_shipping", "storefront",
    "business", "school", "wifi", "bluetooth", "data_usage", "battery_full", "signal_cellular_alt", "signal_wifi_4_bar",
    "airplanemode_active", "location_disabled", "gps_fixed", "settings_brightness", "brightness_medium", "screen_rotation",
    "screen_lock_portrait", "developer_mode", "security", "vpn_lock", "lock_outline", "credit_card", "payment", "money",
    "attach_money", "monetization_on", "trending_up", "trending_down", "thumbs_up_down", "thumb_up", "thumb_down", "stars",
    "star_border", "star_half", "whatshot", "favorite_border", "share", "sms", "phone", "call", "call_end", "contact_phone",
    "contact_mail", "location_on", "place", "map", "directions", "directions_run", "explore", "near_me",
  ];

  defaultPublicMenus = {
    uid: "",
    cardView: true,
    sections: [
      {
        index: 0,
        isEnabled: false,
        name: "Slider",
        icon: "linear_scale",
        displayName: "Main Slider",
        // interval: 1000,
        options: [],
      },
      {
        index: 1,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "Login",
        icon: "login",
        displayName: "Register / Login in",
        displayNameColor: "#000000",
        button: {
          bgColor: "#000000",
          borderColor: "#ffffff",
          title: "Login",
          titleColor: "#ffffff",
        },
      },
      {
        index: 2,
        isEnabled: false,
        cardView: false,
        bgColor: "#FFFFFF",
        name: "News",
        icon: "feed",
        displayName: "Latest News",
        displayNameColor: "#000000",
        displayLimit: 3,
      },
      {
        index: 3,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "Events",
        icon: "calendar_today",
        displayName: "Upcoming Events",
        displayNameColor: "#000000",
      },
      {
        index: 4,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "AppStore",
        icon: "phone_iphone",
        displayName: "Download From The App Store",
        googlePlayUrl: "",
        appleStoreUrl: "",
        displayNameColor: "#000000",
      },
      {
        index: 5,
        isEnabled: false,
        bgColor: "#FFFFFF",
        displayNameColor: "#000000",
        name: "SocialMedia",
        icon: "public",
        displayName: "Social Media",
        facebookUrl: "",
        twitterUrl: "",
        youtubeUrl: "",
        linkedInUrl: "",
      },
      {
        index: 6,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "Departments",
        icon: "account_tree",
        displayName: "Departments",
        displayNameColor: "#000000",
        displayLimit: 4,
      },
      {
        index: 7,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "Governance",
        icon: "account_balance",
        displayName: "Governance",
        displayNameColor: "#000000",
        showChief: false,
        councillorsLimit: 4,
        councillorColor: "#FFFFFF",
        chiefColor: "#151414",
      },
      {
        index: 8,
        isEnabled: false,
        bgColor: "#ffffff",
        name: "ISETReg",
        icon: "how_to_reg",
        displayName: "ISET Registration",
        displayNameColor: "#000000",
        button: {
          bgColor: "#ffffff",
          borderColor: "#000000",
          title: "Apply For Funding",
          titleColor: "#000000",
        },
      },
      {
        index: 9,
        isEnabled: false,
        bgColor: "#FFFFFF",
        textColor: "#000000",
        name: "Footer",
        icon: "call_to_action",
        displayName: "App Footer",
        nationName: environment.firstNation.displayName,
        address: "",
        postalCode: "",
        city: "",
        province: environment.firstNation.province,
        phone: "",
        fax: "",
        tollFree: "",
        contactEmail: "",
      },

      {
        index: 10,
        isEnabled: false,
        bgColor: "#ffffff",
        name: "AboutUs",
        icon: "info",
        displayName: "AboutUs",
        displayNameColor: "#000000",
        button: {
          bgColor: "#ffffff",
          borderColor: "#000000",
          title: "AboutUs",
          titleColor: "#000000",
        },
      },
      {
        index: 11,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "ContactUs",
        icon: "contacts",
        displayName: "ContactUs",
        displayNameColor: "#000000",
        displayLimit: 4,
      },
      {
        index: 12,
        isEnabled: false,
        bgColor: "#FFFFFF",
        name: "CheckIfRegistered",
        icon: "how_to_reg",
        displayName: "Check If Registered",
        displayNameColor: "#000000",
      },
    ],
  };


  @Input() theImage = '';
  @Input() useBase64 = false;
  @Input() aspectRatio = '';
  @Output() returnCroppedImage = new EventEmitter<string>();
  @Output() closeCropModal = new EventEmitter<string>();
  @ViewChild('imageCropperModal') imageCropperModal: ElementRef;
  @Input() sections: any;
  model: any;
  videoId: any;

  constructor(private settingsService: AppSettingsService,
    private mediaService: MediaService,
    private newsService: StaticContentService,
    private eventsService: EventsService,
    private socialService: SocialMediaService,
    private departmentService: DepartmentService,
    private governanceService: GovernanceService,
    // private embedService: EmbedVideoService,
    private eventService: EventsService,
    private router: Router,
    private nationSettingsService: NationSettingsService,
    private helperService: HelperService,
    private aboutUsService: AboutusService,
    private cdRef: ChangeDetectorRef,
    private appSettingService: AppSettingsService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private cdr: ChangeDetectorRef) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.settingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      const menu = menus?.[0]?.submenus?.find(m => m.name === 'Governance');
      this.chiefTitle = menu?.chiefTitle || 'Chief';
      this.councillorTitle = menu?.councillorTitle || 'Councillors';
    });

    //this.councillorTitle = this.settingsService.getGovTitle().councillorTitle ? this.settingsService.getGovTitle().councillorTitle : 'Councillors';

    this.options = {
      animation: 150,
      filter: '.disabled',
      onMove: event => {
        return !event.related.classList.contains('disabled');
      },
      onUpdate: (event: any) => {
        this.pageSections.sections.forEach(element => {
          element.index = this.pageSections.sections.findIndex(x => x.name === element.name);
        });
        if (this.isNewSetting) {
          this.settingsService.saveHomePageSettings(this.pageSections);
        } else {
          this.settingsService.updateHomePageSettings(this.pageSections);
        }
      }
    };
    this.defaultSections = this.dataService.getPublicMenus();
    this.filteredIcons = this.allIcons;
    // if (this.hasISET) {
    //   this.defaultSections.sections.push(
    //     {
    //       index: 10, isEnabled: true, name: 'ISETReg', link: '/iset-registration-form', icon: 'how_to_reg', displayName: 'ISET Registration', addToHeader: true, bgColor: '#ffffff', displayNameColor: '#000000', button: {
    //         bgColor: '#ffffff',
    //         borderColor: '#000000',
    //         title: 'Apply For Funding',
    //         titleColor: '#000000',
    //       },
    //     }
    //   );
    //   this.defaultSections.sections.find(m => m.name == 'Footer').index++;
    // }
    // if (this.nationOfUse === 'Demo') {
    //   this.defaultSections.sections.push(
    //     { index: 11, isEnabled: true, name: 'Demo', link: '', icon: 'account_circle', displayName: 'Demo Account', addToHeader: true }
    //   );
    //   this.defaultSections.sections.find(m => m.name == 'Footer').index++;
    // }
  }

  loginSettings = false;
  loginRef: any;
  newsSettings = false;
  newsRef: any;
  publishedPublicNews: any;
  newsArray: any;
  newsCardView: any;
  eventsSettings = false;
  eventRef: any;
  eventsModel: any;
  eventArray: any;
  appStoreSettings = false;
  appStoreRef: any;
  socialMediaSettings = false;
  socialMediaRef: any;
  socialMediaModel: any;
  footerSettings = false;
  footerRef: any;
  nationSetting: any;
  isetRegSetting = false;
  isetRegRef: any;
  aboutUsSetting = false;
  aboutUsRef: any;
  aboutUsArray: any;
  loginForm: UntypedFormGroup;
  governanceRef: any;
  governanceArray: any;
  counicoilorRef: any
  governanceSettings = false;
  departmentsRef: any;
  departmentsArray: any;
  departmentsSettings = false;
  contactUsSetting = false;
  contactUsRef: any;
  demoAccountSetting = false;
  demoAccountRef: any;
  checkIfRegSetting = false;
  checkIfRegRef: any;

  isPositionChanged = false;
  selectedNewPosition: any;
  footerStyle: any;

  // **************************** Default Sections **************************************
  defaultSections: any;
  currentSection: any;
  bgImage;
  logoUrl: any;

  // chatgpt
  openChatGpt = false
  selectedInput
  inputText
  slideIndex
  changedSlideValue
  bgColor: any;
  textColor: any;
  applyBgColor = false;
  applyTextColor = false;

  cardHeight: any;
  imageHeight: any;
  submenus: any[];
  editName = false;

  ngOnInit(): void {
    this.settingsService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'LandingPage');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.settingsService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp && hp.length > 0) {
        this.pageSections = hp[0];
        this.isNewSetting = false;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.pageSections = this.defaultSections;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.isNewSetting = true;
      }

      this.cardView = this.pageSections.cardView;
      if (this.pageSections.desktopGridSize) this.desktopGridSize = this.pageSections.desktopGridSize;
      else this.pageSections.desktopGridSize = this.desktopGridSize;
      if (this.pageSections.mobileGridSize) this.mobileGridSize = this.pageSections.mobileGridSize;
      else this.pageSections.mobileGridSize = this.mobileGridSize;
      if (this.pageSections.cardShape) this.cardOption = this.pageSections.cardShape;
      else this.pageSections.cardShape = this.cardOption;
      if (this.pageSections.cardIconShape) this.cardIconOption = this.pageSections.cardIconShape;
      else this.pageSections.cardIconShape = this.cardIconOption;
      if (this.pageSections.cardSize) this.cardSizeOption = this.pageSections.cardSize;
      else this.pageSections.cardSize = this.cardSizeOption;
      if (this.pageSections.imageShape) this.imageOption = this.pageSections.imageShape;
      else this.pageSections.imageShape = this.imageOption;
      if (this.pageSections.titlePosition) this.titlePositionOption = this.pageSections.titlePosition;
      else this.pageSections.titlePosition = this.titlePositionOption;

      if (this.pageSections.imageCover) this.imageCoverOption = this.pageSections.imageCover;
      else this.pageSections.imageCover = this.imageCoverOption;


      // if (!this.hasISET) {
      //   let index = this.pageSections.sections.findIndex(object => {
      //     return object.name === 'ISETReg';
      //   });
      //   this.pageSections.sections.splice(index, 1);
      // }

      $(document).ready(function () {
        $('.collapsible').collapsible();
        $('.tabs').tabs();
      });

      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    });

    this.nationSettingsService.getNationSettings().subscribe(sett => {
      if (sett) {
        this.nationSetting = sett[0];
      }
    });


    this.carouselForm = this.fb.group({
      displayName: [''],
      index: [],
      interval: [3000],
      isEnabled: [],
      name: [''],
      opacity: [''],
      overlayColor: [''],
      transition: []
    });

    this.sliderForm = this.fb.group({
      index: [],
      imgUrl: [''],
      type: [''],
      link: [''],
      title: [''],
      titleColor: [''],
      text: [''],
      textColor: [''],
      textPosition: [],
    });

    setTimeout(() => {
      $('.tooltipped').tooltip();
      $('.modal').modal();
      $('.collapsible').collapsible();
    }, 25);

    if (this.sliderRef && this.sliderRef.options[this.slideRef] && this.sliderRef.options[this.slideRef].savedImgUrl) {
      // If a saved image URL exists, update the slider's imgUrl
      this.sliderRef.options[this.slideRef].imgUrl = this.sliderRef.options[this.slideRef].savedImgUrl;
    }

    if (this.preview && this.sections) {
      this.model = this.sections;
      $(document).ready(function () {
        $('.modal').modal();
      });
    } else {
      this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
        if (hp) {
          if (hp.length > 0) {
            this.model = hp[0];
            $(document).ready(function () {
              $('.modal').modal();
            });
          }
        }
      });
    }
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'LandingPage');
    this.submenus[index].displayName = this.sectionName;
    this.settingsService.UpdateMenu('Settings', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  intervalChange(interval) {
    this.sliderRef.interval = interval * 1000;
  }

  get intervalValue(): number {
    return this.sliderRef.interval / 1000;
  }

  //************* Section Setting *******************
  sectionViewChanged() {
    this.pageSections.cardView = !this.pageSections.cardView;
    this.cardView = this.pageSections.cardView;
    $(document).ready(function () {
      $('.collapsible').collapsible();
    });
  }

  gridNoChanged(value, screen) {
    if (screen == 'desktop') {
      this.desktopGridSize = value;
      this.pageSections.desktopGridSize = this.desktopGridSize;
    }
    else if (screen == 'mobile') {
      this.mobileGridSize = value;
      this.pageSections.mobileGridSize = this.mobileGridSize;
    }
  }

  cardOptionClicked(value) {
    this.cardOption = value;
    this.pageSections.cardShape = this.cardOption;
  }

  cardIconOptionClicked(value) {
    this.cardIconOption = value;
    this.pageSections.cardIconShape = this.cardIconOption;
  }

  imageOptionClicked(value) {
    this.imageOption = value;
    this.pageSections.imageShape = this.imageOption;
  }

  imageCoverOptionClicked(value) {
    this.imageCoverOption = value;
    this.pageSections.imageCover = this.imageCoverOption;
  }

  cardSizeOptionClicked(value) {
    this.cardSizeOption = value;
    this.pageSections.cardSize = this.cardSizeOption;
  }

  titlePositionOptionClicked(value) {
    this.titlePositionOption = value;
    this.pageSections.titlePosition = this.titlePositionOption;
  }

  setBgColor(color) {
    this.bgColor = color;
  }

  setTextColor(color) {
    this.textColor = color;
  }

  applyToAll() {
    if (this.bgColor) {
      this.pageSections.sections.forEach(sec => {
        if (sec.name != 'Footer' && sec.bgColor) sec.bgColor = this.bgColor;
      });
      $('.modal').modal('close');
    }

    if (this.textColor) {
      this.pageSections.sections.forEach(sec => {
        if (sec.name != 'Footer' && sec.displayNameColor) sec.displayNameColor = this.textColor;
      });
      $('.modal').modal('close');
    }
  }

  // applyStyleClicked(style) {
  //   if (style == 'bg') this.applyBgColor = !this.applyBgColor;
  //   else if (style == 'text') this.applyTextColor = !this.applyTextColor;
  // }

  applyStyle() {
    this.previewSection = this.pageSections;
    this.previewSection.sections.forEach(sec => {
      if (sec.name != 'Footer' && sec.bgColor) sec.bgColor = this.bgColor;
      if (sec.name != 'Footer' && sec.displayNameColor) sec.displayNameColor = this.textColor;
    });

    this.previewBefore = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#applyStyleModal').modal('open');
    }, 25);
  }

  closeStyleModal() {
    $('#applyStyleModal').modal('close');
  }

  visibilityChanged(section: any) {
    this.currentSection = section.name;
    if (!section.isEnabled) {
      if (section.name == 'Slider') {
        let section = this.pageSections.sections.filter(s => s.name == 'Slider')[0];
        let slides = section.options;
        if (!slides || slides.length == 0) {
          $('.modal').modal();
          $('#noContentModal').modal('open');
        }
      }

      if (section.name == 'Events') {
        this.eventService.getPublicEventsList().valueChanges().subscribe(events => {
          if (!events || events.length == 0) {
            $('.modal').modal();
            $('#noContentModal').modal('open');
          }
        });
      }

      if (section.name == 'News') {
        this.newsService.getContentList().valueChanges().subscribe(news => {
          if (!news || news.length == 0) {
            $('.modal').modal();
            $('#noContentModal').modal('open');
          }
        });
      }

      if (section.name == 'Departments') {
        this.departmentService.getDepartmentList().valueChanges().subscribe(depts => {
          if (!depts || depts.length == 0) {
            $('.modal').modal();
            $('#noContentModal').modal('open');
          }
        });
      }

      if (section.name == 'Governance') {
        this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
          let governance: any = gov[0];
          if (governance.chiefs.length == 0 || governance.councillors.length == 0) {
            $('.modal').modal();
            $('#noContentModal').modal('open');
          }
        });
      }
    }
    section.isEnabled = !section.isEnabled;
    // this.currentSection = null;
  }

  addToHeaderChanged(sectionName) {
    let section = this.pageSections.sections.filter(s => s.name == sectionName)[0];
    section.addToHeader = !section.addToHeader;
  }

  closeNoContentModal() {
    let section = this.pageSections.sections.filter(s => s.name == this.currentSection)[0];
    section.isEnabled = false;
    $('#noContentModal').modal('close');
  }

  editSection(section: any) {
    this.selectedSection = section;
    if (section.name === 'Slider') {
      this.sliderRef = section;
      this.slideRef = 0
      if (this.isNewSetting) {
        this.emptySlideOption = true
        //this.addSlider();
      }
      else {
        if (this.sliderRef.options.length > 0) {
          this.slideRef = 0
          this.sliderRef.options.forEach(element => {
            if (element.title) element.title = element.title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
            if (element.text) element.text = element.text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
          });
        } else {
          this.emptySlideOption = true
          //this.addSlider();
        }
      }
      this.sectionsSetting = false;
      this.sliderSettings = true;
      setTimeout(() => {
        M.updateTextFields();
        $('.slider').slider({
          interval: this.sliderRef.interval,
          transition: 800,
          indicators: false,
        });
        $('.modal').modal({
          onCloseStart: () => {
            $('.slider').slider({
              interval: this.sliderRef.interval,
              transition: 800,
              indicators: false,
            });
          }
        });
        $('.carousel').carousel({
          noWrap: true
        });
      }, 25);
    }
    if (section.name === "Login") {
      this.loginRef = section;
      this.sectionsSetting = false;
      this.loginSettings = true;
      this.bgColor = this.loginRef.bgColor;
      this.textColor = this.loginRef.displayNameColor;

      if (!this.loginRef.button) {
        let button = {
          title: 'Login/Signup',
          titleColor: '#000000',
          bgColor: '#ffffff',
          borderColor: '#ffffff'
        }
        this.loginRef.button = button;
      }
      if (this.loginRef.logoUrl) this.bgImageYes();

      setTimeout(() => {
        M.updateTextFields();
        $('.tooltipped').tooltip();
      }, 25);
    }
    if (section.name === "ISETReg" && this.hasISET) {
      this.isetRegRef = section
      this.sectionsSetting = false;
      this.isetRegSetting = true;
      if (!this.isetRegRef.button) {
        let button = {
          title: 'Login/Signup',
          titleColor: '#000000',
          bgColor: '#ffffff',
          borderColor: '#ffffff'
        }
        this.isetRegRef.button = button;
      }
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }
    if (section.name === "CheckIfRegistered") {
      this.checkIfRegRef = section
      this.checkIfRegSetting = true;
      this.bgColor = this.checkIfRegRef.bgColor;
      this.textColor = this.checkIfRegRef.displayNameColor;
      this.sectionsSetting = false;
      this.checkIfRegSetting = true
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }


    if (section.name === "Departments") {
      this.departmentService.getLimitedContentList(parseInt(section.displayLimit)).valueChanges().subscribe(n => {
        if (n) {
          this.departmentsArray = n;
          this.departmentsRef = section;
          this.bgColor = this.departmentsRef.bgColor;
          this.textColor = this.departmentsRef.displayNameColor;
          this.sectionsSetting = false;
          this.departmentsSettings = true
          setTimeout(() => {
            M.updateTextFields();
            $('.tooltipped').tooltip();
          }, 25);
        }
      });
    }
    if (section.name === "Governance") {
      this.governanceService.getGovernanceList().valueChanges().subscribe(n => {
        if (n) {
          this.governanceArray = n[0];
          this.governanceRef = section;
          this.bgColor = this.governanceRef.bgColor;
          this.textColor = this.governanceRef.displayNameColor;
          this.governanceArray.councillors = this.governanceArray.councillors.splice(0, parseInt(this.governanceRef.councillorsLimit))
          this.sectionsSetting = false;
          this.governanceSettings = true
          if (this.governanceRef.logoUrl) this.bgImageYes();
          setTimeout(() => {
            M.updateTextFields();
          }, 25);
        }
      });
    }
    if (section.name === "News") {
      this.newsService.getSpecificContentList('Public', true).valueChanges().subscribe(n => {
        if (n) {
          this.publishedPublicNews = n;
          this.publishedPublicNews.sort((a, b) => b.datePublished - a.datePublished);
          if (n.length >= section.displayLimit) this.newsArray = this.publishedPublicNews.slice(0, section.displayLimit);
          else this.newsArray = this.publishedPublicNews;
          this.newsRef = section;
          if (this.newsRef.logoUrl) this.bgImageYes();
          this.sectionsSetting = false;
          this.newsSettings = true;
          this.bgColor = this.newsRef.bgColor;
          this.textColor = this.newsRef.displayNameColor;
          if (this.newsRef.logoUrl) this.bgImageYes();
          setTimeout(() => {
            M.updateTextFields();
            $('.tooltipped').tooltip();
          }, 25);
        }
      });
    }
    if (section.name === "Events") {
      this.eventRef = section;
      this.bgColor = this.eventRef.bgColor;
      this.textColor = this.eventRef.displayNameColor;
      if (this.eventRef.logoUrl) this.bgImageYes();
      this.eventsService.getPublicEventsList().valueChanges().subscribe(n => {
        if (n) {
          this.eventsModel = n;
          this.sectionsSetting = false;
          this.eventsSettings = true;
          setTimeout(() => {
            M.updateTextFields();
            $('.tooltipped').tooltip();
          }, 25);
        }
      });
    }
    if (section.name === "AppStore") {
      this.appStoreRef = section;
      this.sectionsSetting = false;
      this.appStoreSettings = true;
      this.bgColor = this.appStoreRef.bgColor;
      this.textColor = this.appStoreRef.displayNameColor;
      if (this.appStoreRef.logoUrl) this.bgImageYes();
      setTimeout(() => {
        M.updateTextFields();
        $('.tooltipped').tooltip();
      }, 25);
    }
    if (section.name === 'SocialMedia') {
      this.socialService.getSocialMedia().valueChanges().subscribe(sm => {
        if (sm) {
          if (sm.length > 0) {
            this.socialMediaModel = sm[0];
            this.socialMediaRef = section;
            this.bgColor = this.socialMediaRef.bgColor;
            this.textColor = this.socialMediaRef.displayNameColor;
            this.sectionsSetting = false;
            this.socialMediaSettings = true;
            if (this.socialMediaRef.logoUrl) this.bgImageYes();
            setTimeout(() => {
              M.updateTextFields();
              $('.tooltipped').tooltip();
            }, 25);
          }
        }
      });
    }
    if (section.name == 'AboutUs') {
      this.aboutUsRef = section;
      this.bgColor = this.aboutUsRef.bgColor;
      this.textColor = this.aboutUsRef.displayNameColor;
      this.sectionsSetting = false;
      this.aboutUsSetting = true;
      if (this.aboutUsRef.logoUrl) this.bgImageYes();
      setTimeout(() => {
        M.updateTextFields();
        $('.tooltipped').tooltip();
      }, 25);
    }
    if (section.name == 'ContactUs') {
      this.contactUsRef = section;
      this.bgColor = this.contactUsRef.bgColor;
      this.textColor = this.contactUsRef.displayNameColor;
      this.sectionsSetting = false;
      this.contactUsSetting = true;
      if (this.contactUsRef.logoUrl) this.bgImageYes();
      setTimeout(() => {
        M.updateTextFields();
        $('.tooltipped').tooltip();
      }, 25);
    }
    if (section.name == 'Demo') {
      this.demoAccountRef = section;
      this.bgColor = this.demoAccountRef.bgColor;
      this.textColor = this.demoAccountRef.displayNameColor;
      this.sectionsSetting = false;
      this.demoAccountSetting = true;
      if (this.demoAccountRef.logoUrl) this.bgImageYes();
      setTimeout(() => {
        M.updateTextFields();
        $('.tooltipped').tooltip();
      }, 25);
    }
    if (section.name === "Footer") {
      this.footerRef = section
      this.sectionsSetting = false;
      this.footerSettings = true;
      setTimeout(() => {
        M.updateTextFields();
        $('.modal').modal();
      }, 25);
    }
  }

  //************************* Slider Setting ******************** */
  addSlider() {
    this.editSlider = false;
    if (this.sliderRef) {
      let slide = {
        index: this.sliderRef.options.length,
        title: '',
        titleColor: '#FFFFFF',
        text: '',
        textColor: '#FFFFFF',
        imgUrl: '',
        type: '',
        link: ''
      }
      this.sliderRef.options.push(slide);
      let index = this.sliderRef.options.length - 1;
      this.slideRef = index
      setTimeout(() => {
        M.updateTextFields();
        $('.modal').modal();
        $('#sliderModal').modal('open');
        $('.slider').slider('pause');
      }, 15);
    }
  }

  saveSliderChanges(element) {
    if (this.isPositionChanged) {
      var newIndex = this.selectedNewPosition //parseInt((document.getElementById("position") as HTMLInputElement).value);
      var oldIndex = element.index
      if (newIndex == oldIndex) return; //Already at the top or bottom.
      if (newIndex >= this.sliderRef.options.length) {
        newIndex = this.sliderRef.options.length - 1
      }
      let el = this.sliderRef.options.splice(oldIndex, 1)[0]
      this.sliderRef.options.splice(newIndex, 0, el);

      this.sliderRef.options.forEach(ele => {
        ele.index = this.sliderRef.options.indexOf(ele);
      });
    }

    let sliderIndex = this.pageSections.sections.findIndex(sec => sec.name == 'Slider');
    this.pageSections.sections[sliderIndex] = this.sliderRef;

    this.saveChanges();
    $('#sliderModal').modal('close');
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal('close');
      $('.slider').slider({
        interval: this.sliderRef.interval,
        transition: 800,
        indicators: false,
      });
    }, 25);
  }

  closeSliderModal() {
    if (this.slideRef == this.sliderRef.options.length - 1) {
      let slide = this.sliderRef.options[this.slideRef];
      if (!slide.imgUrl && !slide.title) {
        this.sliderRef.options.pop();
      }
    }
  }

  sliderClicked(index) {
    this.editSlider = true;
    this.slideRef = index
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#sliderModal').modal('open');
      $('.slider').slider('pause');
    }, 25);
  }

  // updateInterval() {
  //   this.sliderRef.interval = this.sliderRef.interval * 1000;
  //   $('.slider').slider({
  //     interval: this.sliderRef.interval,
  //     transition: 800,
  //     indicators: false,
  //   });
  // }

  //************************* ChatGpt modal ******************** */
  openChatGptModal(slideRef, value) {
    this.openChatGpt = true
    this.slideIndex = slideRef
    this.changedSlideValue = value
    this.selectedInput = this.sliderRef.options[slideRef]
    this.inputText = this.helperService.getInnerText(this.selectedInput[value]);
    setTimeout(() => {
      M.updateTextFields();
      $('#sliderModal').modal('close');
    }, 25);
  }

  updateInputValue(event) {
    this.sliderRef.options[this.slideIndex][this.changedSlideValue] = event.message;
    this.closeModalChatGptModal();
  }

  closeModalChatGptModal() {
    this.openChatGpt = false
    this.sliderClicked(this.slideIndex)
  }

  positionChanged(position: any) {
    this.isPositionChanged = true;
    this.selectedNewPosition = position
  }

  deleteSlider(slider) {
    this.selectedSlider = slider;
    $('#sliderModal').modal('close');
    setTimeout(() => {
      $('.modal').modal();
      $('#confirmationModal').modal('open');
    }, 25);
  }

  removeSlider() {
    if (this.sliderRef.options.length > 0) {
      const index = this.sliderRef.options.indexOf(this.selectedSlider);
      if (index !== -1) {
        this.sliderRef.options.splice(index, 1);
        this.sliderRef.options.forEach(element => {
          element.index = this.sliderRef.options.indexOf(element);
        });
        this.selectedSlider = null;
        M.toast({ html: 'Slider Successfully Deleted', classes: 'red' });
        setTimeout(() => {
          M.updateTextFields();
          $('.modal').modal('close');
          $('.slider').slider({
            interval: this.sliderRef.interval,
            transition: 800,
            indicators: false,
            onCloseStart: () => {
              $('.slider').slider({
                interval: this.sliderRef.interval,
                transition: 800,
                indicators: false,
              });
            }
          });
        }, 25);
      }
      else {
        this.emptySlideOption = true;
        this.selectedSlider = null;
        setTimeout(() => {
          M.updateTextFields();
          $('.modal').modal('close');
          $('.slider').slider('destroy');
        }, 25);
      }
    }
    let sliderIndex = this.pageSections.sections.findIndex(sec => sec.name == 'Slider');
    this.pageSections.sections[sliderIndex] = this.sliderRef;
    this.saveChanges();
  }

  cancelDelete() {
    this.selectedSlider = null;
    $('#confirmationModal').modal('close');
  }

  sliderTitleStyleChanged(event, slideRef) {
    this.sliderRef.options[slideRef].title = event;
  }

  // sliderDescriptionStyleChanged(event, slideRef) {
  //   this.sliderRef.options[slideRef].description = event;
  // }

  //************************* Slider Setting ******************** */
  newsNumberChange(num: any) {
    if (!num) return;
    if (this.publishedPublicNews && this.publishedPublicNews.length >= num) {
      this.newsArray = this.publishedPublicNews.slice(0, num);
    };
  }

  eventNumberChange(num: any) {
    if (!num) return;
    this.eventsService.getLimitedContentList(parseInt(num)).valueChanges().subscribe(n => {
      if (n) {
        this.eventArray = n;
      }
    });
  }

  aboutusNumberChange(num: any) {
    if (!num) return;
    this.aboutUsService.getLimitedContentList(parseInt(num)).valueChanges().subscribe(n => {
      if (n) {
        this.aboutUsArray = n;
      }
    });
  }

  limitChange(num: any, section: string) {
    if (section === 'governance') {
      if (!num) return;
      this.governanceService.getGovernanceList().valueChanges().subscribe(n => {
        if (n) {
          this.governanceArray = n[0];
          this.governanceArray.councillors = this.governanceArray.councillors.splice(0, parseInt(num))
        }
      });
    }
    if (section === 'departments') {
      if (!num) return;
      this.departmentService.getLimitedContentList(parseInt(num)).valueChanges().subscribe(n => {
        if (n) {
          this.departmentsArray = n;
        }
      });
    }
  }

  // showChiefChanged(governance: any) {
  //   governance.showChief = !governance.showChief;
  //   setTimeout(() => {
  //     M.updateTextFields();
  //   }, 25);
  // }

  viewChange() {
    this.newsRef.cardView = !this.newsRef.cardView;
    this.newsCardView = this.newsRef.cardView;
  }

  saveChanges() {
    if (this.pageSections && this.isNewSetting) {
      this.settingsService.saveHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.reset();
      });
    }
    if (this.pageSections && !this.isNewSetting) {
      this.settingsService.updateHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes updated Successfully.', classes: 'green' });
      });
    }
  }

  reset() {
    this.logoUrl = '';
    this.bgImage = false;
  }

  descriptionChange(event) {
    this.appStoreRef.description = event;
  }

  styleChanged(event) {
    this.footerStyle = event;
    this.footerRef.style = this.footerStyle;
  }

  // stringToHTML(str) {
  //   var dom = document.createElement('span');
  //   dom.innerHTML = str;
  //   return dom;
  // };

  //***************************************** Preview ***********************/
  previewChanges() {
    if (!this.preview) {
      const sliderSection = this.pageSections.sections.filter(s => s.name === 'Slider' && s.isEnabled);
      if (sliderSection && sliderSection.length > 0) {
        setTimeout(() => {
          M.updateTextFields();
          $('.slider').slider({
            // interval: sliderSection[0].interval,
            // interval: sliderSection.interval,
            transition: 800,
            indicators: false,
          });
        }, sliderSection.interval);
      }

      const newsSection = this.pageSections.sections.filter(s => s.name === 'News' && s.isEnabled);
      if (newsSection) {
        if (newsSection.length > 0) {
          this.newsService.getLimitedContentList(parseInt(newsSection[0].displayLimit))
            .valueChanges().pipe(
              map(news => news.map((n: any) => ({ ...n, iframe: '' })))
            )
            .subscribe(n => {
              if (n) {
                this.newsArray = n.filter(news => news.visibility === 'Public' && news.published === true);
                this.newsArray.forEach(element => {
                  if (element.type === 'Video') {
                    element.iframe = this.embedVideo(element.videoLink);
                  }
                });
              }
            });
        }
      }

      const eventSection = this.pageSections.sections.filter(s => s.name === 'Events' && s.isEnabled);
      if (eventSection) {
        if (eventSection.length > 0) {
          this.eventsService.getPublicEventsList().valueChanges().subscribe(n => {
            if (n) {
              this.eventsModel = n;
            }
          });
        }
      }

      const socialSection = this.pageSections.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
      if (socialSection) {
        if (socialSection.length > 0) {
          this.socialService.getSocialMedia().valueChanges().subscribe(n => {
            if (n) {
              if (n.length > 0) {
                this.socialMediaModel = n[0];
              }
            }
          });
        }
      }

      const governanceSection = this.pageSections.sections.filter(s => s.name === 'Governance' && s.isEnabled);
      if (governanceSection) {
        if (governanceSection.length > 0) {
          this.governanceService.getGovernanceList().valueChanges().subscribe(n => {
            if (n) {
              this.governanceArray = n[0];
              this.governanceArray.councillors = this.governanceArray.councillors.splice(0, governanceSection[0].councillorsLimit)
            }
          })
        }
      }

      const departmentsSection = this.pageSections.sections.filter(s => s.name === 'Departments' && s.isEnabled);
      if (departmentsSection) {
        if (departmentsSection.length > 0) {
          this.departmentService.getLimitedContentList(parseInt(departmentsSection[0].displayLimit)).valueChanges().subscribe(n => {
            if (n) {
              this.departmentsArray = n;
            }
          })
        }
      }
    } else {
      if (this.sliderRef) {
        setTimeout(() => {
          $('.slider').slider({
            interval: this.sliderRef.interval,
            transition: 800,
            indicators: false,
          });
          $('.modal').modal({
            onCloseStart: () => {
              $('.slider').slider({
                interval: this.sliderRef.interval,
                transition: 800,
                indicators: false,
              });
            }
          });
        }, 25);
      }
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }
    this.preview = !this.preview
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  moveSection(element, position: number) {
    var index = this.pageSections.sections.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.pageSections.sections.length) return; //Already at the top or bottom.
    var indexes = [index, newIndex].sort(function (a, b) {
      return a - b;
    });

    var section1 = Object.assign({}, this.pageSections.sections[indexes[1]]);
    var section2 = Object.assign({}, this.pageSections.sections[indexes[0]]);

    this.pageSections.sections.splice(indexes[0], 2, section1, section2); //Replace from lowest index, two elements, reverting the order
    this.pageSections.sections.forEach((element, i) => {
      element.index = i;
    });
  };

  // descriptionChanged(event, from) {
  //   this.loginRef.description = event;
  // }

  bgImageYes() {
    this.bgImage = true;
  }

  bgImageNo() {
    this.bgImage = false;
  }

  handlelogoInput(files: FileList, from) {
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          this.logoUrl = upload.imgLink;
          if (from == 'Login') this.loginRef.logoUrl = this.logoUrl;
          else if (from == 'News') this.newsRef.logoUrl = this.logoUrl;
          else if (from == 'Events') this.eventRef.logoUrl = this.logoUrl;
          else if (from == 'Departments') this.departmentsRef.logoUrl = this.logoUrl;
          else if (from == 'Governance') this.governanceRef.logoUrl = this.logoUrl;
          else if (from == 'AppStore') this.appStoreRef.logoUrl = this.logoUrl;
          else if (from == 'SocialMedia') this.socialMediaRef.logoUrl = this.logoUrl;
          else if (from == 'AboutUs') this.aboutUsRef.logoUrl = this.logoUrl;
          else if (from == 'ISETReg') this.isetRegRef.logoUrl = this.logoUrl;
          else if (from == 'CheckIfReg') this.checkIfRegRef.logoUrl = this.logoUrl;
        }
      });
    }
  }

  deleteImage(from) {
    if (from == 'Login') this.loginRef.logoUrl = '';
    else if (from == 'News') this.newsRef.logoUrl = '';
    else if (from == 'Events') this.eventRef.logoUrl = '';
    else if (from == 'Departments') this.departmentsRef.logoUrl = '';
    else if (from == 'Governance') this.governanceRef.logoUrl = '';
    else if (from == 'AppStore') this.appStoreRef.logoUrl = '';
    else if (from == 'SocialMedia') this.socialMediaRef.logoUrl = '';
    else if (from == 'AboutUs') this.aboutUsRef.logoUrl = '';
    else if (from == 'ISETReg') this.isetRegRef.logoUrl = this.logoUrl;
    else if (from == 'CheckIfReg') this.checkIfRegRef.logoUrl = this.logoUrl;
    this.logoUrl = '';
  }

  backClicked() {
    if (this.sectionsSetting) this.router.navigate(['admin/dashboard']);
    else {
      if (this.sliderSettings) this.sliderSettings = false;
      if (this.newsSettings) this.newsSettings = false;
      if (this.eventsSettings) this.eventsSettings = false;
      if (this.appStoreSettings) this.appStoreSettings = false;
      if (this.socialMediaSettings) this.socialMediaSettings = false;
      if (this.loginSettings) this.loginSettings = false;
      if (this.isetRegSetting) this.isetRegSetting = false;
      if (this.footerSettings) this.footerSettings = false;
      if (this.departmentsSettings) this.departmentsSettings = false;
      if (this.governanceSettings) this.governanceSettings = false;
      if (this.aboutUsSetting) this.aboutUsSetting = false;
      if (this.contactUsSetting) this.contactUsSetting = false;
      if (this.demoAccountSetting) this.demoAccountSetting = false;
      if (this.checkIfRegSetting) this.checkIfRegSetting = false;
      this.sectionsSetting = true;
      $(document).ready(function () {
        $('.collapsible').collapsible();
      });
    }
    $('.collapsible').collapsible();
  }

  toggleCollapsibleApperance() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleCollapsibleAccessibility() {
    this.isCollapsedd = !this.isCollapsedd;
  }

  // truncateHTML(text: string, limit: string): string {
  //   const charlimit = +limit;
  //   if (!text || text.length <= charlimit) {
  //     return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  //   }
  //   return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  // }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  handleFileInput(files: FileList, slider: any) {
    if (files) {
      this.croppedImage = null;
      this.imageChangedEvent = files.item(0);

      // Set the slider type based on the file type
      if (this.imageChangedEvent.type === 'image/jpeg' || this.imageChangedEvent.type === 'image/png' || this.imageChangedEvent.type === 'image/jpg' || this.imageChangedEvent.type === 'image/svg' || this.imageChangedEvent.type === 'image/webp') {
        slider.type = 'Image';
      } else if (this.imageChangedEvent.type === 'video/mp4') {
        slider.type = 'Video';
      }
      this.openImageCropperModal();
    }
  }

  // handleOnlyScaleDownChange(event: boolean) {
  // }

  // addImage(event: Event) {
  //   this.openImageCropperModal();
  // }

  openImageCropperModal() {
    const modalElement: any = this.imageCropperModal.nativeElement;
    if (modalElement) {
      M.Modal.getInstance(modalElement).open();
    }
  }

  closeImageCropperModal() {
    this.showImageCropperModal = false;
    const modalElement: any = this.imageCropperModal.nativeElement;
    if (modalElement) {
      const modalInstance = M.Modal.getInstance(modalElement);
      modalInstance.close(); // Close the modal
      document.body.style.overflow = 'auto';
      // Resume the slider
      $('.slider').slider({
        interval: this.sliderRef.interval,
        transition: 800,
        indicators: false,
      });
    }
  }

  // openImageCropper() {
  //   const modalElement: any = this.imageCropperModal.nativeElement;
  //   if (modalElement) {
  //     M.Modal.getInstance(modalElement).open();
  //   }
  // }

  // ================== new =================================================================================================================
  saveCroppedImage() {
    if (this.croppedImage) {
      try {
        // Parse the cropped image data into a URL
        const imgUrl = new URL(this.croppedImage);
        const blob = this.dataURItoBlob(this.croppedImage);
        const fileName = new File([blob], 'cropped_image.png');

        // Assuming this.mediaService.UploadBinImage is the function to upload the image
        this.mediaService.UploadBinImage(fileName).subscribe(upload => {
          if (upload) {
            // Update the imgUrl property with the uploaded image URL
            this.sliderRef.options[this.slideRef].imgUrl = upload.imgLink;
            // Trigger change detection to update the template
            this.cdRef.detectChanges();
            // Optionally, you can close the cropping modal
            this.croppedImage = '';
            this.closeImageCropperModal();
          }
        });
      } catch (error) {
        console.error('Error parsing cropped image data as URL:', error);
      }
    } else {
      console.warn('No cropped image data available.');
    }
  }

  // ================== new =================================================================================================================

  imageCropped(event: ImageCroppedEvent) {
    if (event) {
      // Create a FileReader to read the blob data as base64
      const reader = new FileReader();
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        if (this.croppedImage) {
          let base64result = this.croppedImage.split(',')[1];
          const img = new ImageModel();
          img.imgBase64 = base64result;
          this.mediaList.push({ type: 'Image', file: img });
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(event.blob);
    } else {
      console.error('Cropped image data is undefined.');
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.openImageCropperModal();
    //console.log('Cropped Image Data (Base64):', this.imageChangedEvent); // Log the selected image data
  }


  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    if (this.scale > 0.11) {
      this.zoomScale -= 10;
      this.scale -= .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  zoomIn() {
    this.zoomScale += 10;
    if (this.scale < 3.1) {
      this.scale += .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  zoomFromRange() {
    this.scale = this.zoomScale * 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  // updateRotation() {
  //   this.transform = {
  //     ...this.transform,
  //     rotate: this.rotation
  //   };
  // }

  closeCroppingModal() {
    this.closeCropModal.emit();
  }

  // handleCroppedFile() {
  //   this.returnCroppedImage.emit(this.croppedImage);
  // }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  easyChange() {
    if (this.iseasyChange) {
      this.iseasyChange = false;
      this.buttonText = 'Easy Fix';
    } else {
      this.iseasyChange = true;
      this.buttonText = 'Close Easy Fix';
    }
  }

  toggleRearrange() {
    this.isRearrangeClicked = !this.isRearrangeClicked;
  }

  landingPageEditor() {
    this.router.navigate(['/admin/landing-editor-page']);
  }

  nationName() {
    this.router.navigate(['/admin/dashboard-sub-menu/settings/app-settings']);
  }

  headerContent() {
    this.router.navigate(['/admin/dashboard-sub-menu/settings/app-menu-settings']);
  }

  updateImageHeight(image) {

  }

  selectIcon(newIcon: string): void {
    this.selectedIcon = newIcon;
  }

  setDefaultIcons(sectionIndex: number) {
    console.log("Processing setDefaultIcons for section index:", sectionIndex);

    // Find the corresponding section in defaultPublicMenus based on the index
    const defaultSection = this.defaultPublicMenus.sections.find(
      (s) => s.index === sectionIndex
    );

    // Check if defaultSection is defined
    if (defaultSection) {
      console.log("Setting icon for section index:", sectionIndex);

      // Check if selectedSection is defined before trying to update its properties
      if (this.selectedSection) {
        console.log("Updating icon for selectedSection:", this.selectedSection);

        // Update the icon property of the selected section in your component
        this.selectedSection.icon = defaultSection.icon;
      } else {
        console.log("selectedSection is not defined.");
      }
    } else {
      console.log("Default section not found for index:", sectionIndex);
    }
  }


  saveSelectedIcon(selectedIcon: string): void {
    if (selectedIcon && this.currentEditingSection) {
      const editedSection = cloneDeep(this.currentEditingSection);

      // editedSection.icon = selectedIcon;

      if (editedSection.name === "Login") {
        // this.loginRef = editedSection;
        this.loginRef.icon = selectedIcon;
      } else if (editedSection.name === "ISETReg") {
        // this.isetRegRef = editedSection;
        this.isetRegRef.icon = selectedIcon;
      } else if (editedSection.name === "Departments") {
        // this.departmentsRef = editedSection;
        this.departmentsRef.icon = selectedIcon;
      } else if (editedSection.name === "Governance") {
        // this.governanceRef = editedSection;
        this.governanceRef.icon = selectedIcon;
      } else if (editedSection.name === "News") {
        // this.newsRef = editedSection;
        this.newsRef.icon = selectedIcon;
      } else if (editedSection.name === "Events") {
        // this.eventRef = editedSection;
        this.eventRef.icon = selectedIcon;
      } else if (editedSection.name === "AppStore") {
        // this.appStoreRef = editedSection;
        this.appStoreRef.icon = selectedIcon;
      } else if (editedSection.name === "SocialMedia") {
        // this.socialMediaRef = editedSection;
        this.socialMediaRef.icon = selectedIcon;
      } else if (editedSection.name === "AboutUs") {
        // this.aboutUsRef = editedSection;
        this.aboutUsRef.icon = selectedIcon;
      }
      else if (editedSection.name === "ContactUs") {
        // this.aboutUsRef = editedSection;
        this.contactUsRef.icon = selectedIcon;
      }

      this.cdr.detectChanges();
      console.log(
        `Icon updated for ${editedSection.name} section:`,
        selectedIcon
      );
      this.closeModal();
    }
  }

  closeModal(): void {
    const modalInstance = M.Modal.getInstance(document.getElementById('iconPickerModal'));
    modalInstance.close();
  }


  openIconPickerModal(section: any): void {
    this.currentEditingSection = section;
    // $("#iconPickerModal").modal("open");
    $("#iconPickerModal").modal("open");
  }

  filterIcons(): void {
    this.filteredIcons = this.allIcons.filter((icon) =>
      icon.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }


}