import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MemberAuthGuard } from 'src/app/guards/member-auth.guard';

import { MemberMainComponent } from './member-main/member-main.component';
import { MemberMainHomeComponent } from './member-main-home/member-main-home.component';
import { MemberHomeComponent } from './member-home/member-home.component';
import { MemberHomeSubMenuComponent } from './member-home-sub-menu/member-home-sub-menu.component';

import { MyHouseComponent } from './housing/my-house/my-house.component';
import { HouseRepairComponent } from './housing/house-repair/house-repair.component';
import { HouseRenovationComponent } from './housing/house-renovation/house-renovation.component';
import { FamilyCompositionComponent } from './profile/family-composition/family-composition.component';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { HistoryComponent } from './monthly-assistance/entitlement/history/history.component';
import { EntitlementDetailComponent } from './monthly-assistance/entitlement/entitlement-detail/entitlement-detail.component';
import { ProfileMainComponent } from './profile/profile-main/profile-main.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { FamilymemberdetailComponent } from './profile/family-composition/familymemberdetail/familymemberdetail.component';
import { DocumentDetailComponent } from './profile/documents/document-detail/document-detail.component';
import { MonthlyAssistanceMainComponent } from './monthly-assistance/monthly-assistance-main/monthly-assistance-main.component';
import { MyEntitlementComponent } from './monthly-assistance/entitlement/my-entitlement/my-entitlement.component';
import { SupplementalSupportComponent } from './monthly-assistance/supplemental-support/supplemental-support.component';
import { SupportNewComponent } from './monthly-assistance/supplemental-support/support-new/support-new.component';
import { SupportDetailComponent } from './monthly-assistance/supplemental-support/support-detail/support-detail.component';
import { ParticipationsComponent } from './participations/participation/participations.component';
import { ActivityDetailComponent } from './participations/activity/activity-detail/activity-detail.component';
import { ActionDetailComponent } from './participations/action/action-detail/action-detail.component';
import { ParticipationDetailComponent } from './participations/participation-detail/participation-detail.component';
import { MyIncomeStatementComponent } from './monthly-assistance/my-income-statement/my-income-statement.component';
import { NewIncomeStatementComponent } from './monthly-assistance/my-income-statement/new-income-statement/new-income-statement.component';
// tslint:disable-next-line: max-line-length
import { PreviousIncomeStatementComponent } from './monthly-assistance/my-income-statement/previous-income-statement/previous-income-statement.component';
import { EmploymentComponent } from './profile/employment/employment.component';
import { EducationComponent } from './profile/education/education.component';
import { AddEmploymentComponent } from './profile/employment/add-employment/add-employment.component';
import { EditEmploymentComponent } from './profile/employment/edit-employment/edit-employment.component';
import { HousingComponent } from './profile/housing/housing.component';
import { AddEducationComponent } from './profile/education/add-education/add-education.component';
import { EditEducationComponent } from './profile/education/edit-education/edit-education.component';

import { NewsComponent } from './news/news.component';
import { SocialAssistanceMenuComponent } from './monthly-assistance/social-assistance-menu/social-assistance-menu.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';

import { NationWallComponent } from './nation-wall/nation-wall.component';
import { AddNationWallPostComponent } from './nation-wall/add-nation-wall-post/add-nation-wall-post.component';
import { ViewNationWallPostComponent } from './nation-wall/view-nation-wall-post/view-nation-wall-post.component';
import { GovernanceComponent } from './governance/governance.component';
import { DepartmentComponent } from './department/department.component';
import { ManageDepartmentComponent } from './department/manage-department/manage-department.component';
import { ManageSubDepartmentComponent } from './department/manage-sub-department/manage-sub-department.component';
import { ViewDepartmentInfoComponent } from './department/view-department-info/view-department-info.component';
import { ViewSubdepartmentInfoComponent } from './department/view-subdepartment-info/view-subdepartment-info.component';
import { UploadStatementComponent } from './monthly-assistance/my-income-statement/upload-statement/upload-statement.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { SurveyListComponent } from './pollAnsSurvey/survey-list/survey-list.component';
import { SurveyDetailComponent } from './pollAnsSurvey/survey-detail/survey-detail.component';
import { PollsListComponent } from './pollAnsSurvey/polls-list/polls-list.component';
import { PollDetailComponent } from './pollAnsSurvey/poll-detail/poll-detail.component';
import { DepNewsListComponent } from './department/Dep-news/dep-news-list/dep-news-list.component';
import { DepNewsDetailComponent } from './department/Dep-news/dep-news-detail/dep-news-detail.component';
import { MyInboxComponent } from './member-messaging/my-inbox/my-inbox.component';
import { MyDiscussionComponent } from './member-messaging/my-discussion/my-discussion.component';

import { ContactDepartmentMemberComponent } from './department/contact-department-member/contact-department-member.component';
import { FamilyCompositionFirebaseComponent } from './profile/family-composition-firebase/family-composition-firebase.component';
import { NewFamilyMemberComponent } from './profile/family-composition-firebase/new-family-member/new-family-member.component';
import { EditFamilyMemberComponent } from './profile/family-composition-firebase/edit-family-member/edit-family-member.component';
import { ElectionListComponent } from './pollAnsSurvey/election-list/election-list.component';
import { NominationListComponent } from './pollAnsSurvey/nomination-list/nomination-list.component';
import { ElectionDetailComponent } from './pollAnsSurvey/election-list/election-detail/election-detail.component';
import { NominationDetailComponent } from './pollAnsSurvey/nomination-list/nomination-detail/nomination-detail.component';
import { MyNominationsComponent } from './pollAnsSurvey/my-nominations/my-nominations.component';
import { VoteComponent } from './pollAnsSurvey/vote/vote.component';
import { MyVotesComponent } from './pollAnsSurvey/my-votes/my-votes.component';
import { CampaignPageComponent } from './pollAnsSurvey/campaign-page/campaign-page.component';
import { CandidatePageComponent } from './pollAnsSurvey/candidate-page/candidate-page.component';
import { EditCampaignPageComponent } from './pollAnsSurvey/edit-campaign-page/edit-campaign-page.component';
import { MemberEventsCallendarComponent } from './events/member-events-callendar/member-events-callendar.component';
import { MemberEventsDetailComponent } from './events/member-events-detail/member-events-detail.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { AccessScreenComponent } from './access-screen/access-screen.component';
import { MessagingComponent } from './messaging/messaging.component';

import { ActivityComponent } from './profile/activity/activity.component';
import { AddActivityComponent } from './profile/activity/add-activity/add-activity.component';
import { EditActivityComponent } from './profile/activity/edit-activity/edit-activity.component';
import { CertificateComponent } from './profile/certificate/certificate.component';
import { AddCertificateComponent } from './profile/certificate/add-certificate/add-certificate.component';
import { EditCertificateComponent } from './profile/certificate/edit-certificate/edit-certificate.component';
import { HighlightsComponent } from './profile/highlights/highlights.component';
import { AddHighlightComponent } from './profile/highlights/add-highlight/add-highlight.component';
import { EditHighlightComponent } from './profile/highlights/edit-highlight/edit-highlight.component';
import { ObjectiveComponent } from './profile/objective/objective.component';
import { AddObjectiveComponent } from './profile/objective/add-objective/add-objective.component';
import { EditObjectiveComponent } from './profile/objective/edit-objective/edit-objective.component';
import { ReferenceComponent } from './profile/reference/reference.component';
import { AddReferenceComponent } from './profile/reference/add-reference/add-reference.component';
import { EditReferenceComponent } from './profile/reference/edit-reference/edit-reference.component';
import { SkillsComponent } from './profile/skills/skills.component';
import { AddSkillComponent } from './profile/skills/add-skill/add-skill.component';
import { EditSkillComponent } from './profile/skills/edit-skill/edit-skill.component';
import { SignMultipleBdComponent } from './monthly-assistance/sign-multiple-bd/sign-multiple-bd.component';
import { IncomeSupportApplicationComponent } from './profile/income-support-application/income-support-application.component';
//import { SaveApplicationComponent } from './profile/income-support-application/save-application/save-application.component';
import { SkCasePlanComponent } from './profile/sk-case-plan/sk-case-plan.component';
import { SKCasePlanFRMComponent } from './profile/sk-case-plan-frm/sk-case-plan-frm.component';
import { JobBoardComponent } from './job-board/job-board.component';
import { PSSSPApplicationComponent } from './profile/psssp-application/psssp-application.component';
//import { PseApplicationComponent } from './profile/pse-application/pse-application.component';
import { PollResultComponent } from './pollAnsSurvey/poll-result/poll-result.component';
import { BoardListComponent } from './boards/board-list/board-list.component';
import { BoardDetailsComponent } from './boards/board-details/board-details.component';

import { SurveyResultsComponent } from './pollAnsSurvey/survey-results/survey-results.component';
import { MyAreasOfInterestComponent } from './profile/my-areas-of-interest/my-areas-of-interest.component';
import { ElectionResultComponent } from './pollAnsSurvey/election-result/election-result.component';

import { SKIncomeSupportAppComponent } from './profile/income-support-application/sk-income-support-app/sk-income-support-app.component';

import { EditDepNewsComponent } from './department/Dep-news/edit-dep-news/edit-dep-news.component';
import { AddDepNewsComponent } from './department/Dep-news/add-dep-news/add-dep-news.component';
import { ManageDepNewsComponent } from './department/Dep-news/manage-dep-news/manage-dep-news.component';
import { ManageDepartmentMembersComponent } from './department/manage-department-members/manage-department-members.component';
import { AddMemberToDepartmentComponent } from './department/manage-department-members/add-member-to-department/add-member-to-department.component';
import { EditDepartmentEmployeeComponent } from './department/manage-department-members/edit-department-employee/edit-department-employee.component';

//import { ApplyDialogComponent } from './isets/apply-dialog/apply-dialog.component';
//import { RequestApplicationComponent } from './isets/request-application/request-application.component';
//import { RequestRegistrationComponent } from './isets/request-registration/request-registration.component';
import { PseEntitlementComponent } from './profile/pse-entitlement/pse-entitlement.component';
//import { PseEntitlementDetailComponent } from './profile/pse-entitlement/pse-entitlement-detail/pse-entitlement-detail.component';
import { HousingApplicationComponent } from './housing/housing-application/housing-application.component';
import { TestApplicationComponent } from './test-application/test-application.component';
import { ViewApplicationsComponent } from './view-applications/view-applications.component';
import { ScisApplicationComponent } from './profile/scis-application/scis-application.component';
import { PseCourseRegistrationComponent } from './profile/pse-course-registration/pse-course-registration.component';
import { LrrcnApplicationComponent } from './profile/lrrcn-application/lrrcn-application.component';
import { PseContractComponent } from './profile/pse-contract/pse-contract.component';
import { LrrcnApplicationMemberListComponent } from './profile/lrrcn-application/lrrcn-application-list/lrrcn-application-list.component';
import { PseReleaseFormComponent } from './profile/pse-release-form/pse-release-form.component';
import { PseSupplementalFundingComponent } from './profile/pse-entitlement/pse-supplemental-funding/pse-supplemental-funding.component';
import { PseAppV2Component } from './profile/pse-app-v2/pse-app-v2.component';
import { PseCourseComponent } from './profile/pse-course/pse-course.component';
import { PseFundingsComponent } from './profile/pse-entitlement/pse-fundings/pse-fundings.component';
import { InterventionComponent } from './isets/intervention/intervention.component';
import { InterventionDetailComponent } from './isets/intervention/intervention-detail/intervention-detail.component';
import { ServicePlanComponent } from './isets/service-plan/service-plan.component';
import { ServicePlanDetailComponent } from './isets/service-plan/service-plan-detail/service-plan-detail.component';
import { UploadIsetsDocumentsComponent } from './isets/upload-isets-documents/upload-isets-documents.component';
import { ActionPlanComponent } from './isets/action-plan/action-plan.component';
import { EditActionPlanComponent } from './isets/action-plan/edit-action-plan/edit-action-plan.component';
import { PseAppComponent } from './profile/pse-app/pse-app.component';

import { Children } from 'src/app/models/incomeSupportModel';
import { GovMemberDetailComponent } from '../app/governance/gov-member-detail/gov-member-detail.component';
import { GovChiefDetailComponent } from '../app/governance/gov-chief-detail/gov-chief-detail.component';
import { RequestRegistrationComponent } from './isets/request-registration/request-registration.component';
import { RequestApplicationComponent } from './isets/request-application/request-application.component';
import { ApplyDialogComponent } from './isets/apply-dialog/apply-dialog.component';
import { GetVerifiedComponent } from './get-verified/get-verified.component';
import { CustomSectionsComponent } from './custom-sections/custom-sections.component';

const routes: Routes = [
  // {
  //   path: 'member',
  //   component: MemberMainComponent,
  //   canActivate: [MemberAuthGuard],
  // children: [
  {
    path: 'member-main-home',
    component: MemberMainHomeComponent,
    canActivate: [MemberAuthGuard],
    children: [

      // =================================== home ================================
      {
        path: 'member-home',
        component: MemberHomeComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'member-home-sub-Menu',
        component: MemberHomeSubMenuComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'get-verified/:id',
        component: GetVerifiedComponent,
        canActivate: [MemberAuthGuard],
      },

      // =================================== my account ================================
      {
        path: 'profile',
        component: ProfileMainComponent,
        canActivate: [MemberAuthGuard],
        children: [
          {
            path: 'my-areas-of-interest',
            component: MyAreasOfInterestComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'upload-document',
            component: DocumentsUploadComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'personal-information',
            component: PersonalInformationComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'family-composition',
            component: FamilyCompositionComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'family-composition-firebase',
            component: FamilyCompositionFirebaseComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'new-family-member',
            component: NewFamilyMemberComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'edit-family-member/:id',
            component: EditFamilyMemberComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'family-member-detail/:id',
            component: FamilymemberdetailComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'housing',
            component: HousingComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'my-house',
            component: MyHouseComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'documents',
            component: DocumentsComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'documents-preview/:id',
            component: DocumentDetailComponent,
            canActivate: [MemberAuthGuard],
          },

          {
            path: 'objective',
            component: ObjectiveComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'add-objective',
            component: AddObjectiveComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'edit-objective/:id',
            component: EditObjectiveComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'is-application',
            component: IncomeSupportApplicationComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'sk-application',
            component: SKIncomeSupportAppComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'case-plan',
            component: SKCasePlanFRMComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'psssp-app',
            component: PSSSPApplicationComponent
          },
          // {
          //   path: 'pse-app',
          //   component: PseAppComponent
          // },
          {
            path: 'pse-app-v2',
            component: PseAppV2Component
          },
          // {
          //   path: 'pse-entitlement',
          //   component: PseEntitlementComponent
          // },

          {
            path: 'pse-supplemental-funding',
            component: PseSupplementalFundingComponent
          },

          // {
          //   path: 'pse-fundings',
          //   component: PseFundingsComponent
          // },

          {
            path: 'scis-app',
            component: ScisApplicationComponent
          },

          {
            path: 'pse-course-registraion',
            component: PseCourseRegistrationComponent
          },
          // {
          //   path: 'pse-course',
          //   component: PseCourseComponent
          // },

          {
            path: 'lrrcn',
            component: LrrcnApplicationComponent,
          },

          // {
          //   path: 'pse-contract',
          //   component: PseContractComponent
          // },
          {
            path: 'lrrcn-list',
            component: LrrcnApplicationMemberListComponent,
          },

          // {
          //   path: 'pse-release-form',
          //   component: PseReleaseFormComponent
          // },

          {
            path: 'employment',
            component: EmploymentComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'addemployment',
            component: AddEmploymentComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'edit-employment/:id',
            component: EditEmploymentComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'education',
            component: EducationComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'add-education',
            component: AddEducationComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'edit-education/:id',
            component: EditEducationComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'certificate',
            component: CertificateComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'add-certificate',
            component: AddCertificateComponent,
            canActivate: [MemberAuthGuard],
          },
          {
            path: 'edit-certificate/:id',
            component: EditCertificateComponent,
            canActivate: [MemberAuthGuard],
          },
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'section/:id',
        component: CustomSectionsComponent,
        canActivate: [MemberAuthGuard],
      },

      // =================================== my nation ================================
      {
        path: 'job-board',
        component: JobBoardComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'department',
        component: DepartmentComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'boards-list',
        component: BoardListComponent
      },
      {
        path: 'board-detail/:id',
        component: BoardDetailsComponent
      },
      {
        path: 'governance',
        component: GovernanceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'governance-detail/:id',
        component: GovMemberDetailComponent
      },
      {
        path: 'governance-chief',
        component: GovChiefDetailComponent
      },
      // =================================== news  ================================
      {
        path: 'news',
        component: NewsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'news-detail/:id',
        component: NewsDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      // =================================== events  ================================
      {
        path: 'events-calendar',
        component: MemberEventsCallendarComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'event-detail/:id',
        component: MemberEventsDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      // =================================== job related ================================
      {
        path: 'highlight',
        component: HighlightsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-highlight',
        component: AddHighlightComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-highlight/:id',
        component: EditHighlightComponent,
        canActivate: [MemberAuthGuard],
      },

      // =================================== my service ================================
      {
        path: 'pse-app',
        component: PseAppComponent
      },
      {
        path: 'pse-entitlement',
        component: PseEntitlementComponent
      },
      {
        path: 'pse-fundings',
        component: PseFundingsComponent
      },

      {
        path: 'pse-course',
        component: PseCourseComponent
      },

      {
        path: 'pse-contract',
        component: PseContractComponent
      },

      {
        path: 'pse-release-form',
        component: PseReleaseFormComponent
      },

      // {
      //   path: 'isets',
      //   component: ProfileMainComponent,
      //   canActivate: [MemberAuthGuard],
      //   children: [
      {
        path: 'application',
        component: ApplyDialogComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'new-application',
        component: RequestApplicationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'registration',
        component: RequestRegistrationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'intervention/:id',
        component: InterventionComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'intervention-detail/:id',
        component: InterventionDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'service-plan',
        component: ServicePlanComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'service-plan-detail/:id',
        component: ServicePlanDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'upload_iset_documents',  // path: 'upload_documents',
        component: UploadIsetsDocumentsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'action-plan',
        component: ActionPlanComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-action-plan/:id',
        component: EditActionPlanComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        canActivate: [MemberAuthGuard],
      },
      // ]
      // },
      // =================================== polls amd survey ================================
      {
        path: 'poll-list',
        component: PollsListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'poll-detail/:id',
        component: PollDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'poll-result/:id',
        component: PollResultComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'my-nominations',
        component: MyNominationsComponent,
        canActivate: [MemberAuthGuard]
      },
      // =================================== chat  ================================
      {
        path: 'messages',
        component: MessagingComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'objective',
        component: ObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-objective',
        component: AddObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-objective/:id',
        component: EditObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'reference',
        component: ReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-reference',
        component: AddReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-reference/:id',
        component: EditReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-activity',
        component: AddActivityComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-activity/:id',
        component: EditActivityComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'certificate',
        component: CertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-certificate',
        component: AddCertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-certificate/:id',
        component: EditCertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'education',
        component: EducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-education',
        component: AddEducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-education/:id',
        component: EditEducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'skill',
        component: SkillsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-skill',
        component: AddSkillComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-skill/:id',
        component: EditSkillComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'employment',
        component: EmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'addemployment',
        component: AddEmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-employment/:id',
        component: EditEmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'election-list',
        component: ElectionListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'survey-list',
        component: SurveyListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'survey-detail/:id',
        component: SurveyDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'survey-result/:id',
        component: SurveyResultsComponent,
        canActivate: [MemberAuthGuard]
      },

      {
        path: 'nomination-list',
        component: NominationListComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'job-board',
        component: JobBoardComponent,
        canActivate: [MemberAuthGuard],
      },
      // =================================== Housing  ================================
      {
        path: 'my-house',
        component: MyHouseComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'house-app',
        component: HousingApplicationComponent,
        canActivate: [MemberAuthGuard]
      },

      {
        path: 'house-repair',
        component: HouseRepairComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'house-renovation',
        component: HouseRenovationComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'manage-department/:id',
        component: ManageDepartmentComponent,
        canActivate: [MemberAuthGuard]
      },

      // =================================== to be cleaned  ================================

      {
        path: 'survey-list',
        component: SurveyListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'survey-detail/:id',
        component: SurveyDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'survey-result/:id',
        component: SurveyResultsComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'poll-list',
        component: PollsListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'poll-detail/:id',
        component: PollDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'poll-result/:id',
        component: PollResultComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'my-inbox',
        component: MyInboxComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'discussion/:id',
        component: MyDiscussionComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'my-house',
        component: MyHouseComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'house-app',
        component: HousingApplicationComponent,
        canActivate: [MemberAuthGuard]
      },

      {
        path: 'house-repair',
        component: HouseRepairComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'house-renovation',
        component: HouseRenovationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'election-list',
        component: ElectionListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'nomination-list',
        component: NominationListComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'election-detail/:id',
        component: ElectionDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'nomination-detail/:id',
        component: NominationDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'my-nominations',
        component: MyNominationsComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'candidate-page/:id',
        component: CandidatePageComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'vote/:id',
        component: VoteComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'my-votes/:id',
        component: MyVotesComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'election-campaign/:id',
        component: CampaignPageComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'election-result/:id',
        component: ElectionResultComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'edit-campaign/:id',
        component: EditCampaignPageComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'messages',
        component: MessagingComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'boards-list',
        component: BoardListComponent
      },
      {
        path: 'board-detail/:id',
        component: BoardDetailsComponent
      },
      {
        path: 'manage-department-members',
        component: ManageDepartmentMembersComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'add-member-to-department',
        component: AddMemberToDepartmentComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'edit-department-employee/:id',
        component: EditDepartmentEmployeeComponent,
        canActivate: [MemberAuthGuard]
      },
    ]
  },
  //  ====================new end
  {
    path: 'job-board',
    component: JobBoardComponent,
    canActivate: [MemberAuthGuard],
  },
  // {
  //   path: 'home',
  //   component: MemberHomeComponent,
  //   canActivate: [MemberAuthGuard],
  // },
  {
    path: 'access',
    component: AccessScreenComponent,
    canActivate: [MemberAuthGuard],
  },
  // {
  //   path: 'member-home',
  //   component: MemberHomeComponent,
  //   canActivate: [MemberAuthGuard],
  // },
  {
    path: 'member-home-sub-Menu',
    component: MemberHomeSubMenuComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'manage-department/:id',
    component: ManageDepartmentComponent,
    canActivate: [MemberAuthGuard]
  },

  {
    path: 'profile',
    component: ProfileMainComponent,
    canActivate: [MemberAuthGuard],
    children: [
      {
        path: 'my-areas-of-interest',
        component: MyAreasOfInterestComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'upload-document',
        component: DocumentsUploadComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'personal-information',
        component: PersonalInformationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'family-composition',
        component: FamilyCompositionComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'family-composition-firebase',
        component: FamilyCompositionFirebaseComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'new-family-member',
        component: NewFamilyMemberComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-family-member/:id',
        component: EditFamilyMemberComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'family-member-detail/:id',
        component: FamilymemberdetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'employment',
        component: EmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'addemployment',
        component: AddEmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-employment/:id',
        component: EditEmploymentComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'education',
        component: EducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-education',
        component: AddEducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-education/:id',
        component: EditEducationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'housing',
        component: HousingComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'my-house',
        component: MyHouseComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'documents-preview/:id',
        component: DocumentDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'certificate',
        component: CertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-certificate',
        component: AddCertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-certificate/:id',
        component: EditCertificateComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'objective',
        component: ObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-objective',
        component: AddObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-objective/:id',
        component: EditObjectiveComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-activity',
        component: AddActivityComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-activity/:id',
        component: EditActivityComponent,
        canActivate: [MemberAuthGuard],
      },

      {
        path: 'skill',
        component: SkillsComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-skill',
        component: AddSkillComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-skill/:id',
        component: EditSkillComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'reference',
        component: ReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'add-reference',
        component: AddReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'edit-reference/:id',
        component: EditReferenceComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'is-application',
        component: IncomeSupportApplicationComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'sk-application',
        component: SKIncomeSupportAppComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'case-plan',
        component: SKCasePlanFRMComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'psssp-app',
        component: PSSSPApplicationComponent
      },
      {
        path: 'pse-app',
        component: PseAppComponent
      },
      {
        path: 'pse-app-v2',
        component: PseAppV2Component
      },
      {
        path: 'pse-entitlement',
        component: PseEntitlementComponent
      },

      {
        path: 'pse-supplemental-funding',
        component: PseSupplementalFundingComponent
      },

      {
        path: 'pse-fundings',
        component: PseFundingsComponent
      },

      {
        path: 'scis-app',
        component: ScisApplicationComponent
      },

      {
        path: 'pse-course-registraion',
        component: PseCourseRegistrationComponent
      },

      {
        path: 'pse-course',
        component: PseCourseComponent
      },

      {
        path: 'lrrcn',
        component: LrrcnApplicationComponent,
      },

      {
        path: 'pse-contract',
        component: PseContractComponent
      },
      {
        path: 'lrrcn-list',
        component: LrrcnApplicationMemberListComponent,
      },

      {
        path: 'pse-release-form',
        component: PseReleaseFormComponent
      },

    ]
  },
  {
    path: 'monthly-assistance',
    component: MonthlyAssistanceMainComponent,
    canActivate: [MemberAuthGuard],
    children: [
      {
        path: 'menu',
        component: SocialAssistanceMenuComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'history',
        component: HistoryComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'my-entitlement',
        component: MyEntitlementComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'entitlement-detail',
        component: EntitlementDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'multiple-bd',
        component: SignMultipleBdComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'entitlement-detail/:id',
        component: EntitlementDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'my-income-statement',
        component: MyIncomeStatementComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'new-income-statement',
        component: NewIncomeStatementComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'upload-statement',
        component: UploadStatementComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'previous-income-statement',
        component: PreviousIncomeStatementComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'supplemental-support',
        component: SupplementalSupportComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'new-support-request',
        component: SupportNewComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'support-request-detail/:id',
        component: SupportDetailComponent,
        canActivate: [MemberAuthGuard],
      },
    ]
  },
  {
    path: 'participation',
    component: ParticipationsComponent,
    canActivate: [MemberAuthGuard],
    children: [
      {
        path: 'detail',
        component: ParticipationDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'activity-detail/:id',
        component: ActivityDetailComponent,
        canActivate: [MemberAuthGuard],
      },
      {
        path: 'action-detail/:id',
        component: ActionDetailComponent,
        canActivate: [MemberAuthGuard],
      },
    ]
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'news-detail/:id',
    component: NewsDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'events-calendar',
    component: MemberEventsCallendarComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'event-detail/:id',
    component: MemberEventsDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'contact-department-member/:id',
    component: ContactDepartmentMemberComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'manage-department/:id',
    component: ManageDepartmentComponent,
    canActivate: [MemberAuthGuard],
    children: [

      {
        path: 'manage-sub-department/:id',
        component: ManageSubDepartmentComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'view-department-info/:id',
        component: ViewDepartmentInfoComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'view-subdepartment-info/:id',
        component: ViewSubdepartmentInfoComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'department-news-list/:id',
        component: DepNewsListComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'view-department-news/:id',
        component: DepNewsDetailComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'add-department-news/:id',
        component: AddDepNewsComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'edit-department-news/:id',
        component: EditDepNewsComponent,
        canActivate: [MemberAuthGuard]
      },
      {
        path: 'manage-department-news/:id',
        component: ManageDepNewsComponent,
        canActivate: [MemberAuthGuard]
      },
    ]
  },
  {
    path: 'nation-wall',
    component: NationWallComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'add-nation-wall-post',
    component: AddNationWallPostComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'view-nation-wall-post/:id',
    component: ViewNationWallPostComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'governance',
    component: GovernanceComponent,
    canActivate: [MemberAuthGuard],
  },
  // {
  //   path: 'upload-document',
  //   component: DocumentsUploadComponent,
  //   canActivate: [MemberAuthGuard],
  // },
  {
    path: 'survey-list',
    component: SurveyListComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'survey-detail/:id',
    component: SurveyDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'survey-result/:id',
    component: SurveyResultsComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'poll-list',
    component: PollsListComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'poll-detail/:id',
    component: PollDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'poll-result/:id',
    component: PollResultComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'my-inbox',
    component: MyInboxComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'discussion/:id',
    component: MyDiscussionComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'my-house',
    component: MyHouseComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'house-app',
    component: HousingApplicationComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'house-repair',
    component: HouseRepairComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'house-renovation',
    component: HouseRenovationComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'election-list',
    component: ElectionListComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'nomination-list',
    component: NominationListComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'election-detail/:id',
    component: ElectionDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'nomination-detail/:id',
    component: NominationDetailComponent,
    canActivate: [MemberAuthGuard],
  },
  {
    path: 'my-nominations',
    component: MyNominationsComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'candidate-page/:id',
    component: CandidatePageComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'vote/:id',
    component: VoteComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'my-votes/:id',
    component: MyVotesComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'election-campaign/:id',
    component: CampaignPageComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'election-result/:id',
    component: ElectionResultComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'edit-campaign/:id',
    component: EditCampaignPageComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'messages',
    component: MessagingComponent,
    canActivate: [MemberAuthGuard],
  },

  {
    path: 'boards-list',
    component: BoardListComponent
  },
  {
    path: 'board-detail/:id',
    component: BoardDetailsComponent
  },
  {
    path: 'manage-department-members',
    component: ManageDepartmentMembersComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'add-member-to-department',
    component: AddMemberToDepartmentComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'edit-department-employee/:id',
    component: EditDepartmentEmployeeComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'isets',
    component: ProfileMainComponent,
    canActivate: [MemberAuthGuard],
    children: [
      // {
      //   path: 'application',
      //   component: ApplyDialogComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'new-application',
      //   component: RequestApplicationComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'registration',
      //   component: RequestRegistrationComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'intervention/:id',
      //   component: InterventionComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'intervention-detail/:id',
      //   component: InterventionDetailComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'service-plan',
      //   component: ServicePlanComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'service-plan-detail/:id',
      //   component: ServicePlanDetailComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'upload_documents',
      //   component: UploadIsetsDocumentsComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'action-plan',
      //   component: ActionPlanComponent,
      //   canActivate: [MemberAuthGuard],
      // },

      // {
      //   path: 'edit-action-plan/:id',
      //   component: EditActionPlanComponent,
      //   canActivate: [MemberAuthGuard],
      // },
    ]
  },
  {
    path: 'test-application',
    component: TestApplicationComponent,
    canActivate: [MemberAuthGuard]
  },
  {
    path: 'view-application/:name',
    component: ViewApplicationsComponent,
    canActivate: [MemberAuthGuard]
  }

  //]
  //}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MemberRoutingModule { }
