<div class="body-h container-pi">
  <!-- <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Add New Certificate </h4>
    </div>
  </div> -->
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
    </span>Add New Certificate
  </h4>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(addCertificateForm.value)" [formGroup]="addCertificateForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">Please Select a Course<strong class="red-text">*</strong></div>
            <select class="form-control" formControlName="course" id="course" autocomplete="off" require
              class="validate browser-default" placeholder="Course">
              <option *ngFor="let c of courses" [value]="c">{{c}}</option>
            </select>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Institution Name<strong class="red-text">*</strong></div>
            <input formControlName="Institution" id="Institution" type="text" maxlength="48" autocomplete="off" require
              class="capitalize validate" placeholder="Institution Name">
            <div *ngIf="(Institution.dirty || Institution.touched) && Institution.invalid">
              <small *ngIf="Institution.errors.required" class="red-text">The Institution Name Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Description</div>
            <input formControlName="description" id="description" type="text" maxlength="190" autocomplete="off"
              class="capitalize validate" placeholder="Description"> <!-- required class="capitalize validate" -->
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Start Date <strong class="red-text">*</strong></div>
            <input id="start_date" formControlName="start_date" type="text" class="datepicker1"
              (change)="startDateChange()" placeholder="Start Date">
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Date Completed </div>
            <input id="completion_date" formControlName="completion_date" type="text" class="datepicker2"
              (change)="completedDateChange()" placeholder="Date Completed">
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Expiry Date <strong class="red-text">*</strong></div>
            <input id="expiry_date" formControlName="expiry_date" type="text" class="datepicker3"
              (change)="expiryDateChange()" placeholder="Expiry Date">
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addCertificateForm.valid">
            <i class="material-icons right">add_circle</i> Save Certificate
          </button>
          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Certificate List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>