<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">
      <h4 class=""> Upcoming Birthdays </h4>
    </div>

    <main class="StickyContent white ">
      <div class="row col s12">
        <div class="col s12 m12">
          <div class="row">
            <div class="col s12 m4 ">
              <a class="waves-light btn-small col s12" [ngClass]="{'teal darken-4': filters.today}"
                style="margin-bottom: 5px;" (click)="today()"><i class="material-icons right">cake</i>Today</a>
            </div>
            <div class="col s12 m4 ">
              <a class="waves-light btn-small col s12" [ngClass]="{'teal darken-4': filters.thisWeek}"
                style="margin-bottom: 5px;" (click)="thisWeek()"><i class="material-icons right">cake</i>This Week</a>
            </div>
            <div class="col s12 m4 ">
              <a class="waves-light btn-small col s12" [ngClass]="{'teal darken-4': filters.thisMonth}"
                (click)="thisMonth()"><i class="material-icons right">cake</i>This Month</a>
            </div>
          </div>

          <div class="row card-panel white darken-2" *ngIf="members?.length > 0" style="min-width: fit-content;">
            <div class="row col s12">
              <div class="row col s12" style="margin-top: 25px;">
                <label class="col m4 s12">
                  <input name="filter" type="radio" class="filled-in" [checked]="showOnlyEmail" class="with-gap"
                    (click)="filterBy('email')" />
                  <span>show only with Email </span>
                </label>
                <label class="col m4 s12">
                  <input name="filter" type="radio" [checked]="showOnlyMobile" class="filled-in" class="with-gap"
                    (click)="filterBy('mobile')" />
                  <span>show only with Mobile </span>
                </label>
                <label class="col m4 s12 left">
                  <input name="filter" type="radio" [checked]="showOnlyRegistered" class="filled-in" class="with-gap"
                    (click)="filterBy('registered')" />
                  <span>show only Registered </span>
                </label>
              </div>

              <p class="center"><strong> Total : <span class="green-text"></span> {{count}} </strong> </p>
              <table class="striped centered hide-on-med-and-down" *ngIf="count > 0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Dob</th>
                    <th>Email</th>
                    <th>Registered</th>
                    <th>Mobile Number</th>
                    <th class="hide-on-small-only">Send Email
                      <label class="col s12">
                        <input type="checkbox" class="filled-in" [checked]="selectAllChecked" (click)="selectAll()" />
                        <span> All</span>
                      </label>
                      <span class="badge" *ngIf="emailList.length > 0">{{emailList.length}}</span>
                    </th>
                    <th class="hide-on-small-only">Send SMS
                      <label class="col s12">
                        <input type="checkbox" class="filled-in" [checked]="selectAllNosChecked"
                          (click)="selectAllNos(p)" />
                        <span> All</span>
                      </label>
                      <span class="badge" *ngIf="phoneNoList.length > 0">{{phoneNoList.length}}</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let m of membersData">
                    <td><strong> {{m.LastName}} {{m.GivenName}} </strong></td>
                    <td>{{m.dob | date: 'dd/MMM/yyyy'}}</td>
                    <td>{{m.Email}}</td>
                    <td *ngIf="m.isRegistred" class="hide-on-small-only"> <i
                        class="material-icons green-text">check_circle</i> </td>
                    <td *ngIf="!m.isRegistred" class="hide-on-small-only"> <i class="material-icons red-text">cancel</i>
                    </td>
                    <td class="hide-on-small-only">{{m.mobileNumber}} </td>
                    <td class="hide-on-small-only">
                      <label>
                        <input [disabled]='!m.Email' type="checkbox" [checked]="emailList.includes(m.Email)"
                          class="filled-in" (click)="emailSelected(m.Email)" />
                        <span></span>
                      </label>
                    </td>
                    <td class="hide-on-small-only">
                      <label>
                        <input [disabled]="!m.mobileNumber" type="checkbox"
                          [checked]="phoneNoList.includes(m.mobileNumber)" class="filled-in"
                          (click)="phoneNoSelected(m.mobileNumber)" />
                        <span></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row col s12 right hide-on-large-only">
                <label class="col m6 s12">
                  <input type="checkbox" class="filled-in" [checked]="selectAllChecked" (click)="selectAll()" />
                  <span> Select All Emails<span class="badge" *ngIf="emailList.length > 0">( {{emailList.length}}
                      )</span></span>
                </label>

                <label class="col m6 s12">
                  <input type="checkbox" class="filled-in" [checked]="selectAllNosChecked" (click)="selectAllNos(p)" />
                  <span> Select All Mobile<span class="badge" *ngIf="phoneNoList.length > 0">( {{phoneNoList.length}}
                      )</span></span>
                </label>
              </div>

              <table class="striped centered hide-on-large-only" *ngIf="count > 0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Dob</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let m of membersData" (click)="showDetail(m)" Class="modal-trigger"
                    href="#memberDetailModal">
                    <td><strong> {{m.LastName}} {{m.GivenName}}</strong></td>
                    <td>{{m.dob | date: 'dd/MMM/yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="memberDetailModal" class="modal">
            <div class="modal-content">
              <h4>Member Details</h4>
              <div *ngIf="selectedMember">
                <p><strong>Full Name : </strong>{{selectedMember.GivenName}} {{selectedMember.LastName}}</p>
                <p><strong>Date of Birth : </strong>{{selectedMember.dob | date: 'dd/MMM/yyyy'}} </p>
                <p><strong>Email : </strong>{{selectedMember.Email}} </p>
                <p><strong>Mobile Number : </strong>{{selectedMember.mobileNumber}} </p>
                <p *ngIf="selectedMember.isRegistred"><strong>Registered : </strong>Yes <i
                    class="material-icons green-text">check_circle</i></p>
                <p *ngIf="!selectedMember.isRegistred"><strong>Registered : </strong>No <i
                    class="material-icons red-text">cancel</i></p>
                <p>
                  <label>
                    <input [disabled]="!selectedMember.Email" [checked]="emailList.includes(selectedMember.Email)"
                      type="checkbox" class="filled-in" (click)="emailSelected(selectedMember.Email)" />
                    <span>Send Email<i class="material-icons left">mail</i></span>
                  </label>
                </p>
                <p>
                  <label>
                    <input [disabled]="!selectedMember.mobileNumber" type="checkbox" class="filled-in"
                      [checked]="phoneNoList.includes(selectedMember.mobileNumber)"
                      (click)="phoneNoSelected(selectedMember.mobileNumber)" />
                    <span>Send SMS<i class="material-icons left">perm_phone_msg</i></span>
                  </label>
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <a class="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
          </div>

          <div class="row card-panel red darken-2" *ngIf="members?.length == 0">
            <h6 class="white-text"><strong> 0 Record Found In The Database </strong></h6>
          </div>

          <button *ngIf="members?.length > 0" class="col s12 btn waves-effect" (click)="sendEmail()"
            [ngClass]="'btn-flat ' + buttonType" [disabled]="emailList.length == 0" style="margin-bottom: 15px;"
            [ngStyle]="{'background-color': themeColor}"><i class="material-icons right">mail</i> Send
            Email<span *ngIf="emailList.length > 0"> ( {{emailList.length}} )</span></button>

          <button *ngIf="members?.length > 0" class='col s12 btn waves-effect' (click)="sendSMS()"
            [ngClass]="'btn-flat ' + buttonType" style="margin-bottom: 15px;" [disabled]="phoneNoList.length == 0"
            [ngStyle]="{'background-color': themeColor}">
            <i class="material-icons right">perm_phone_msg</i> Send Text Message<span> (
              {{phoneNoList.length}} )</span></button>

          <button class="col s12 white" style="border: 1px solid;"
            [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
            [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">arrow_back_ios</i>Back To Previous
            Page</button>
        </div>
      </div>

      <div *ngIf="contact">
        <app-contact-members [message]=message [contactDetail]="contactDetail" (success)="contactHandler($event)">
        </app-contact-members>
      </div>
    </main>
  </div>
</div>