<div class="row center-align">
  <h6><strong>Invite Employees</strong></h6>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 l10" *ngIf="isManager || canManageEmployees">
    <div class="row" *ngIf="step1">
      <form [formGroup]="searchMemberForm" (ngSubmit)="searchMember(searchMemberForm.value)" autocomplete="off">

        <div class="row card-panel white">
          <div class="row">
            <div class="input-field col s12 m6">
              <i class="material-icons prefix purple-text">search</i>
              <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off" />
              <label for="lastName">Last Name</label>
            </div>
            <div class="input-field col s12 m6">
              <i class="material-icons prefix purple-text">search</i>
              <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off" />
              <label for="firstName">First Name</label>
            </div>
          </div>
        </div>

        <div class="row card-panel white " *ngIf="searchResult && searchResult.length > 0">
          <div class="table-responsive">
            <table class="row hover centered striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of searchResult; let i = index" (click)="selectMember(item)">
                  <td>{{item?.applicantID}}</td>
                  <td>{{item?.LastName}}</td>
                  <td>{{item?.GivenName}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="searchResult && searchResult.length === 0" class="row card-panel red lighten-1">
          <h6 class="white-text"> <strong>
              No Member Found In The DataBase.</strong>
          </h6>
        </div>

        <button type="submit" style="margin-bottom: 15px;" class="col s12 btn green">
          <i class="material-icons right">search</i> Search Member
        </button>

        <button type="button" *ngIf="searchResult && searchResult.length > 0" class="col s12 btn blue"
          (click)="newResearch()" style="margin-bottom: 15px;">
          <i class="material-icons right">search</i> New Research
        </button>
      </form>
    </div>

    <div class="row" *ngIf="step2">
      <form [formGroup]="inviteMemberForm" (ngSubmit)="inviteEmployee(inviteMemberForm.value)">
        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Employee Information</strong></h6>

          <div class="input-field col s12">
            <i class="material-icons prefix teal-text">portrait</i>
            <input id="fnme" type="text" [value]="selectedMember.GivenName" disabled />
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">portrait</i>
            <input id="lnme" type="text" [value]="selectedMember.LastName" disabled />
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix pink-text">work</i>
            <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate" autocomplete="off" />
            <label for="jobTitle">Job Title</label>
            <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
              <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">email</i>
            <input id="email" type="text" formControlName='email' required class="validate" autocomplete="off" />
            <label for="email">Email</label>
            <div *ngIf="(email.dirty || email.touched) && email.invalid">
              <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
              <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix teal-text">call</i>
            <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}" required
              class="validate" autocomplete="off" /> -->
              <input id="workPhone" type="text" formControlName='workPhone' required
              class="validate" autocomplete="off" />
            <label for="workPhone">Work Phone</label>
            <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
              <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix purple-text text-darken-2">stay_current_portrait</i>
            <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
              required class="validate" autocomplete="off" /> -->
              <input id="mobilePhone" type="text"  formControlName='mobilePhone'
              required class="validate" autocomplete="off" />
            <label for="mobilePhone">Mobile Phone</label>
            <div *ngIf="(mobilePhone.dirty || mobilePhone.touched) && mobilePhone.invalid">
              <small *ngIf="mobilePhone.errors.required" class="red-text"> The Mobile Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix green-text">description</i>
            <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
              autocomplete="off"></textarea>
            <label for="description">Responibilities</label>
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> The Job Description is required. </small>
            </div>
          </div>
        </div>

        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Employee Contact Settings</strong>
          </h6>

          <div class="col s12">
            <p><strong>Show Mobile Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Public'" (click)="publicMobileClick()" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                  (click)="membersMobileClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                  (click)="privateMobileClick()" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Office Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                  (click)="publicOfficeClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                  (click)="membersOfficeClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                  (click)="privateOfficeClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Show Email Address</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Public'" (click)="publicEmailClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                  (click)="membersEmailClick()" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                  (click)="privateEmailClick()" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="col s12">
            <p><strong>Allow Contact Us Messages </strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Public'"
                  (click)="publicContactClick()" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Members'"
                  (click)="membersContactClick()" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Private'"
                  (click)="privateContactClick()" />
                <span class="red-text"><strong>Do Not Allow</strong></span>
              </label>
            </div>
          </div>
        </div>

        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Permissions</strong></h6>
          <div class="col s12">
            <div class="row">
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageAccount' (click)='manageAccountClicked()' />
                  <span><strong>Update Account Information</strong></span>
                </label>
              </div>
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageContact' (click)='manageContactClicked()' />
                  <span><strong> Update Contact Settings</strong></span>
                </label>
              </div>
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageDepartmentInfo' (click)='manageDepartmentInfoClicked()' />
                  <span><strong> Update Department Information</strong></span>
                </label>
              </div>
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageDepartmentNews' (click)='manageDepartmentNewsClicked()' />
                  <span><strong>Manage Department News</strong></span>
                </label>
              </div>
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageDepartmentEmployees'
                    (click)='manageDepartmentEmployeesClicked()' />
                  <span><strong> Manage Department Employees</strong></span>
                </label>
              </div>
              <div class="col s12 l6">
                <label>
                  <input type="checkbox" [checked]='manageSubDepartments' (click)='manageSubDepartmentsClicked()' />
                  <span> <strong> Manage Sub Department</strong></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row card-panel white">
          <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Account Validation</strong></h6>
          <div class="row">
            <div class="col s4">
              <label>
                <input type="checkbox" [checked]='validateSin' (click)='validateSinClicked()' />
                <span> <strong>SIN</strong></span>
              </label>
            </div>
            <div class="col s4">
              <label>
                <input type="checkbox" [checked]='validateIsn' (click)='validateIsnClicked()' />
                <span><strong>ISN</strong></span>
              </label>
            </div>
            <div class="col s4">
              <label>
                <input type="checkbox" [checked]='validateDob' (click)='validateDobClicked()' />
                <span><strong>DOB</strong></span>
              </label>
            </div>
          </div>
        </div>

        <button type="submit" [disabled]="!inviteMemberForm.valid" class="btn col s12 blue white-text">
          Send Invitation Link <i class="material-icons white right">send</i>
        </button>
      </form>
    </div>
  </div>
  <div class="col l1 "></div>
</div>