<div class="row center-align">
  <h6><strong>Edit Employee Account</strong></h6>
</div>
<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 l10">

    <form [formGroup]="employeeForm" (ngSubmit)="updateEmployee(employeeForm.value)" *ngIf="model">
      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>General Information</strong></h6>

        <div class="input-field col s12">
          <i class="material-icons prefix teal-text">portrait</i>
          <input id="fnme" type="text" [value]="model.givenName" disabled />
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix blue-text">portrait</i>
          <input id="lnme" type="text" [value]="model.lastName" disabled />
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix pink-text">people_outline</i>
          <select formControlName="headOffice" class="validate" (change)="headOfficeChange($event.target.value)">
            <option value="" disabled selected>Please Select</option>
            <option *ngFor="let c of SelectChoice" [value]="c">{{c}}</option>
          </select>
          <label for="headOffice">Head Office </label>
        </div>

        <div class="input-field col s12" *ngIf="communities && !isHead">
          <i class="material-icons prefix red-text">location_on</i>
          <select formControlName="community" class="validate">
            <option value="" disabled selected>Select</option>
            <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
          </select>
          <label for="community">Community </label>
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix blue-text">email</i>
          <input id="email" type="text" [value]="model.email" disabled />
        </div>

        <div *ngIf="!isManager" class="input-field col s12 l12">
          <i class="material-icons prefix pink-text">work</i>
          <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate" autocomplete="off" />
          <label for="jobTitle">Job Title</label>
          <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
            <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required. </small>
          </div>
        </div>

        <div *ngIf="isManager" class="input-field col s12">
          <i class="material-icons prefix orange-text">work</i>
          <select formControlName="jobTitle" class="validate">
            <option value="" disabled selected>Please Select Title</option>
            <option *ngFor="let t of managerTitles" [value]="t">{{t}}</option>
          </select>
          <label for="jobTitle">Job Title </label>
        </div>

        <div class="">
          <div class="input-field col m8 s7">
            <i class="material-icons prefix teal-text">call</i>
            <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}" required
              class="validate" autocomplete="off" /> -->
              <input id="workPhone" type="text" formControlName='workPhone' required
              class="validate" autocomplete="off" />
            <label for="workPhone">Work Phone <span class="red-text">*</span></label>
            <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
              <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required. </small>
            </div>
          </div>
          <div class=" input-field col m4 s5">
            <i class="material-icons prefix red-text">call</i>
            <!-- <input id="ext" type="text" formControlName='ext' [textMask]="{mask: extention}" autocomplete="off" /> -->
            <input id="ext" type="text" formControlName='ext'  autocomplete="off" />
            <label for="ext">Ext :</label>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix purple-text text-darken-2">stay_current_portrait</i>
          <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
            autocomplete="off" /> -->
            <input id="mobilePhone" type="text"  formControlName='mobilePhone'
            autocomplete="off" />
          <label for="mobilePhone">Mobile Phone</label>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix green-text">description</i>
          <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
            autocomplete="off"></textarea>
          <label for="description">Responibilities</label>
          <div *ngIf="(description.dirty || description.touched) && description.invalid">
            <small *ngIf="description.errors.required" class="red-text"> The Job Responsibilities is required. </small>
          </div>
        </div>
      </div>


      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact Settings</strong></h6>
        <div class="col s12">
          <p><strong>Show Mobile Phone</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Public'" (click)="publicMobileClick()" />
              <span><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Members'" (click)="membersMobileClick()" />
              <span class="blue-text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group1" [checked]="showMobileNo === 'Private'" (click)="privateMobileClick()" />
              <span class="red-text"><strong>Do Not Show </strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Show Office Phone</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                (click)="publicOfficeClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                (click)="membersOfficeClick()" />
              <span class="blue-text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group0" [checked]="showOfficeNo === 'Private'" (click)="privateOfficeClick()" />
              <span class="red-text"><strong>Do Not Show</strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Show Email Address</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Public'" (click)="publicEmailClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Members'" (click)="membersEmailClick()" />
              <span class="blue-text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group2" [checked]="showEmailAdr === 'Private'" (click)="privateEmailClick()" />
              <span class="red-text"><strong>Do Not Show</strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Allow Contact Us Messages </strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Public'" (click)="publicContactClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Members'"
                (click)="membersContactClick()" />
              <span class="blue-text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input class="with-gap" type="radio" name="group3" [checked]="allowContact === 'Private'"
                (click)="privateContactClick()" />
              <span class="red-text"><strong>Do Not Allow</strong></span>
            </label>
          </div>
        </div>
        <div class="row">
          <br />
        </div>
      </div>

      <div class="row card-panel white" *ngIf="appsInDepartment">
        <div class="row col s12">
          <p><strong>Assign to Application</strong></p>
          <div class="input-field col s12">
            <i class="material-icons prefix green-text">people_outline</i>
            <select multiple formControlName="applicationName" class="validate">
              <option value="" disabled selected>Please Select Application</option>
              <option *ngFor="let app of appsInDepartment" [value]="app">{{app}}</option>
            </select>
            <label for="applicationName">Applications </label>
          </div>
        </div>

        <div *ngIf="isManager" class="row card-panel white">
          <p><strong>Permissions</strong></p>
          <div formGroupName="permissions">
            <div class="col s12 m12 l6">
              <label>
                <input type="checkbox" formControlName="allowDeptCreate">
                <span>Allow Creating Department News</span>
              </label>
            </div>
            <div class="col s12 m12 l6">
              <label>
                <input type="checkbox" formControlName="allowAddEmp">
                <span>Allow Adding Employee</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" [disabled]="!employeeForm.valid" class="btn col s12 green white-text">
        Save Changes <i class="material-icons white-text right">send</i>
      </button>

      <button type="button" style="margin-top: 10px;" class="col s12 btn waves-effect"
        [ngStyle]="{'background-color': themeColor}" type="button" (click)="backToDeartmentEmployees()"><i
          class="material-icons left">arrow_back_ios</i> Back To Deartment Employees
      </button>
    </form>
  </div>
  <div class="col l1 "></div>
</div>