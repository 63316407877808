<div class="row container-pi">
  <h4 *ngIf="nationOfUSe !== 'Batc'" class="title-section1 title-member icon-texts"> <span
      class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
    </span>Departments List</h4>
  <h4 *ngIf="nationOfUSe === 'Batc'" class="title-section1 title-member icon-texts"> <span
      class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
    </span>Offices List</h4>

  <div class="col s12 l12 card-panel b-n-1 ">
    <div class="row" *ngIf="model">
      <div class="col s12 m5 l3" *ngFor="let dep of model">
        <div class="card white b-n-1" (click)="editDepartmentData(dep.id)"
          style="max-height:250px; min-height:250px;" [ngStyle]="{'border-color': themeColor}">
          <div class="card-info">
            <span>
              <div class="responsive-img image-data" *ngIf="dep.logo">
                <img [src]="dep.logo" class="circle responsive-img">
              </div>
              <div class="responsive-img image-none" *ngIf="!dep.logo">
                <div class="avatar-circle hide-on-med-and-up">
                  <span class="initials">{{getInitials(dep.name)}}</span>
                </div>
                <div class="avatar-circle2 hide-on-small-only show-on-medium-and-up">
                  <span class="initials2">{{getInitials(dep.name)}}</span>
                </div>
              </div>
            </span>
            <h1 class="title-section3 bold-600 p-m-1" [innerHTML]="dep.name"></h1>
            <div class="description-section middle-body-snippet bold-100 p-m-0">
              {{dep.mandate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>