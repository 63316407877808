<div class="col s12 ">
    <div class="body-h">

        <!-- <div class="row center-align">
            <h5><strong> New House</strong></h5>
        </div> -->

        <div class="header-setting b-m-2 ">
            <div class="col l12 m12 s12 p-b-2">
                <h4>New House</h4>
            </div>

        </div>
        <main class="departments-list StickyContent white row " style="padding-top:0%;">


            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div *ngIf="step1">
                <div class="row" *ngIf="members && communities">

                    <div class="col s12 l11">
                        <form [formGroup]="propertyForm" (ngSubmit)="submitForm(propertyForm.value)">
                            <div class="white">
                                <div class="row">
                                    <div class="input-field col s12 l6">
                                        <!-- <i class="material-icons prefix green-text" *ngIf="!ownerSelected">person</i>
                                        <i class="material-icons prefix green-text" *ngIf="ownerSelected">how_to_reg</i> -->
                                        <div class="label-input">Head of Household <span class="red-text">*</span></div>
                                        <input id="HoH" type="text" formControlName='HoH' class="validate"
                                            placeholder="Select Head of Household" autocomplete="off"
                                            style="text-transform: capitalize;" (focus)="showMembers()" />
                                        <!-- <label for="HoH">Head of Household<span class="red-text">*</span></label> -->

                                        <a class="waves-effect btn-flat  blue-text" style="cursor: pointer; display: flex; vertical-align: middle;"
                                        (click)="showMembers()" *ngIf="!ownerSelected"><i
                                            class="material-icons left">person_add_alt_1</i>Select</a>
                                    <a class="waves-effect btn-flat  red-text" style="cursor: pointer;"
                                        (click)="clearHoH()" *ngIf="ownerSelected"><i
                                            class="material-icons left">person_remove_alt_1</i>Clear</a>
                                    </div>

                                    <!-- <a class="waves-effect btn-flat col s2  blue-text" style="cursor: pointer; display: flex; vertical-align: middle;"
                                        (click)="showMembers()" *ngIf="!ownerSelected"><i
                                            class="material-icons left">person_add_alt_1</i>Select</a>
                                    <a class="waves-effect btn-flat col s2  red-text" style="cursor: pointer;"
                                        (click)="clearHoH()" *ngIf="ownerSelected"><i
                                            class="material-icons left">person_remove_alt_1</i>Clear</a> -->

                                    <div class="input-field col s12 m6">
                                        <!-- <i class="material-icons prefix blue-text">people</i> -->
                                        <div class="label-input">Community<span class="red-text">*</span></div>
                                        <select formControlName="community" class="browser-default">
                                            <option value="" disabled selected>Select</option>
                                            <option *ngFor="let c of communities" [value]="c.community">{{c.community}}
                                            </option>
                                        </select>
                                        <!-- <label for="community">Community<span class="red-text">*</span></label> -->
                                    </div>

                                    <div class="input-field col s12 card-section" >
                                        <div class="row col s12">
                                            <label class="col m3 s6">
                                                <input name="desc_type" type="radio" formControlName="desc_type" class="with-gap"
                                                    value="HN" (click)="onHouseNo()" />
                                                <span>House Number</span>
                                            </label>
                                            <label class="col m3 s6">
                                                <input name="desc_type" type="radio" formControlName="desc_type" class="with-gap"
                                                    value="LBP" (click)="onLotBlock()" />
                                                <span>LOT-BLOCK-PLAN</span>
                                            </label>
                                            <label class="col m3 s6">
                                                <input name="desc_type" type="radio" formControlName="desc_type" class="with-gap"
                                                    value="LLD" (click)="onLandDesc()" />
                                                <span>Legal Land Description</span>
                                            </label>
                                            <label class="col m3 s6">
                                                <input name="desc_type" type="radio" formControlName="desc_type" class="with-gap"
                                                    value="Other" (click)="onOther()" />
                                                <span>Other</span>
                                            </label>
                                        </div>
                                    

                                    <div class="input-field col s12 l6" *ngIf="houseNo">
                                        <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                        <div class="label-input"> House Number<span class="red-text">*</span></div>
                                        <input id="house_number" type="number" min="0" formControlName='house_number'
                                            (blur)="checkHouseNo($event.target.value)" class="validate"
                                            autocomplete="off" style="text-transform: capitalize;" placeholder="House Number"/>

                                        <!-- <label for="house_number">House Number<span class="red-text">*</span></label> -->
                                        <div *ngIf="(house_number.dirty || house_number.touched) && house_number.invalid"
                                            style="position: absolute;">
                                            <small *ngIf="house_number.errors.required" class="red-text"> House Number
                                                is
                                                required.
                                            </small>
                                            <small *ngIf="house_number.errors.inUse" class="red-text"> House Number
                                                already
                                                Exists.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col s12" *ngIf="lotBlock">
                                        <div class="input-field col s4">
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <div class="label-input">Block<span class="red-text">*</span></div>
                                            <input id="block" type="number" min="0" formControlName='block'
                                                class="validate" autocomplete="off" placeholder="Block"
                                                style="text-transform: capitalize;" />
                                            <!-- <label for="block">Block<span class="red-text">*</span></label> -->
                                            <div *ngIf="(block.dirty || block.touched) && block.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="block.errors.required" class="red-text"> House Block is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s8">
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                             <div class="label-input">Lot<span class="red-text">*</span></div>
                                            <input id="lot" type="number" min="0" formControlName='lot' class="validate"
                                                autocomplete="off" style="text-transform: capitalize;" placeholder="Lot" />
                                            <!-- <label for="lot">Lot<span class="red-text">*</span></label> -->
                                            <div *ngIf="(lot.dirty || lot.touched) && lot.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="lot.errors.required" class="red-text"> House Lot is
                                                    required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col s12" *ngIf="landDesc">
                                        <div class="input-field col s4">
                                            <div class="label-input">QRT<span class="red-text">*</span></div>
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <select formControlName="QRT" class="browser-default">
                                                <option value="NE">NE</option>
                                                <option value="NW">NW</option>
                                                <option value="SE">SE</option>
                                                <option value="SW">SW</option>
                                            </select>
                                            <!-- <label for="block">QRT<span class="red-text">*</span></label> -->
                                        </div>

                                        <div class="input-field col s8">
                                            <div class="label-input">Section<span class="red-text">*</span></div>
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <input id="section" type="text" formControlName='section' class="validate"
                                                autocomplete="off" style="text-transform: capitalize;" placeholder="Section" />
                                            <!-- <label for="section">Section<span class="red-text">*</span></label> -->
                                            <div *ngIf="(section.dirty || section.touched) && section.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="section.errors.required" class="red-text"> Section is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s4">
                                            <div class="label-input">Township<span class="red-text">*</span></div>
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <input id="township" type="text" formControlName='township' class="validate"
                                                autocomplete="off" style="text-transform: capitalize;" placeholder="Township"/>
                                            <!-- <label for="township">Township<span class="red-text">*</span></label> -->
                                            <div *ngIf="(township.dirty || township.touched) && township.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="township.errors.required" class="red-text"> Township is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s4">
                                            <div class="label-input">Range<span class="red-text">*</span></div>
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <input id="range" type="text" formControlName='range' class="validate"
                                                autocomplete="off" style="text-transform: capitalize;" placeholder="Range"/>
                                            <!-- <label for="range">Range<span class="red-text">*</span></label> -->
                                            <div *ngIf="(range.dirty || range.touched) && range.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="range.errors.required" class="red-text"> Range is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s4">
                                            <div class="label-input">Meridian<span class="red-text">*</span></div>
                                            <!-- <i class="material-icons prefix purple-text">tag</i> -->
                                            <input id="meridian" type="text" formControlName='meridian' class="validate"
                                                autocomplete="off" style="text-transform: capitalize;" placeholder="Meridian"/>
                                            <!-- <label for="meridian">Meridian<span class="red-text">*</span></label> -->
                                            <div *ngIf="(meridian.dirty || meridian.touched) && meridian.invalid"
                                                style="position: absolute;">
                                                <small *ngIf="meridian.errors.required" class="red-text"> Meridian is
                                                    required.
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-field col s12" *ngIf="other">
                                        <div class="label-input">Description</div>
                                        <!-- <i class="material-icons prefix purple-text">my_location</i> -->
                                        <input id="other_location" type="text" formControlName='other_location'
                                            class="validate" autocomplete="off" style="text-transform: capitalize;" placeholder="Description" />
                                        <!-- <label for="other_location">Description</label> -->
                                        <div *ngIf="(other_location.dirty || other_location.touched) && other_location.invalid"
                                            style="position: absolute;">
                                            <small *ngIf="other_location.errors.required" class="red-text"> Description
                                                is
                                                required.
                                            </small>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="input-field col s12 l6">
                                        <div class="label-input"> House in CMHC <span class="red-text">*</span></div>
                                        <!-- <div>House in CMHC <span class="red-text">*</span></div> -->
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input name="CMHC" type="radio" formControlName="CMHC" [value]="true" class="with-gap" />
                                                <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input name="CMHC" type="radio" formControlName="CMHC" class="with-gap"
                                                    [value]="false" />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 l6">
                                        <div class="label-input">Financed <span class="red-text">*</span></div>
                                        <!-- <span>Financed <span class="red-text">*</span></span> -->
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input name="financed" type="radio" formControlName="financed" class="with-gap"
                                                    [value]="true" />
                                                <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input name="financed" type="radio" formControlName="financed" class="with-gap"
                                                    [value]="false" />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>


                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Style</div>
                                        <!-- <i class="material-icons prefix blue-text">style</i> -->
                                        <select formControlName="style" class="browser-default">
                                            <option value="" disabled selected>Select</option>
                                            <option *ngFor="let type of houseTypes" [value]="type">{{type}}</option>
                                        </select>
                                        <!-- <label for="style">Style</label> -->
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Year</div>
                                        <!-- <i class="material-icons prefix green-text">event</i> -->
                                        <!-- <input id="year" type="text" [textMask]="{mask: yearMask}"
                                            (change)="yearChanged($event.target.value)" formControlName='year'
                                            class="validate" placeholder="yyyy" autocomplete="off" /> -->
                                            <input id="year" type="text" [inputMask]="yearMask" 
                                            (change)="yearChanged($event.target.value)" formControlName='year'
                                            class="validate" placeholder="yyyy" autocomplete="off" />
                                        <!-- <label for="year">Year</label> -->
                                        <div *ngIf="yearSelected" style="position: absolute;">
                                            <small class="green-text"> Age of House : {{ageOfHouse}}
                                            </small>
                                        </div>
                                    </div>


                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Number of Rooms</div>
                                        <!-- <i class="material-icons prefix purple-text">meeting_room</i> -->
                                        <input id="rooms" type="number" formControlName='rooms' class="validate"
                                            autocomplete="off" style="text-transform: capitalize;" placeholder="Number of Rooms" />
                                        <!-- <label for="rooms">Number of Rooms</label> -->
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Cost of Building ($)</div>
                                        <!-- <i class="material-icons prefix orange-text">paid</i> -->
                                        <input id="value" type="number" formControlName='value' class="validate"
                                            autocomplete="off" min="0" step="0.25" value="0.00" placeholder="0.00"
                                            (change)="setTwoNumberDecimal($event.target.value, 'costOfBuilding')"
                                            style="text-transform: capitalize;" />
                                        <!-- <label for="value">Cost of Building ($)</label> -->
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Size of House (sq ft)</div>
                                        <!-- <i class="material-icons prefix green-text">house</i> -->
                                        <input id="size" type="number" min="0" formControlName='size' class="validate"
                                            autocomplete="off" style="text-transform: capitalize;" placeholder="Size of House (sq ft)"/>
                                        <!-- <label for="size">Size of House (sq ft)</label> -->
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Primary Heat Source</div>
                                        <!-- <i class="material-icons prefix red-text">fireplace</i> -->
                                        <select formControlName="fuel_type" class="browser-default">
                                            <option value="" disabled selected>Select</option>
                                            <option *ngFor="let type of fuelType" [value]="type">{{type}}</option>
                                        </select>
                                        <!-- <label for="fuel_type">Primary Heat Source</label> -->
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <div class="label-input">Secondary Heat Source</div>
                                        <!-- <i class="material-icons prefix orange-text">fireplace</i> -->
                                        <select formControlName="fuel_type2" class="browser-default">
                                            <option value="" disabled selected>Select</option>
                                            <option *ngFor="let type of fuelType" [value]="type">{{type}}</option>
                                        </select>
                                        <!-- <label for="fuel_type">Secondary Heat Source</label> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row col s12">
                                <button type="button" style="margin-bottom: 15px;" (click)="getOccupants()"
                                    *ngIf="ownerSelected" class="col s12 btn waves-effect blue">
                                    <i class="material-icons right">groups</i> Occupants
                                </button>

                                <button type="submit" style="margin-bottom: 15px;"
                                    class="col s12 btn waves-effect green" [disabled]="!propertyForm.valid">
                                    <i class="material-icons right">save</i> Save House
                                </button>

                                <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()"><i
                                        class="material-icons left">house</i>
                                    Back To Home Page </a>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <div id="memberListModal" class="modal modal-fixed-footer white b-n-1" *ngIf="members">
                <div class="modal-content">
                    <div class="modal-title row">
                        <h4 class="col s11">Members</h4>
                        <span class="material-symbols-outlined col s1 right modal-close black-text">
                            close
                            </span>
                    </div>
                   
                    <form class="row" #filterForm="ngForm" style="padding: 10px;">
                        <div class="input-field col s12 m6">
                            <div class="label-input">First Name</div>
                            <input name="firstName" type="text" [(ngModel)]="filters.firstName" autocomplete="off"
                                (input)="filterMembers()" style="text-transform: capitalize;" class="validate" placeholder="First Name" />
                            <!-- <label for="firstName">First Name</label> -->
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Last Name</div>
                            <input name="lastName" type="text" [(ngModel)]="filters.lastName" autocomplete="off"
                                (input)="filterMembers()" style="text-transform: capitalize;"  class="validate" placeholder="Last Name"/>
                            <!-- <label for="lastName">Last Name</label> -->
                        </div>
                    </form>

                    <div class="row card">
                        <table class="highlight " *ngIf="members.length >0">
                            <thead style="background-color: rgb(238, 242, 249);">
                                <tr>
                                    <th>ID</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of filtredMembers? filtredMembers : members | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;"
                                    (click)="selectHoH(m)" style="cursor: pointer;">
                                    <td>{{m.applicantID}}</td>
                                    <td>{{m.GivenName}}</td>
                                    <td>{{m.LastName}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row center">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <a class="modal-close waves-effect waves-light btn-flat">Close</a>
                </div>
            </div>

            <div id="messageModel" class="modal" *ngIf="member">
                <div class="modal-content">
                    <h6><strong> Select HoH for the House </strong></h6>
                    <p>
                        {{member.GivenName}} {{member.LastName}} has a house in the system.
                        Please Make sure you have selected the right member.
                    </p>
                </div>
                <div class="modal-footer">
                    <a class="modal-close waves-effect waves-light btn-flat">Close</a>
                </div>
            </div>

            <div class="row" *ngIf="step2">
                <div class="row">
                    <h5>Occupants</h5>
                </div>
                <div class="row col s12 card white" *ngIf="familyMembers">
                    <div class="col s12 card-content">
                        <table class="highlight centered">
                            <thead>
                                <tr>
                                    <th>Memebr ID</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Classification</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="member">
                                    <td>{{member.applicantID}}</td>
                                    <td>{{member.GivenName}}</td>
                                    <td>{{member.LastName}}</td>
                                    <td>Head Of Household</td>
                                </tr>
                                <tr *ngFor="let fam of familyMembers; let i=index;">
                                    <td>{{fam.applicantIDOfChild}}</td>
                                    <td>{{fam.given_name}}</td>
                                    <td>{{fam.last_name}}</td>
                                    <td>{{fam.Relationship}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row col s12">
                    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToPreviousPage()"><i
                            class="material-icons left">arrow_back</i>
                        Back To previous Page </a>
                </div>
            </div>
        </main>
    </div>
</div>