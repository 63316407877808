<div class="row">
  <form [formGroup]="contentForm" (ngSubmit)="PostContent(contentForm)">

    <div class="col l6 s12 m12 editor-content">
      <!-- ------------------------------------------section -->
      <div class="row card white" *ngIf="contentType !== 'AboutUs'">
        <div class="card-content">
          <p class="sub-title-section bold-700 p-b-1">Share the News/Event With</p>
          <div class="col s6">
            <label>
              <input type="radio" value="Yes" name="group0" [checked]="isPublic" (click)="publicClicked()" class="with-gap" />
              <span><strong class="green-text">Public</strong></span>
            </label>
          </div>
          <div class="col s6">
            <label>
              <input type="radio" value="No" name="group0" [checked]="isPrivate" (click)="privateClicked()" class="with-gap" />
              <span><strong class="blue-text">Members Only</strong></span>
            </label>
          </div>
          <br />
          <a class="right btn" style="background-color: #3b5998;"
            *ngIf="actionType === 'Edit' && model.visibility === 'Public'" (click)="shareSocialMediaEdit()" 
            [ngStyle]="{'background-color': themeColor}">
            <i class="fa fa-share-alt right"></i>Share
          </a>
        </div>
      </div>

      <!-- ------------------------------------------ section ------------------------------------------>
      <div class="row card white" *ngIf="showFilters">
        <div class="card-content">
          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">wc</i>
            <select formControlName="genderFilter" (change)="genderChange($event.target.value)">
              <option value="" disabled selected>Select Gender</option>
              <option *ngFor="let g of genders" [value]="g">{{g}}</option>
            </select>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix orange-text">location_on</i>
            <select formControlName="communityFilter" (change)="communityChange($event.target.value)">
              <option value="" disabled selected>Select Community</option>
              <option *ngFor="let c of nationCommunities" [value]="c.community">{{c.community}}</option>
            </select>
          </div>

          <div class="input-field col s12 m6">
            <i class="material-icons prefix green-text">date_range</i>
            <input type="number" id="minAgeFilter" formControlName="minAgeFilter" autocomplete="off" min="1" max="99"
              (input)="minAgeChange($event.target.value)" />
            <label for="minAgeFilter">Min Age:</label>
          </div>

          <div class="input-field col s12 m6">
            <i class="material-icons prefix teal-text">date_range</i>
            <input type="number" id="maxAgeFilter" formControlName='maxAgeFilter' autocomplete="off" min="1" max="99"
              (input)="maxAgeChange($event.target.value)" />
            <label for="maxAgeFilter">Max Age: </label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">location_on</i>
            <select formControlName="residencyFilter" (change)="residencyChange($event.target.value)">
              <option value="" disabled selected>Select Residency</option>
              <option *ngFor="let r of residency" [value]="r">{{r}}</option>
            </select>
          </div>

          <div *ngIf="areasOfInterestsList">
            <div class="input-field col s12">
              <select multiple>
                <option value="" disabled selected></option>
                <option *ngFor="let a of areasOfInterestsList" [value]="a.id">{{a.name}}</option>
              </select>
              <label>Areas Of Interest</label>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------section -->
      <div class="row card white">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 black-text p-b-1">Title</p>
          <div class="input-field">
            <app-editor [title]=true [control]="cardTitle"></app-editor>
            <div *ngIf="(cardTitle.dirty || cardTitle.touched) && cardTitle.invalid">
              <small *ngIf="cardTitle.errors.required" class="red-text"> The Title is required. </small>
            </div>
          </div>

        </div>
      </div>

      <!-- ------------------------------------------section ------------------------------------------->
      <div class="row card white">
        <div class="card-content">
          <div class="input-field">
            <p class="sub-title-section4 bold-700 ">Description</p>
            <app-editor [control]="content"></app-editor>
            <div *ngIf="(content.dirty || content.touched) && content.invalid">
              <small *ngIf="content.errors.required" class="red-text"> The Content is required. </small>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------section -->
      <div class="row card white">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">Would you like to Upload an Image, video or PDF File From
            Your Device ?
          </p>
          <div class="row">
            <div class="col s6">
              <label>
                <input type="radio" value="Yes" name="group1" [checked]="hasMedia" (click)="yesClicked()" class="with-gap" />
                <span><strong class="green-text">Yes</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" value="No" name="group1" [checked]="hasNoMedia" (click)="noClicked()" class="with-gap" />
                <span><strong class="blue-text">No</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------section -->
      <div class="row card white" *ngIf="addMedia">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">Please Select a Media Type</p>
          <div class="row">
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isPhoto" name="group2" (click)="photoClick()" class="with-gap" />
                <span><strong>Photo</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isVideo" name="group2" (click)="youTubeClick()" class="with-gap" />
                <span><strong>YouTube Video</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isUpload" name="group2" (click)="uploadClick()" class="with-gap" />
                <span><strong>Upload Video</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isPdf" name="group2" (click)="uploadPdf()" class="with-gap" />
                <span><strong>PDF File</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------Link/URL ---------------------------------------->
      <div class="row card white" *ngIf="model.type === 'Link' && addMedia">
        <div class="card-content">
          <div class="row col s12">
            <p class="sub-title-section4 bold-700 p-b-1">Please Copy/Paste The Link Or Url</p>
            <div class="input-field">
              <i class="material-icons prefix blue-text">link</i>
              <input id="videoLink" type="text" formControlName='videoLink' class='validate' autocomplete="off" />
              <label for="videoLink">URL </label>
              <div *ngIf="(videoLink.dirty || videoLink.touched) && videoLink.invalid">
                <small *ngIf="videoLink.errors.required" class="red-text">The Link/URL is required. </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------ photos -------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'Photo' && addMedia">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 p-b-1">Please Select A Logo From Your Library</p>

          <div class="card col s12 m6 l5" *ngIf="!isMobile " (change)="handleFileInput($event.target.files)">
            <div class="center-align">
              <input type="file" formControlName="imgLink" accept="image/*"
                (change)="handleFileInput($event.target.files)" />
              <i class="fa fa-cloud-upload p-t-2"></i>
              <h4 class="label-input p-b-2  bold-200">
                Drop & Drop file here or Click to Upload</h4>
            </div>

            <p class="red-text" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>

          <div class="row col s12" *ngIf="isMobile">
            <p class="sub-title-section4 bold-700 p-b-1">Please Select A Logo From Your Library Mobile version</p>
            <div class="file-field input-field">
              <div class="btn white-text" [ngStyle]="{'background-color': themeColor}"
                (click)='handleMobilePhotoInput()'>Browse
              </div>
            </div>
            <p class="red-text" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>

          <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="uploadedImage">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>
            <img class="materialboxed responsive-img" [src]="uploadedImage" width="100%" height="auto" />
          </div>
        </div>
      </div>

      <!-- ------------------------------------------upload video ----------------------------------------->
      <div class="row card white" *ngIf="model.type === 'uploadVideo' && addMedia ">
        <div class="card-content">
          <p class="sub-title-section bold-700 p-b-1">Please Select A Video From Your Library</p>
          <div class="card col l5 s12 m6" (change)="handleVideoInput($event.target.files)">
            <div class="center-align">
              <input type="file" formControlName="imgLink" accept="video/*"
                (change)="handleVideoInput($event.target.files)" />
              <i class="fa fa-cloud-upload p-t-3"></i>
              <h4 class="label-input p-b-3  bold-200">
                Drop & Drop file here or Click to Upload</h4>
            </div>
          </div>

          <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="videoUploaded || model.videoLink">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>
            <div class="center center-align">
              <video controls class="uploadedVideo" width="100%" height="auto" *ngIf="videoUploaded">
                <source id="video_here">
                Your browser does not support HTML5 video.
              </video>

              <video controls width="100%" height="auto" *ngIf="model.videoLink">
                <source id="video_here" [src]="model.videoLink" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------youtube videos ------------------------------------------>
      <div class="row card button-included white" *ngIf="model.type === 'Video' && addMedia">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 p-b-1">Please Copy/Paste The Video Link From YouTube</p>
          <div class="row col s12 m6 l6">
            <div class="input-field">
              <div class="label-input">YouTube Video Link </div>
              <input id="videoLink" type="text" formControlName='videoLink' class='validate' autocomplete="off"
                placeholder="YouTube Video Link" (input)="youtubeLink($event)" />
              <div *ngIf="(videoLink.dirty || videoLink.touched) && videoLink.invalid">
                <small *ngIf="videoLink.errors.required" class="red-text">The Video Link is required. </small>
              </div>
            </div>
          </div>

          <div class="col s12 m6 l5 pus img-wrap">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>
            <div class="video-container">
              <div [innerHTML]="iframehtml"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------PDF ----------------------------------------->
      <div class="row card white" *ngIf="model.type === 'PDF' && addMedia">
        <div class="card-content">
          <div class="row col s12">
            <p class="sub-title-section4 bold-700 p-b-1">Please Select A PDF File From Your Library</p>
            <div class="file-field input-field">
              <div class="btn white-text" [ngStyle]="{'background-color': themeColor}">
                <span>Browse</span>
                <input type="file" formControlName="imgLink" accept="file"
                  (change)="handlePDFInput($event.target.files)" />
              </div>
              <div class="file-path-wrapper">
                <input formControlName="imgLink" class="file-path validate" type="text"
                  placeholder="Select PDF File From Library" />
              </div>
            </div>
            <p class="red-text" *ngIf="pdfErrorMsg"> <strong><small>{{pdfErrorMsg}}</small></strong> </p>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------section ----------------------------------------->
      <div class="row card white" *ngIf="showDate">
        <div class="card-content">
          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">calendar_today</i>
            <input id="expiryDate" type="text" class="datepicker" (change)="dateChange()" maxlength="49"
              formControlName='expiryDate' autocomplete="off" />
            <label for="expiryDate">Expiry Date <span class="red-text">*</span></label>
            <div *ngIf="(expiryDate.dirty || expiryDate.touched) && expiryDate.invalid">
              <small *ngIf="expiryDate.errors.required" class="red-text"> The Expiry Date is required. </small>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------------------section --------------------------------------->

      <div class="row card grey lighten-3 hide-on-med-and-up">
        <div class="col s10 push-s1">
          <h6 class="title-section3 bold-900 center"><strong><i class="material-icons">preview</i>
              Preview</strong>
          </h6>
          <div class="row card">
            <div class="card-content">
              <div class="row">
               
                <h6 class="center" [innerHTML]="cardTitle.value"></h6>
                <div class="section"></div>
                <div *ngIf="model.type === 'Photo'">
                  <div class="center center-align" *ngIf="actionType === 'Add' && base64Image">
                    <img class="responsive-img" [src]="base64Image" height="50%" width="50%" alt="New image" />
                  </div>

                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.imgLink">
                    <img class="responsive-img" [src]="model.imgLink" height="50%" width="50%" alt="Edit image"/>
                  </div>
                </div>

                <div class="row" *ngIf="model.type === 'Video'">
                  <p>iframehtml</p>
                  <div class="video-container">
                    <div [innerHTML]="iframehtml"></div>
                  </div>
                </div>

                <div *ngIf="model.type === 'uploadVideo'">
                  <div class="center center-align" *ngIf="actionType === 'Add'">
                    <video controls class="uploadedVideo" width="100%" height="auto">
                      <source id="video_here">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>

                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.videoLink">
                    <video width="320" height="240" controls>
                      <source [src]="model.videoLink" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div *ngIf="model.type === 'PDF'">
                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.videoLink">
                    <iframe [src]="model.videoLink | safeUrl" width="100%" height="500px"></iframe>
                  </div>
                </div>

                <div class="row col s12">
                  <span [innerHTML]="content.value | noSanitize"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------------------section -->

      <div class="row p-b-6 p-t-6">

        <a class="col  l3 s9 push-s1 btn back-button white black-text hide-on-med-and-down"
          [ngClass]="'btn ' + buttonType" (click)="backClicked()" style="margin: 15px;  margin-right:90px;">
          Back
        </a>

        <a class="col  l3 s9 push-s1 btn back1-button white hide-on-large-only" [ngClass]="'btn ' + buttonType"
          (click)="backClicked()" style="margin: 15px;  margin-right:90px;">
          Back
        </a>
        <!-- || (expirable !== true && expirable !== false -->
        <button [disabled]="!contentForm.valid || !model.visibility || (addMedia !== true && addMedia !== false )  "
          *ngIf="actionType === 'Add' && (contentType === 'News' || contentType === 'DepNews' )"
          class="col  l3 s8 push-l1 push-s1  btn publish-button modal-trigger" [ngClass]="'btn ' + buttonType"
          data-target="modal1" style="margin: 15px;">
          <!-- Publish {{msgName}} -->
          Publish
        </button>

        <button [disabled]="!contentForm.valid" *ngIf="actionType === 'Add' && contentType === 'AboutUs'"
          class="col  l3 s9 push-l1 push-s1   btn publish-button  modal-trigger" [ngClass]="'btn ' + buttonType"
          data-target="modal1" style="margin: 15px; ">
          Publish
        </button>

        <button [disabled]="!contentForm.valid || !model.visibility"
          *ngIf="actionType === 'Add' && contentType === 'Event'"
          class="col  l3 s9 push-s1  btn add-button  modal-trigger" [ngClass]="'btn ' + buttonType" data-target="modal1"
          style="margin-bottom: 15px;">
          Create
        </button>

        <button [disabled]="!contentForm.valid || loggedUser.isDemo" *ngIf="actionType === 'Edit'"
          class="col  l3 s9 push-s1   btn update-button modal-trigger" [ngClass]="'btn ' + buttonType"
          data-target="modal1" style="margin: 15px;">
          Update
        </button>

        <button type="button" [disabled]="loggedUser.isDemo"
          *ngIf="model.published && contentType !== 'AboutUs' && actionType === 'Edit'"
          class="col  l3 s9 push-s1  btn edit-button modal-trigger" [ngClass]="'btn ' + buttonType" data-target="modal2"
          style="margin: 15px;">
          Archive
        </button>

        <button type="button" [disabled]="loggedUser.isDemo"
          *ngIf="model.unPublished && contentType !== 'AboutUs' && actionType === 'Edit'  "
          class="col   l3 s9 push-s1 btn edit-button modal-trigger" [ngClass]="'btn ' + buttonType" data-target="modal3"
          style="margin: 15px;">
          Unarchive
        </button>


        <button [disabled]="loggedUser.isDemo" *ngIf="actionType === 'Edit'"
          class="col  l3 s9  push-s1 btn delete-button  modal-trigger" [ngClass]="'btn ' + buttonType"
          data-target="modal4" style="margin: 15px; text-align: center;">
          Delete
        </button>

      </div>
    </div>
    <!-- ----------------------------------------- preview --------------------------------->
    <div id="demoItem2" class="col l6 s12 m12 preview-content hide-on-med-and-down">

      <div class="preview-wrapper">

        <input type="radio" name="size" class="size-select size1 with-gap" checked id="ss1"><label for="ss1"
          title="Small (eg phone)">
          <i class="material-symbols-outlined black-text">
            smartphone
          </i>
        </label>

        <input type="radio" name="size" class="size-select size2 with-gap " id="ss2"><label for="ss2" title="Medium (eg tablet)">
          <i class="material-symbols-outlined black-text">
            tablet_android
          </i>
        </label>

        <input type="radio" name="size" class="size-select size3 with-gap " id="ss3"><label for="ss3" title="Large (eg desktop)">
          <i class="material-symbols-outlined black-text">
            airplay
          </i>
        </label>

        <div class="col s12" id="viewer" name="Viewer">
         
          <div class="row card ">
            <div class="card-content">

              <div class="row">
                <h6 class="center" [innerHTML]="cardTitle.value"></h6>

                <div class="section"></div>
                <div *ngIf="model.type === 'Photo'">
                  <div class="center center-align" *ngIf="actionType === 'Add' && base64Image">
                    <img class="responsive-img" [src]="base64Image" height="100%" width="100%" alt="Add image"/>
                  </div>

                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.imgLink">
                    <img class="responsive-img" [src]="model.imgLink" height="100%" width="100%" alt="Edit image"/>
                    <!-- <img class="responsive-img" [src]="oldUrl" height="150" width="150" /> -->
                  </div>
                </div>

                <div class="row" *ngIf="model.type === 'Video'">
                  <div class="video-container">
                    <div [innerHTML]="iframehtml"></div>
                  </div>
                </div>

                <div *ngIf="model.type === 'uploadVideo'">
                  <div class="center center-align" *ngIf="actionType === 'Add'">
                    <video controls class="uploadedVideo" width="100%" height="auto">
                      <source id="video_here">
                      Your browser does not support HTML5 video.
                    </video>
                  </div>

                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.videoLink">
                    <video width="320" height="240" controls>
                      <source [src]="model.videoLink" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>

                <div *ngIf="model.type === 'PDF'">
                  <div class="center center-align" *ngIf="actionType === 'Edit' && model.videoLink">
                    <iframe [src]="model.videoLink | safeUrl" width="100%" height="500px"></iframe>
                  </div>
                </div>

                <div class="row col s12">
                  <span [innerHTML]="content.value | noSanitize"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------------------------- modal ------------------------------------>

    <div id="modal1" class="modal b-n-1">
      <div class="modal-content">

        <h4 class="col s11" *ngIf="actionType === 'Add'">Publish {{msgName}}</h4>
        <h4 class="col s11" *ngIf="actionType === 'Edit'">Update {{msgName}}</h4>
        <i class="material-icons right modal-close col s1">close</i><br />
       
        <div class="valign-wrapper card-panel blue lighten-5 row col s12">
          <i class="material-icons left blue-text" style="font-size: 40px; font-weight: 100;">
            cloud_upload
          </i>
          <div class="modal-delete">
            <h6 *ngIf="actionType === 'Add'">Publish {{msgName}}</h6>
            <h6 *ngIf="actionType === 'Edit'">Update {{msgName}}</h6>

            <h6 *ngIf="actionType === 'Add'"> Are you sure you want to publish this new {{msgName}} ?</h6>
            <h6 *ngIf="actionType === 'Edit'"> Are you sure you want to update this {{msgName}} ?</h6>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" *ngIf="actionType === 'Add'" class="btn waves-effect green" style="margin-right: 15px;"
          [ngClass]="'btn ' + buttonType"> Publish
        </button>
        <button type="submit" *ngIf="actionType === 'Edit'" class="btn update-button" style="margin-right: 15px;"
          [ngClass]="'btn ' + buttonType"> Update
        </button>
      </div>
    </div>

    <div id="modal2" class="modal b-n-1">
      <div class="modal-content">
        <h4 class="col s11">Archive {{msgName}}</h4>
        <i class="material-icons right modal-close col s1">close</i>

        <p>Are you sure you want to Archive this {{msgName}} ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="UnpublishContent()" class="btn waves-effect orange white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">archive</i> Archive
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <div id="modal3" class="modal b-n-1 red">
      <div class="modal-content">
        <h4>Unarchive {{msgName}}</h4>
        <i class="material-icons right modal-close col s1">close</i>
       
        <div class="valign-wrapper card-panel blue lighten-5 row col s12">
          <i class="material-icons left red-text" style="font-size: 40px; font-weight: 100;">
            update
          </i>
          <div class="modal-delete">
            <h6>Unarchive {{msgName}}? </h6>
            <h6 class="red-text"><strong>Are you sure you want to Unarchive this {{msgName}} ?</strong></h6>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" (click)="publishContent()" class="btn waves-effect blue white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">unarchive</i> Unarchive
        </button>
      </div>
    </div>

    <div id="modal4" class="modal b-n-1 ">
      <div class="modal-content">
        <h4 class="col s11">Delete {{msgName}}</h4>
        <i class="material-icons right modal-close col s1">close</i>

        <div class="valign-wrapper card-panel red lighten-5 row col s12">
          <i class="material-icons left red-text" style="font-size: 40px; font-weight: 100;">
            warning_amber
          </i>
          <div class="modal-delete">
            <h6>Delete {{msgName}}? </h6>
            <h6 class="red-text"><strong>Are you sure you want to Delete this {{msgName}} ?</strong></h6>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" (click)="DeleteContent()" class="modal-close btn delete-button"
            style="margin-right: 15px;" [ngClass]="'btn ' + buttonType">
            Delete
          </button>
        </div>
      </div>
    </div>

    <div id="shareModal" class="modal b-n-1">
      <div class="modal-content">
        <i class="material-icons modal-close right">close</i>
        <!-- Facebook -->
        <a *ngIf="contentType === 'News'" class="waves-effect btn share-button col s12"
          style="background-color: #3b5998;" (click)="postToFacebook()">
          <i class="fa fa-facebook prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'Event'" class="waves-effect btn share-button col s12"
          style="background-color: #3b5998;" (click)="postEventToFacebook()">
          <i class="fa fa-facebook prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'DepNews'" class="waves-effect btn share-button col s12"
          style="background-color: #3b5998;" (click)="postDepartmentNewsToFacebook()">
          <i class="fa fa-facebook prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'AboutUs'" class="waves-effect btn share-button col s12"
          style="background-color: #3b5998;" (click)="postAboutUsToFacebook()">
          <i class="fa fa-facebook prefix "></i>
          Share
        </a>

        <!-- Twitter -->
        <br />
        <a *ngIf="contentType === 'News'" class="waves-effect btn share-button twitter-share-button col s12"
          [href]="twitterURL+postLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-twitter prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'Event'" class="waves-effect btn share-button twitter-share-button col s12"
          [href]="twitterURL+postEventLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-twitter prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'DepNews'" class="waves-effect btn share-button twitter-share-button col s12"
          [href]="twitterURL+postDeptNewsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-twitter prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'AboutUs'" class="waves-effect btn share-button twitter-share-button col s12"
          [href]="twitterURL+aboutUsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-twitter prefix "></i>
          Share
        </a>

        <!-- LinkedIn -->
        <br />
        <a *ngIf="contentType === 'News'" class="waves-effect btn share-button linkedin-share-button col s12"
          [href]="linkedinURL+postLinkLinkedIn" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-linkedin prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'Event'" class="waves-effect btn share-button linkedin-share-button col s12"
          [href]="linkedinURL+postEventLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-linkedin prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'DepNews'" class="waves-effect btn share-button linkedin-share-button col s12"
          [href]="linkedinURL+postDeptNewsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-linkedin prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'AboutUs'" class="waves-effect btn share-button linkedin-share-button col s12"
          [href]="linkedinURL+aboutUsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
          <i class="fa fa-linkedin prefix "></i>
          Share
        </a>

        <!-- Pintrest -->
        <br />
        <a *ngIf="contentType === 'News' && (model.imgLink && model.imgLink !== '') "
          [href]="pinterestURL+postLinkLinkedIn+'&amp;media='+model.imgLink+'&amp;description=New%20Nation%20News'"
          target='_blank' class="waves-effect btn share-button linkedin-share-button pin-it-button col s12"
          count-layout="horizontal" style="background-color: #E60023;">
          <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
          <i class="fa fa-pinterest prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'Event' && (model.imgLink && model.imgLink !== '') "
          [href]="pinterestURL+postEventLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20Nation%20Events'"
          target='_blank' class="waves-effect btn share-button linkedin-share-button pin-it-button col s12"
          count-layout="horizontal" style="background-color: #E60023;">
          <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
          <i class="fa fa-pinterest prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'DepNews' && (model.imgLink && model.imgLink !== '') "
          [href]="pinterestURL+postDeptNewsLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20About%20Nation%20Post'"
          target='_blank' class="waves-effect btn share-button linkedin-share-button pin-it-button col s12"
          count-layout="horizontal" style="background-color: #E60023;">
          <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
          <i class="fa fa-pinterest prefix "></i>
          Share
        </a>
        <br />
        <a *ngIf="contentType === 'AboutUs' && (model.imgLink && model.imgLink !== '') "
          [href]="pinterestURL+aboutUsLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20Department%20Nation%20News'"
          target='_blank' class="waves-effect btn share-button linkedin-share-button pin-it-button col s12"
          count-layout="horizontal" style="background-color: #E60023;">
          <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
          <i class="fa fa-pinterest prefix "></i>
          Share
        </a>
      </div>
    </div>
  </form>
</div>